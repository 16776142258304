import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { ListResult, Service } from 'common/api/v1/types'

import { ServicesRequestParams } from '../../api/nm-types'
import { ThunkApi } from '../../store'
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from './notificationActions'
import { withDefaultPagination } from '../../utils'
import routes from '../../utils/routes'

export const updateService = createAsyncThunk<void, Service, ThunkApi>(
  'service/updateService',
  async (service, { dispatch, extra: { api, history } }) => {
    try {
      await api.serviceApi.updateService(service)
      history().push(routes.services())
      dispatch(enqueueSuccessSnackbar(`Edited Service: ${service.purpose}`))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'update service' }))
      throw err
    }
  },
)

export const getServices = createAsyncThunk<ListResult<Service>, Partial<ServicesRequestParams>, ThunkApi>(
  'service/getServices',
  async (params, { dispatch, extra: { api } }) => {
    try {
      return await api.serviceApi.getServices(withDefaultPagination(params))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch services' }))
      throw err
    }
  },
)

export const getService = createAsyncThunk<Service, Service['id'], ThunkApi>(
  'service/getService',
  async (id, { extra: { api } }) => {
    return await api.serviceApi.getService(id)
  },
)

export const clearService = createAction('service/clearService')
export const clearServices = createAction('service/clearServices')
