import { useEffect } from 'react'
import { FormikProps } from 'formik'
import { get } from 'lodash'

import { Address, ApplianceType, Output, ZixiLinkMode, ZixiMode, ZixiOutputPort } from 'common/api/v1/types'

import { Checkbox, Select, TextInput } from '../../../../../common/Form'
import { createDefaultFiledValues, makeAddressOptions } from '../../../../../../utils'
import LinksArray, { LinkFields, linkSetDefault, ZixiPushOutput } from './LinksArray'

export enum ZixiFields {
  zixiMode = 'zixiMode',
  localPort = 'localPort',
  streamId = 'streamId',
  password = 'password',
  fecLatency = 'fecLatency',
  optimizeFec = 'optimizeFec',
  maxFecOverhead = 'maxFecOverhead',
  pullPort = 'pullPort',
  retransmitBuf = 'retransmitBuf',
  maxBitrateMbps = 'maxBitrateMbps',
  unrecoveredPacketsDetection = 'unrecoveredPacketsDetection',
  unrecoveredPacketsThreshold = 'unrecoveredPacketsThreshold',
  linkMode = 'linkMode',
  linkSet1 = 'linkSet1',
  linkSet2 = 'linkSet2',
}
export const zixiDefaults = createDefaultFiledValues(
  Object.keys(ZixiFields),
  [ZixiFields.unrecoveredPacketsDetection, ZixiFields.optimizeFec],
  {
    [ZixiFields.retransmitBuf]: 500,
    [ZixiFields.maxBitrateMbps]: 20,
    [ZixiFields.fecLatency]: 30,
    [ZixiFields.linkMode]: ZixiLinkMode.single,
    [ZixiFields.maxFecOverhead]: 0,
    [ZixiFields.linkSet1]: [{ ...linkSetDefault }],
    [ZixiFields.linkSet2]: [{ ...linkSetDefault }],
  },
)
export const getZixiFieldsToSave = (port: ZixiOutputPort) => [
  ZixiFields.zixiMode,
  ZixiFields.streamId,
  ZixiFields.password,
  ZixiFields.unrecoveredPacketsDetection,
  ...(port.unrecoveredPacketsDetection ? [ZixiFields.unrecoveredPacketsThreshold] : []),
  ...(port.zixiMode === ZixiMode.push
    ? [
        ZixiFields.fecLatency,
        ZixiFields.maxBitrateMbps,
        ZixiFields.optimizeFec,
        ZixiFields.maxFecOverhead,
        ZixiFields.retransmitBuf,
        ZixiFields.linkMode,
        ZixiFields.linkSet1,
        ...(port.linkMode === ZixiLinkMode.single ? [] : [ZixiFields.linkSet2]),
      ]
    : []),
]

interface ZixiFormProps {
  form: FormikProps<Output>
  addresses: Array<Address>
  namePrefix: string
  applianceType: ApplianceType
  allocatedPort?: { addresses: Address[]; portNumber: number }
}
const ZixiForm = ({ form, addresses, namePrefix, applianceType, allocatedPort }: ZixiFormProps) => {
  const { values } = form
  const port = get(values, namePrefix) as ZixiOutputPort
  const isCoreAppliance = applianceType == ApplianceType.core
  const localAddressSelector = `${namePrefix}.${ZixiFields.linkSet1}[0].${LinkFields.localIp}`
  const disableLocalPortFields = !!allocatedPort

  useEffect(() => {
    if (allocatedPort) {
      const allocatedAddress = allocatedPort.addresses[0].address
      if (get(form.values, localAddressSelector) != allocatedAddress) {
        form.setFieldValue(localAddressSelector, allocatedAddress)
      }
    }
  }, [allocatedPort, form])

  return (
    <>
      <Select
        name={`${namePrefix}.${ZixiFields.zixiMode}`}
        label="Connection mode"
        options={isCoreAppliance ? [ZixiMode.push] : Object.values(ZixiMode)}
        required
      />

      {port.zixiMode === ZixiMode.push && (
        <>
          <Select
            name={`${namePrefix}.${ZixiFields.linkMode}`}
            label="Link mode"
            options={isCoreAppliance ? [ZixiLinkMode.single] : Object.values(ZixiLinkMode)}
            required
          />

          {port.linkMode === ZixiLinkMode.single && (
            <>
              <Select
                name={localAddressSelector}
                label="Local address"
                options={makeAddressOptions(get(values, localAddressSelector), addresses)}
                disabled={disableLocalPortFields}
                newLine
                required
                validators={{
                  addressIn: { addresses },
                }}
              />
              <TextInput
                name={`${namePrefix}.${ZixiFields.linkSet1}[0].${LinkFields.remoteIp}`}
                label="Remote host"
                required
                validators={{ ip: {} }}
              />
              <TextInput
                name={`${namePrefix}.${ZixiFields.linkSet1}[0].${LinkFields.remotePort}`}
                label="Remote UDP port"
                type="number"
                required
                noNegative
                validators={{ port: {} }}
              />
            </>
          )}
          {port.linkMode !== ZixiLinkMode.single && (
            <LinksArray
              form={(form as unknown) as FormikProps<ZixiPushOutput>}
              addresses={addresses}
              namePrefix={namePrefix}
            />
          )}

          <TextInput
            name={`${namePrefix}.${ZixiFields.retransmitBuf}`}
            label="Retransmission buffer (ms)"
            type="number"
            required
            noNegative
            newLine
            validators={{
              number: {
                lessThanOrEqualTo: 30000,
                message: 'Must be no more than 30000',
              },
            }}
          />
          {isCoreAppliance && (
            <TextInput
              name={`${namePrefix}.${ZixiFields.maxBitrateMbps}`}
              label="Max bitrate (Mbps)"
              tooltip={'Recommended: double the expected bitrate of the stream'}
              type="number"
              required
              noNegative
              validators={{
                number: {
                  greaterThanOrEqualTo: 1,
                  message: 'Must be greater than 0',
                },
              }}
            />
          )}
          <TextInput
            name={`${namePrefix}.${ZixiFields.maxFecOverhead}`}
            label="Max fec overhead (%)"
            type="number"
            noNegative
            validators={{
              number: {
                lessThanOrEqualTo: 100,
                message: 'Must be no more than 100',
              },
            }}
          />
          <TextInput
            name={`${namePrefix}.${ZixiFields.fecLatency}`}
            label="Fec latency (ms)"
            type="number"
            noNegative
            validators={{
              number: {
                lessThanOrEqualTo: Math.pow(2, 32) - 1,
                message: `Must be no more than ${Math.pow(2, 32) - 1}`,
              },
            }}
          />
          {!isCoreAppliance && <Checkbox name={`${namePrefix}.${ZixiFields.optimizeFec}`} label="Optimize fec" />}
        </>
      )}

      <TextInput name={`${namePrefix}.${ZixiFields.streamId}`} label="Stream id" required newLine />
      <TextInput name={`${namePrefix}.${ZixiFields.password}`} label="Password" />
      {!isCoreAppliance && (
        <Checkbox
          name={`${namePrefix}.${ZixiFields.unrecoveredPacketsDetection}`}
          label="Unrecovered packets alarm"
          tooltip="Turn detection of unrecovered packets on or off, including associated alarm. Threshold is in packets per minute. Generates an alarm if unrecovered packets per minute is higher than threshold."
          newLine
        />
      )}
      {port.unrecoveredPacketsDetection && (
        <TextInput
          name={`${namePrefix}.${ZixiFields.unrecoveredPacketsThreshold}`}
          label="Threshold (packets/min)"
          type="number"
          noNegative
          validators={{
            number: {
              lessThanOrEqualTo: Math.pow(2, 64),
              message: `Must be no more than ${Math.pow(2, 64)}`,
            },
          }}
        />
      )}
    </>
  )
}

export default ZixiForm
