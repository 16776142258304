import { createSlice } from '@reduxjs/toolkit'

import { KubernetesNode, KubernetesNodeResourceUsage, ListResultError } from 'common/api/v1/types'
import { logoutUser } from '../actions/userActions'
import {
  clearKubernetesNode,
  clearKubernetesNodes,
  getKubernetesNode,
  getKubernetesNodes,
  updateKubernetesNode,
  getResourceUsage,
} from '../actions/k8sActions'

interface State {
  kubernetesNodes: Array<KubernetesNode>
  kubernetesNode: KubernetesNode | undefined
  resourceUsage: KubernetesNodeResourceUsage[]
  formErrors?: Array<{ name: string; reason: string }>
  loading: boolean
  loadingResourceUsage: boolean
  saving: boolean
  total: number
  errors?: ListResultError[]
}

const initialStateNodes: State = {
  kubernetesNodes: [],
  kubernetesNode: undefined,
  resourceUsage: [],
  loading: false,
  loadingResourceUsage: false,
  saving: false,
  total: 0,
}

const nodesSlice = createSlice({
  name: 'kubernetesNodes',
  initialState: initialStateNodes,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getKubernetesNodes.pending, (state): State => ({ ...state, loading: true }))
      .addCase(getKubernetesNodes.rejected, (state): State => ({ ...state, loading: false }))
      .addCase(
        getKubernetesNodes.fulfilled,
        (state, { payload: { items: kubernetesNodes, total, errors } }): State => ({
          ...state,
          kubernetesNodes: kubernetesNodes,
          loading: false,
          total,
          errors,
        }),
      )

      .addCase(getKubernetesNode.pending, (state): State => ({ ...state, loading: true }))
      .addCase(getKubernetesNode.rejected, (state): State => ({ ...state, loading: false }))
      .addCase(
        getKubernetesNode.fulfilled,
        (state, { payload: kubernetesNode }): State => ({
          ...state,
          kubernetesNode,
          loading: false,
        }),
      )

      .addCase(updateKubernetesNode.pending, (state): State => ({ ...state, loading: true }))
      .addCase(updateKubernetesNode.rejected, (state): State => ({ ...state, loading: false }))
      .addCase(
        updateKubernetesNode.fulfilled,
        (state, { payload: kubernetesNode }): State => ({
          ...state,
          kubernetesNode,
          formErrors: undefined,
        }),
      )

      .addCase(clearKubernetesNode, (state): State => ({ ...state, kubernetesNode: undefined, formErrors: undefined }))
      .addCase(clearKubernetesNodes, (state): State => ({ ...state, kubernetesNodes: [], formErrors: undefined }))

      .addCase(logoutUser.fulfilled, (): State => initialStateNodes)

      .addCase(getResourceUsage.pending, (state): State => ({ ...state, loadingResourceUsage: true }))
      .addCase(getResourceUsage.rejected, (state): State => ({ ...state, loadingResourceUsage: false }))
      .addCase(
        getResourceUsage.fulfilled,
        (state, { payload: resourceUsage }): State => ({
          ...state,
          resourceUsage,
          loadingResourceUsage: false,
        }),
      )
  },
})

export default nodesSlice.reducer
