import { useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import Toolbar from '@material-ui/core/Toolbar'

import SearchInput from '../../../common/SearchInput'
import Pendable from '../../../common/Pendable'
import { getOutputsWithLists } from '../../../../redux/actions/outputsActions'
import { useDebouncedFilter, useOutputsWithListsSelector } from '../../../../utils'
import { AppDispatch, GlobalState } from '../../../../store'

import Item from './Item'
import { useStyles } from './Item/style'
import { Pagination } from '../../../common/Table/Pagination'
import useWindowDimensions from '../../../../utils/hooks'

const LOCALE_STORAGE_PER_PAGE_KEY = 'edge:sm:outputsPerPage'

const Outputs = () => {
  const classes = useStyles()
  const [filter, setFilter] = useState('')
  const [pageNumber, setPageNumber] = useState('0')
  const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem(LOCALE_STORAGE_PER_PAGE_KEY) || '10')
  const debouncedFilter = useDebouncedFilter(filter)
  const params = {
    pageNumber,
    rowsPerPage,
    filter: debouncedFilter || undefined,
  }
  const { outputsWithLists, total, loading, saving } = useOutputsWithListsSelector(params)
  const { dialogSaving: listSending } = useSelector(({ inputsReducer }: GlobalState) => inputsReducer, shallowEqual)
  const dispatch = useDispatch<AppDispatch>()
  const { width } = useWindowDimensions()
  const hidePerPagePaginationOption = width < 1200

  useEffect(() => {
    if (saving || listSending) {
      return
    }

    dispatch(getOutputsWithLists(params))
  }, [saving, listSending, pageNumber, rowsPerPage, debouncedFilter])

  return (
    <div className={classes.contentList}>
      <Toolbar disableGutters className={classes.toolbar} id="outputs-toolbar">
        <SearchInput
          onChange={input => {
            setPageNumber('0')
            setFilter(input)
          }}
        />
      </Toolbar>
      <div id="outputs-list">
        <Pendable pending={loading || saving || listSending} cover id="outputs">
          <Pagination
            total={total}
            page={pageNumber}
            perPage={rowsPerPage}
            changePageCallback={setPageNumber}
            changeRowsPerPageCallback={rows => {
              localStorage.setItem(LOCALE_STORAGE_PER_PAGE_KEY, rows)
              setRowsPerPage(rows)
            }}
            useUrlSearchParams={false}
            id={'output-pagination'}
            labelRowsPerPage={`Items`}
            hidePerPageOption={hidePerPagePaginationOption}
            compact={true}
          />
          {outputsWithLists.map((item, ind) => (
            <Item output={item} key={ind} />
          ))}
        </Pendable>
      </div>
    </div>
  )
}

export default Outputs
