import { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Typography from '@material-ui/core/Typography'

import DashboardIcon from '@material-ui/icons/Dashboard'
import OndemandVideoOutlinedIcon from '@material-ui/icons/OndemandVideoOutlined'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import SettingsIcon from '@material-ui/icons/Settings'
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined'
import WarningIcon from '@material-ui/icons/Warning'
import PersonIcon from '@material-ui/icons/Person'

import { AlarmWithImpact, BuildInfo, CiBuildInfo, Role, User } from 'common/api/v1/types'
import { logoutAndNavigateToMainPage, stopImpersonation } from '../../../redux/actions/userActions'
import { toggleSidebarMenu } from '../../../redux/actions/uiActions'
import { AppDispatch, GlobalState } from '../../../store'
import Logo from '../../common/Logo'

import MenuItem, { Item } from './MenuItem'
import routes from '../../../utils/routes'
import DevModeSwitch from './DevModeSwitch'
import AlarmItemNameWithActiveAlarms from './AlarmItemNameWithActiveAlarms'
import { useHistory } from 'react-router-dom'

const menuItems: (
  role: Role,
  alarms: AlarmWithImpact[],
) => Array<(Item & { iconColorFn?: (uiReducer: GlobalState['uiReducer'], theme: Theme) => string | false }) | null> = (
  userRole,
  alarms,
) => [
  {
    name: 'Overview',
    url: routes.overview(),
    icon: <DashboardIcon />,
  },
  {
    name: 'Inputs',
    url: routes.inputs(),
    icon: <VideocamOutlinedIcon />,
  },
  {
    name: 'Outputs',
    url: routes.outputs(),
    icon: <OndemandVideoOutlinedIcon />,
  },
  // {
  //   name: 'Event overview',
  //   url: routes.geo(),
  //   icon: <MapIcon />,
  // },
  {
    name: <AlarmItemNameWithActiveAlarms alarms={alarms} />,
    url: routes.alarms(),
    icon: <WarningIcon />,
  },
  {
    name: 'Settings',
    icon: <SettingsIcon />,
    children: [
      {
        name: 'Regions',
        url: routes.regions(),
      },
      {
        name: 'Appliances',
        url: routes.appliances(),
      },
      {
        name: 'Interfaces',
        url: routes.interfaces(),
        divider: true,
      },
      userRole === Role.super
        ? {
            name: 'Networks',
            url: routes.networks(),
          }
        : null,
      userRole === Role.super
        ? {
            name: 'Address mappings',
            url: routes.addressMappings(),
          }
        : null,
      userRole === Role.super
        ? {
            name: 'Kubernetes nodes',
            url: routes.kubernetesNodes(),
          }
        : null,
      userRole === Role.super
        ? {
            name: 'Kubernetes services',
            url: routes.services(),
            divider: true,
          }
        : null,
      {
        name: 'Groups',
        url: routes.groups(),
      },
      {
        name: 'Users',
        url: routes.users(),
        divider: true,
      },
      {
        name: 'Audit log',
        url: routes.audit(),
        divider: true,
      },
      userRole === Role.super
        ? {
            name: 'Billing',
            url: routes.billing(),
          }
        : null,
      userRole === Role.super || userRole === Role.admin
        ? {
            name: 'Usage',
            url: routes.usage(),
            divider: true,
          }
        : null,
      userRole === Role.super
        ? {
            name: 'Global settings',
            url: routes.globalSettings(),
          }
        : null,
    ].filter(Boolean),
  },
]

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  logoWrapper: {
    padding: theme.spacing(1, 2),
  },
  list: {
    overflowY: 'auto',
  },
  menuBottom: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-end',
  },
  open: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  close: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden' as const,
    width: 0,
  },
  version: {
    fontSize: 10,
    padding: theme.spacing(1, 2),
  },
}))

const isCiBuild = (buildInfo: BuildInfo): buildInfo is CiBuildInfo => buildInfo.ciBuild

const AppVersion = () => {
  const { buildInfo } = useSelector(({ buildInfoReducer }: GlobalState) => buildInfoReducer, shallowEqual)
  const classes = useStyles()

  if (!buildInfo) {
    return <></>
  }

  const commit = isCiBuild(buildInfo) && buildInfo.ciCommitShortSha ? `, ${buildInfo.ciCommitShortSha}` : ''
  const versionInfo = `${buildInfo.release}, ${buildInfo.buildTime}${commit}`

  return <Typography className={classes.version}>{versionInfo}</Typography>
}

export const Sidebar = () => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const { uiReducer, user, alarms } = useSelector(
    ({ uiReducer, userReducer, alarmsReducer }: GlobalState) => ({
      uiReducer,
      user: userReducer.user as User,
      alarms: alarmsReducer.alarmsForNotifications,
    }),
    shallowEqual,
  )

  const { open } = uiReducer
  const getIsMobile = () => document.body.offsetWidth && document.body.offsetWidth < 768
  const [isMobile] = useState(getIsMobile())
  const dispatch = useDispatch<AppDispatch>()
  const logoutUserAction = () => void dispatch(logoutAndNavigateToMainPage())
  const stopImpersonationAction = () => void dispatch(stopImpersonation())

  const menu = (
    <div className={classes.container} id="sidebar">
      <div className={classes.logoWrapper}>
        <Logo />
      </div>
      <Divider />
      <List id="menu" disablePadding className={classes.list}>
        {menuItems(user.role, alarms).map((item, ind) => {
          if (!item) return null
          item.iconColor = item.iconColorFn ? item.iconColorFn(uiReducer, theme) : undefined

          return <MenuItem item={item} key={item.url || ind} location={history.location.pathname} />
        })}
      </List>
      <Divider />
      <div className={classes.menuBottom}>
        <ListItem>
          <ListItemText>
            <Typography variant="body2" component="div" data-test-id="current-user">
              {user.username}
            </Typography>
            <Typography variant="body2" component="div">
              {user.impersonatedBy && (
                <span style={{ opacity: 0.5 }}>
                  (by <span data-test-id="current-impersonator">{user.impersonatedBy.username}</span>)
                </span>
              )}
            </Typography>
          </ListItemText>
        </ListItem>
        <Divider />
        {user.impersonatedBy ? (
          <MenuItem
            item={{ name: `Switch to admin`, icon: <PersonIcon /> }}
            id="stop-impersonation"
            action={stopImpersonationAction}
          />
        ) : (
          <MenuItem
            item={{ name: 'Log out', icon: <PowerSettingsNewIcon /> }}
            key="/logout"
            id="logout-button"
            action={logoutUserAction}
          />
        )}
        <DevModeSwitch>
          <AppVersion />
        </DevModeSwitch>
      </div>
    </div>
  )
  const onToggle = () => dispatch(toggleSidebarMenu())
  return isMobile ? (
    <SwipeableDrawer anchor="left" open={open} onOpen={onToggle} onClose={onToggle}>
      {menu}
    </SwipeableDrawer>
  ) : (
    <Drawer variant="persistent" anchor="left" open={open} classes={{ root: open ? classes.open : classes.close }}>
      {menu}
    </Drawer>
  )
}

export default Sidebar
