import { BuildInfo } from 'common/api/v1/types'
import { enqueueNewVersionAvailableSnackbar } from './notificationActions'
import { ThunkApi } from '../../store'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { sleep } from '../../utils'
import { areComparable, releaseTagToNumber } from 'common/buildInfoUtils'

const newVersionSnackbarVisible = () => document.querySelector('[data-reload-page-new-version]') !== null

export const getBuildInfo = createAsyncThunk<BuildInfo, void, ThunkApi>(
  'buildInfo/readBuildInfo',
  async (_, { dispatch, getState, extra: { api } }) => {
    const { buildInfo: currentBuildIfnfo } = getState().buildInfoReducer
    const buildInfo = await api.buildInfoApi.readBuildInfo()

    if (currentBuildIfnfo && areComparable(currentBuildIfnfo.release, buildInfo.release)) {
      const current = releaseTagToNumber(currentBuildIfnfo.release)
      const release = releaseTagToNumber(buildInfo.release)

      if (current < release && !newVersionSnackbarVisible()) {
        dispatch(enqueueNewVersionAvailableSnackbar())
      }
    }

    return buildInfo
  },
)

let initialized = false
export const initBuildInfo = createAsyncThunk<void, void, ThunkApi>(
  'buildInfo/initBuildInfo',
  async (_, { dispatch }) => {
    if (initialized) {
      return
    }

    initialized = true
    // eslint-disable-next-line no-constant-condition
    while (true) {
      try {
        // Await so it doesn't queue up requests if the backend is slow
        await dispatch(getBuildInfo())
        await sleep(30_000)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    }
  },
)
