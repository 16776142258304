import {
  Input,
  Output,
  MetricWindow,
  RistInputMetrics,
  RistInputMultipathState,
  RistMetricType,
  StreamMetrics,
} from 'common/api/v1/types'

export function makeFormatter<T>(t?: T) {
  return (getter: (s: T) => any) => {
    if (!t) {
      return 'N/A'
    }
    try {
      const val = getter(t)
      return val == null || Number.isNaN(val) ? 'N/A' : val
    } catch {
      // eslint-disable-next-line no-console
      console.warn(`Unexpected missing value on object`, t)
      return 'N/A'
    }
  }
}

export function applyUnit(val: number, unit: string) {
  if (val == null || Number.isNaN(val)) {
    return undefined
  }
  return `${val} ${unit}`
}

const isRistInputOnAppliance = (applianceId: string) => (m: StreamMetrics): m is RistInputMetrics =>
  m.type === RistMetricType.ristInput && m.window === MetricWindow.s10 && m.applianceId === applianceId

export const isApplianceStandby = (applianceId: string, input: Input | undefined, outputs: Output[]): boolean => {
  const ristMetricsInInput = input?.metrics?.ristMetrics ?? []
  const ristMetricsInOutputs = outputs.map(o => o.metrics?.ristMetrics ?? [])
  const ristInputs = [ristMetricsInInput, ...ristMetricsInOutputs].flat().filter(isRistInputOnAppliance(applianceId))
  return ristInputs.length > 0 && ristInputs.every(m => m.multipathState === RistInputMultipathState.standby)
}
