import { LngLatBounds } from 'mapbox-gl'
import routes from '../utils/routes'
import {
  ActiveService,
  ActiveServiceInput,
  ActiveServiceType,
  Address,
  Alarm,
  AlarmCause,
  Appliance,
  ApplianceType,
  BuildInfo,
  CoaxPortMode,
  Coordinates,
  CoordinatesMapbox,
  EntityType,
  Group,
  GroupOrGroupRecipientList,
  Input,
  InputPort,
  IpPortMode,
  LimitedAppliance,
  ListResult,
  Output,
  OutputOrRecipientList,
  OutputPort,
  PhysicalPort,
  PortType,
  Role,
  SrtMode,
  User,
  VideonPortMode,
  ZixiMode,
} from 'common/api/v1/types'
import { getPortsUsedByInputPort, getPortsUsedByOutputPort, OccupiedPort } from 'common/ports'
import { RichOption } from '../components/common/Form/Select'
import { PaginatedRequestParams } from '../api/nm-types'

export * from './hooks'
export * from './validation'

export function listResult<TItem>(items: TItem[]): ListResult<TItem> {
  return {
    items: items.slice(),
    total: items.length,
  }
}

/**
 * Returns correct verbal for plural form
 * @param number
 * @param word
 * @param plural - optional, if plural form is not just "<word>s"
 */
export const pluralize = (number: number, word: string, plural = `${word}s`): string =>
  number === 11 || number.toString().slice(-1) !== '1' ? `${number} ${plural}` : `${number} ${word}`

export const pluralizeWord = (number: number, word: string, plural = `${word}s`): string =>
  number === 11 || number.toString().slice(-1) !== '1' ? plural : word

/**
 * Transforms object from formik state removing empty strings '' and applying transformations added with parameter config
 * @param form
 * @param config - optional, applying some specific transformation. Watch tests and input/output forms for examples
 */
// eslint-disable-next-line
export function formTransform(form: any, config?: any): any {
  if (form === null) {
    if (config && config._transform) return config._transform(form)
    return undefined
  }
  if (typeof form !== 'object' || Array.isArray(form)) {
    if (config && config._transform) return config._transform(form)
    return form !== '' ? form : undefined
  }

  const transformedForm = config && config._transform ? config._transform(form) : { ...form }
  if (!transformedForm) return undefined
  // eslint-disable-next-line
  return Object.entries<any>(transformedForm).reduce((acc, [key, value]) => {
    if (['', null].includes(value)) {
      if (config && config[key] && config[key]._transform)
        return {
          ...acc,
          [key]: config[key]._transform(value),
        }
      return acc
    }
    if (Array.isArray(value)) {
      return {
        ...acc,
        [key]: value.map(item => formTransform(item, config && config[key])).filter(Boolean),
      }
    }

    if (config && config[key] && config[key]._transform)
      return {
        ...acc,
        [key]: formTransform(config[key]._transform(value), { ...config[key], _transform: undefined }),
      }
    return {
      ...acc,
      [key]: formTransform(value, config && config[key]),
    }
  }, {})
}

/**
 * Returns the name for the interface (port) since we have to always show it as conjunctions with the appliance name
 * @param name - interface name
 * @param appliance - appliance object with its name inside
 */
export const getInterfaceName = ({
  name,
  appliance: { name: appliance = '' },
}: Pick<PhysicalPort, 'name' | 'appliance'>) => `${appliance}:${name}`

/**
 * Helper to detect if we are in the Stream Manager now
 * @param currentPage - the current page url
 */
export const getIsStreamManager = (currentPage: string) => currentPage.includes(routes.stream.route)

/**
 * Default values for pagination
 */

export const DEFAULT_PAGINATION = {
  pageNumber: '0',
  get rowsPerPage() {
    return localStorage.getItem('rowsPerPage') || '10'
  },
  set rowsPerPage(value: string) {
    localStorage.setItem('rowsPerPage', value)
  },
}
/**
 * Adds default pagination values to request parameters
 * @param params
 */
export const withDefaultPagination = <T extends PaginatedRequestParams>(params: Partial<T>) => ({
  ...DEFAULT_PAGINATION,
  ...params,
})

/**
 * Defines if ActiveService is ActiveServiceInput
 * @param entity - ActiveServiceInput or ActiveServiceOutput
 */
export function isInput(entity: ActiveService): entity is ActiveServiceInput {
  return entity.type === ActiveServiceType.Input
}

/**
 * Defines if OutputOrRecipientList is Output
 * @param entity - Output | OutputRecipientList
 */
export function isOutput(entity: OutputOrRecipientList): entity is Output {
  return 'ports' in entity
}

/**
 * Defines if GroupOrGroupRecipientList is group
 * @param entity - Group or GroupRecipientList
 */
export function isGroup(entity: GroupOrGroupRecipientList): boolean {
  return entity.object === EntityType.group
}

/**
 * Coordinates we use and coordinates Mapbox uses differs with its order
 * @param lat
 * @param lng
 */
export const toMapboxCoords = ([lat, lng]: Coordinates): CoordinatesMapbox => [lng, lat]
/**
 * Defines bounds from coordinates array to use in setViewport
 */
export const getBounds = (coordinates: Array<Coordinates>) => {
  const bounds = new LngLatBounds()
  coordinates.forEach(coords => bounds.extend(toMapboxCoords(coords)))
  return bounds
}

type FormikObj = {
  [key: string]:
    | string
    | number
    | boolean
    | { [key: string]: number | string }
    | Array<{ [key: string]: number | string }>
}
/**
 * Creates default form values for formik forms (Formik requires '' for empty values and false for empty checkboxes)
 * @param names - array of field names
 * @param bools - array on the field names that should have false instead of '' (checkboxes)
 * @param special - special values to set (defaults for example)
 */
export const createDefaultFiledValues = (names: Array<string>, bools: Array<string>, special: FormikObj = {}) =>
  names.reduce<FormikObj>((acc, name) => {
    let value
    if (name in special) value = special[name]
    else if (bools.includes(name)) value = false
    else value = ''

    return {
      ...acc,
      [name]: value,
    }
  }, {})

/**
 * Numeric enums has weird transformation into object, you need to use this function instead of just Object.entries
 * @param enumToTransform
 */
export const numericEnum = (enumToTransform: { [key: string]: string | number }) =>
  Object.entries(enumToTransform).reduce<{ [key: string]: number }>(
    (acc, [key, value]) => ({ ...acc, ...(typeof value !== 'number' ? {} : { [key]: value }) }),
    {},
  )

/**
 * Function to get the Typography color to show input/output name based on its adminStatus
 * @param adminStatus
 */
export const inputOutputColor = ({ adminStatus }: Partial<Input | Output>) => ({
  color: adminStatus ? ('textPrimary' as const) : ('textSecondary' as const),
})

/**
 * Whether user can edit appliance
 * @param user - current user
 * @param applianceOwner - appliance's owner id
 */
export const isEditableAppliance = (user: User, applianceOwner: string): boolean =>
  (applianceOwner && (applianceOwner === user.group || user.role === Role.super)) || false

/**
 * Whether user can edit group
 * @param groupId - group id
 * @param user - current user
 */
export const isEditableGroup = (groupId: Group['id'], user: User) =>
  user.role === Role.super || (user.role === Role.admin && user.group === groupId)

/**
 * Whether user can edit interface. Currently only if super user or owner of appliance.
 * @param applianceOwnerId - port's appliance owner id
 * @param user - current user
 */
export const isEditableInterface = (applianceOwnerId: string, user: User) =>
  user.role === Role.super || (user.role === Role.admin && applianceOwnerId === user.group)

/**
 * Return appliance's owner id
 * @param owner - group id or full group object
 */
export const getApplianceOwnerId = (a?: Appliance): string =>
  (a && (typeof a.owner === 'string' ? a.owner : a.owner.id)) || ''

/**
 * Whether user can edit output
 * @param ownerId - output's group
 * @param user - current user
 */
export const isEditableOutput = (ownerId: Output['group'], user: User) =>
  user.role === Role.super || (user.role === Role.admin && ownerId === user.group)

/**
 * Whether user can edit region
 * @param user - current user
 */
export const isEditableRegion = (user: User) => user.role === Role.super

/**
 * Checks if the received interface is available for use by the received input/output, based on vacancy, alarm status or addresses.
 * @param aInterface The port to check if it is available for use
 * @param inputOrOutput The input or output wanting to use the port
 * @return the reason why the received interface is not unavailable, or undefined if the interface is available.
 */
export function whyIsInterfaceUnavailable(
  aInterface: PhysicalPort & { _inputs?: Input[]; _outputs?: Output[]; _appliance?: Pick<Appliance, 'alarms'> },
  inputOrOutput: { id: string | undefined; name: string | undefined },
): string | undefined {
  const otherInputsUsingPort = aInterface._inputs?.filter(input => input.id != inputOrOutput?.id) || []
  const otherOutputsUsingPort = aInterface._outputs?.filter(output => output.id != inputOrOutput?.id) || []
  const isCoaxPortInUse =
    aInterface.portType === PortType.coax && (otherInputsUsingPort.length > 0 || otherOutputsUsingPort.length > 0)
  if (isCoaxPortInUse)
    return `interface already in use by "${otherInputsUsingPort[0]?.name ||
      otherOutputsUsingPort[0]?.name ||
      'another input/output'}"`

  const hasNoAddresses = aInterface.portType === PortType.ip && aInterface.addresses.length === 0
  if (hasNoAddresses) return `interface has no addresses`

  const hasAlarms = (alarmsThatDisablePort(aInterface._appliance, aInterface.id) || []).length > 0
  if (hasAlarms) return `interface has active alarms`

  return undefined
}

/**
 * If port is editable based on alarm status.
 * This can be called before state is fully loaded and must handle undefined appliance as value.
 * @param appliance
 * @param portId
 */
export const alarmsThatDisablePort = (appliance?: Pick<Appliance, 'alarms'>, portId?: string): Alarm[] | undefined => {
  if (!appliance) return
  const portAlarms = appliance.alarms.filter(alarm => alarm.physicalPortId == portId)

  const alarmsThatCausePortBeDisabled = portAlarms.filter(portAlarm => {
    if (portAlarm.alarmCause == AlarmCause.INTERFACE_REMOVED_BUT_IN_USE) return true
  })

  if (alarmsThatCausePortBeDisabled.length > 0) {
    return alarmsThatCausePortBeDisabled
  }

  return
}

export function isCoreNode(applianceType: ApplianceType) {
  return applianceType === ApplianceType.core
}

export const sleep = (ms: number) => new Promise(res => setTimeout(res, ms))

export function areSetsEqual<T>(s1: Set<T>, s2: Set<T>): boolean {
  if (s1.size !== s2.size) {
    return false
  }
  for (const element of s1) {
    if (!s2.has(element)) {
      return false
    }
  }
  return true
}

/**
 * Helper function to extract current page pathname from hashed URL.
 *
 * /#/url/path?query=params --> returns /url/path
 */
export const getCurrentPage = (): string => {
  return (
    document.location.hash
      ?.slice(1)
      .split('?')
      .shift() || document.location.pathname
  )
}

export function hasAccessToAppliance(appliance: Appliance | LimitedAppliance, user: User) {
  return ('owner' in appliance && user && appliance.owner == user.group) || user.role == Role.super
}

export function inputType(input: Input) {
  const ports = input.ports
  if (input.deriveFrom) {
    return ['derived']
  }
  if (!ports || !ports.length) {
    return ['Not configured']
  }

  const portModes = Array.from(new Set(ports.map(inputTypeFromPort)))
  return portModes
}

export function inputTypeFromPort(port: InputPort) {
  switch (port.mode) {
    case CoaxPortMode.sdi:
      return 'SDI'
    case CoaxPortMode.asi:
      return 'ASI'
    case VideonPortMode.videonAuto:
      return 'Videon auto'
    case VideonPortMode.videonSdi:
      return 'Videon SDI'
    case VideonPortMode.videonHdmi:
      return 'Videon HDMI'
    case IpPortMode.rist:
      return 'RIST'
    case IpPortMode.rtmp:
      return 'RTMP'
    case IpPortMode.rtp:
      return 'RTP'
    case IpPortMode.udp:
      return 'UDP'
    case IpPortMode.generator:
      return 'generator'
    case IpPortMode.zixi:
      switch (port.zixiMode) {
        case ZixiMode.pull:
          return 'Zixi pull'
        case ZixiMode.push:
          return 'Zixi push'
      }
    case IpPortMode.srt:
      switch (port.srtMode) {
        case SrtMode.listener:
          return 'SRT listener'
        case SrtMode.caller:
          return 'SRT caller'
        case SrtMode.rendezvous:
          return 'SRT rendezvous'
      }
  }
}

export function outputType(port?: OutputPort) {
  if (!port) {
    return 'Not configured'
  }
  switch (port.mode) {
    case CoaxPortMode.sdi:
      return 'SDI'
    case CoaxPortMode.asi:
      return 'ASI'
    case IpPortMode.rist:
      return 'RIST'
    case IpPortMode.rtmp:
      return 'RTMP'
    case IpPortMode.rtp:
      return 'RTP'
    case IpPortMode.udp:
      return 'UDP'
    case IpPortMode.zixi:
      switch (port.zixiMode) {
        case ZixiMode.pull:
          return 'Zixi pull'
        case ZixiMode.push:
          return 'Zixi push'
      }
    case IpPortMode.srt:
      switch (port.srtMode) {
        case SrtMode.listener:
          return 'SRT listener'
        case SrtMode.caller:
          return 'SRT caller'
        case SrtMode.rendezvous:
          return 'SRT rendezvous'
      }
    default:
      return 'N/A'
  }
}

export const makeAddressOptions = (currentAddress: string, addresses: Address[]): Array<RichOption> => {
  const availableAddresses: RichOption[] = addresses.map(({ address, publicAddress }) => ({
    name: `${address}${publicAddress ? ` (${publicAddress})` : ''}`,
    value: address,
  }))
  if (currentAddress && !availableAddresses.find(a => a.value === currentAddress)) {
    availableAddresses.unshift({
      name: currentAddress,
      disabled: true,
      tooltip: 'The current address is not available',
    })
  }
  return availableAddresses
}

export function getPortsInUseBy({ inputs = [], outputs = [] }: { inputs: Input[]; outputs: Output[] }): OccupiedPort[] {
  const occupiedPorts: OccupiedPort[] = []
  for (const input of inputs) {
    occupiedPorts.push(...getPortsUsedByInput(input))
  }
  for (const output of outputs) {
    occupiedPorts.push(...getPortsUsedByOutput(output))
  }
  return occupiedPorts
}

function getPortsUsedByInput(input: Input): OccupiedPort[] {
  const occupants: OccupiedPort[] = []
  for (const port of input.ports || []) {
    occupants.push(...getPortsUsedByInputPort(port))
  }
  return occupants.map(o => ({ ...o, input }))
}

function getPortsUsedByOutput(output: Output): OccupiedPort[] {
  const occupants: OccupiedPort[] = []
  for (const port of output.ports) {
    occupants.push(...getPortsUsedByOutputPort(port))
  }
  return occupants.map(o => ({ ...o, output }))
}

export const runningDifferentSoftwareVersion = (version?: string, buildInfo?: BuildInfo): boolean => {
  return buildInfo !== undefined && version !== undefined && version !== buildInfo.release
}
