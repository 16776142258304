import { useRef, useState } from 'react'
import ReactMapboxGl, { Popup, ViewState, NavigationControl } from 'react-map-gl'
import { makeStyles, Theme } from '@material-ui/core/styles'
import 'mapbox-gl/dist/mapbox-gl.css'

import Appliances from '../../geo/Map/Appliances/Appliances'
import { FeatureEvents, PopupProps } from './utils'

const useStyles = makeStyles((theme: Theme) => ({
  container: ({ margin }: { margin: string }) => ({
    coordinates: 'relative',
    margin: theme.spacing(0, -margin, -margin),
    width: `calc(100% + ${theme.spacing(2 * +margin)}px)`,
    height: '50vh',
    maxHeight: '700px',
    '& a': {
      color: theme.palette.background.default,
      '&:hover': { color: theme.palette.background.paper },
    },
  }),
  popup: {
    '& .mapboxgl-popup-content': {
      background: theme.palette.grey[800],
      color: theme.palette.text.primary,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[2],
      maxHeight: '40vh',
      maxWidth: '50vh',
      overflow: 'auto',
      padding: theme.spacing(2),
    },
    '& .mapboxgl-popup-tip': {
      borderTopColor: theme.palette.grey[800],
    },
  },
}))

export enum MapDataType {
  services = 'services',
  appliances = 'appliances',
}

interface MapProps {
  sideMargin: number
  dataType?: MapDataType
}

const MAPBOX_TOKEN = process.env.MAPBOX_TOKEN

/**
 * Mapbox container
 * @param sideMargin - needed to avoid having padding (left, right & bottom) on parent Paper
 * @param dataType - do we show appliances only or the whole services
 */
const Map = ({ sideMargin, dataType = MapDataType.services }: MapProps) => {
  const featureEvents = useRef(new FeatureEvents())
  const classes = useStyles({ margin: String(sideMargin) })
  const map = useRef() as any
  const [popup, setPopup] = useState<undefined | PopupProps>(undefined)
  const [currentHoverFeature, setCurrentHoverFeature] = useState<number | undefined>(undefined)
  const [viewport, setViewport] = useState({
    latitude: 59.359010042385975,
    longitude: 17.980828484893433,
    zoom: 2,
  } as ViewState)

  return (
    <div className={classes.container}>
      <ReactMapboxGl
        ref={map}
        mapboxApiAccessToken={
          MAPBOX_TOKEN // nimbra-edge-dev token from .env unless MAPBOX_TOKEN is set (which in the pipeline is set to nimbra-edge-production
        }
        {...viewport}
        minZoom={0.5}
        maxZoom={10}
        style={{}}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/netinsight/ck9sgm2mi0ryl1ipff3wfxmhl"
        interactiveLayerIds={['appliances']}
        onClick={e => {
          const feature = map.current.getMap().queryRenderedFeatures(e.point, { layers: ['appliances'] })[0]
          if (feature) {
            featureEvents.current.appliances.emit('onClick', feature.properties.id)
          } else {
            setPopup(undefined)
          }
        }}
        onMouseEnter={e => {
          const feature = map.current.getMap().queryRenderedFeatures(e.point, { layers: ['appliances'] })[0]
          if (feature) {
            featureEvents.current.appliances.emit('onMouseEnter', feature.properties.id)
            setCurrentHoverFeature(feature.properties.id)
          }
        }}
        onMouseLeave={_e => {
          if (currentHoverFeature !== undefined) {
            featureEvents.current.appliances.emit('onMouseLeave', currentHoverFeature)
            setCurrentHoverFeature(undefined)
          }
        }}
        onViewportChange={(nextViewport: ViewState) => {
          setViewport(nextViewport)
        }}
      >
        <>
          <NavigationControl showCompass={false} />
          {dataType === MapDataType.appliances && (
            <Appliances
              map={map && map.current && map.current.getMap()}
              setPopup={setPopup}
              featureEvents={featureEvents.current}
              setViewport={setViewport}
            />
          )}
          {!!popup && (
            <Popup
              onClose={() => setPopup(undefined)}
              latitude={popup.coordinates[0]}
              longitude={popup.coordinates[1]}
              anchor="bottom"
              className={classes.popup}
            >
              {popup.content}
            </Popup>
          )}
        </>
      </ReactMapboxGl>
    </div>
  )
}

export default Map
