import Button from '@material-ui/core/Button'

import { OutputRecipientList } from 'common/api/v1/types'
import { usePageParamsFilteredSelector, useOutputListsSelector } from '../../utils'
import Table, { MoveToEntity, TableConfig } from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import Wrapper from '../common/Wrapper'
import { Link } from '../common/Link'
import routes from '../../utils/routes'

export const ListView = () => {
  const { outputLists, total, loading } = usePageParamsFilteredSelector(useOutputListsSelector)

  const tableConfig: TableConfig<OutputRecipientList> = [
    {
      title: 'name',
      getValue: ({ name }) => name,
      sorting: { byParameter: 'name' },
    },
    {
      title: 'description',
      getValue: ({ description }) => description,
      sorting: { byParameter: 'description' },
    },
    {
      getValue: ({ id }) => <MoveToEntity url={routes.outputListsUpdate({ id })} entityName="output list" />,
      props: { align: 'right' },
    },
  ]

  return (
    <Wrapper name="Output lists">
      <Table<OutputRecipientList>
        emptyMessageComponent={
          <MissingContent message="No output lists" buttonText="Add output list" url={routes.outputListsNew()} />
        }
        config={tableConfig}
        data={outputLists}
        pending={loading}
        actions={[
          <Link key="outputListCreate" type="button" to={routes.outputListsNew()}>
            <Button variant="contained" color="primary">
              Add output list
            </Button>
          </Link>,
        ]}
        pagination={{ total, useUrlSearchParams: true }}
      />
    </Wrapper>
  )
}
