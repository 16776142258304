import {
  ActiveService,
  ActiveServiceOutput,
  ActiveServiceType,
  Appliance,
  ApplianceType,
  AsiInputPort,
  AudioCodec,
  AudioLayout,
  AuditLog,
  AuditOperation,
  BillingReport,
  BuildInfo,
  CoaxPortMode,
  ExternalRegionMode,
  Group,
  GroupRecipientList,
  Input,
  InputAdminStatus,
  InputOperStatus,
  IpPortMode,
  Network,
  Output,
  OutputAdminStatus,
  OutputOperStatus,
  OutputRecipientList,
  PhysicalPort,
  PortType,
  Region,
  RistserverLogLevel,
  Role,
  SdiInputPort,
  Service,
  ThumbnailMode,
  TlsCertRead,
  UdpInputPort,
  UsageReport,
  User,
  VideoCodec,
  ZixiLinkMode,
  ZixiMode,
} from 'common/api/v1/types'

export const MOCK_DELAY_MS = 750

export const simpleUdpInputPort: UdpInputPort = {
  id: 'port-id-1',
  mode: IpPortMode.udp,
  physicalPort: 'id of physical port',
  address: 'host',
  port: 0,
}

const complexUdpInputPort: UdpInputPort = {
  id: 'port-id-2',
  mode: IpPortMode.udp,
  physicalPort: 'id of physical port',
  address: 'host',
  port: 0,
  multicastAddress: 'multiAdd',
  multicastSource: 'multiSource',
}

const sdiPort: SdiInputPort = {
  id: 'port-id-3',
  mode: CoaxPortMode.sdi,
  physicalPort: 'id of physical port',
}

const sdiPortAsi: AsiInputPort = {
  id: 'port-id-4',
  mode: CoaxPortMode.asi,
  physicalPort: 'id of physical port',
}

export const MOCK_GROUPS: Group[] = [
  { id: '36ca45f0-7b17-11e9-bf58-0242ac110002', name: 'system', applianceSecret: 'secret' },
  { id: '01', name: 'First org', applianceSecret: 'secret1', parent: '36ca45f0-7b17-11e9-bf58-0242ac110002' },
  { id: '02', name: 'Second org', applianceSecret: 'secret2', parent: '36ca45f0-7b17-11e9-bf58-0242ac110002' },
  { id: '03', name: 'Third org', applianceSecret: 'secret3', parent: '36ca45f0-7b17-11e9-bf58-0242ac110002' },
]

export const MOCK_FEATURES = {
  coaxPorts: 4,
  encoder: {
    video: {
      codecs: [
        {
          name: VideoCodec.h264,
          bitrate: {
            min: 1000000,
            max: 30000000,
          },
        },
        {
          name: VideoCodec.h265,
          bitrate: {
            min: 1000000,
            max: 30000000,
          },
        },
      ],
    },
    audio: {
      maxAudioStreams: 2,
      layout: [AudioLayout.mono, AudioLayout.stereo],
      codecs: [
        {
          name: AudioCodec.aes3,
          bitrates: [1920],
        },
        {
          name: AudioCodec.aacLc,
          bitrates: [96, 128, 192, 256, 384],
        },
        {
          name: AudioCodec.mpeg2,
          bitrates: [96, 128, 192, 256, 384],
        },
        {
          name: AudioCodec.heAac,
          bitrates: [96, 128],
        },
      ],
    },
  },
  fec: {
    maxSize: 100,
    rowRange: {
      min: 4,
      max: 20,
    },
    colRange: {
      min: 1,
      max: 20,
    },
  },
}
export const MOCK_APPLIANCES: Appliance[] = [
  {
    id: '123456',
    contact: 'contact',
    geoLocation: {
      location: 'Stockholm',
      coordinates: [59.3293327, 18.0664193],
    },
    version: { controlImageVersion: 'version', controlSoftwareVersion: 'version' },
    alarms: [],
    name: 'Appliance 1',
    hostname: 'hostname',
    type: ApplianceType.nimbraVA225,
    serial: 'string',
    owner: MOCK_GROUPS[0].id,
    features: MOCK_FEATURES,
    lastMessageAt: new Date(),
    region: {
      id: '1',
      name: 'default',
    },
    physicalPorts: [
      {
        id: '123',
        name: 'Test1',
        index: '0',
        mac: '00:00:00:00:00:00',
        portType: PortType.coax,
        appliance: { id: '123456', name: 'Appliance 1', type: ApplianceType.nimbraVA225 },
        owner: '1234',
        addresses: [],
        networks: [],
      },
      {
        id: '456',
        name: 'Test2',
        index: '0',
        mac: '00:00:00:00:00:00',
        portType: PortType.coax,
        appliance: { id: '123456', name: 'Appliance 1', type: ApplianceType.nimbraVA225 },
        owner: '1234',
        addresses: [],
        networks: [],
      },
    ],
    logLevel: 3,
    ristserverLogLevel: RistserverLogLevel.info,
  },
  {
    id: '98765',
    contact: 'contact',
    geoLocation: {
      location: 'Stockholm',
      coordinates: [59.3293327, 18.0664193],
    },
    version: { controlImageVersion: 'version', controlSoftwareVersion: 'version' },
    alarms: [],
    name: 'Appliance 2',
    hostname: 'hostname',
    type: ApplianceType.nimbraVA225,
    serial: 'string',
    owner: 'string',
    lastMessageAt: new Date(),
    region: {
      id: '1',
      name: 'default',
    },
    features: MOCK_FEATURES,
    physicalPorts: [
      {
        id: '789',
        name: 'Test3',
        index: '0',
        mac: '00:00:00:00:00:00',
        portType: PortType.coax,
        appliance: { id: '98765', name: 'Appliance 2', type: ApplianceType.nimbraVA225 },
        owner: '1234',
        addresses: [],
        networks: [],
      },
      {
        id: '1011',
        name: 'Test4',
        index: '0',
        mac: '00:00:00:00:00:00',
        portType: PortType.coax,
        appliance: { id: '98765', name: 'Appliance 2', type: ApplianceType.nimbraVA225 },
        owner: '1234',
        addresses: [],
        networks: [],
      },
    ],
    logLevel: 3,
    ristserverLogLevel: RistserverLogLevel.info,
  },
  {
    id: '98769',
    contact: 'contact',
    geoLocation: {
      location: 'Stockholm',
      coordinates: [59.3293327, 18.0664193],
    },
    version: { controlImageVersion: 'version', controlSoftwareVersion: 'version' },
    alarms: [],
    name: 'Edge Connect 1',
    hostname: 'hostname',
    type: ApplianceType.edgeConnect,
    serial: 'string',
    owner: 'string',
    lastMessageAt: new Date(),
    region: {
      id: '1',
      name: 'default',
    },
    features: {},
    physicalPorts: [
      {
        id: '1789',
        name: 'lo',
        index: '0',
        mac: '00:00:00:00:00:00',
        portType: PortType.ip,
        appliance: { id: '98769', name: 'Edge Connect 1', type: ApplianceType.edgeConnect },
        owner: '1234',
        addresses: [
          {
            address: '127.0.0.1',
            netmask: '255.0.0.0',
          },
        ],
        networks: [],
      },
      {
        id: '11011',
        name: 'eth0',
        index: '1',
        mac: '00:00:00:00:00:00',
        portType: PortType.ip,
        appliance: { id: '98769', name: 'Edge Connect 1', type: ApplianceType.edgeConnect },
        owner: '1234',
        addresses: [
          {
            address: '10.1.2.3',
            publicAddress: '10.1.2.3',
            netmask: '255.255.0.0',
          },
        ],
        networks: [],
      },
    ],
    logLevel: 3,
    ristserverLogLevel: RistserverLogLevel.info,
  },
  {
    id: '1298797',
    contact: 'contact 1',
    geoLocation: {
      location: 'Stockholm',
      coordinates: [59.3293327, 18.0664193],
    },
    version: { controlImageVersion: 'version', controlSoftwareVersion: 'version' },
    alarms: [],
    name: 'video-region-stockholm',
    hostname: 'hostname',
    type: ApplianceType.core,
    serial: 'string',
    owner: 'string',
    lastMessageAt: new Date(),
    region: {
      id: '1',
      name: 'default',
    },
    features: {},
    physicalPorts: [
      {
        id: 'corestockholm-lo',
        name: 'lo',
        index: '0',
        mac: '00:00:00:00:00:00',
        portType: PortType.ip,
        appliance: { id: '98769', name: 'video-region-stockholm', type: ApplianceType.core },
        owner: '1234',
        addresses: [
          {
            address: '127.0.0.1',
            netmask: '255.0.0.0',
          },
        ],
        networks: [],
      },
      {
        id: 'corestockholm-eth0',
        name: 'eth0',
        index: '1',
        mac: '00:00:00:00:00:00',
        portType: PortType.ip,
        appliance: { id: '98769', name: 'video-region-stockholm', type: ApplianceType.core },
        owner: '1234',
        addresses: [
          {
            address: '10.0.2.3',
            publicAddress: '10.0.2.3',
            netmask: '255.255.0.0',
          },
        ],
        networks: [],
      },
    ],
    logLevel: 3,
    ristserverLogLevel: RistserverLogLevel.info,
  },
]

export const MOCK_OUTPUTS: Output[] = [
  {
    id: '1',
    adminStatus: OutputAdminStatus.on,
    appliances: [MOCK_APPLIANCES[0]],
    upstreamAppliances: [MOCK_APPLIANCES[1]],
    name: 'Test 1',
    // inputId: '1',
    object: 'output',
    input: 'input',
    group: '6f60458c-ee20-4fa7-80dd-d9467b63cba7',
    ports: [
      {
        id: 'string',
        physicalPort: 'string',
        mode: CoaxPortMode.sdi,
      },
    ],
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: '2',
    appliances: [MOCK_APPLIANCES[0]],
    upstreamAppliances: [MOCK_APPLIANCES[1]],
    adminStatus: OutputAdminStatus.on,
    name: 'Test 2',
    // inputId: '2',
    object: 'output',
    input: 'input',
    group: '6f60458c-ee20-4fa7-80dd-d9467b63cba7',
    ports: [
      {
        id: 'string',
        physicalPort: 'string',
        mode: IpPortMode.udp,
        address: 'localhost',
        port: 1234,
      },
    ],
    createdAt: new Date(),
    updatedAt: new Date(),
  },
]

export const MOCK_INPUTS: Input[] = [
  {
    id: '01',
    adminStatus: InputAdminStatus.on,
    channelId: 0,
    name: 'Input 0 - no port',
    tr101290Enabled: true,
    thumbnailMode: ThumbnailMode.core,
    canSubscribe: true,
    numOutputs: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
    appliances: [MOCK_APPLIANCES[0]],
    downstreamAppliances: [MOCK_APPLIANCES[1]],
  },
  {
    id: '11',
    adminStatus: InputAdminStatus.on,
    channelId: 1,
    name: 'Input 1 - udp simple',
    tr101290Enabled: true,
    thumbnailMode: ThumbnailMode.core,
    ports: [simpleUdpInputPort],
    numOutputs: 0,
    canSubscribe: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    appliances: [MOCK_APPLIANCES[0]],
    downstreamAppliances: [MOCK_APPLIANCES[1]],
  },
  {
    id: '21',
    adminStatus: InputAdminStatus.on,
    channelId: 2,
    name: 'Input 2 - udp complex',
    tr101290Enabled: true,
    thumbnailMode: ThumbnailMode.core,
    ports: [complexUdpInputPort],
    numOutputs: 0,
    canSubscribe: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    appliances: [MOCK_APPLIANCES[0]],
    downstreamAppliances: [MOCK_APPLIANCES[1]],
  },
  {
    id: '31',
    adminStatus: InputAdminStatus.on,
    channelId: 3,
    name: 'Input 3 - sdi',
    tr101290Enabled: true,
    thumbnailMode: ThumbnailMode.core,
    ports: [sdiPort],
    numOutputs: 0,
    canSubscribe: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    downstreamAppliances: [],
  },
  {
    id: '41',
    adminStatus: InputAdminStatus.on,
    channelId: 4,
    name: 'Input 4 - sdi asi',
    tr101290Enabled: true,
    thumbnailMode: ThumbnailMode.core,
    ports: [sdiPortAsi],
    numOutputs: 0,
    canSubscribe: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    downstreamAppliances: [],
  },
]

export const MOCK_PORTS: PhysicalPort[] = MOCK_APPLIANCES.map(a => {
  return a.physicalPorts.map(p => ({ ...p, appliance: { ...p.appliance, name: a.name } }))
}).flat()

export const MOCK_USER: User = {
  id: '1',
  group: '01',
  username: 'user username',
  role: Role.super,
}

export const MOCK_USERS: User[] = [
  {
    id: '1',
    group: '01',
    username: 'user admin',
    role: Role.super,
  },
  {
    id: '2',
    group: '02',
    username: 'user normal',
    role: Role.basic,
  },
]

export const MOCK_SERVICES: (Service & { id: string })[] = [
  {
    id: 'es',
    purpose: 'Log storage',
    product: 'ElasticSearch',
    description: '',
    collectLogs: false,
  },
  {
    id: 'infl',
    purpose: 'Metrics storage',
    description: '',
    product: 'InfluxDb',
    collectLogs: true,
  },
]

export const MOCK_REGIONS: Region[] = [
  {
    id: '1',
    name: 'default',
    // eslint-disable-next-line
    default_region: true,
    external: ExternalRegionMode.external,
  },
]

export const MOCK_OUTPUT_LISTS: Array<OutputRecipientList> = [
  {
    id: '1',
    name: 'First',
    group: MOCK_GROUPS[0].id,
  },
  {
    id: '2',
    name: 'Second',
    group: MOCK_GROUPS[1].id,
    description: 'Some description',
  },
]

export const MOCK_ACTIVE_SERVICES: Array<ActiveService> = [
  {
    id: '04dc377c-fe68-4611-a43f-149cae9c21ef',
    name: 'f13ff',
    ports: [
      {
        id: '0003',
        physicalPort: MOCK_PORTS[0].id,

        mode: IpPortMode.zixi,
        streamId: 'f123f',
        password: '',
        unrecoveredPacketsDetection: false,
        unrecoveredPacketsThreshold: 0,

        zixiMode: ZixiMode.push,
        linkMode: ZixiLinkMode.bonded,
        linkSet1: [{ localIp: '10.101.201.159', remoteIp: '3.24.5.3', remotePort: 2088, rateLimit: 1000000000 }],
        linkSet2: [{ localIp: '10.101.201.159', remoteIp: '23.5.1.5', remotePort: 2088, rateLimit: 1000000000 }],
        retransmitBuf: 500,
        maxBitrateMbps: 20,

        fecLatency: 30,
        optimizeFec: false,
        maxFecOverhead: 0,
      },
    ],
    metrics: { ristMetrics: [], tr101290Metrics: [] },
    health: { state: OutputOperStatus.metricsMissing, title: 'Missing measurements: received 0/2 entries' },
    input: '0faf313c-2469-4cbe-8298-8f5a376ceef0',
    appliance: {
      id: '8c77542e-5fce-4e8e-9547-8cf1a2c6a565',
      name: 'vt-159-Nimbra410',
      geoLocation: { location: 'Novosibirsk, Novosibirsk Oblast, Russia', coordinates: [55.0282171, 82.9234509] },
      region: { id: '69920e49-31c4-476a-b6bb-023305262007', name: 'default' },
      coreNode: {
        name: 'video-region-default-1',
        id: '885c0f0c-36c3-4436-a723-2f0257006d23',
        type: ApplianceType.core,
        region: { id: '69920e49-31c4-476a-b6bb-023305262007', name: 'default' },
        geoLocation: { location: 'Novosibirsk, Novosibirsk Oblast, Russia', coordinates: [55.0282171, 82.9234509] },
      },
    },
    type: ActiveServiceType.Output,
    adminStatus: OutputAdminStatus.on,
    groupName: 'system',
    activeServiceInput: {
      id: '0faf313c-2469-4cbe-8298-8f5a376ceef0',
      name: 'first',
      ports: [
        {
          id: '31eaf33b-848f-4d9f-8e8b-c248010166f8',
          copies: 1,
          mode: IpPortMode.udp,
          physicalPort: 'cb4c7240-53ba-4c87-b5dc-37a5dedd656e',
          address: '10.101.201.158',
          port: 1236,
        },
      ],
      adminStatus: InputAdminStatus.on,
      metrics: { ristMetrics: [], tr101290Metrics: [] },
      health: { state: InputOperStatus.metricsMissing, title: 'No input bitrate measurements' },
      appliance: {
        id: 'b38e951d-c2ae-4310-b551-fa43fd96d2fc',
        name: 'vt-158-Nimbra412',
        geoLocation: { location: 'Madrid, Community of Madrid, Spain', coordinates: [40.4167047, -3.7035825] },
        region: { id: '89a83362-6f7d-11ea-bc55-0242ac130003', name: 'stockholm' },
        coreNode: {
          name: 'fake-core-1-stockholm',
          id: 'c33a56bb-2f80-4fb9-91e8-d4ea1259a115',
          type: ApplianceType.core,
          region: { id: '89a83362-6f7d-11ea-bc55-0242ac130003', name: 'stockholm' },
          geoLocation: { location: 'Larnaca, Cyprus', coordinates: [34.9236095, 33.6236184] },
        },
      },
      type: 0,
      ownerName: 'system',
    },
  } as ActiveServiceOutput,
]

export const MOCK_AUDIT: Array<AuditLog> = ([
  {
    id: '-8897950567016366000',
    entity: 'user',
    entityName: 'admin',
    entityId: 'a4ff0b44-7b1e-11e9-ba87-0242ac110002',
    operation: AuditOperation.login,
    username: 'admin',
    userId: 'a4ff0b44-7b1e-11e9-ba87-0242ac110002',
    createdAt: '2020-08-12T11:57:11.113Z',
  },
  {
    id: '-8897978981848973000',
    entity: 'user',
    entityName: 'admin',
    entityId: 'a4ff0b44-7b1e-11e9-ba87-0242ac110002',
    operation: AuditOperation.create,
    username: 'admin',
    userId: 'a4ff0b44-7b1e-11e9-ba87-0242ac110002',
    createdAt: '2020-08-12T11:28:57.457Z',
  },
  {
    id: '-8898127240227717000',
    entity: 'user',
    entityName: 'admin',
    entityId: 'a4ff0b44-7b1e-11e9-ba87-0242ac110002',
    operation: AuditOperation.update,
    username: 'admin',
    userId: 'a4ff0b44-7b1e-11e9-ba87-0242ac110002',
    createdAt: '2020-08-12T09:01:40.589Z',
  },
] as unknown) as Array<AuditLog>

export const MOCK_GROUP_LISTS: Array<GroupRecipientList> = [
  {
    id: '001',
    name: 'First',
    group: MOCK_GROUPS[0].id,
  },
  {
    id: '002',
    name: 'Second',
    group: MOCK_GROUPS[1].id,
    description: 'vsem privet',
  },
]

export const MOCK_TLS: TlsCertRead = {
  fingerprint: 'mock_fingerprint',
  createdAt: new Date(),
  expiresAt: new Date(),
}

export const MOCK_BILLING: BillingReport = {
  systemName: 'mocked billing report',
  startDate: new Date(),
  endDate: new Date(),
  periods: [],
  summary: {
    ingressCredits: 5,
    egressCredits: 100,
    totalCredits: 105,
  },
}

export const MOCK_USAGE: UsageReport = {
  systemName: 'mock usage report',
  startDate: new Date(),
  endDate: new Date(),
  ingressTrafficGB: 5,
  egressTrafficGB: 10,
  details: [],
  total: 0,
}

export const MOCK_BUILD_INFO: BuildInfo = {
  ciBuild: false,
  buildTime: 'mock build time',
  release: 'mock release',
}

export const MOCK_NETWORKS: Network[] = [
  {
    id: '001',
    name: 'Fast connect',
    enabled: false,
  },
  {
    id: '002',
    name: 'Japan',
    enabled: true,
  },
  {
    id: '003',
    name: 'France',
    enabled: true,
  },
]

export const resolveMock = <T>(result: T): Promise<T> =>
  new Promise(resolve => setTimeout(() => resolve(result), MOCK_DELAY_MS))
