import { makeStyles, Theme } from '@material-ui/core/styles'

import Inputs from './Inputs'
import Outputs from './Outputs'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    position: 'relative',
    overflow: 'hidden',
  },
  inputs: {
    flexGrow: 3,
    maxWidth: '75%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
      maxWidth: '68%',
    },
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1,
      maxWidth: '50%',
    },
  },
  outputs: {
    flexGrow: 1,
    height: '100%',
    overflowY: 'auto',
  },
}))

const List = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.inputs}>
        <Inputs />
      </div>
      <div className={classes.outputs}>
        <Outputs />
      </div>
    </div>
  )
}

export default List
