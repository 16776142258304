import { ListResult, ActiveService } from 'common/api/v1/types'
import { IActiveServicesApi } from './api'
import { MOCK_ACTIVE_SERVICES, MOCK_DELAY_MS } from '../MockData'
import { listResult } from '../../utils'

export class MockActiveServicesApi implements IActiveServicesApi {
  getActiveServices(): Promise<ListResult<ActiveService>> {
    return new Promise(resolve =>
      setTimeout(() => {
        resolve(listResult(MOCK_ACTIVE_SERVICES))
      }, MOCK_DELAY_MS),
    )
  }
}
