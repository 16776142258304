import {
  Appliance,
  ApplianceType,
  AuditOperation,
  Group,
  GroupInputPermission,
  Input,
  InputPort,
  KubernetesNode,
  ListAlarmSortableField,
  ListApplianceSortableField,
  ListGroupSortableField,
  ListInputSortableField,
  ListIpMappingSortableField,
  ListOutputSortableField,
  ListPortSortableField,
  ListServiceSortableField,
  ListUserSortableField,
  MetricWindow,
  Network,
  NetworkSortableField,
  NewUser,
  Output,
  OutputPort,
  OutputRecipientList,
  PhysicalPort,
  PortType,
  SortOrder,
  User,
} from 'common/api/v1/types'
import { Query } from 'common/query'

export interface EnrichedApplianceWithOwner extends Appliance {
  _owner: Group
  _restarting?: boolean
}

export interface EnrichedInput extends Input {
  _redundant?: boolean
  _owner?: Group
}

export interface EnrichedInputWithPorts extends EnrichedInput {
  ports?: EnrichedInputPort[]
}

export type EnrichedInputPort = InputPort & { _port: PhysicalPort & { _appliance: Appliance } }

export interface EnrichedUser extends User {
  _group: Omit<Group, 'applianceSecret'>
}

export interface EnrichedOutput extends Output {
  _input?: Input
  _group?: Group
}

export type EnrichedOutputPort = OutputPort & { _port: PhysicalPort & { _appliance: Appliance } }

export interface EnrichedOutputWithPorts extends Output {
  ports: EnrichedOutputPort[]
  _input?: EnrichedInput
  _group?: Group
}

export interface EnrichedGroup extends Group {
  _userNumber: number
  _applianceNumber: number
  _interfaceNumber: number
  _permission?: GroupInputPermission
}

export interface EnrichedOutputRecipientList extends OutputRecipientList {
  _hasOutputsInUse?: boolean
}

export interface EnrichedPhysicalPort extends PhysicalPort {
  _owner: Group
  _inputs?: Array<Input>
  _outputs?: Array<Output>
  _appliance?: EnrichedApplianceWithOwner
}

export interface EnrichedAppliance extends Appliance {
  _physicalPorts: Array<EnrichedPhysicalPort>
  _restarting?: boolean
}

export interface PaginatedRequestParams<TSort extends string = string> {
  pageNumber: string
  rowsPerPage: string
  // filter is used for populating "Query.searchName"
  filter?: string
  asc?: TSort
  desc?: TSort
}

export interface InputsRequestParams extends PaginatedRequestParams<ListInputSortableField> {
  /** @param canSubscribe - to show only those you have access to send to output */
  canSubscribe?: boolean
  /** @param applianceId - to show only those which ports belong to this appliance */
  applianceId?: string
  derived?: boolean
}

export interface UsageRequestParams extends PaginatedRequestParams {
  startDate: Date
  endDate: Date
  type?: 'egress' | 'ingress'
  format?: 'csv'
}

export interface OutputsRequestParams extends PaginatedRequestParams<ListOutputSortableField> {
  applianceId?: string
  hasInput?: boolean
  output?: Output['id']
  input?: Input['id']
  notInput?: Input['id']
  inputTr101290Window?: MetricWindow
}

export interface GroupsRequestParams extends PaginatedRequestParams<ListGroupSortableField> {
  inputShared?: Input['id']
  inputNotShared?: Input['id']
  userGroup?: string
}

export interface UsersRequestParams extends PaginatedRequestParams<ListUserSortableField> {
  owner?: Group['id']
}

export interface ServicesRequestParams extends PaginatedRequestParams<ListServiceSortableField> {}

export interface AlarmsRequestParams extends PaginatedRequestParams<ListAlarmSortableField> {
  applianceId?: string
}

export interface PortsRequestParams extends PaginatedRequestParams<ListPortSortableField> {
  owner?: Group['id']
  portType?: PortType
  applianceType?: ApplianceType
  appliance?: Appliance['id']
}

export interface AppliancesRequestParams extends PaginatedRequestParams<ListApplianceSortableField> {
  isSystemProvider?: boolean
  owner?: Group['id']
  withCore?: boolean
  types?: ApplianceType[]
}

export interface IpMappingsRequestParams extends PaginatedRequestParams<ListIpMappingSortableField> {}

export interface AuditLogRequestParams extends PaginatedRequestParams {
  fromDate?: string
  toDate?: string
  operation?: AuditOperation
  entity?: string
  username?: string
  entityName?: string
}

export interface KubernetesNodesRequestParams extends PaginatedRequestParams {
  name?: KubernetesNode['name']
}

export interface NetworksRequestParams extends PaginatedRequestParams<NetworkSortableField> {
  ids?: Array<Network['id']>
  port?: string
}
/** Returns Query<TFilter, SortOrder<TSortOrder>> (i.e. single SortOrder) for backend APIs that only accept queries with a single sort param **/
export function singleSortQueryFromPaginatedRequestParams<TFilter, TSortOrder extends string>({
  filter,
  paginatedRequestParams,
}: {
  filter: TFilter
  paginatedRequestParams: PaginatedRequestParams<TSortOrder>
}): Query<TFilter, SortOrder<TSortOrder>> {
  const { pageNumber, rowsPerPage, asc, desc } = paginatedRequestParams
  return {
    filter,
    skip: +pageNumber * +rowsPerPage,
    limit: +rowsPerPage,
    order: makeSortOrder({ asc, desc }),
  }
}

/** For backend APIs that receive queries with a single sort order **/
function makeSortOrder<SortableEnumType extends string>({
  asc,
  desc,
}: {
  asc: SortableEnumType | undefined
  desc: SortableEnumType | undefined
}): SortOrder<SortableEnumType> | undefined {
  if (asc !== undefined) {
    return { descending: false, field: asc }
  } else if (desc !== undefined) {
    return { descending: true, field: desc }
  }
  return undefined
}

export type ExistingUserForUpdate = User & { password?: NewUser['password'] }
