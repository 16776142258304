import React from 'react'

import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'

import defaultThemeDark from './themes/defaultDark'
import defaultThemeLight from './themes/defaultLight'
import { getIsStreamManager } from './utils'
import { useLocation } from 'react-router-dom'

export const ThemeSwitcher: React.FunctionComponent = ({ children }) => {
  const { pathname } = useLocation()
  const currentTheme = getIsStreamManager(pathname) ? defaultThemeLight : defaultThemeDark
  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default ThemeSwitcher
