import { useSelector, shallowEqual } from 'react-redux'
import cn from 'classnames'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import ExpandMore from '@material-ui/icons/ExpandMore'

import { Link } from './Link'
import { TopLeftLogo } from './LogoFetcher'
import { getIsStreamManager } from '../../utils'

import { GlobalState } from '../../store'
import { Role } from 'common/api/v1/types'
import routes from '../../utils/routes'
import { useLocation } from 'react-router-dom'
import { EnrichedUser } from '../../api/nm-types'

const useStyles = makeStyles((theme: Theme) => ({
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    marginRight: theme.spacing(1),
  },
  link: {
    overflow: 'hidden',
  },
  buttonWrapper: {
    width: '100%',
  },
  button: {
    textTransform: 'none',
    textAlign: 'left',
    overflow: 'hidden',
  },
  system: {
    lineHeight: '14px',
  },
  group: {
    fontSize: '10px',
    lineHeight: '12px',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(0.5),
  },
}))

interface LogoProps {
  className?: string
}

/**
 * Logo for both network manager and Stream manager top left corner
 * @param className - some specific css class for it
 */
const Logo = ({ className }: LogoProps) => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const isStreamManager = getIsStreamManager(pathname)
  const user = useSelector(({ userReducer }: GlobalState) => userReducer.user as EnrichedUser, shallowEqual)
  return (
    <div className={cn(classes.logoWrapper, className)}>
      <div className={classes.logo}>
        <TopLeftLogo />
      </div>
      <Link
        to={isStreamManager ? routes.overview() : routes.stream()}
        className={classes.link}
        muiStyle={{ width: '100%', display: 'block' }}
      >
        {user.role !== Role.basic && (
          <Button className={classes.buttonWrapper}>
            <div className={classes.button}>
              <Typography variant="caption" component="div" className={classes.system}>
                {isStreamManager ? 'Stream manager' : 'Network Manager'}
              </Typography>
              <Typography variant="caption" color="textSecondary" component="div" className={classes.group}>
                {!!user._group && user._group.name}
              </Typography>
            </div>
            <ExpandMore className={classes.icon} />
          </Button>
        )}
      </Link>
    </div>
  )
}

export default Logo
