import { get } from 'lodash'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { Appliance, Group } from 'common/api/v1/types'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    margin: theme.spacing(2, 0),
  },
}))

interface RemoveApplianceProps {
  appliance: Appliance
}
export const RemoveDialog = ({ appliance }: RemoveApplianceProps) => {
  const classes = useStyles()
  const owner = appliance.owner as Group

  return (
    <Typography variant="h2" color="textPrimary" className={classes.title}>
      You are about to delete appliance &apos;{appliance.name}&apos;, owned by &apos;{get(owner, 'name')}&apos;&nbsp;
    </Typography>
  )
}
