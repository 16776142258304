import axios from 'axios'
import store from '../store'
import { ApplicationException } from '../components/common/ApplicationException'
import { clearState } from '../localstorage'

axios.interceptors.response.use(
  response => {
    tryConvertDates(response.data)
    return response
  },
  error => {
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      if (error.response.status === 401 && !store.getState().userReducer.changingUser) {
        clearState()
        document.cookie = 'edgetoken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT'
        if (
          error.config &&
          // Response from login request
          error.config.url !== '/api/login/' &&
          // Already logged out
          window.location.hash !== '#/'
        ) {
          window.location.assign('/')
        }
      }

      const defaultTitle = `Unexpected status code: ${error.response.status}`

      throw new ApplicationException({
        fatal: error.response.status !== 409,
        errorCode: `${error.response.status}`,
        text: error.response.data?.title || defaultTitle,
        details: error.response.data?.detail || error.response.data?.title,
        origin: '',
      })
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      const request = error.request as XMLHttpRequest
      const url = request.responseURL || error.config?.url
      throw new ApplicationException({
        fatal: true,
        errorCode: `No response received from ${url}`,
        text: `${error.message}`,
        details: `No response received ${url ? `from ${url}` : ''}`,
        origin: '',
      })
    }
    // Something happened in setting up the request that triggered an Error
    throw new ApplicationException({
      fatal: true,
      errorCode: `Failed sending request: ${error.message}`,
      text: `Failed sending request`,
      details: error.message,
      origin: '',
    })
  },
)

const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/

function tryConvertDates(item: any): any {
  if (!item || typeof item != 'object') {
    return item
  }

  if (Array.isArray(item)) {
    return item.map(tryConvertDates)
  }

  const keys = Object.keys(item)
  for (const key of keys) {
    const val = item[key]
    if (item[key] && typeof item[key] === 'object') {
      item[key] = tryConvertDates(item[key])
    } else if (typeof val === 'string' && isoDatePattern.test(val)) {
      item[key] = new Date(val)
    }
  }
  return item
}

export default axios
