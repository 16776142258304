import React from 'react'
import { omit } from 'lodash/fp'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Grid, { GridSize } from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    width: '100%',
    background: 'transparent',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    fontSize: theme.typography.body2.fontSize,
  },
  icon: {
    marginLeft: theme.spacing(1),
    transform: 'translateY(3px)',
  },
}))
export const omitGridProps = omit(['xs', 'lg', 'xl', 'newLine', 'fullWidth', 'tooltip', 'tooltipStyle'])

export interface GridItemProps {
  xs?: GridSize
  lg?: GridSize
  xl?: GridSize
  newLine?: boolean
  tooltip?: string
  tooltipStyle?: React.CSSProperties
}

/**
 * Every field should be wrapped with this component. It provides with media query sizing, tooltip, and whether its field should always be on the new line
 * @param props
 */
const GridItem: React.FunctionComponent<GridItemProps> = props => {
  const classes = useStyles()
  return (
    <>
      {props.newLine && <Divider component="div" className={classes.divider} />}
      <Grid item xs={props.xs || 12} lg={props.lg || 6} xl={props.xl || 4}>
        <div className={classes.container}>
          {props.children}
          {!!props.tooltip && (
            <Tooltip
              title={props.tooltip}
              classes={{ tooltip: classes.tooltip }}
              style={props.tooltipStyle}
              placement="top"
            >
              <HelpOutlineIcon className={classes.icon} />
            </Tooltip>
          )}
        </div>
      </Grid>
    </>
  )
}

export default GridItem
