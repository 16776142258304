import {
  Group,
  GroupFilter,
  GroupRecipientList,
  GroupRecipientListFilter,
  GroupRecipientListInit,
  GroupRecipientListUpdate,
  ListResult,
} from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'
import { GroupsRequestParams, PaginatedRequestParams, singleSortQueryFromPaginatedRequestParams } from '../nm-types'

export interface IGroupListApi {
  createGroupList(outputList: GroupRecipientListInit): Promise<GroupRecipientList>
  getGroups(listId: string, inList: boolean, params: GroupsRequestParams): Promise<ListResult<Group>>
  getGroupList(id: GroupRecipientList['id']): Promise<GroupRecipientList>
  getGroupLists(params: PaginatedRequestParams): Promise<ListResult<GroupRecipientList>>
  removeGroupList(id: GroupRecipientList['id']): Promise<GroupRecipientList>
  updateGroupList(id: GroupRecipientList['id'], update: GroupRecipientListUpdate): Promise<GroupRecipientList>
}

export class GroupListApi implements IGroupListApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  createGroupList(outputList: GroupRecipientListInit): Promise<GroupRecipientList> {
    return this.edgeClient.createGroupRecipientList(outputList)
  }
  updateGroupList(id: GroupRecipientList['id'], update: GroupRecipientListUpdate): Promise<GroupRecipientList> {
    return this.edgeClient.updateGroupRecipientList(id, update)
  }
  removeGroupList(id: GroupRecipientList['id']): Promise<GroupRecipientList> {
    return this.edgeClient.deleteGroupRecipientList(id)
  }
  getGroupList(id: GroupRecipientList['id']): Promise<GroupRecipientList> {
    return this.edgeClient.getGroupRecipientList(id)
  }
  getGroupLists(params: PaginatedRequestParams): Promise<ListResult<GroupRecipientList>> {
    const filter: GroupRecipientListFilter = { searchName: params.filter }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    return this.edgeClient.listGroupRecipientLists(query)
  }

  /**
   * Getting groups to select for the group list
   * @param listId - groupList id
   * @param inList - true to show those in list already, false - those that available to add to the list
   * @param userGroup - we have to exclude user's group from the response, it makes no sense to have your own group in any groupList
   * @param searchName - term to search form
   * @param params - pagination parameters
   */
  getGroups(
    listId: string,
    inList: boolean,
    { userGroup, filter: searchName, ...params }: GroupsRequestParams,
  ): Promise<ListResult<Group>> {
    const filter: GroupFilter = {
      searchName,
      excludeIds: userGroup ? [userGroup] : undefined,
    }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    return inList
      ? this.edgeClient.listGroupRecipientListGroups(listId, query)
      : this.edgeClient.listGroupRecipientListGroupsNotInLists(listId, query)
  }
}
