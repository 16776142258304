import { formatISO } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

import { makeStyles } from '@material-ui/core/styles'
import { DateTimePicker as MuiDatePicker } from '@material-ui/pickers/DateTimePicker'
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider'
import DateRange from '@material-ui/icons/DateRange'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import { FilterProps } from './utils'
import { usePageParams } from '../../../utils'

const useStyles = makeStyles({
  input: {
    cursor: 'pointer',
  },
})

/**
 * Date filter, setting both date and time
 * @param paramName - parameter name
 * @param label - input label to show
 */
const DatePicker = ({ paramName, label, minDate, maxDate, maxDateMessage, minDateMessage }: FilterProps) => {
  const classes = useStyles()
  const [params, setPageParams] = usePageParams()
  const value = params[paramName]

  return (
    <MuiPickersUtilsProvider key={`filter-${paramName}`} utils={DateFnsUtils}>
      <div>
        <MuiDatePicker
          autoOk
          inputVariant="outlined"
          disableFuture
          emptyLabel=""
          format="yyyy-MM-dd hh:mm a"
          label={label}
          onChange={value => value && setPageParams({ [paramName]: formatISO(new Date(value)), pageNumber: '0' })}
          value={value}
          variant="inline"
          minDate={minDate}
          maxDate={maxDate}
          minDateMessage={minDateMessage}
          maxDateMessage={maxDateMessage}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <DateRange />
              </InputAdornment>
            ),
            classes: { root: classes.input, input: classes.input },
          }}
        />
        {value && (
          <IconButton aria-label="clear" onClick={() => setPageParams({ [paramName]: undefined })} edge="end">
            <HighlightOffIcon />
          </IconButton>
        )}
      </div>
    </MuiPickersUtilsProvider>
  )
}

export default DatePicker
