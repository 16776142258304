import React, { useEffect, useState } from 'react'
import { matchPath } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { alpha } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import Link from '@material-ui/core/Link'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'

import { NavLink } from '../Link'
import Divider from '@material-ui/core/Divider'

const styles = (theme: Theme) => ({
  listItem: {
    padding: theme.spacing(1.2, 2),
    cursor: 'pointer',
  },
  link: {
    display: 'block',
    outline: 'none',
    transition: theme.transitions.create('background'),
    '&:hover, &:focus': {
      background: alpha(theme.palette.common.white, 0.15),
    },
    [`&.selected, &.selected:hover`]: {
      background: theme.palette.primary.main,
    },
  },
})
const useStyles = makeStyles(styles)

export interface Item {
  children?: Array<Item | null>
  icon?: React.ReactElement
  url?: string
  href?: string
  name: React.ReactElement | string
  iconColor?: string | false
  divider?: boolean
}
interface MenuItemProps {
  action?: () => void
  id?: string
  item: Item
  location?: string
}

/**
 * Sidebar menu item
 * @param name - text in it
 * @param url - url to lead to
 * @param href - if we need to lead somewhere outside of the system
 * @param icon - icon to show
 * @param children - array of subItems
 * @param iconColor - if wee need to set other color for the icon
 * @param location - current location to know if we need to show children expanded
 * @param action - if we need to call some action instead of leading somewhere
 * @param id
 */
const MenuItem = ({
  item: { name, url, href, icon, children, iconColor },
  location = '',
  action,
  id,
}: MenuItemProps) => {
  const classes = useStyles()
  const shouldBeExpanded = children?.some(child => matchPath(location, { path: child?.url || '' })) || false
  const [expanded, setExpanded] = useState(shouldBeExpanded)

  useEffect(() => {
    setExpanded(shouldBeExpanded)
  }, [location])

  const onClick = () => (children ? setExpanded(!expanded) : null)
  const leftPadding = '34px'
  const listItem = (
    <ListItem dense onClick={action} className={classes.listItem} id={id} component="div">
      {!!icon && <ListItemIcon style={{ minWidth: leftPadding, color: iconColor || 'inherit' }}>{icon}</ListItemIcon>}
      <ListItemText style={{ paddingLeft: icon ? 0 : leftPadding }} primary={name} />
      {!!children && (expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />)}
    </ListItem>
  )
  if (href)
    return (
      <Link href={href} target="_blank" className={classes.link} underline="none">
        {listItem}
      </Link>
    )
  return url ? (
    <NavLink to={url} className={classes.link} activeClassName="selected">
      {listItem}
    </NavLink>
  ) : (
    <>
      <div tabIndex={0} className={classes.link} onClick={onClick}>
        {listItem}
      </div>
      {!!children && (
        <Collapse in={expanded}>
          {children.map((child, ind) => {
            const key = child?.url || `${name}-${ind}`
            return (
              !!child && (
                <React.Fragment key={key}>
                  <MenuItem item={child} />
                  {child.divider && <Divider variant="middle" />}
                </React.Fragment>
              )
            )
          })}
        </Collapse>
      )}
    </>
  )
}

export default MenuItem
