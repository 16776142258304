import Badge from '@material-ui/core/Badge'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { AlarmSeverityLevels, AlarmWithImpact } from 'common/api/v1/types'

type IProps = {
  alarms: AlarmWithImpact[]
}

const AlarmItemNameWithActiveAlarms = ({ alarms }: IProps) => {
  const useStyles = makeStyles(() => ({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }))

  const StyledBadge = withStyles(theme => ({
    badge: {
      transform: 'unset',
      color: theme.palette.grey[900],
    },
    colorPrimary: {
      background: theme.palette.warning.main,
    },
    colorError: {
      background: theme.palette.error.main,
    },
  }))(Badge)

  const classes = useStyles()
  const hasCritical = alarms.some(alarm => alarm.alarmSeverity === AlarmSeverityLevels.critical)
  const badgeColor = hasCritical ? 'error' : 'primary'

  return (
    <div className={classes.container}>
      Alarms
      <StyledBadge overlap="rectangular" color={badgeColor} badgeContent={alarms.length} max={9}></StyledBadge>
    </div>
  )
}

export default AlarmItemNameWithActiveAlarms
