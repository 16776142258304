import { IAlarmApi } from './api'
import { MOCK_DELAY_MS } from '../MockData'
import { AlarmWithImpact, ListResult } from 'common/api/v1/types'

export class MockAlarmApi implements IAlarmApi {
  readAlarmsInBackground(): Promise<AlarmWithImpact[]> {
    return new Promise(resolve =>
      setTimeout(() => {
        return resolve([])
      }, MOCK_DELAY_MS),
    )
  }

  getAlarms(): Promise<ListResult<AlarmWithImpact>> {
    return Promise.resolve({ total: 0, items: [] })
  }
}
