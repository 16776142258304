import { useEffect } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { getTotalOutputCount } from '../../../redux/actions/outputsActions'
import Pendable from '../../common/Pendable'
import { Link } from '../../common/Link'
import NumericalDataSet from '../../common/NumericalDataSet'
import routes from '../../../utils/routes'
import { useApplianceSelector, useInputsSelector } from '../../../utils'
import { DEFAULT_PAGINATION } from '../../../utils'
import { Api, AppDispatch, GlobalState } from '../../../store'
import { getUsers } from '../../../redux/actions/userActions'
import { useAsync } from 'react-async-hook'

const { userApi } = Api

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  container: {
    width: '100%',
    padding: theme.spacing(0, 1),
  },
}))

const Availability = () => {
  const classes = useStyles()
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    dispatch(getTotalOutputCount())
  }, [dispatch])
  const { total: outputsTotal, loading: outputsLoading } = useSelector(
    ({ outputsReducer }: GlobalState) => outputsReducer,
    shallowEqual,
  )
  const { loading: inputsLoading, total: inputsTotal } = useInputsSelector(DEFAULT_PAGINATION)
  const { loading: appliancesLoading, total: appliancesTotal, totalRegions } = useApplianceSelector(DEFAULT_PAGINATION)
  const users = useAsync(() => getUsers(DEFAULT_PAGINATION, { dispatch, userApi }), [])

  const isPending = users.loading || appliancesLoading || inputsLoading || outputsLoading
  return (
    <Pendable pending={isPending}>
      <div className={classes.container}>
        <NumericalDataSet
          values={[
            {
              title: (
                <Link to={routes.inputs()} className={classes.link}>
                  Inputs available
                </Link>
              ),
              value: inputsTotal,
            },
            {
              title: (
                <Link to={routes.outputs()} className={classes.link}>
                  Outputs available
                </Link>
              ),
              value: outputsTotal,
            },
            {
              title: (
                <Link to={routes.users()} className={classes.link}>
                  User accounts
                </Link>
              ),
              value: users.result?.total,
            },
            {
              title: (
                <Link to={routes.appliances()} className={classes.link}>
                  Appliances
                </Link>
              ),
              value: appliancesTotal,
            },
            {
              title: (
                <Link to={routes.regions()} className={classes.link}>
                  Regions
                </Link>
              ),
              value: totalRegions,
            },
          ]}
        />
      </div>
    </Pendable>
  )
}

export default Availability
