import React, { Fragment, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Map as TMap } from 'mapbox-gl'
import { Theme, useTheme } from '@material-ui/core/styles'
import { ApplianceType, Coordinates, GeoAppliance } from 'common/api/v1/types'
import { CircleData, CircleMarkers, Polyline } from '../Markers'
import { AppDispatch, GlobalState } from '../../../../store'
import { getGeoAppliances } from '../../../../redux/actions/applianceActions'
import { FeatureEvents, PopupProps } from '../utils'
import { getBounds } from '../../../../utils'
import { ViewState } from 'react-map-gl'
import { notUndefinedOrNull } from 'common/api/v1/helpers'

const getLocationString = (point: Coordinates) => `${point[0]}:${point[1]}`
type LocatedAppliance = GeoAppliance & Pick<Required<GeoAppliance>, 'geoLocation'>
/**
 * Returns points to show on map and connections between core nodes from different regions
 * @param appliances - array of GeoAppliances
 * @return {
 *   coreNodes: {'ltd:lng': {coordinates, name, id of coreNode}}
 *   coreConnections: {'ltd:lng-ltd:lng': [outputCoreNodeCoordinates, inputCoreNodeCoordinates]}
 *   dots: {'ltd:lng': array of appliances with these coordinates}
 * }
 */
const getMapObjects = (appliances: Array<GeoAppliance>) => {
  const coreNodes: { [key: string]: { coordinates: Coordinates; name: string; id: string } } = {}
  const dots = appliances.reduce<{ [key: string]: Array<LocatedAppliance> }>((acc, item) => {
    if (!item.geoLocation?.coordinates) return acc
    if (item.type === ApplianceType.core && item.region && item.geoLocation?.coordinates)
      coreNodes[item.region.id] = { coordinates: item.geoLocation?.coordinates, name: item.name, id: item.id }
    const key = getLocationString(item.geoLocation.coordinates)
    return { ...acc, [key]: [...(acc[key] || []), item as LocatedAppliance] }
  }, {})
  const coreNodeValues = Object.values(coreNodes)
  const coreConnections = coreNodeValues.reduce<Array<[Coordinates, Coordinates]>>((acc, { coordinates }, ind) => {
    for (let i = ind + 1; i < coreNodeValues.length; i++) {
      acc.push([coordinates, coreNodeValues[i].coordinates])
    }
    return acc
  }, [])
  return { coreNodes, coreConnections, dots }
}
/**
 * returns the color for both line and marker
 * @param theme
 * @param isCoreNode - so it is either green for core node, or blue for other appliances
 */
export const getColor = (theme: Theme, isCoreNode = false) => {
  const colors = {
    appliance: theme.palette.primary.main,
    coreNode: theme.palette.success.light,
  }
  return !isCoreNode ? colors.appliance : colors.coreNode
}

interface ServiceProps {
  map?: TMap
  setPopup: (p: undefined | PopupProps) => void
  featureEvents: FeatureEvents
  setViewport: (viewport: ViewState) => void
}

/**
 * Overview page map
 * Two iterations over dots map, since we have to first draw lines and then markers in order for markers to be on top
 */
const Appliances: React.FunctionComponent<ServiceProps> = ({ map, setPopup, featureEvents, setViewport }) => {
  const theme = useTheme()
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    dispatch(getGeoAppliances({ rowsPerPage: '100', pageNumber: '0' }))
  }, [dispatch])
  const { loading, geoAppliances } = useSelector(
    ({ appliancesReducer }: GlobalState) => appliancesReducer,
    shallowEqual,
  )
  const { dots, coreNodes, coreConnections } = getMapObjects(geoAppliances)
  useEffect(() => {
    const points = Object.values(dots).reduce<Array<Coordinates>>(
      (acc, appliances) => [...acc, ...appliances.map(a => a.geoLocation?.coordinates)],
      [],
    )
    if (map && points.length) {
      // Animations enabled makes map.getCenter() and map.getZoom() return the pre-fitBounds() values which causes setViewport()
      // not work and the map to jump around when the user starts dragging
      map.fitBounds(getBounds(points), { padding: 40, animate: false })
      const center = map.getCenter()
      const zoom = map.getZoom()
      const newBounds = { zoom, longitude: center.lng, latitude: center.lat }
      setViewport(newBounds)
    }
  }, [map, geoAppliances])

  if (loading) return null

  const circles: CircleData[] = Object.entries(dots)
    .map(([_, appliances], index) => {
      const { geoLocation } = appliances[0]
      if (!geoLocation) return null
      return { id: index, coordinates: geoLocation.coordinates, appliances }
    })
    .filter(notUndefinedOrNull)

  return (
    <>
      {coreConnections.map((position, ind) => (
        <Polyline key={`pos-${ind}`} color={getColor(theme, true)} width={2} position={position} />
      ))}
      {Object.values(dots).map((appliances, ind) => {
        const { geoLocation } = appliances[0]
        if (!geoLocation) return null
        const point = geoLocation.coordinates
        return (
          <Fragment key={`appliances-lines-${ind}`}>
            {appliances.map(({ region, type, id }) => {
              if (type === ApplianceType.core) return null
              const { coordinates: coreNodeLocation } =
                region?.id && coreNodes[region.id] ? coreNodes[region.id] : { coordinates: null }
              if (!coreNodeLocation) return null
              return (
                <Polyline
                  key={`appliance-connection-${id}`}
                  color={getColor(theme)}
                  width={2}
                  position={[point, coreNodeLocation]}
                />
              )
            })}
          </Fragment>
        )
      })}
      <CircleMarkers circles={circles} featureEvents={featureEvents} setPopup={setPopup} />
    </>
  )
}

export default Appliances
