import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { makeStyles, Theme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import MenuIcon from '@material-ui/icons/Menu'

import { toggleSidebarMenu } from '../../redux/actions/uiActions'

import { useDebouncedFilter, usePageParams } from '../../utils'
import { AppDispatch, GlobalState } from '../../store'
import SearchInput from './SearchInput'

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    borderBottom: '0',
    zIndex: theme.zIndex.appBar,
    transition: 'all 150ms ease 0s',
    boxShadow: '0',
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  content: {
    marginTop: theme.spacing(2),
  },
}))

export interface WrapperProps {
  name: string
  entityName?: string
  searchbarPlaceholder?: string
}
/**
 * Common wrapper for edge manager page content
 * @param entityName - to show the name of the entity (input name for example) we opened
 * @param name - the name of the page
 * @param searchbarPlaceholder
 * @param children - content of the page
 */
const Wrapper: React.FunctionComponent<WrapperProps> = ({ entityName, name, searchbarPlaceholder, children }) => {
  const classes = useStyles()
  const dispatch = useDispatch<AppDispatch>()
  const ToggleSidebarMenuAction = () => dispatch(toggleSidebarMenu())
  const { isSearchable } = useSelector(({ uiReducer }: GlobalState) => uiReducer, shallowEqual)
  const [params, setPageParams] = usePageParams<{ filter: string; pageNumber: string }>()
  const { filter: initial = '' } = params
  const [filter, setFilter] = useState(initial)
  useDebouncedFilter(filter, filter => setPageParams({ filter, pageNumber: '0' }))
  useEffect(() => {
    if (initial !== filter) setFilter(initial)
  }, [initial])
  return (
    <section>
      <AppBar position="relative" data-test-id="app-bar">
        <Toolbar disableGutters>
          <IconButton edge="start" color="inherit" aria-label="Open drawer" onClick={ToggleSidebarMenuAction}>
            <MenuIcon />
          </IconButton>

          {entityName ? (
            <Typography component="h1" variant="h2" color="inherit" noWrap>
              {name || 'Content dashboard'} / {entityName}
            </Typography>
          ) : (
            <Typography component="h1" variant="h2" color="inherit" noWrap>
              {name || 'Content dashboard'}
            </Typography>
          )}

          <div className={classes.grow} />
          {!!isSearchable && (
            <div className={classes.search}>
              <SearchInput
                initialValue={filter}
                onChange={input => setFilter(input)}
                placeholder={searchbarPlaceholder}
                stretchable
              />
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Grid container spacing={5}>
        <Grid item xs={12} className={classes.content}>
          {children}
        </Grid>
      </Grid>
    </section>
  )
}

export default Wrapper
