import { useState, FormEvent } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import FormHelperText from '@material-ui/core/FormHelperText'

import { GlobalState, AppDispatch } from '../../store'
import { Loading } from '../common/Loading'
import { loginUser } from '../../redux/actions/userActions'
import { LoginLogo } from '../common/LogoFetcher'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const commonInputProps = {
  variant: 'outlined' as const,
  margin: 'normal' as const,
  required: true,
  fullWidth: true,
}

const ErrorHelper = ({ shown }: { shown?: boolean }) =>
  shown ? (
    <FormHelperText error variant="filled">
      Username or password is incorrect!
    </FormHelperText>
  ) : null

export const Login = () => {
  const classes = useStyles()
  const dispatch = useDispatch<AppDispatch>()
  const { loading, loginFail } = useSelector((state: GlobalState) => state.userReducer, shallowEqual)
  const loginUserFunc = (username: string, password: string) => dispatch(loginUser({ username, password }))
  const [user, setUser] = useState({ name: '', password: '' })

  const handleLogin = (e: FormEvent) => {
    e.preventDefault()
    //TODO VALIDATE
    if (user && user.name && user.password) {
      loginUserFunc(user.name, user.password)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Loading loading={loading} />
      <div className={classes.paper}>
        <LoginLogo />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleLogin}>
          <TextField
            {...commonInputProps}
            value={user.name}
            onChange={e => setUser({ ...user, name: e.target.value })}
            id="email"
            label="Email Address"
            autoComplete="email"
            autoFocus
          />
          <TextField
            {...commonInputProps}
            value={user.password}
            onChange={e => setUser({ ...user, password: e.target.value })}
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <ErrorHelper shown={loginFail} />
          <Button
            id="login-button"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!user.name || !user.password}
          >
            Sign In
          </Button>
        </form>
      </div>
    </Container>
  )
}
