import CircularProgress from '@material-ui/core/CircularProgress'
import Modal from '@material-ui/core/Modal'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    position: 'relative' as const,
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    outline: 0,
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  progressBox: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 0,
  },
  progress: {
    margin: theme.spacing(2),
    outline: 0,
  },
}))

interface Iprops {
  loading: boolean
}

/**
 * Loading spinner for login page
 * @param loading - whether it is shown
 */
export const Loading = ({ loading = false }: Iprops) => {
  const classes = useStyles()
  return (
    <Modal open={loading} className={classes.layout}>
      <div className={classes.progressBox}>
        <CircularProgress className={classes.progress} />
      </div>
    </Modal>
  )
}
