import { FormikProps } from 'formik'
import { CoaxInputPort, CoaxPortMode, Input } from 'common/api/v1/types'
import { Select } from '../../../common/Form'
import { get } from 'lodash'
import { useEffect } from 'react'

interface CoaxPortFormProps {
  namePrefix: string
  modes?: CoaxPortMode[]
  form: FormikProps<Input>
}
const CoaxPortForm = ({ namePrefix, modes, form }: CoaxPortFormProps) => {
  const modeKey = `${namePrefix}.mode`
  const modeOptions = (modes || Object.values(CoaxPortMode)).sort((m1, m2) => m1.localeCompare(m2))
  const logicalPort: CoaxInputPort = get(form.values, namePrefix)

  useEffect(() => {
    const isValidMode = modeOptions.includes(logicalPort.mode)
    if (!isValidMode && (logicalPort.mode as string) !== '') {
      form.setFieldValue(modeKey, '')
    }
  }, [modeOptions, logicalPort.mode, modeKey])

  return (
    <Select
      label="Mode"
      name={modeKey}
      required
      newLine
      options={modeOptions}
      validators={{
        oneOf: { validValues: new Set(modeOptions) },
      }}
    />
  )
}

export default CoaxPortForm
