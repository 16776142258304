import { makeStyles, Theme } from '@material-ui/core/styles'

const SIDE_PADDING = 1

export const useStyles = makeStyles((theme: Theme) => ({
  contentList: {
    background: theme.palette.background.paper,
    minHeight: '100%',
  },
  toolbar: {
    padding: theme.spacing(0, SIDE_PADDING),
  },
  itemStyle: {
    background: theme.palette.background.default,
    padding: theme.spacing(1, SIDE_PADDING),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    transition: 'background .2s linear',
  },
  itemHovered: {
    background: theme.palette.grey[300],
  },
  item: {
    margin: theme.spacing(1),
  },
  listContainer: {
    position: 'relative',
    margin: theme.spacing(1, 1, 3, 3),
  },
  listContent: {
    display: 'flex',
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    alignItems: 'center',
    '& > *:first-child': {
      marginRight: theme.spacing(2),
    },
  },
  listItem: {
    position: 'relative',
  },
  listSign: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    minHeight: '50px',
  },
  title: {
    textTransform: 'uppercase',
  },
  card: {
    display: 'flex',
    transition: 'transform .1s, opacity 0.2s',
    margin: theme.spacing(1, 0),
    opacity: 1,
    position: 'relative',
    '&.hovered': {
      opacity: 0.4,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    '&:hover .inputRemove': {
      transform: 'translateX(0)',
      [theme.breakpoints.up('sm')]: {
        boxShadow: `-15px 0px 22px 5px ${theme.palette.background.paper}`,
      },
    },
  },
  thumbnail: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '90px',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  content: {
    padding: theme.spacing(1),
  },
  empty: {
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: theme.spacing(2, 0),
  },
  '@global': {
    '.inputRemove': {
      position: 'absolute',
      right: 0,
      top: 0,
      width: '50px',
      height: '100%',
      transform: 'translateX(100%)',
      transition: 'all .2s',
      background: 'inherit',
      boxShadow: `0 0 0 0 ${theme.palette.background.paper}`,
      '&.shown': {
        transform: 'translateX(0)',
      },
    },
  },
}))
