import { get } from 'lodash/fp'
import { FieldProps } from 'formik'

import FormHelperText from '@material-ui/core/FormHelperText'

interface BackendValidationProps {
  form: FieldProps['form']
  name: string
}

/**
 * For server validation failure display we use Formik's statuses. If we find status for field, we show error helpers below it
 * @param form - form state
 * @param name - field's full name (with full path for complex objects)
 */
const BackendValidation = ({ form, name }: BackendValidationProps) => {
  const status = get(`status.${name}`, form)
  return status ? (
    <FormHelperText error variant="filled">
      {status}
    </FormHelperText>
  ) : null
}

export default BackendValidation
