import React, { useEffect } from 'react'
import cn from 'classnames'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Circle from '@material-ui/icons/FiberManualRecord'
import Round from '@material-ui/icons/FiberManualRecordOutlined'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import ReducedRedundancyIcon from '@material-ui/icons/CallMerge'
import VideocamOffIcon from '@material-ui/icons/VideocamOff'
import NotInterestedIcon from '@material-ui/icons/NotInterested'

import {
  Appliance,
  ApplianceConnectionState,
  ApplianceStatus,
  Input,
  InputAdminStatus,
  InputOperStatus,
  InputStatus,
  LimitedAppliance,
  OutputAdminStatus,
  OutputOperStatus,
  OutputStatus,
  ServiceState,
  ServiceStatus,
} from 'common/api/v1/types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { AppDispatch, GlobalState } from '../../store'
import { registerOutputObserver, unregisterOutputObserver } from '../../redux/actions/outputsActions'
import { registerApplianceObserver, unregisterApplianceObserver } from '../../redux/actions/applianceActions'
import { registerInputObserver, unregisterInputObserver } from '../../redux/actions/inputsActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import { runningDifferentSoftwareVersion } from '../../utils'

const useStyles = makeStyles((theme: Theme) => ({
  warning: {
    color: theme.palette.warning.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  smallIcon: {
    height: '24px',
    width: '24px',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '34px',
    height: '34px',
    '& svg': {
      display: 'block',
    },
  },
  inlineWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      marginLeft: theme.spacing(1),
    },
  },
}))

interface InputIndicatorProps {
  status: InputOperStatus
  disabled?: boolean
}

interface OutputIndicatorProps {
  status: OutputOperStatus
  disabled?: boolean
}

interface ApplianceIndicatorProps {
  status: ApplianceStatus
  restarting?: boolean
  softwareMismatch?: boolean
}

interface ServiceIndicatorProps {
  status: ServiceStatus
}

const InputIndicator = ({ status, disabled }: InputIndicatorProps) => {
  const classes = useStyles()
  if (disabled) return <NotInterestedIcon color="disabled" />
  switch (status) {
    case InputOperStatus.allOk:
      return <Circle fontSize="large" className={cn(classes.success, classes.smallIcon)} />
    case InputOperStatus.inputError:
      return <VideocamOffIcon className={classes.error} />
    case InputOperStatus.notConfigured:
      return <Round fontSize="large" className={cn(classes.success, classes.smallIcon)} />
    case InputOperStatus.tr101290Priority1Error:
      return <Circle fontSize="large" className={cn(classes.warning, classes.smallIcon)} />
    case InputOperStatus.transportError:
      return <LinkOffIcon className={classes.error} />
    case InputOperStatus.reducedRedundancy:
      return <ReducedRedundancyIcon className={cn(classes.success, classes.smallIcon)} />
    case InputOperStatus.metricsMissing:
    default:
      return <HelpOutlineIcon className={classes.warning} />
  }
}

const OutputIndicator = ({ status, disabled }: OutputIndicatorProps) => {
  const classes = useStyles()
  if (disabled) return <NotInterestedIcon color="disabled" />
  switch (status) {
    case OutputOperStatus.allOk:
      return <Circle fontSize="large" className={cn(classes.success, classes.smallIcon)} />
    case OutputOperStatus.inputError:
      return <VideocamOffIcon className={classes.error} />
    case OutputOperStatus.notConfigured:
      return <Round fontSize="large" className={cn(classes.success, classes.smallIcon)} />
    case OutputOperStatus.outputError:
      return <LinkOffIcon className={classes.error} />
    case OutputOperStatus.reducedRedundancy:
      return <ReducedRedundancyIcon className={cn(classes.success, classes.smallIcon)} />
    case OutputOperStatus.metricsMissing:
    default:
      return <HelpOutlineIcon className={classes.warning} />
  }
}

const ApplianceIndicator = ({ status, restarting, softwareMismatch }: ApplianceIndicatorProps) => {
  const classes = useStyles()
  const className = softwareMismatch ? classes.warning : classes.success
  if (restarting) {
    return <CircularProgress size={25} />
  }
  switch (status.state) {
    case ApplianceConnectionState.connected:
      return <Circle fontSize="large" className={cn(className, classes.smallIcon)} />
    case ApplianceConnectionState.neverConnected:
      return <LinkOffIcon className={classes.error} />
    // return <NotInterestedIcon color="disabled" />
    case ApplianceConnectionState.missing:
      return <Circle fontSize="large" className={cn(classes.error, classes.smallIcon)} />
    default:
      return null
  }
}

export const NetworkIndicator = ({ enabled }: { enabled: boolean }) => {
  const classes = useStyles()
  if (!enabled)
    return (
      <Tooltip title={'Disabled'}>
        <NotInterestedIcon color="disabled" />
      </Tooltip>
    )
  return (
    <Tooltip title={'Enabled'}>
      <Circle fontSize="large" className={cn(classes.success, classes.smallIcon)} />
    </Tooltip>
  )
}

export const ServiceIndicator = ({ status }: ServiceIndicatorProps) => {
  const classes = useStyles()
  void status
  return <Circle fontSize="large" className={cn(classes.success, classes.smallIcon)} />
}

interface InputIndicatorWrapperProps {
  status?: InputStatus
  disabled?: boolean
  inline?: boolean
  noText?: boolean
}
/**
 * Input health indicator wrapper
 * @param status - fetched status
 * @param inline - whether it should be inline component
 * @param noText - whether we should hide the text for inline component
 * @param disabled - whether input is disabled
 */
export const InputIndicatorWrapper: React.FunctionComponent<InputIndicatorWrapperProps> = ({
  status,
  inline,
  noText,
  disabled,
}) => {
  if (!status) {
    status = {
      title: 'Unknown',
      state: InputOperStatus.metricsMissing,
    }
  }
  const classes = useStyles()
  return inline || noText ? (
    <div className={classes.inlineWrapper}>
      <InputIndicator status={status.state} disabled={disabled} />
      {!noText && <span>{disabled ? 'Disabled' : status.title}</span>}
    </div>
  ) : (
    <Tooltip title={disabled ? 'Input is disabled' : status.title}>
      <div className={classes.container}>
        <div>
          <InputIndicator status={status.state} disabled={disabled} />
        </div>
      </div>
    </Tooltip>
  )
}

interface OutputIndicatorWrapperProps {
  status?: OutputStatus
  disabled?: boolean
  inline?: boolean
  noText?: boolean
}

/**
 * Output health indicator wrapper
 * @param status - fetched status
 * @param inline - whether it should be inline component
 * @param noText - whether we should hide the text for inline component
 * @param disabled - whether output is disabled
 */
export const OutputIndicatorWrapper: React.FunctionComponent<OutputIndicatorWrapperProps> = ({
  status,
  inline,
  noText,
  disabled,
}) => {
  if (!status) {
    status = {
      title: 'Unknown',
      state: OutputOperStatus.metricsMissing,
    }
  }
  const classes = useStyles()
  const title = disabled ? 'Output is disabled' : status.title
  return inline || noText ? (
    <div className={classes.inlineWrapper}>
      <OutputIndicator status={status.state} disabled={disabled} />
      {!noText && <span>{title}</span>}
    </div>
  ) : (
    <Tooltip title={title}>
      <div className={classes.container}>
        <div>
          <OutputIndicator status={status.state} disabled={disabled} />
        </div>
      </div>
    </Tooltip>
  )
}

interface ApplianceIndicatorWrapperProps {
  status: ApplianceStatus
  disabled?: boolean
  inline?: boolean
  noText?: boolean
  restarting?: boolean
  softwareMismatch?: boolean
}
/**
 * Appliance health indicator wrapper
 * @param status - fetched status
 * @param inline - whether it should be inline component
 * @param noText - whether we should hide the text for inline component
 * @param restarting - whether the appliance is restarting
 * @param softwareMismatch - wheter the appliance software version differ fron buildInfo version
 */
const ApplianceIndicatorWrapper: React.FunctionComponent<ApplianceIndicatorWrapperProps> = ({
  status,
  inline,
  noText,
  restarting,
  softwareMismatch,
}) => {
  const classes = useStyles()
  const title = softwareMismatch ? (
    <div style={{ whiteSpace: 'pre-line', textAlign: 'center' }}>
      {status.title}
      {'\n'}Software version is outdated, restart appliance to update
    </div>
  ) : (
    status.title
  )
  return inline || noText ? (
    <div className={classes.inlineWrapper}>
      <ApplianceIndicator status={status} restarting={restarting} />
      {!noText && <span>{status.title}</span>}
    </div>
  ) : (
    <Tooltip title={restarting ? 'Restarting' : title} placement={'top'}>
      <div className={classes.container}>
        <div>
          <ApplianceIndicator status={status} restarting={restarting} softwareMismatch={softwareMismatch} />
        </div>
      </div>
    </Tooltip>
  )
}

interface ServiceIndicatorWrapperProps {
  status: ServiceStatus
  disabled?: boolean
  inline?: boolean
  noText?: boolean
}
const ServiceIndicatorWrapper: React.FunctionComponent<ServiceIndicatorWrapperProps> = ({ status, inline, noText }) => {
  const classes = useStyles()
  return inline || noText ? (
    <div className={classes.inlineWrapper}>
      <ServiceIndicator status={status} />
      {!noText && <span>{status.title}</span>}
    </div>
  ) : (
    <Tooltip title={status.title}>
      <div className={classes.container}>
        <div>
          <ServiceIndicator status={status} />
        </div>
      </div>
    </Tooltip>
  )
}

interface InputHealthIndicatorProps {
  inputId?: Input['id']
  inline?: boolean
  noText?: boolean
}

interface OutputHealthIndicatorProps {
  outputId: string
  inline?: boolean
  noText?: boolean
}

interface ApplianceHealthIndicatorProps {
  applianceId: string
  inline?: boolean
  noText?: boolean
}

interface ServiceHealthIndicatorProps {
  serviceId: string
  inline?: boolean
  noText?: boolean
}

/**
 * Logic for input health indicator with fetching
 * @param input - input to show the health of
 * @param inline - whether it should be inline component
 * @param noText - whether we should hide the text for inline component
 */
export const InputHealthIndicator = ({ inputId, inline, noText }: InputHealthIndicatorProps) => {
  const dispatch = useDispatch<AppDispatch>()
  if (!inputId) {
    return null
  }
  useEffect(() => {
    dispatch(registerInputObserver({ inputId }))
    return () => {
      dispatch(unregisterInputObserver({ inputId }))
    }
  }, [inputId])

  const selectorInputs = useSelector(({ inputsReducer }: GlobalState) => inputsReducer.inputs, shallowEqual)
  const selectorInput = useSelector(({ inputsReducer }: GlobalState) => inputsReducer.input, shallowEqual)
  const soSelectorInput = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => serviceOverviewReducer.input,
    shallowEqual,
  )
  const input = [...selectorInputs, selectorInput, soSelectorInput].find(o => o?.id == inputId)

  if (!input) {
    return null
  }

  const health = input.health
  if (!health) {
    return null
  }

  return (
    <InputIndicatorWrapper
      status={health}
      inline={inline}
      noText={noText}
      disabled={input.adminStatus === InputAdminStatus.off}
    />
  )
}

/**
 * Logic for output health indicator with fetching
 * @param output - output to show the health of
 * @param inline - whether it should be inline component
 * @param noText - whether we should hide the text for inline component
 */
export const OutputHealthIndicator = ({ outputId, inline, noText }: OutputHealthIndicatorProps) => {
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(registerOutputObserver({ outputId }))
    return () => {
      dispatch(unregisterOutputObserver({ outputId }))
    }
  }, [outputId])

  const selectorOutputs = useSelector(({ outputsReducer }: GlobalState) => outputsReducer.outputs, shallowEqual)
  const selectorOutput = useSelector(({ outputsReducer }: GlobalState) => outputsReducer.output, shallowEqual)
  const output = [...selectorOutputs, selectorOutput].find(o => o?.id == outputId)

  if (!output) {
    return null
  }

  const health = output.health
  if (!health) {
    return null
  }

  return (
    <OutputIndicatorWrapper
      status={health}
      inline={inline}
      noText={noText}
      disabled={output.adminStatus === OutputAdminStatus.off}
    />
  )
}

export const ServiceOverviewApplianceHealthIndicator = ({
  applianceId,
  inline,
  noText,
}: ApplianceHealthIndicatorProps) => {
  if (!applianceId) {
    return null
  }
  const serviceOverviewAppliances: Array<Appliance | LimitedAppliance> = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => serviceOverviewReducer.appliances,
    shallowEqual,
  )
  const appliance = serviceOverviewAppliances.find(a => a?.id == applianceId)
  return appliance && 'health' in appliance && appliance.health ? (
    <ApplianceIndicatorWrapper status={appliance.health} inline={inline} noText={noText} />
  ) : null
}

export const ApplianceHealthIndicator = ({ applianceId, inline, noText }: ApplianceHealthIndicatorProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { buildInfo } = useSelector(({ buildInfoReducer }: GlobalState) => buildInfoReducer, shallowEqual)

  if (!applianceId) {
    return null
  }
  useEffect(() => {
    dispatch(registerApplianceObserver({ applianceId }))
    return () => {
      dispatch(unregisterApplianceObserver({ applianceId }))
    }
  }, [applianceId])

  const { appliances: selectorAppliances, appliance: selectorAppliance } = useSelector(
    ({ appliancesReducer }: GlobalState) => appliancesReducer,
    shallowEqual,
  )

  const appliance = selectorAppliances.find(a => a?.id == applianceId) || selectorAppliance
  const controlSoftwareVersion = appliance?.version?.controlSoftwareVersion
  const dataSoftwareVersion = appliance?.version?.dataSoftwareVersion
  const softwareMismatch =
    runningDifferentSoftwareVersion(controlSoftwareVersion, buildInfo) ||
    runningDifferentSoftwareVersion(dataSoftwareVersion, buildInfo)

  return appliance && appliance.health ? (
    <ApplianceIndicatorWrapper
      status={appliance.health}
      inline={inline}
      noText={noText}
      restarting={appliance._restarting}
      softwareMismatch={softwareMismatch}
    />
  ) : null
}

export const ServiceHealthIndicator = (props: ServiceHealthIndicatorProps) => {
  void props

  return <ServiceIndicatorWrapper noText={true} status={{ state: ServiceState.ok, title: 'All ok' }} />
}
