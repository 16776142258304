import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Paper } from './Form'

const useStyles = makeStyles((theme: Theme) => ({
  message: {
    textTransform: 'uppercase',
  },
  button: {
    margin: theme.spacing(2),
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
  },
}))

export interface FailedFetchingContentProps {
  message: string
  inline?: boolean
}

/**
 * Common component to show errors on empty list returned for entities list page
 * @param message - the message to show
 * @param buttonText - the text for the button that leads to create the entity instance
 * @param url - url for the entity creation form
 */
export const FailedFetchingContent = ({ message, inline }: FailedFetchingContentProps) => {
  const classes = useStyles()

  const content = (
    <div className={classes.centered}>
      <Typography className={classes.message} variant="h2">
        {message}
      </Typography>
      <div className={classes.button}>
        <Button variant="outlined" onClick={() => window.location.reload()}>
          Reload
        </Button>
      </div>
    </div>
  )

  return inline ? content : <Paper>{content}</Paper>
}
