import { useState } from 'react'
import { useDispatch } from 'react-redux'

import cn from 'classnames'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import Thumbnail from '../../../../common/Thumbnail'

import { useStyles } from './style'
import { setInputOfOutput } from '../../../../../redux/actions/outputsActions'
import { useConfirmationDialog } from '../../../../../utils'
import { inputOutputColor } from '../../../../../utils'
import { EnrichedOutput } from '../../../../../api/nm-types'
import { getFormattedTransportStreamContent } from 'common/api/v1/helpers'
import { AppDispatch } from 'src/store'

interface OutputProps {
  output: EnrichedOutput
  isHovered: boolean
}

const Item = ({ output, isHovered }: OutputProps) => {
  const classes = useStyles()
  const dispatch = useDispatch<AppDispatch>()
  const setConfirmation = useConfirmationDialog()
  const [showClear, setShowClear] = useState(false)
  const onClearInput = () => {
    setShowClear(true)
    setConfirmation(
      () => void dispatch(setInputOfOutput({ output, inputId: undefined })),
      'Do you want to stop streaming this input with this output',
      undefined,
      () => setShowClear(false),
    )
  }

  const input = output._input
  const format = getFormattedTransportStreamContent((input?.tsInfo || [])[0])
  return (
    <div className={cn(classes.item, classes.itemStyle, isHovered && classes.itemHovered)} data-test-id="output">
      <Typography
        className={classes.title}
        variant="body1"
        color={isHovered ? 'primary' : output.adminStatus ? 'textPrimary' : 'textSecondary'}
        data-test-id="output-title"
      >
        {output.name}
      </Typography>
      <div>
        {input ? (
          <>
            <div className={classes.container}>
              <Card
                className={cn(classes.card, isHovered && 'hovered')}
                data-test-id="input-streaming"
                style={{ transform: isHovered ? 'translateX(50%)' : 'translateX(0)' }}
              >
                <div className={classes.thumbnail}>
                  <Thumbnail input={input} outputId={output.id} />
                </div>
                <CardContent className={classes.content}>
                  <Typography variant="body2" data-test-id="input-title" {...inputOutputColor(input)}>
                    {input.name}
                  </Typography>
                </CardContent>
                <div className={cn('inputRemove', showClear && 'shown')} title="Clear output">
                  <IconButton aria-label="clear" onClick={onClearInput} edge="end">
                    <CloseIcon />
                  </IconButton>
                </div>
              </Card>
            </div>
            <Typography variant="subtitle2" color="textSecondary">
              {format}
            </Typography>
          </>
        ) : (
          <Typography
            className={cn(classes.empty, classes.content)}
            color="textSecondary"
            variant="caption"
            component="div"
          >
            {isHovered ? 'Drop to switch' : 'Waiting for input'}
          </Typography>
        )}
      </div>
    </div>
  )
}

export default Item
