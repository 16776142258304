import { createSlice } from '@reduxjs/toolkit'

import { BillingReport } from 'common/api/v1/types'
import { readBilling } from '../actions/billingActions'

interface State {
  billing?: BillingReport
  loading: boolean
  error: boolean
}
export const initialStateBilling: State = {
  billing: undefined,
  loading: false,
  error: false,
}
const billingSlice = createSlice({
  name: 'billing',
  initialState: initialStateBilling,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(readBilling.pending, (state): State => ({ ...state, loading: true, error: false, billing: undefined }))
      .addCase(
        readBilling.fulfilled,
        (state, { payload: billing }): State => ({ ...state, loading: false, error: false, billing }),
      )
      .addCase(readBilling.rejected, (state): State => ({ ...state, loading: false, error: true, billing: undefined }))
  },
})

export default billingSlice.reducer
