import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { usePageParams } from '../../../utils'

export enum SortDirection {
  asc = 'asc',
  desc = 'desc',
}

const useStyles = makeStyles({
  columnHeader: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
})

/**
 * Returns reverse direction or 'asc' as default
 * @param direction - current direction
 */
const getNewDirection = (direction?: SortDirection) => {
  if (!direction) return SortDirection.asc
  return direction === SortDirection.asc ? SortDirection.desc : SortDirection.asc
}

export interface ColumnHeaderProps<TSort extends string = string> {
  title: React.ReactNode | string
  sorting?: {
    byParameter: TSort
    hidden?: boolean
  }
}
/**
 * Column header for sortable column (only for Redux parameters based tables)
 * @param byParameter - which sort parameter we should set by clicking on it
 * @param title - column title
 */
const ParamsSortableHeader: React.FC<Required<ColumnHeaderProps>> = ({ sorting: { byParameter }, title }) => {
  const [{ asc, desc }, setPageParams] = usePageParams()
  const classes = useStyles()

  let sortProps: {
    active?: boolean
    direction?: SortDirection
  } = {}
  if ((asc || desc) === byParameter) {
    sortProps = {
      active: true,
      direction: asc ? SortDirection.asc : SortDirection.desc,
    }
  }
  const setSort = (parameter: string, direction: SortDirection) =>
    setPageParams({
      asc: direction === SortDirection.asc ? parameter : undefined,
      desc: direction === SortDirection.desc ? parameter : undefined,
    })

  const onClick = () => setSort(byParameter, getNewDirection(sortProps.direction))

  return (
    <TableSortLabel {...sortProps} onClick={onClick}>
      <Typography variant="body2" className={classes.columnHeader}>
        {title}
      </Typography>
    </TableSortLabel>
  )
}
/**
 * Common table column header
 * @param props - title and sorting properties
 */
const ColumnHeader: React.FC<ColumnHeaderProps> = props => {
  const classes = useStyles()

  return props.sorting && !props.sorting.hidden ? (
    <ParamsSortableHeader {...(props as Required<ColumnHeaderProps>)} />
  ) : (
    <Typography variant="body2" className={classes.columnHeader}>
      {props.title}
    </Typography>
  )
}

export default ColumnHeader
