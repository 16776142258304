import Grid from '@material-ui/core/Grid'
import { Paper } from '../../common/Form'
import React from 'react'

export interface InfoSectionProps {
  // display title
  title?: string

  // selector for tests
  id?: string

  tooltip?: string
}

export const InfoSection: React.FunctionComponent<InfoSectionProps> = ({ children, title, id, tooltip }) => {
  return (
    <Paper id={id} title={title || 'Information'} tooltip={tooltip}>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Paper>
  )
}
