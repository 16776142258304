import {
  Group,
  GroupFilter,
  GroupInit,
  GroupInputPermission,
  GroupOrGroupRecipientList,
  GroupUpdate,
  ListResult,
} from 'common/api/v1/types'
import { EnrichedGroup, GroupsRequestParams, singleSortQueryFromPaginatedRequestParams } from '../nm-types'
import { EdgeClient } from 'common/generated/edgeClient'

export interface GroupWithPermission extends Group {
  _permission?: GroupInputPermission
}

export interface IGroupsApi {
  createGroup(group: GroupInit): Promise<Group>
  getGroupsWithLists(params: GroupsRequestParams): Promise<ListResult<GroupOrGroupRecipientList>>
  getGroup(groupId: Group['id']): Promise<Group>
  getGroups(params: GroupsRequestParams): Promise<ListResult<EnrichedGroup>>
  getPureGroups(params: GroupsRequestParams): Promise<ListResult<Group>>
  getGroupPermissions(params: GroupsRequestParams): Promise<ListResult<GroupWithPermission>>
  removeGroup(id: string): Promise<Group>
  updateGroup(id: string, group: GroupUpdate): Promise<Group>
}

export class GroupsApi implements IGroupsApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  createGroup(group: GroupInit): Promise<Group> {
    return this.edgeClient.createGroup(group)
  }

  updateGroup(id: string, group: GroupUpdate): Promise<Group> {
    return this.edgeClient.updateGroup(id, group)
  }

  removeGroup(id: string): Promise<Group> {
    return this.edgeClient.deleteGroup(id)
  }

  getGroup(groupId: string): Promise<Group> {
    return this.edgeClient.getGroup(groupId)
  }

  /**
   * Returns ListResult of groups populating every one with user, appliance and interface number
   * @param params - pagination params
   */
  async getGroups(params: GroupsRequestParams): Promise<ListResult<EnrichedGroup>> {
    const { items, total } = await this.getPureGroups(params)
    if (items.length === 0) {
      return { items: [], total }
    }
    //TODO: Future improvement: listUsers filter by multiple groupIds
    const users = await Promise.all(
      items.map(group => this.edgeClient.listUsers({ filter: { group: group.id }, skip: 0, limit: 1 })),
    )
    //TODO: Future improvement: listAppliances filter by multiple groupIds
    const appliances = await Promise.all(
      items.map(group => this.edgeClient.listAppliances({ filter: { group: group.id }, skip: 0, limit: 1 })),
    )

    //TODO: Future improvement: listPorts filter by multiple ownerIds
    const ports = (await this.edgeClient.listPorts()).items

    //TODO: Future improvement: include numUsers, numAppliances and numInterfaces in listGroups
    return {
      items: items.map((group, ind) => ({
        ...group,
        _userNumber: users[ind].total,
        _applianceNumber: appliances[ind].total,
        _interfaceNumber: ports.filter(p => p.owner === group.id).length,
      })),
      total,
    }
  }

  /**
   * Returns ListResult of groups and groupLists mixed
   * @param inputNotShared - to show those which are not yet have access to this input
   * @param userGroup - we don't want to share input to the user's group since it already has access obviously
   * @param searchName - term to search for
   * @param params - pagination params
   */
  getGroupsWithLists({
    inputNotShared,
    userGroup,
    filter: searchName,
    ...params
  }: GroupsRequestParams): Promise<ListResult<GroupOrGroupRecipientList>> {
    const filter: GroupFilter = {
      withoutAccessToAnyInputs: inputNotShared ? [inputNotShared] : undefined,
      excludeIds: userGroup ? [userGroup] : undefined,
      searchName,
    }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    return this.edgeClient.listGroupAndRecipientLists(query)
  }

  /**
   * Returns groups without any data population
   * @param inputNotShared - to show those which are not yet have access to this input
   * @param inputShared - to show those which are already have access to this input
   * @param userGroup - if we want to exclude user's group from response
   * @param searchName - term to search for
   * @param params - pagination params
   */
  getPureGroups({
    inputNotShared,
    inputShared,
    userGroup,
    filter: searchName,
    ...params
  }: GroupsRequestParams): Promise<ListResult<Group>> {
    const filter: GroupFilter = {
      withAccessToAnyInputs: inputShared ? [inputShared] : undefined,
      withoutAccessToAnyInputs: inputNotShared ? [inputNotShared] : undefined,
      excludeIds: userGroup ? [userGroup] : undefined,
      searchName,
    }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    return this.edgeClient.listGroups(query)
  }

  /**
   * Returns groups with their access to the input set with params.inputShared
   * @param params - pagination params with inputShared required
   */
  async getGroupPermissions(params: GroupsRequestParams): Promise<ListResult<GroupWithPermission>> {
    const { items, total } = await this.getPureGroups(params)

    let list: Array<GroupWithPermission>
    if (params.inputShared) {
      const distribution = await this.edgeClient.listInputDistributions(params.inputShared)
      list = items.reduce<Array<GroupWithPermission>>((acc, group) => {
        const permission = distribution.find(({ groupId }) => groupId === group.id)
        return [...acc, { ...group, _permission: permission }]
      }, [])
    } else {
      list = items as Array<GroupWithPermission>
    }

    return {
      items: list,
      total,
    }
  }
}
