import {
  Appliance,
  ApplianceConfiguration,
  GeoAppliance,
  ListResult,
  Role,
  UpdateAppliancePayload,
  User,
} from 'common/api/v1/types'
import { WithRedirectBack } from './index'
import { AppliancesRequestParams, EnrichedAppliance, EnrichedApplianceWithOwner } from '../../api/nm-types'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from '../../store'
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from './notificationActions'
import { getCurrentPage, getIsStreamManager, sleep, withDefaultPagination } from '../../utils'
import { get } from 'lodash'
import { getLocationParams } from '../../utils/params'
import routes from '../../utils/routes'

export const removeAppliance = createAsyncThunk<Appliance['id'], Appliance['id'], ThunkApi>(
  'appliance',
  async (id, { dispatch, extra: { api } }) => {
    try {
      await api.appliancesApi.removeAppliance(id)
      dispatch(getAppliances(withDefaultPagination(getLocationParams()) as Partial<AppliancesRequestParams>))
      return id
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'delete appliance' }))
      throw err
    }
  },
)

export const updateAppliance = createAsyncThunk<
  Appliance,
  { appliance: Appliance } & {
    values: UpdateAppliancePayload
  } & WithRedirectBack,
  ThunkApi
>('appliance/updateAppliance', async ({ appliance, redirect, values }, { dispatch, extra: { api, history } }) => {
  try {
    const res = await api.appliancesApi.updateAppliance(appliance.id, values)
    redirect && history().push(routes.appliances())
    dispatch(enqueueSuccessSnackbar(`Appliance ${appliance.name} updated`))
    dispatch(getAppliances(withDefaultPagination(getLocationParams()) as AppliancesRequestParams))
    return res
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'update appliance' }))
    throw err
  }
})

export const getAppliances = createAsyncThunk<
  ListResult<EnrichedApplianceWithOwner>,
  Partial<AppliancesRequestParams>,
  ThunkApi
>('appliance/getAppliances', async (params, { dispatch, getState, extra: { api } }) => {
  try {
    return await api.appliancesApi.getAppliances(
      withDefaultPagination({
        ...params,
        isSystemProvider: get(getState(), 'value.userReducer.user.role') === Role.super,
      }),
    )
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch appliances' }))
    throw err
  }
})

export const getGeoAppliances = createAsyncThunk<ListResult<GeoAppliance>, Partial<AppliancesRequestParams>, ThunkApi>(
  'appliance/getGeoAppliances',
  async (params, { dispatch, getState, extra: { api } }) => {
    try {
      return await api.appliancesApi.getGeoAppliances(
        withDefaultPagination({
          ...params,
          isSystemProvider: get(getState(), 'value.userReducer.user.role') === Role.super,
        }),
      )
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch geo appliances' }))
      throw err
    }
  },
)

export const getAppliance = createAsyncThunk<EnrichedAppliance, Appliance['id'], ThunkApi>(
  'appliance/getAppliance',
  async (id, { dispatch, extra: { api } }) => {
    try {
      return await api.appliancesApi.getAppliance(id)
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch appliance' }))
      throw 'Failed to fetch appliance'
    }
  },
)

export const getTotalRegions = createAsyncThunk<{ totalRegions: number }, undefined, ThunkApi>(
  'appliance/getTotalRegions',
  async (_, { extra: { api } }) => {
    const { total } = await api.regionApi.getRegions(withDefaultPagination({}))
    return { totalRegions: total }
  },
)

export const clearAppliance = createAction('appliances/clearAppliance')
export const clearAppliances = createAction('appliances/clearAppliances')

export const restartAppliance = createAsyncThunk<
  Appliance,
  { id: Appliance['id']; showSuccessSnackbar: boolean },
  ThunkApi
>('appliance/restartAppliance', async ({ id, showSuccessSnackbar }, { dispatch, extra: { api } }) => {
  try {
    const res = await api.appliancesApi.restart(id)
    if (showSuccessSnackbar) {
      dispatch(enqueueSuccessSnackbar('Appliance restarted'))
    }
    return res
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'restart appliance' }))
    throw err
  }
})

export const restartAppliances = createAsyncThunk<void, { ids: Appliance['id'][] }, ThunkApi>(
  'appliance/restartAppliances',
  async ({ ids }, { dispatch }) => {
    ids.forEach(id => {
      dispatch(restartAppliance({ id, showSuccessSnackbar: false }))
    })
    dispatch(enqueueSuccessSnackbar('Appliances are restarting'))
  },
)

export const recreateTunnels = createAsyncThunk<Appliance, { id: Appliance['id'] }, ThunkApi>(
  'appliance/recreateTunnels',
  async ({ id }, { dispatch, extra: { api } }) => {
    try {
      const res = await api.appliancesApi.recreateTunnels(id)
      dispatch(enqueueSuccessSnackbar('Appliance tunnels recreated'))
      return res
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'recreate appliance tunnels' }))
      throw err
    }
  },
)

export const getApplianceConfig = createAsyncThunk<ApplianceConfiguration, Appliance['id'], ThunkApi>(
  'appliance/getApplianceConfig',
  async (id, { dispatch, extra: { api } }) => {
    try {
      return await api.appliancesApi.getConfig(id)
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch appliance config' }))
      throw err
    }
  },
)

export const initAppliance = createAsyncThunk<void, void, ThunkApi>(
  'appliance/initAppliance',
  async (_, { getState, dispatch }) => {
    const user = getState().userReducer.user as User
    if ('id' in user && user.role !== Role.basic) {
      dispatch(getTotalRegions())
    }
  },
)

let hasStartedFetchingAppliances = false
export const registerApplianceObserver = createAsyncThunk<void, { applianceId: string }, ThunkApi>(
  'appliance/registerApplianceObserver',
  async (_, { dispatch, getState }) => {
    if (!hasStartedFetchingAppliances) {
      hasStartedFetchingAppliances = true
      // eslint-disable-next-line no-constant-condition
      while (true) {
        // Await so it doesn't queue up requests if the backend is slow, now it will sleep 10s after each fetch
        await sleep(10 * 1000)
        if (
          !getIsStreamManager(getCurrentPage()) &&
          !!(getState().userReducer.user as User).id &&
          !getState().userReducer.changingUser
        ) {
          await dispatch(getBareAppliances())
        }
      }
    }
  },
)

export const unregisterApplianceObserver = createAction<{ applianceId: string }>(
  'appliance/unregisterApplianceObserver',
)

export const getBareAppliances = createAsyncThunk<ListResult<Appliance>, void, ThunkApi>(
  'appliances/getBareAppliances',
  async (_, { getState, extra: { api } }) => {
    const applianceIds = Object.keys(getState().appliancesReducer.appliancesToObserve)
    if (applianceIds.length > 0) {
      return await api.appliancesApi.getBareAppliances({ ids: applianceIds })
    } else {
      return { total: 0, items: [] }
    }
  },
)
