import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { parseISO, endOfMonth } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

import { Theme, makeStyles } from '@material-ui/core/styles'
import { DatePicker, DatePickerProps } from '@material-ui/pickers/DatePicker'
import { MuiPickersUtilsProvider } from '@material-ui/pickers/MuiPickersUtilsProvider'
import DateRange from '@material-ui/icons/DateRange'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import GetAppIcon from '@material-ui/icons/GetApp'
import { BillingPayload, readBilling } from '../../../redux/actions/billingActions'
import { api } from '../../../utils/routes'
import { usePageParams } from '../../../utils'
import { endOfMonthUTC, startOfDayUTC, startOfMonthUTC } from 'common/api/v1/helpers'
import { Grid } from '@material-ui/core'
import { AppDispatch } from 'src/store'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flex: 1,
  },
  buttonContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  picker: {
    marginRight: theme.spacing(2),
  },
  input: {
    cursor: 'pointer',
  },
  buttonText: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  icon: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
}))

const Picker = (props: DatePickerProps) => {
  const classes = useStyles()
  return (
    <DatePicker
      autoOk
      views={['year', 'month']}
      disableToolbar
      variant="inline"
      format="yyyy MMM"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <DateRange />
          </InputAdornment>
        ),
        classes: { root: classes.input, input: classes.input },
      }}
      {...props}
    />
  )
}

enum DateField {
  'startDate' = 'startDate',
  'endDate' = 'endDate',
}
const getRequestParams = (from: Date, to: Date): BillingPayload => ({
  [DateField.startDate]: from,
  [DateField.endDate]: to,
})

const PeriodFilter = () => {
  const classes = useStyles()
  const [{ startDate, endDate }, setPageParams] = usePageParams<{ startDate: string; endDate: string }>()
  const [from, setFrom] = useState<Date>(startDate ? parseISO(startDate) : startOfMonthUTC(new Date()))
  const [to, setTo] = useState<Date>(endDate ? parseISO(endDate) : new Date())
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    startDate && setFrom(parseISO(startDate))
    endDate && setTo(parseISO(endDate))
  }, [startDate, endDate])
  useEffect(() => {
    dispatch(readBilling(getRequestParams(from, to)))
  }, [dispatch, to, from])

  const setDate = (dateField: DateField, value: Date | null) => {
    if (!value) return
    if (dateField === DateField.endDate) {
      value = endOfMonthUTC(value)
    } else {
      value = startOfMonthUTC(value)
    }
    const params = {
      [DateField.startDate]: dateField === DateField.startDate ? value.toISOString() : startDate,
      [DateField.endDate]: dateField === DateField.endDate ? value.toISOString() : endDate,
    }
    setPageParams(params)
  }
  return (
    <>
      <Grid item className={classes.container}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Picker
            label="From month:"
            value={startOfDayUTC(from)}
            onChange={value => setDate(DateField.startDate, value)}
            className={classes.picker}
            maxDate={to}
            disableFuture
          />
          <Picker
            label="To month:"
            value={startOfDayUTC(to)}
            onChange={value => setDate(DateField.endDate, value)}
            className={classes.picker}
            minDate={from}
            maxDate={endOfMonth(new Date())}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item>
        <Link
          underline="none"
          href={api.billing({
            ...getRequestParams(from, to),
            format: 'pdf',
          })}
        >
          <Button variant="outlined">
            <span className={classes.buttonText}>Download</span>
            <GetAppIcon className={classes.icon} />
          </Button>
        </Link>
      </Grid>
    </>
  )
}

export default PeriodFilter
