import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import {
  AlarmCause,
  AlarmSeverityLevels,
  AlarmWithImpact,
  ListAlarmSortableField,
  VaAlarmOwnSeverityLevels,
} from 'common/api/v1/types'
import { AppDispatch, GlobalState } from '../../../store'
import Pendable from '../../common/Pendable'
import { Table } from '../../common/Table'
import { Link } from '../../common/Link'
import routes from '../../../utils/routes'
import { useAlarmsSelector, useInterval, usePageParams, usePageParamsFilteredSelector } from '../../../utils'
import { AlarmSeverityPicker } from './index'
import { AlarmsRequestParams } from '../../../api/nm-types'
import { getAlarms, readAlarmsInBackground } from '../../../redux/actions/alarmsActions'
import Tooltip from '@material-ui/core/Tooltip'
import { format } from 'date-fns'
import { Size } from '@material-ui/core'
import { DATE_FORMAT_LONG } from 'common/api/v1/helpers'

const useStyles = makeStyles((theme: Theme) => ({
  container: ({ margin }: { margin: string }) => ({
    margin: theme.spacing(0, -margin),
    width: `calc(100% + ${theme.spacing(2 * +margin)}px)`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  }),
  noValue: {
    padding: theme.spacing(2),
  },
}))

const BADGE_SIZE = 1.4
const useTabStyles = makeStyles((theme: Theme) => ({
  tab: {
    minWidth: 'auto',
    padding: theme.spacing(1),
  },
  full: {
    opacity: 1,
  },
  badge: {
    display: 'inline-block',
    padding: `0 ${BADGE_SIZE / 3}rem`,
    margin: theme.spacing(0, 1),
    minWidth: `${BADGE_SIZE}rem`,
    height: `${BADGE_SIZE}rem`,
    lineHeight: BADGE_SIZE * 1.2,
    borderRadius: `${BADGE_SIZE / 2}rem`,
    color: theme.palette.grey[900],
    [`&.${AlarmSeverityLevels.critical}`]: {
      background: theme.palette.error.main,
    },
    [`&.${AlarmSeverityLevels.major}`]: {
      background: theme.palette.warning.main,
    },
    [`&.${AlarmSeverityLevels.minor}`]: {
      background: theme.palette.success.dark,
    },
    [`&.${AlarmSeverityLevels.warning}`]: {
      background: theme.palette.primary.light,
    },
  },
}))

type Alarm = AlarmWithImpact

interface TabLabelProps {
  alarms: { [key: string]: Array<Alarm> }
  type: AlarmSeverityLevels
  isCriticalAmount: boolean
}

const getTabProps = ({ alarms, type, isCriticalAmount }: TabLabelProps) => {
  const classes = useTabStyles()
  const amount = alarms[type].length
  return {
    label: (
      <div>
        {(amount || isCriticalAmount) && (
          <span className={cn(classes.badge, type)}>{isCriticalAmount ? '!!!' : amount}</span>
        )}
        <span>{type}</span>
      </div>
    ),
    className: cn(classes.tab, amount && classes.full),
    value: type,
  }
}

const getAlarmTime = (alarm: Alarm) => new Date(alarm.time)

interface AlarmsProps {
  sideMargin: number
  tab?: AlarmSeverityLevels | AlarmSeverityPicker
  includes?: string
  showTexts?: boolean
  size?: Size
}

const Alarms = ({ sideMargin, tab: paramTab, includes = '', showTexts, size = 'medium' }: AlarmsProps) => {
  const classes = useStyles({ margin: String(sideMargin) })
  const { alarms: activeAlarms, loading } = usePageParamsFilteredSelector((params: AlarmsRequestParams) =>
    // Delete alarmTab since it isn't sent to the backend and causes unnecessary requests on each tab change otherwise
    useAlarmsSelector({ ...params, alarmTab: undefined } as AlarmsRequestParams),
  )
  const { isCriticalAmount } = useSelector(({ alarmsReducer }: GlobalState) => alarmsReducer, shallowEqual)
  const [tab, setTab] = useState(AlarmSeverityPicker.mostSevereWithAlarms)
  const dispatch = useDispatch<AppDispatch>()
  const [params] = usePageParams()

  useEffect(() => {
    dispatch(readAlarmsInBackground({}))
  }, [])

  useInterval(async () => {
    dispatch(getAlarms({ ...params, silent: true }))
  }, 10_000)

  const alarms = activeAlarms.reduce(
    (acc, item) => {
      const severity = item.alarmSeverity
      if (
        severity === VaAlarmOwnSeverityLevels.cleared ||
        !item.alarmCause.toLowerCase().includes(includes.toLowerCase())
      ) {
        return acc
      }
      return { ...acc, [severity]: [...acc[severity], item] }
    },
    {
      [AlarmSeverityLevels.critical]: [],
      [AlarmSeverityLevels.major]: [],
      [AlarmSeverityLevels.minor]: [],
      [AlarmSeverityLevels.warning]: [],
    },
  )

  const [_, setPageParams] = usePageParams()

  let effectiveTab = paramTab || tab
  if (effectiveTab === AlarmSeverityPicker.mostSevereWithAlarms) {
    for (const level of Object.keys(AlarmSeverityLevels) as AlarmSeverityLevels[]) {
      effectiveTab = AlarmSeverityLevels.critical
      if (alarms[level].length > 0) {
        effectiveTab = level
        break
      }
    }
  }

  return (
    <>
      <Tabs
        value={effectiveTab}
        variant="fullWidth"
        onChange={(_, val) => {
          paramTab ? setPageParams({ alarmTab: val }) : setTab(val)
        }}
        className={classes.container}
      >
        {(Object.keys(AlarmSeverityLevels) as AlarmSeverityLevels[]).map(level => (
          <Tab
            key={`tab-${level}`}
            data-test-id={`tab-${level}`}
            {...getTabProps({ alarms, isCriticalAmount, type: level })}
          />
        ))}
      </Tabs>
      <Pendable pending={loading}>
        <Table<Alarm>
          id="alarms"
          emptyMessageComponent={
            <Grid container justifyContent="space-around">
              <Grid item>
                <Typography className={classes.noValue} color="textSecondary">
                  {isCriticalAmount
                    ? 'The amount of alarms is too large, only recent ones are fetched'
                    : `No ${effectiveTab} alarms.`}
                </Typography>
              </Grid>
            </Grid>
          }
          data={alarms[effectiveTab as AlarmSeverityLevels]}
          getCustomEntityId={(alarm: Alarm) => `${alarm.id}-${alarm.applianceId}`}
          props={{ size }}
          isSmall={size === 'small'}
          config={[
            {
              title: 'Time ago',
              getValue: alarm => (
                <Tooltip
                  data-test-id="time-raw"
                  title={format(new Date(getAlarmTime(alarm)), DATE_FORMAT_LONG)}
                  placement="top"
                >
                  <Typography color="textPrimary" variant="body2">
                    {formatDistanceToNow(getAlarmTime(alarm))}
                  </Typography>
                </Tooltip>
              ),
              sorting: { byParameter: ListAlarmSortableField.time },
            },
            {
              title: 'Appliance',
              getValue: ({ applianceId, applianceName }) => {
                if (applianceId) {
                  return (
                    <Link underline="hover" to={routes.appliancesUpdate({ id: applianceId })}>
                      {applianceName}
                    </Link>
                  )
                }
              },
              sorting: { byParameter: ListAlarmSortableField.applianceName },
            },
            {
              title: 'Alarm',
              getValue: ({ alarmCause }) => alarmCause,
              sorting: { byParameter: ListAlarmSortableField.alarmCause },
            },
            showTexts
              ? {
                  title: 'Message',
                  getValue: ({ text }) => text,
                  sorting: { byParameter: ListAlarmSortableField.text },
                }
              : null,
            {
              title: 'Entity',
              getValue: ({ _input, _output, applianceId, applianceName, alarmCause, objectName }) => {
                if (_input) {
                  return (
                    <Link underline="hover" to={routes.service({ id: _input.id })}>
                      {`input "${_input.name}"`}
                    </Link>
                  )
                }
                if (_output) {
                  return (
                    <Link
                      underline="hover"
                      to={
                        _output.input
                          ? routes.service({ id: _output.input, outputId: _output.id })
                          : routes.outputsUpdate({ id: _output.id })
                      }
                    >
                      {`output "${_output.name}"`}
                    </Link>
                  )
                }
                if (applianceId) {
                  return (
                    <Link underline="hover" to={routes.appliancesUpdate({ id: applianceId })}>
                      {`appliance "${applianceName}"`}
                    </Link>
                  )
                }
                if (alarmCause === AlarmCause.BACKUP_JOB_FAILED) {
                  return `job "${objectName}"`
                }
                return ''
              },
            },
          ]}
        />
      </Pendable>
    </>
  )
}
export default Alarms
