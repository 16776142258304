import { Redirect, Route, Switch } from 'react-router-dom'

import { Role } from 'common/api/v1/types'
import StreamManagerMain from '../streamManager'
import { getAppClasses } from '../../Common'
import routes from '../../utils/routes'
import { useUser } from '../../utils'

export const StreamManagerContainer = () => {
  const classes = getAppClasses()
  const user = useUser()

  return (
    <div className={classes.content}>
      <Switch>
        <Route path={routes.stream()} component={StreamManagerMain} />
        {user.role === Role.basic ? (
          <Route>
            <Redirect to={routes.stream()} />
          </Route>
        ) : (
          <Redirect exact from="/" to={routes.overview()} />
        )}
      </Switch>
    </div>
  )
}
