import { makeStyles, Theme } from '@material-ui/core/styles'
import NumericalDataSet from '../../common/NumericalDataSet'
import Pendable from '../../common/Pendable'
import { getTransferredString } from 'common/api/v1/helpers'
import { Grid } from '@material-ui/core'
import { UsageReport } from 'common/api/v1/types'

interface TotalProps {
  usage: UsageReport | undefined
  loading: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      '& > * + *': {
        marginLeft: '20%',
      },
    },
  },
}))

const Total = ({ usage, loading }: TotalProps) => {
  const classes = useStyles()

  return (
    <Pendable pending={loading}>
      <Grid item className={classes.wrapper}>
        {usage && (
          <NumericalDataSet
            values={[
              { title: 'Ingress transferred data', value: getTransferredString(usage.ingressTrafficGB) },
              { title: 'Egress transferred data', value: getTransferredString(usage.egressTrafficGB) },
            ]}
          />
        )}
      </Grid>
    </Pendable>
  )
}

export default Total
