import { parse, compile, Key } from 'path-to-regexp'
import { omit, drop } from 'lodash/fp'
import { UsageRequestParams } from 'src/api/nm-types'
import { GetUsageReportFilter } from 'common/api/v1/types'

interface Params {
  [key: string]: string | number | undefined
}

/**
 * Generates url using path-to-regexp, using params as both path and query parameters
 * @param path
 * @param params
 */
const getUrl = (path: string, params?: Params) => {
  if (!params) return path
  const keys = drop(1, parse(path))
  const pathParams = (keys as Array<Key>).map(({ name }) => name)
  if (Object.entries(params).some(([key, value]) => pathParams.includes(key) && !value)) return ''
  const searchParams = Object.entries(omit(pathParams, params))
  const queryString = searchParams.length
    ? searchParams.reduce(
        (acc, [key, value], ind) =>
          value !== undefined ? `${acc}${ind ? '&' : '?'}${key}=${encodeURIComponent(value as string)}` : acc,
        '',
      )
    : ''
  return `${compile(path)(params)}${queryString}`
}

interface Route {
  (params?: Params): string
  route: string
}

/**
 * Returns Route which is route creator (to use with path-parameters to get the url) with access to the initial path
 * @param path - path with ':<...>' to use as path params
 */
const getRoute = (path: string): Route => {
  const outFunc = (params?: Params) => getUrl(path, params)
  outFunc.route = path
  return outFunc
}

const getCreateRoute = (path: string, query?: string) => getRoute(`${path}/new` + (query ? `\\?${query}` : ''))
const getUpdateRoute = (path: string) => getRoute(`${path}/update/:id`)

const root = {
  alarms: '/alarms',
  audit: '/audit',
  appliances: '/settings/appliances',
  billing: '/settings/billing',
  usage: '/settings/usage',
  geo: '/geo',
  globalSettings: '/globalSettings',
  graph: '/graph',
  inputs: '/inputs',
  interfaces: '/settings/interfaces',
  groups: '/settings/groups',
  groupLists: '/settings/groups/lists',
  outputs: '/outputs',
  outputLists: '/outputs/lists',
  overview: '/overview',
  regions: '/settings/regions',
  service: '/service/:id',
  setting: '/settings',
  stream: '/stream',
  users: '/settings/users',
  services: '/settings/services',
  nodes: '/settings/kubernetesNodes',
  addresses: '/settings/addresses',
  addressMappings: '/settings/addressMappings',
  networks: '/settings/networks',
}

/**
 * In case some api request can be used not just in IApi instance
 */
export const api = {
  billing: (params: { startDate: Date; endDate: Date; format?: string }) => {
    return getRoute('/api/billing-report')({
      startDate: params.startDate.toISOString(),
      endDate: params.endDate.toISOString(),
      format: params.format,
    })
  },
  usage: (params: UsageRequestParams) => {
    const filter: GetUsageReportFilter = {
      startDate: params.startDate,
      endDate: params.endDate,
      format: params.format,
      type: params.type,
    }
    return getRoute('/api/usage-report')() + `?q=${encodeURIComponent(JSON.stringify({ filter }))}`
  },
  appliance: (params: { id: string }) => getRoute('/api/appliance/:id/proxy')({ id: params.id }),
}

/**
 * All the page routes. Never use constants, add new instances here
 */
export default {
  overview: getRoute(root.overview),
  graph: getRoute(root.graph),
  geo: getRoute(root.geo),
  service: getRoute(root.service),
  globalSettings: getRoute(root.globalSettings),

  inputs: getRoute(root.inputs),
  inputsNew: getCreateRoute(root.inputs),
  inputsUpdate: getUpdateRoute(root.inputs),
  inputsCopy: getRoute(`${root.inputs}/copy/:id`),
  inputsDerive: getCreateRoute(root.inputs, `deriveFrom=:id`),

  outputs: getRoute(root.outputs),
  outputsNew: getCreateRoute(root.outputs),
  outputsUpdate: getUpdateRoute(root.outputs),

  outputLists: getRoute(root.outputLists),
  outputListsNew: getCreateRoute(root.outputLists),
  outputListsUpdate: getUpdateRoute(root.outputLists),

  alarms: getRoute(root.alarms),

  appliances: getRoute(root.appliances),
  appliancesConfig: getRoute(`${root.appliances}/:id/config`),
  appliancesUpdate: getUpdateRoute(root.appliances),

  groups: getRoute(root.groups),
  groupsNew: getCreateRoute(root.groups),
  groupsUpdate: getUpdateRoute(root.groups),

  groupLists: getRoute(root.groupLists),
  groupListsNew: getCreateRoute(root.groupLists),
  groupListsUpdate: getUpdateRoute(root.groupLists),

  users: getRoute(root.users),
  usersNew: getCreateRoute(root.users),
  usersUpdate: getUpdateRoute(root.users),

  services: getRoute(root.services),
  servicesUpdate: getUpdateRoute(root.services),

  addressMappings: getRoute(root.addressMappings),
  addressMappingsNew: getCreateRoute(root.addressMappings),
  addressMappingsUpdate: getRoute(`${root.addressMappings}/update/:region/:privateIp`),

  interfaces: getRoute(root.interfaces),
  interfacesUpdate: getUpdateRoute(root.interfaces),

  settings: getRoute(root.setting),

  stream: getRoute(root.stream),

  billing: getRoute(root.billing),

  usage: getRoute(root.usage),

  audit: getRoute(root.audit),

  regions: getRoute(root.regions),
  regionsUpdate: getRoute(`${root.regions}/update/:id`),
  kubernetesNodes: getRoute(root.nodes),
  kubernetesNodesUpdate: getRoute(`${root.nodes}/update/:regionId/node/:name`),

  networks: getRoute(root.networks),
  networkNew: getCreateRoute(root.networks),
  networkUpdate: getUpdateRoute(root.networks),
}
