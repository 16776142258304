import { createSlice } from '@reduxjs/toolkit'

import {
  logoutUser,
  loginUser,
  setAuthorizationError,
  impersonateUser,
  stopImpersonation,
} from '../actions/userActions'
import { isOneOf } from '../actions'
import { EnrichedUser } from '../../api/nm-types'

interface State {
  draftUser?: EnrichedUser
  loading: boolean
  loginFail?: boolean
  saving?: boolean
  total: number
  user: EnrichedUser | unknown
  users: Array<EnrichedUser>
  changingUser: boolean
}

const initialStateUser: State = { user: {}, users: [], loading: false, total: 0, changingUser: false }

const userSlice = createSlice({
  name: 'user',
  initialState: initialStateUser,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(
        setAuthorizationError,
        (state, { payload: loginFail }): State => ({ ...state, loginFail, changingUser: false }),
      )
      .addCase(
        loginUser.fulfilled,
        (state, { payload }): State => ({ ...state, loading: false, user: payload, changingUser: false }),
      )
      .addCase(logoutUser.fulfilled, (): State => initialStateUser)
      .addCase(
        impersonateUser.fulfilled,
        (state, { payload: user }): State => ({ ...state, user, users: [], loading: false, changingUser: false }),
      )
      .addCase(
        stopImpersonation.fulfilled,
        (state, { payload: user }): State => ({ ...state, user, users: [], loading: false, changingUser: false }),
      )
      .addMatcher(
        isOneOf([loginUser.pending, logoutUser.pending, impersonateUser.pending, stopImpersonation.pending]),
        (state): State => ({ ...state, loading: true, changingUser: true }),
      )
      .addMatcher(
        isOneOf([loginUser.rejected, logoutUser.rejected, impersonateUser.rejected, stopImpersonation.rejected]),
        (state): State => ({ ...state, loading: false, changingUser: false }),
      )
  },
})

export default userSlice.reducer
