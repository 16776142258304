import { DelayMode } from './rist'

export const DEFAULT_BUFFER_DURATION = 6000
export const DEFAULT_DELAY = 1000
export const DEFAULT_DELAY_TR101 = 1000
export const DEFAULT_DELAY_MODE = parseInt(process.env.DELAY_MODE || '2') as DelayMode.basedOnOriginTime
export const DEFAULT_DELAY_MODE_TR_101 = parseInt(process.env.DELAY_MODE_TR_101 || '1') as DelayMode.basedOnArrivalTime
export const USE_ORIGIN_TIMING = true
export const SRT_STATS_INTERVAL = 10
export const defaultRegionId = '69920e49-31c4-476a-b6bb-023305262007'
export const EDGE_OPERATOR_API_TOKEN_NAME = 'edge-operator-api-token'
export const EDGE_SUPER_API_TOKEN_SECRET_NAME = 'edge-operator-api-token'
