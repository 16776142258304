import { Route } from 'react-router-dom'

import { Edit } from './Edit'
import { ListView as List } from './List'

import routes from '../../utils/routes'

export const Interfaces = () => (
  <>
    <Route path={routes.interfacesUpdate.route} component={Edit} />
    <Route exact path={routes.interfaces.route} component={List} />
  </>
)
