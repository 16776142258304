import { useEffect, useState, useContext } from 'react'
import cn from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'
import NotInterestedIcon from '@material-ui/icons/NotInterested'

import {
  Input,
  InputAdminStatus,
  OutputAdminStatus,
  OutputOperStatus,
  ThumbnailMode,
  VideoPreviewMode,
} from 'common/api/v1/types'
import waitingThumb from 'url:../../img/thumb-waiting.svg'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { registerOutputObserver, unregisterOutputObserver } from '../../redux/actions/outputsActions'
import { GlobalState, AppDispatch } from '../../store'
import { ThumbnailFetcher } from '../../thumbnail/ThumbnailFetcher'
import { isOutput } from '../../utils'
import { EnrichedOutput } from '../../api/nm-types'
import { ContentDialogContext } from './ContentDialog'
import Button from '@material-ui/core/Button'
import { Preview } from './Preview'
import {
  ElementaryStreamTypeName,
  getInputPidsOnThumbNode,
  getTransportStreamContentInfo,
  getVideoCodec,
  isMpts,
} from 'common/api/v1/helpers'

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    height: 0,
    position: 'relative',
    paddingTop: '56.25%',
  },
  container: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
  },
  common: {
    maxWidth: '100%',
    maxHeight: '100%',
    width: '100%',
    height: '100%',
  },
  img: {
    position: 'absolute',
    top: 0,
    left: 0,
    transition: `opacity 1s`,
  },
  iconContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  videoModalButton: {
    width: '100%',
    height: '100%',
  },
})

interface ThumbnailProps {
  input?: Input
  outputId?: string
  linkToPlaylist?: boolean
}

const Thumbnail = ({ input, outputId, linkToPlaylist = true }: ThumbnailProps) => {
  const classes = useStyles()
  const imgProps = {
    className: cn(classes.common, classes.img),
    alt: 'stream preview thumbnail',
    draggable: false,
    'data-anchor': 'thumbnail',
  }
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (outputId) {
      dispatch(registerOutputObserver({ outputId }))
    }
    return () => {
      if (outputId) {
        dispatch(unregisterOutputObserver({ outputId }))
      }
    }
  }, [outputId])

  const selectorOutputs = useSelector(({ outputsReducer }: GlobalState) => outputsReducer.outputs, shallowEqual)
  const selectorOutput = useSelector(({ outputsReducer }: GlobalState) => outputsReducer.output, shallowEqual)
  const selectorOutputOrRecipientLists = useSelector(
    ({ outputsReducer }: GlobalState) => outputsReducer.outputsWithLists,
    shallowEqual,
  ).filter(o => isOutput(o)) as EnrichedOutput[]
  const output = [...selectorOutputs, selectorOutput, ...selectorOutputOrRecipientLists].find(o => o?.id == outputId)

  const isInputDisabled = input?.adminStatus === InputAdminStatus.off
  const isOutputDisabled = output?.adminStatus == OutputAdminStatus.off
  const isTr101290Disabled = input?.tr101290Enabled === false // TR101290 is most likely disabled due to the the transport protocol being something else than MPEG-TS (meaning that no (Av)TsInfo is available).
  const pids = getInputPidsOnThumbNode(input)
  const { hasMpegTsAudio, hasMpegTsVideo } = getTransportStreamContentInfo(pids)
  const isThumbnailGenerationDisabled = input?.thumbnailMode === ThumbnailMode.none

  let component
  if (isOutputDisabled) {
    component = <DisabledView title={'Output disabled'} />
  } else if (isInputDisabled) {
    component = <DisabledView title={'Input disabled'} />
  } else if (!input) {
    // If an enabled Output doesn't have any Input configured
    component = <NoInputImage />
  } else if (isTr101290Disabled || hasMpegTsVideo) {
    if (isThumbnailGenerationDisabled) {
      component = <NoThumbnailView tooltipText={'Thumbnails disabled'} imageProperties={imgProps} />
    } else {
      const previewMode = input.previewSettings?.mode || VideoPreviewMode.ondemand

      if (output) {
        const isOutputHealthy =
          output.health && [OutputOperStatus.allOk, OutputOperStatus.reducedRedundancy].includes(output.health.state)
        if (isOutputHealthy) {
          component = (
            <ThumbnailImageView
              input={input}
              previewMode={previewMode}
              linkToPlaylist={linkToPlaylist}
              imageProperties={imgProps}
            />
          )
        } else {
          component = <DisabledView title={'Output health not OK'} />
        }
      } else {
        component = (
          <ThumbnailImageView
            input={input}
            previewMode={previewMode}
            linkToPlaylist={linkToPlaylist}
            imageProperties={imgProps}
          />
        )
      }
    }
  } else if (hasMpegTsAudio) {
    component = <AudioOnlyView tooltipText={'Audio only'} imageProperties={imgProps} />
  } else {
    component = <NoThumbnailView tooltipText={'No video'} imageProperties={imgProps} />
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.common}>{component}</div>
      </div>
    </div>
  )
}

export const NoInputImage = () => {
  const classes = useStyles()
  const imgProps = {
    className: cn(classes.common, classes.img),
    alt: 'no input',
    draggable: false,
    'data-anchor': 'thumbnail',
  }
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.common}>
          <NoThumbnailView tooltipText={'No input'} imageProperties={imgProps} />
        </div>
      </div>
    </div>
  )
}

const DisabledView = ({ title }: { title: string }) => {
  const classes = useStyles()
  return (
    <div className={classes.iconContainer}>
      <Tooltip title={title} placement="top">
        <Icon>
          <NotInterestedIcon color="disabled" />
        </Icon>
      </Tooltip>
    </div>
  )
}

const NoThumbnailView = ({ tooltipText, imageProperties }: { tooltipText: string; imageProperties: any }) => {
  return <ThumbnailView text="NO THUMBNAIL" tooltipText={tooltipText} imageProperties={imageProperties} />
}

const AudioOnlyView = ({ tooltipText, imageProperties }: { tooltipText: string; imageProperties: any }) => {
  return <ThumbnailView text="AUDIO ONLY" tooltipText={tooltipText} imageProperties={imageProperties} />
}

const ThumbnailView = ({
  text,
  tooltipText,
  imageProperties,
}: {
  text: string
  tooltipText: string
  imageProperties: any
}) => {
  const classes = useStyles()
  return (
    <div className={classes.iconContainer}>
      <Tooltip title={tooltipText} placement="top">
        <Icon>
          <svg
            width="534"
            height="300"
            viewBox="0 0 534 300"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...imageProperties}
          >
            <rect width="534" height="300" fill="white" />
            <rect width="534" height="300" fill="black" />
            <text
              x="50%"
              y="50%"
              textAnchor="middle"
              fill="white"
              fontFamily="Roboto"
              fontSize="50"
              fontWeight="bold"
              letterSpacing="7"
            >
              {text}
            </text>
            <path d="M91 181H442" stroke="white" strokeWidth="6" strokeDasharray="25 15" />
          </svg>
        </Icon>
      </Tooltip>
    </div>
  )
}

const ThumbnailImageView = ({
  input,
  previewMode,
  imageProperties,
  linkToPlaylist: linkToPlaylist = true,
}: {
  input: Input
  previewMode: VideoPreviewMode
  imageProperties: any
  linkToPlaylist: boolean
}) => {
  const classes = useStyles()
  const inputId = input.id
  const thumbnails = useSelector(({ thumbnailReducer }: GlobalState) => thumbnailReducer.thumbnails)
  const cachedThumbnail = thumbnails[inputId]
  const thumbnail = ThumbnailFetcher.isThumbnailStale(cachedThumbnail) ? undefined : cachedThumbnail
  const FETCH_INTERVAL_MS = 3_000

  const videoCodec = getVideoCodec(input.tsInfo)
  const isMptsInput = isMpts(input.tsInfo)
  const isPlayable = !isMptsInput && videoCodec == ElementaryStreamTypeName.h264

  const {
    actions: { handleOpen },
  } = useContext(ContentDialogContext)

  useEffect(() => {
    ThumbnailFetcher.fetchThumbnailWithId(inputId) // Fetch once initially
    const intervalId = setInterval(() => {
      ThumbnailFetcher.fetchThumbnailWithId(inputId)
    }, FETCH_INTERVAL_MS)
    return () => clearInterval(intervalId)
  }, [inputId])

  return (
    <>
      {thumbnail &&
      (previewMode === VideoPreviewMode.ondemand || previewMode === VideoPreviewMode.alwayson) &&
      linkToPlaylist &&
      isPlayable ? (
        <Button
          className={classes.videoModalButton}
          onClick={() => {
            handleOpen(
              <Preview thumbnail={thumbnail} isOnDemand={previewMode === VideoPreviewMode.ondemand} />,
              `Input: ${input.name}`,
            )
          }}
        >
          <TransitioningImage src={thumbnail.url} errorSrc={waitingThumb} imageProperties={imageProperties} />
        </Button>
      ) : (
        <TransitioningImage src={thumbnail?.url} errorSrc={waitingThumb} imageProperties={imageProperties} />
      )}
    </>
  )
}

const TransitioningImage = ({
  src,
  errorSrc,
  imageProperties,
}: {
  src: string | undefined
  errorSrc: string
  imageProperties: any
}) => {
  const [rearSrc, setRearSrc] = useState(src || errorSrc)
  const [frontSrc, setFrontSrc] = useState(src || errorSrc)
  const [frontOpacity, setFrontOpacity] = useState(0)

  useEffect(() => {
    const nextFrontOpacity = frontOpacity === 0 ? 1 : 0
    const setSrcFn = nextFrontOpacity === 0 ? setRearSrc : setFrontSrc
    if (src) {
      setSrcFn(src)
    } else {
      setRearSrc(errorSrc)
      setFrontSrc(errorSrc)
    }
    setFrontOpacity(nextFrontOpacity)
  }, [src]) // Only re-run the effect if 'src' changes

  return (
    <>
      <img src={rearSrc} onError={() => setRearSrc(errorSrc)} {...imageProperties} />
      <img
        src={frontSrc}
        onError={() => setFrontSrc(errorSrc)}
        style={{ opacity: frontOpacity }}
        {...imageProperties}
      />
    </>
  )
}

export default Thumbnail
