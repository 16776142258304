import { Route } from 'react-router-dom'
import { NetworksList } from './NetworksList'
import routes from '../../utils/routes'
import EditNetwork from './NetworkForm'

export const Networks = () => (
  <>
    <Route path={routes.networkUpdate.route} component={EditNetwork} />
    <Route exact path={routes.networks.route} component={NetworksList} />
    <Route path={routes.networkNew.route} component={EditNetwork} />
  </>
)
