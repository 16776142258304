import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import routes from '../../utils/routes'
import { GroupRecipientList, GroupRecipientListInit, ListResult, GroupRecipientListUpdate } from 'common/api/v1/types'

import { ThunkApi } from '../../store'
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from './notificationActions'
import { withDefaultPagination } from '../../utils'
import { PaginatedRequestParams } from '../../api/nm-types'

export const createGroupList = createAsyncThunk<
  GroupRecipientList,
  GroupRecipientListInit & Pick<GroupRecipientListUpdate, 'addGroups'>,
  ThunkApi
>('groupLists/createGroupList', async (groupList, { dispatch, extra: { api, history } }) => {
  try {
    const res = await api.groupListsApi.createGroupList(groupList)
    history().push(routes.groupLists())
    dispatch(enqueueSuccessSnackbar(`Created group list ${groupList.name}`))
    return res
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'create group list' }))
    throw err
  }
})

export const removeGroupList = createAsyncThunk<GroupRecipientList, GroupRecipientList['id'], ThunkApi>(
  'groupList/removeGroupList',
  async (groupListId, { dispatch, extra: { api, history } }) => {
    try {
      const res = await api.groupListsApi.removeGroupList(groupListId)
      history().push(routes.groupLists())
      dispatch(enqueueSuccessSnackbar(`Removed group list: ${res.name}`))
      return res
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'delete group list' }))
      throw err
    }
  },
)

export const updateGroupList = createAsyncThunk<
  GroupRecipientList,
  {
    groupList: GroupRecipientList & GroupRecipientListUpdate
    withRedirection: boolean
  },
  ThunkApi
>('groupLists/updateGroupList', async ({ groupList, withRedirection }, { dispatch, extra: { api, history } }) => {
  try {
    const res = await api.groupListsApi.updateGroupList(groupList.id, groupList)
    withRedirection && history().push(routes.groupLists())
    dispatch(enqueueSuccessSnackbar(`Edited group list: ${groupList.name}`))
    return res
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'update group list' }))
    throw err
  }
})

export const getGroupLists = createAsyncThunk<
  ListResult<GroupRecipientList>,
  Partial<PaginatedRequestParams>,
  ThunkApi
>('groupLists/getGroupLists', async (params, { extra: { api } }) => {
  return await api.groupListsApi.getGroupLists(withDefaultPagination(params))
})

export const getGroupList = createAsyncThunk<GroupRecipientList, GroupRecipientList['id'], ThunkApi>(
  'groupLists/getGroupList',
  async (id, { extra: { api } }) => {
    return await api.groupListsApi.getGroupList(id)
  },
)

export const clearGroupList = createAction('groupLists/clearGroupList')
