import { useEffect } from 'react'
import { RouteComponentProps, Redirect } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import Grid from '@material-ui/core/Grid'
import {
  clearAppliance,
  getAppliance,
  updateAppliance,
  registerApplianceObserver,
  unregisterApplianceObserver,
  restartAppliance,
  removeAppliance,
  recreateTunnels,
} from '../../../redux/actions/applianceActions'
import { Appliance, ApplianceType, Coordinates, Group, Role } from 'common/api/v1/types'
import { AppDispatch, GlobalState } from '../../../store'
import {
  alarmsThatDisablePort,
  formTransform,
  getApplianceOwnerId,
  useConfirmationDialog,
  useUser,
} from '../../../utils'
import routes from '../../../utils/routes'
import Pendable from '../../common/Pendable'
import Wrapper from '../../common/Wrapper'
import Interfaces, { ISharedPort } from './ApplianceForm/Interfaces'
import Settings from './ApplianceForm/Settings'
import { ButtonsPane, SafeRouting } from '../../common/Form'
import Meta from './ApplianceForm/Meta'
import { RemoveDialog } from './RemoveDialog'
import InputsOutputs from './ApplianceForm/InputsOutputs'
import ApplicationError from '../../common/ApplicationError'

export const Edit = ({ history, match }: RouteComponentProps<{ id: string }>) => {
  const user = useUser()
  const dispatch = useDispatch<AppDispatch>()
  const appliance = useSelector(({ appliancesReducer }: GlobalState) => appliancesReducer.appliance, shallowEqual)
  const { saving, restarting, loading, error, recreatingTunnels } = useSelector(
    ({ appliancesReducer }: GlobalState) => appliancesReducer,
    shallowEqual,
  )
  const showConfirmation = useConfirmationDialog()

  const { devMode } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)

  useEffect(() => {
    const applianceId = match.params.id
    dispatch(registerApplianceObserver({ applianceId }))
    applianceId && dispatch(getAppliance(applianceId))
    return () => {
      dispatch(unregisterApplianceObserver({ applianceId }))
      dispatch(clearAppliance())
    }
  }, [])

  if (appliance && user.group && (appliance.owner as Group).id !== user.group && user.role !== Role.super) {
    return <Redirect to={routes.appliances()} />
  }

  const onSubmit = (appliance: Appliance, values: { ports: Array<ISharedPort>; coordinates?: Coordinates }) => {
    const changingPortOwner = values.ports.some(port => port.owner !== port._owner.id)
    const transformedValues = {
      ...values,
      ports: values.ports.map(port => ({ ...port, networks: port._networks?.map(n => n.id) })),
    }
    const action = () => void dispatch(updateAppliance({ appliance, values: transformedValues, redirect: true }))

    if (changingPortOwner) {
      showConfirmation(
        action,
        'You are changing owner of an interface. All inputs and outputs on this interface will be cleared. Are you sure you want to proceed?',
      )
    } else {
      action()
    }
  }

  const onDelete = (appliance: Appliance) => {
    showConfirmation(() => performDelete(appliance), <RemoveDialog appliance={appliance} />, {
      ok: { text: 'Delete', variant: 'outlined' },
      cancel: { variant: 'contained' },
    })
  }

  const performRestartAppliance = (appliance: Appliance) => {
    showConfirmation(
      () => void dispatch(restartAppliance({ id: appliance.id, showSuccessSnackbar: true })),
      'Restarting will affect all video streams on the appliance. After restart the appliance will run the latest software version. Do you want to continue?',
    )
  }

  const performRecreateTunnels = (appliance: Appliance) => {
    showConfirmation(
      () => void dispatch(recreateTunnels({ id: appliance.id })),
      'Recreating tunnels will affect all video streams on the appliance that involve RIST tunnels. Do you want to continue?',
    )
  }

  const performDelete = (appliance: Appliance) => {
    dispatch(removeAppliance(appliance.id))
    history.push(routes.appliances())
  }

  const isCoreNode = appliance?.type === ApplianceType.core
  const isThumbNode = appliance?.type === ApplianceType.thumb
  const isApplianceOwner = user.group === getApplianceOwnerId(appliance)
  const showDeleteButton =
    ((!isCoreNode && !isThumbNode) || devMode) &&
    (user.role === Role.super || (user.role === Role.admin && isApplianceOwner))

  const ports: ISharedPort[] = (appliance?._physicalPorts || []).map(
    ({ id: port, name, _owner, owner, addresses, networks }) => ({
      port,
      name,
      owner,
      networks: networks?.map(network => network.id),
      _networks: networks,
      _address: addresses[0]?.address,
      _publicAddress: addresses[0]?.publicAddress,
      _interRegionPublicAddress: addresses[0]?.interRegionPublicAddress,
      _owner,
      _alarmsThatDisablePort: alarmsThatDisablePort(appliance, port),
    }),
  )

  const restartAction = (appliance: Appliance) => ({
    'Restart appliance': {
      onClick: () => performRestartAppliance(appliance),
      savingState: !!restarting,
    },
  })

  const recreateTunnelsAction = (appliance: Appliance) => {
    const recreate = {
      'Recreate tunnels': {
        onClick: () => performRecreateTunnels(appliance),
        savingState: !!recreatingTunnels,
      },
    } as const
    if (devMode) {
      return recreate
    } else {
      return {}
    }
  }

  return (
    <Wrapper name="Appliances" entityName={appliance?.name}>
      <Grid container spacing={0}>
        <Pendable pending={loading}>
          {appliance && (
            <Formik
              onSubmit={values => {
                const transformed = formTransform(values, {
                  secondaryRegion: {
                    _transform: (v: string) => {
                      if (v == '' || v == null) {
                        return null
                      }
                      return v
                    },
                  },
                })
                if (values.geoLocation === null) {
                  transformed['geoLocation'] = null
                }
                onSubmit(appliance, transformed)
              }}
              initialValues={{
                id: appliance.id,
                geoLocation: appliance.geoLocation || null,
                logLevel: appliance.logLevel,
                ristserverLogLevel: appliance.ristserverLogLevel,
                ports: ports,
                region: appliance.region || null,
                secondaryRegion: appliance.secondaryRegion || null,
                settings: appliance.settings || {},
              }}
            >
              {formik => (
                <Grid container>
                  <Grid item xs={12}>
                    <SafeRouting enabled={formik.dirty && !formik.isSubmitting} />
                    <Form translate="no" id="appliance-form" noValidate>
                      <Meta appliance={appliance} />
                      <Settings
                        formik={formik}
                        secondaryRegion={appliance.secondaryRegion}
                        region={appliance.region}
                        geoLocation={appliance.geoLocation}
                        type={appliance.type}
                        isCoreNode={isCoreNode}
                      />
                      <Interfaces formik={formik} ports={ports} appliance={appliance} />
                      {appliance.type !== ApplianceType.thumb && <InputsOutputs appliance={appliance} />}
                      <ButtonsPane
                        main={{
                          Cancel: {
                            onClick: () => {
                              history.push(routes.appliances())
                            },
                          },
                          Save: { primary: true, savingState: !!saving, type: 'submit' },
                        }}
                        secondary={
                          showDeleteButton
                            ? {
                                ...restartAction(appliance),
                                'Delete appliance': { onClick: () => onDelete(appliance) },
                                ...recreateTunnelsAction(appliance),
                              }
                            : restartAction(appliance)
                        }
                      />
                    </Form>
                  </Grid>
                </Grid>
              )}
            </Formik>
          )}
          {!appliance && error && !loading && (
            <ApplicationError error={error} onOKButtonClicked={() => history.goBack()} />
          )}
        </Pendable>
      </Grid>
    </Wrapper>
  )
}
