import { makeStyles, Theme } from '@material-ui/core/styles'

import { Paper } from '../../common/Form'
import Wrapper from '../../common/Wrapper'

import Filter from './Filter'
import Summary from './Summary'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(1, 2, 3),
    marginBottom: theme.spacing(2),
    overflowX: 'auto',
  },
}))

const Report = () => {
  const classes = useStyles()
  return (
    <Wrapper name="Billing">
      <Paper classes={classes.paper} title="Period">
        <Filter />
      </Paper>
      <Paper classes={classes.paper} title="Monthly Billing">
        <Summary />
      </Paper>
    </Wrapper>
  )
}

export default Report
