import React, { ChangeEvent } from 'react'
import cn from 'classnames'

import { makeStyles, Theme, alpha } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'

const ICON_WIDTH = 7
const useStyles = makeStyles((theme: Theme) => ({
  search: {
    width: '100%',
    position: 'relative' as const,
    borderRadius: theme.shape.borderRadius * 50,
    backgroundColor: alpha(theme.palette.common.white, theme.palette.type === 'dark' ? 0.1 : 1),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, theme.palette.type === 'dark' ? 0.2 : 0.6),
    },
    '&.stretchable': {
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(ICON_WIDTH),
    height: '100%',
    position: 'absolute' as const,
    pointerEvents: 'none' as const,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: theme.palette.type === 'dark' ? 1 : 0.54,
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, ICON_WIDTH),
    transition: theme.transitions.create('width'),
    width: '100%',
    '&.stretchable': {
      [theme.breakpoints.up('sm')]: {
        width: 120,
        '&:focus': {
          width: 200,
        },
      },
    },
  },
}))

export interface SearchInputProps {
  initialValue?: string
  onChange: (input: string) => void
  placeholder?: string
  stretchable?: boolean
}

/**
 * Component with no logic to show special 'search' input
 * @param initialValue - the initial value for the input
 * @param onChange - callback to call on value _change_
 * @param placeholder - the text inside the empty input
 * @param stretchable - whether we stretch it on focus
 */
const SearchInput: React.FunctionComponent<SearchInputProps> = ({
  initialValue,
  onChange,
  placeholder = 'Search…',
  stretchable,
}) => {
  const classes = useStyles()
  const onInputChangeHandler = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange(event.target.value)
  }
  return (
    <div className={cn(classes.search, stretchable && 'stretchable')}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        value={initialValue}
        onChange={onInputChangeHandler}
        placeholder={placeholder}
        classes={{
          root: classes.inputRoot,
          input: cn(classes.inputInput, stretchable && 'stretchable'),
        }}
      />
    </div>
  )
}
export default SearchInput
