import React, { useEffect, useState } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import cn from 'classnames'

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { AppDispatch, GlobalState } from '../../store'
import { getApplianceConfig } from '../../redux/actions/applianceActions'
import Pendable from '../common/Pendable'
import Wrapper from '../common/Wrapper'
import DataSet from '../common/DataSet'
import { ButtonsPane, GridItem, Paper, useStyles } from '../common/Form'
import { ApplianceConfiguration } from 'common/api/v1/types'

const toStringified = (object: { [key: string]: any }) =>
  Object.entries(object).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: String(value),
    }),
    {},
  )

interface RecursiveDataSetProps {
  object: { [key: string]: any }
  style?: React.CSSProperties
}
const RecursiveDataSet = ({ object, style }: RecursiveDataSetProps) => {
  const theme = useTheme()
  const entries = Object.entries(object)
  const { flat, objects, arrays } = entries.reduce<{
    flat: { [key: string]: any }
    objects: Array<[string, any]>
    arrays: Array<[string, Array<any>]>
  }>(
    (acc, [key, value]) => {
      if (Array.isArray(value)) {
        acc.arrays.push([key, value])
      } else if (value && typeof value === 'object') {
        acc.objects.push([key, value])
      } else {
        acc.flat = { ...acc.flat, [key]: value }
      }
      return acc
    },
    { flat: {}, objects: [], arrays: [] },
  )
  return (
    <div style={style}>
      <DataSet values={toStringified(entries.length > 0 ? flat : { '': object })} />
      {objects.map(([key, value], ind) => (
        <div key={`${key}-${ind}`}>
          <Typography variant="h3">{key}</Typography>
          <RecursiveDataSet object={value} style={{ marginLeft: theme.spacing(2) }} />
        </div>
      ))}
      {arrays.map(([key, value], ind) => (
        <div key={`${key}-${ind}`}>
          <Typography variant="h3">{key}</Typography>
          {value.map((item, i) => (
            <div key={`${item}-${i}`}>
              <Typography variant="h4">Item {i}</Typography>
              <RecursiveDataSet key={`${ind}-${i}`} object={item} style={{ marginLeft: theme.spacing(2) }} />
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

const useOwnStyles = makeStyles((theme: Theme) => ({
  paperItem: {
    marginTop: 0,
    marginBottom: 0,
  },
  itemsContainer: {
    marginTop: theme.spacing(1),
  },
}))

type ApplianceConfigurationObjectsToShow = keyof Pick<
  ApplianceConfiguration,
  'inputs' | 'outputs' | 'tunnels' | 'channels' | 'vaInput' | 'vaOutput' | 'commands'
>
const initialState = (collapsed: boolean) => ({
  inputs: collapsed,
  outputs: collapsed,
  tunnels: collapsed,
  channels: collapsed,
  vaInput: collapsed,
  vaOutput: collapsed,
  commands: collapsed,
  firewallWhitelists: collapsed,
})
export const Config = ({ match }: RouteComponentProps<{ id: string }>) => {
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    match.params.id && dispatch(getApplianceConfig(match.params.id))
  }, [])
  const { devMode } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)
  const { config, loading }: { config?: ApplianceConfiguration; loading?: boolean } = useSelector(
    (state: GlobalState) => state.appliancesReducer,
    shallowEqual,
  )
  const classes = useStyles()
  const ownClasses = useOwnStyles()

  const objects: {
    [key in ApplianceConfigurationObjectsToShow]: string
  } = {
    inputs: 'Inputs',
    outputs: 'Outputs',
    tunnels: 'Tunnels',
    channels: 'Channels',
    vaInput: 'VA inputs',
    vaOutput: 'VA outputs',
    commands: 'Commands',
  }

  const [collapsed, setCollapsed] = useState(initialState(true))

  return (
    <Wrapper name="Appliance configuration">
      <Grid container spacing={0}>
        <Pendable pending={!!match.params.id && loading && !!config}>
          <Paper title="Meta data">
            <GridItem xs={12} lg={6}>
              <DataSet
                values={{
                  logLevel: config?.logLevel,
                  ...(devMode && { ristserverLogLevel: config?.ristserverLogLevel }),
                }}
              />
            </GridItem>
          </Paper>
          <ButtonsPane
            main={
              Object.values(collapsed).some(c => c)
                ? {
                    'Expand all': {
                      onClick: () => setCollapsed(initialState(false)),
                      primary: false,
                    },
                  }
                : {
                    'Collapse all': {
                      onClick: () => setCollapsed(initialState(true)),
                      primary: false,
                    },
                  }
            }
          />
          {(Object.entries(objects) as Array<[ApplianceConfigurationObjectsToShow, string]>).map(
            ([key, title]) =>
              config !== undefined &&
              !!config[key]?.length && (
                <Paper
                  key={key}
                  classes={cn(classes.paper, 'outlined')}
                  title={title}
                  collapsible
                  collapsed={collapsed[key]}
                  onCollapseChange={c => setCollapsed({ ...collapsed, [key]: c })}
                >
                  {(config[key] as any)?.map((object: any, ind: number) => (
                    <GridItem key={`${key}-${ind}`} xs={12} lg={6}>
                      <Paper classes={cn(classes.paper, ownClasses.paperItem)}>
                        <RecursiveDataSet object={object} />
                      </Paper>
                    </GridItem>
                  ))}
                </Paper>
              ),
          )}
          {
            <Paper
              key={'firewallWhitelist'}
              classes={cn(classes.paper, 'outlined')}
              title={'Firewall whitelists'}
              collapsible
              collapsed={collapsed['firewallWhitelists']}
              onCollapseChange={c => setCollapsed({ ...collapsed, firewallWhitelists: c })}
            >
              {config?.firewall.whitelists?.map((object: any, ind: number) => (
                <GridItem key={`${'firewallWhitelist'}-${ind}`} xs={12} lg={6}>
                  <Paper classes={cn(classes.paper, ownClasses.paperItem)}>
                    <RecursiveDataSet object={object} />
                  </Paper>
                </GridItem>
              ))}
            </Paper>
          }
          {navigator.clipboard && (
            <ButtonsPane
              main={{
                'Copy JSON': {
                  onClick: () => void navigator.clipboard.writeText(JSON.stringify(config)),
                  primary: true,
                },
              }}
            />
          )}
        </Pendable>
      </Grid>
    </Wrapper>
  )
}
