import { useState } from 'react'
import { useFormikContext } from 'formik'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import { Output, OutputOperStatus, OutputRecipientList, OutputRecipientListUpdate } from 'common/api/v1/types'
import { Api } from '../../../store'
import PaginatedList from '../../common/SelfStatePaginatedList'
import { Table } from '../../common/Table'
import { getOutputOperState } from 'common/api/v1/helpers'
import { Link } from '../../common/Link'
import routes from '../../../utils/routes'
import { OutputHealthIndicator as HealthIndicator } from '../../common/Indicator'
import { OutputsRequestParams } from '../../../api/nm-types'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minHeight: '48px',
    minWidth: '140px',
  },
}))

const outputListsApi = Api.outputListsApi

const Outputs = () => {
  const classes = useStyles()
  const [hideFilter, setHideFilter] = useState(true)
  const { values, setFieldValue } = useFormikContext<
    OutputRecipientList & Pick<OutputRecipientListUpdate, 'removeOutputs'>
  >()
  const onRemove = (id: Output['id']) => setFieldValue('removeOutputs', values.removeOutputs.concat(id))
  const onCancel = (id: Output['id']) =>
    setFieldValue(
      'removeOutputs',
      values.removeOutputs.filter(outputId => outputId !== id),
    )

  return (
    <Grid item xs={12}>
      <PaginatedList<OutputsRequestParams, Output>
        api={function(params) {
          return outputListsApi.getOutputs(values.id, true, params)
        }}
        emptyMessage="no outputs"
        hideSearch={hideFilter}
        notFoundMessage="no matching outputs"
        onListChange={({ filter, total = 0 }) => {
          setHideFilter(!filter && total < 10)
        }}
        searchPlaceholder="Find specific output…"
        List={({ list }) => (
          <Table
            data={list}
            isSmall
            hasBorders
            noHeader
            config={[
              {
                getValue: ({ name }) => <Typography>{name}</Typography>,
              },
              {
                getValue: output =>
                  getOutputOperState(output).state === OutputOperStatus.outputError ? (
                    <Link type="button" to={routes.alarms()}>
                      <HealthIndicator outputId={output.id} />
                    </Link>
                  ) : (
                    <HealthIndicator outputId={output.id} />
                  ),
              },
              {
                getValue: ({ id }) => (
                  <div className={classes.actions}>
                    {values.removeOutputs.includes(id) ? (
                      <Button className={classes.button} onClick={() => onCancel(id)} variant="outlined" size="small">
                        Cancel remove
                      </Button>
                    ) : (
                      <Tooltip title="Remove from list" placement="top">
                        <IconButton edge="start" aria-label="remove" onClick={() => onRemove(id)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                ),
                props: {
                  align: 'right',
                },
              },
            ]}
          />
        )}
      />
    </Grid>
  )
}

export default Outputs
