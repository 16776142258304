import { Route, Switch } from 'react-router-dom'

import { StreamManagerContainer } from './StreamManagerContainer'
import { NetworkManagerContainer } from './NetworkManagerContainer'
import { getAppClasses } from '../../Common'
import { useUser } from '../../utils'
import routes from '../../utils/routes'
import { Role } from 'common/api/v1/types'
import { ConfirmDialogProvider } from '../common/ConfirmDialog'
import { ContentDialogProvider } from '../common/ContentDialog'

const LoggedIn = () => {
  const classes = getAppClasses()
  const user = useUser()
  return (
    <ConfirmDialogProvider>
      <ContentDialogProvider>
        <div className={classes.root}>
          <Switch>
            <Route
              path={user.role !== Role.basic ? routes.stream.route : undefined}
              component={StreamManagerContainer}
            />
            {user.role !== Role.basic && <Route component={NetworkManagerContainer} />}
          </Switch>
        </div>
      </ContentDialogProvider>
    </ConfirmDialogProvider>
  )
}

export default LoggedIn
