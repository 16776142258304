import React from 'react'
import cn from 'classnames'

import { makeStyles, Theme, alpha } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  coverContainer: {
    position: 'relative',
    minHeight: '78px',
    width: '100%',
  },
  cover: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: alpha(theme.palette.background.paper, 0.5),
    visibility: 'hidden',
    opacity: 0,
    transition: 'all .2s',
    '&.pending': {
      visibility: 'visible',
      opacity: 1,
    },
  },
  progress: {
    position: 'sticky',
    top: '20px',
  },
}))
export interface PendableProps {
  pending: boolean
  cover?: boolean
  id?: string
}
/**
 * Component to display 'loading' state on component (we wrap component with it)
 * @param pending - when we should show the 'loading' state
 * @param children - component to wrap around
 * @param cover - whether we should show component covered with shadow and 'loading' spinner on it, otherwise spinner replaces component
 * @param id
 */
const Pendable: React.FunctionComponent<PendableProps> = ({ pending, children, cover, id }) => {
  const classes = useStyles()
  return !cover ? (
    pending ? (
      <Grid container justifyContent="center">
        <Grid item className={classes.container} data-test-id={id ? `pendable-${id}` : undefined}>
          <CircularProgress data-test-id="progress" />
        </Grid>
      </Grid>
    ) : (
      <>{children}</>
    )
  ) : (
    <div className={classes.coverContainer}>
      {children}
      <Grid container justifyContent="center" className={cn(classes.cover, pending && 'pending')}>
        <Grid item className={classes.container} data-test-id={id ? `pendable-${id}` : undefined}>
          <CircularProgress className={classes.progress} data-test-id="progress" />
        </Grid>
      </Grid>
    </div>
  )
}

export default Pendable
