import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import TablePagination from '@material-ui/core/TablePagination'
import { DEFAULT_PAGINATION, usePageParams } from '../../../utils'

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    background: 'transparent',
  },
  caption: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
  },
  select: {
    fontSize: theme.typography.body2.fontSize,
  },
  selectRoot: {
    marginRight: '20px',
  },
  actions: {},
}))
const useCompactStyles = makeStyles(() => ({
  actions: {
    marginLeft: '14px',
    '& > button': { padding: '8px' },
  },
}))

export interface PaginationProps {
  id?: string
  total: number
  useUrlSearchParams?: boolean
  changePageCallback?: (val: string) => void
  changeRowsPerPageCallback?: (val: string) => void
  page?: string
  compact?: boolean
  perPage?: string
  labelRowsPerPage?: string
  hidePerPageOption?: boolean
}

/**
 * Common pagination component
 * @param id
 * @param changePageCallback - callback to call on page number change
 * @param changeRowsPerPageCallback - callback to call on rows per page number change
 * @param page - default page number
 * @param perPage - default rows per page number
 * @param total - total amount of entities
 * @param useUrlSearchParams - whether we should set url search params 'rowsPerPage' and 'pageNumber' etc, instead of calling callbacks on changes
 * @param compact - display compact pagination
 */
export const Pagination = ({
  id,
  changePageCallback = (_page: string) => undefined,
  changeRowsPerPageCallback = (_rows: string) => undefined,
  page,
  perPage,
  total,
  useUrlSearchParams,
  hidePerPageOption,
  compact,
  ...rest
}: PaginationProps) => {
  const classes = useStyles()
  const compactClasses = useCompactStyles()
  if (compact) {
    classes.actions = compactClasses.actions
  }
  const [params, setPageParams] = usePageParams<{ pageNumber: string; rowsPerPage: string }>()

  if (!total) {
    return null
  }

  // Set current page according to order: 1) TablePagination 2) Url params 3) Defaults
  const pageNumber = page !== undefined ? page : params.pageNumber || DEFAULT_PAGINATION.pageNumber
  const rowsPerPage = perPage !== undefined ? perPage : params.rowsPerPage || DEFAULT_PAGINATION.rowsPerPage

  const setPageNumber = (pageNumber: string) =>
    setPageParams({
      rowsPerPage: String(rowsPerPage),
      pageNumber,
    })
  const setRowsPerPage = (rowsPerPage: string) => {
    DEFAULT_PAGINATION.rowsPerPage = rowsPerPage
    return setPageParams({
      pageNumber: '0',
      rowsPerPage,
    })
  }

  const onPageChange = (_e: React.MouseEvent<HTMLButtonElement> | null, page: number) =>
    useUrlSearchParams ? setPageNumber(String(page)) : changePageCallback(String(page))

  const onRowsPerPageChange = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    useUrlSearchParams ? setRowsPerPage(String(target.value)) : changeRowsPerPageCallback(String(target.value))

  const rowsPerPageOptions = hidePerPageOption
    ? []
    : Array.from(new Set([10, 30, 50, parseInt(rowsPerPage)])).sort((a, b) => a - b)

  return (
    <TablePagination
      data-test-id={id ? `pagination-${id}` : undefined}
      classes={classes}
      component="div"
      count={total}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      page={Number(pageNumber)}
      rowsPerPageOptions={rowsPerPageOptions}
      rowsPerPage={Number(rowsPerPage)}
      {...rest}
    />
  )
}
