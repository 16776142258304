// Might want to include owner when we display appliance name,
// but since this is currently only used for linking a core node
// I'm leaving it out
import { Appliance, ApplianceType, Input, LimitedAppliance, Output, User } from 'common/api/v1/types'
import { Link } from './Link'
import routes from '../../utils/routes'
import { hasAccessToAppliance } from '../../utils'

const ApplianceLink = ({ appliance }: { appliance: Pick<Appliance, 'id' | 'name'> }) => (
  <Link to={routes.appliancesUpdate({ id: appliance.id })} underline="hover">
    {appliance.name}
  </Link>
)

export function getCoreNodesInput(values: Input): (Appliance | LimitedAppliance)[] {
  if (values.appliances && values.appliances[0]?.type === ApplianceType.core) {
    return values.appliances
  }

  if (values.downstreamAppliances.length) {
    return values.downstreamAppliances
  }

  return []
}

export function getCoreNodesOutput(values: Output): (Appliance | LimitedAppliance)[] {
  if (values.appliances && values.appliances[0].type === ApplianceType.core) {
    return values.appliances
  }

  if (values.upstreamAppliances.length) {
    return values.upstreamAppliances.filter(a => a.type === ApplianceType.core)
  }

  return []
}

export function coreNodesList(coreNodes: (Appliance | LimitedAppliance)[], user: User) {
  if (coreNodes.length == 0) {
    return ''
  }
  return (
    <>
      {coreNodes
        .map((coreNode, i, arr) => {
          const components: (JSX.Element | string)[] = [
            hasAccessToAppliance(coreNode, user) ? (
              <ApplianceLink key={coreNode.id} appliance={coreNode} />
            ) : (
              coreNode.name
            ),
          ]
          if (i != arr.length - 1) {
            components.push(', ')
          }
          return components
        })
        .flat()}
    </>
  )
}
