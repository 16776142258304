import { makeStyles, Theme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'

import { FilterProps, FilterType } from './utils'
import DatePicker from './DatePicker'
import Select from './Select'
import FilterAutocomplete from './Autocomplete'
import TextInput from './TextInput'

export * from './utils'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(1, 0),
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0, 1, 2, 0),
    },
  },
}))

interface FiltersProps {
  filters: Array<FilterProps>
}

/**
 * If we need any filter other than just name substring we can add this component (see Audit Log for example)
 * @param filters - array of filters to be added, four types are implemented so far - date, text, autocomplete and select
 */
const Filter = ({ filters }: FiltersProps) => {
  const classes = useStyles()

  return (
    <Toolbar disableGutters className={classes.container}>
      {filters.map(params => {
        if (params.type === FilterType.date) return <DatePicker key={`filter-${params.paramName}`} {...params} />
        if (params.type === FilterType.select) return <Select key={`filter-${params.paramName}`} {...params} />
        if (params.type === FilterType.text) return <TextInput key={`filter-${params.paramName}`} {...params} />
        if (params.type === FilterType.autocomplete && !!params.autocomplete)
          return (
            <FilterAutocomplete
              key={`filter-${params.paramName}`}
              {...(params as FilterProps & { autocomplete: NonNullable<FilterProps['autocomplete']> })}
            />
          )
        return null
      })}
    </Toolbar>
  )
}

export default Filter
