import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Link } from './Link'
import { usePageParams } from '../../utils'

const useStyles = makeStyles((theme: Theme) => ({
  message: {
    textTransform: 'uppercase',
  },
  button: {
    margin: theme.spacing(2),
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}))

export interface MissingContentProps {
  message: string
  buttonText?: string
  url?: string
}

/**
 * Common component to show on empty list returned for entities list page
 * @param message - the message to show
 * @param buttonText - the text for the button that leads to create the entity instance
 * @param url - url for the entity creation form
 */
export const MissingContent = ({ message, buttonText, url }: MissingContentProps) => {
  const [{ filter: filterString }, setPageParams] = usePageParams<{ filter: string }>()
  const classes = useStyles()

  if (filterString)
    return (
      <div className={classes.centered}>
        <Typography className={classes.message} variant="h2">
          {`Could not find "${filterString}"`}
        </Typography>
        <Typography>Currently only names are searchable</Typography>
        <Button
          className={classes.button}
          variant="outlined"
          data-test-id="clear-search-button"
          onClick={() => setPageParams({ filter: undefined })}
        >
          Clear search
        </Button>
      </div>
    )

  return (
    <div className={classes.centered}>
      <Typography className={classes.message} variant="h2">
        {message}
      </Typography>
      {url && buttonText && (
        <div className={classes.button}>
          <Link type="button" to={url}>
            <Button variant="outlined">{buttonText}</Button>
          </Link>
        </div>
      )}
    </div>
  )
}
