import { makeStyles, Theme } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Typography from '@material-ui/core/Typography'

import { Output, OutputRecipientList } from 'common/api/v1/types'
import { Api } from '../../../store'
import Title from '../../common/Dialog/Title'
import { Table } from '../../common/Table'
import PaginatedList from '../../common/SelfStatePaginatedList'
import { PaginatedRequestParams } from '../../../api/nm-types'

const { outputListsApi } = Api

const useStyles = makeStyles((theme: Theme) => ({
  search: {
    padding: theme.spacing(2, 0),
  },
  name: {
    fontWeight: 'bold',
    fontSize: theme.typography.h4.fontSize,
  },
  row: {
    cursor: 'pointer',
  },
}))

interface InputsTableProps {
  output: Output
  closeDialog: () => void
  onListSelect: (output: Output, list: OutputRecipientList) => void
}

const InputsTable = ({ output, closeDialog, onListSelect }: InputsTableProps) => {
  const classes = useStyles()
  const onAddToListClick = (output: Output, list: OutputRecipientList) => () => {
    closeDialog()
    return onListSelect(output, list)
  }

  return (
    <PaginatedList<PaginatedRequestParams, OutputRecipientList>
      api={outputListsApi.getOutputLists.bind(outputListsApi)}
      searchClass={classes.search}
      searchPlaceholder="Find output list…"
      emptyMessage="no output lists are available"
      notFoundMessage="no matching output lists"
      List={({ list }) => (
        <Table
          data={list}
          emptyMessageComponent="no output lists are available"
          props={{ size: 'small' }}
          noHeader
          rowProps={outputList => ({
            onClick: onAddToListClick(output, outputList),
            className: classes.row,
          })}
          config={[
            {
              getValue: ({ name }) => <Typography className={classes.name}>{name}</Typography>,
            },
            {
              getValue: ({ description }) => (
                <Typography variant="body2" color="textSecondary">
                  {description}
                </Typography>
              ),
            },
          ]}
        />
      )}
    />
  )
}

interface SelectInputDialogProps extends InputsTableProps {
  show: boolean
}

export const SelectOutputListDialog = ({ output, show, closeDialog, onListSelect }: SelectInputDialogProps) => {
  return (
    <Dialog open={show && !!output} onClose={closeDialog} maxWidth="sm" fullWidth>
      <Title title={`Add "${output?.name}" to output list`} onClose={closeDialog} />
      <DialogContent>
        <DialogContentText component="div">
          {show && <InputsTable output={output} closeDialog={closeDialog} onListSelect={onListSelect} />}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
