import { SortOrder } from './api/v1/types'
import { jsonParse } from './serialization'

export interface Query<TFilter, TSortOrder = SortOrder> {
    filter?: TFilter
    skip?: number
    limit?: number
    order?: TSortOrder
}

export function toQueryParams(q: Query<any, any>): { q: string } {
    return { q: JSON.stringify(q) }
}

export function fromQueryParams<TFilterType, TSortOrderType = SortOrder>(q: { [key: string]: string }) {
    if (!q.q) {
        return {}
    }
    let obj
    try {
        obj = jsonParse(q.q)
    } catch (e) {
        // Try to decodeURIComponent first to support older API clients
        obj = jsonParse(decodeURIComponent(q.q))
    }
    return obj as Query<TFilterType, TSortOrderType>
}
