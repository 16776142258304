import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import routes from '../../utils/routes'
import { ListResult, Region } from 'common/api/v1/types'
import { ThunkApi } from '../../store'
import { withDefaultPagination } from '../../utils/index'
import { PaginatedRequestParams } from '../../api/nm-types'
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from './notificationActions'
import { RegionDetails } from '../../api/region/api'

export const getRegions = createAsyncThunk<ListResult<Region>, Partial<PaginatedRequestParams>, ThunkApi>(
  'regions/getRegions',
  async (params, { extra: { api } }) => {
    return api.regionApi.getRegions(withDefaultPagination(params))
  },
)

export const getRegionDetails = createAsyncThunk<RegionDetails, Region['id'], ThunkApi>(
  'regions/getRegionDetails',
  async (id, { dispatch, extra: { api } }) => {
    try {
      const region = await api.regionApi.getRegionDetailsById(id)
      return region
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch region' }))
      throw err
    }
  },
)

export const updateRegion = createAsyncThunk<
  void,
  { regionId: string; regionName: string; videoScaleCount: number; thumbScaleCount: number },
  ThunkApi
>('regions/updateRegion', async (params, { dispatch, extra: { api, history } }) => {
  try {
    await api.regionApi.setVideoScale(params.regionId, params.videoScaleCount)
    await api.regionApi.setThumbScale(params.regionId, params.thumbScaleCount)
    history().push(routes.regions())
    dispatch(enqueueSuccessSnackbar(`Updated region: ${params.regionName}`))
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'update region' }))
    throw err
  }
})

export const clearRegions = createAction('regions/clearRegions')
export const clearRegion = createAction('kubernetes/clearRegion')
