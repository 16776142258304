import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Api } from '../../store'
import { Video } from './Video'
import { FetchedThumbnail } from '../../thumbnail/ThumbnailFetcher'
import axios from '../../utils/edgeAxios'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles({
  videoWrapper: {
    width: '500px',
    height: '290px',
  },
  preview: {
    'text-align': 'center',
  },
})
interface PreviewProps {
  thumbnail: FetchedThumbnail
  isOnDemand: boolean
}

const PREVIEW_KEEPALIVE_INTERVAL = 3000
const PREVIEW_PLAYLIST_READY_CHECK_INTERVAL = 500

const PreviewLoading = () => {
  const styles = useStyles()
  return (
    <div className={styles.preview}>
      <CircularProgress />
      <p>Preparing video preview...</p>
    </div>
  )
}

export const Preview: React.FC<PreviewProps> = ({ thumbnail, isOnDemand }) => {
  const styles = useStyles()
  const [playlistReady, setPlaylistReady] = useState(false)
  const playlistUrl = `/hls/${thumbnail?.inputId}/playlist.m3u8`

  useEffect(() => {
    if (isOnDemand) {
      // enable generating preview and keep it alive
      const feedWatchdog = () => Api.inputApi.enableInputPreview(thumbnail.inputId)

      feedWatchdog()
      const interval = setInterval(() => {
        feedWatchdog()
      }, PREVIEW_KEEPALIVE_INTERVAL)
      return () => clearInterval(interval)
    }
  }, [isOnDemand])

  useEffect(() => {
    const checkPlaylistReady = () => {
      return axios
        .head(playlistUrl)
        .then(() => {
          setPlaylistReady(true)
          return true
        })
        .catch(() => false)
    }

    checkPlaylistReady()
    const interval = setInterval(() => {
      if (playlistReady) {
        clearInterval(interval)
        return
      }
      checkPlaylistReady().then(res => {
        if (res) {
          clearInterval(interval)
        }
      })
    }, PREVIEW_PLAYLIST_READY_CHECK_INTERVAL)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className={styles.videoWrapper}>
      {playlistReady ? <Video url={playlistUrl} poster={thumbnail?.url} autoplay={true}></Video> : <PreviewLoading />}
    </div>
  )
}
