import { Route } from 'react-router-dom'
import { Edit } from './Edit'
import { GroupsList as List } from './List'

import routes from '../../utils/routes'
import { Edit as GroupListsEdit } from '../groupLists/Edit'
import { ListView as GroupLists } from '../groupLists/List'

export const Groups = () => (
  <>
    <Route path={routes.groupsUpdate.route} component={Edit} />
    <Route path={routes.groupsNew.route} component={Edit} />
    <Route exact path={routes.groups.route} component={List} />
    <Route path={routes.groupListsUpdate.route} component={GroupListsEdit} />
    <Route path={routes.groupListsNew.route} component={GroupListsEdit} />
    <Route exact path={routes.groupLists.route} component={GroupLists} />
  </>
)
