import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Form, Formik, FormikProps } from 'formik'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import MuiLink from '@material-ui/core/Link'
import Tooltip from '@material-ui/core/Tooltip'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import {
  GlobalSettings,
  ImageName,
  LogLevel,
  Role,
  TlsCertRead,
  TlsCertWrite,
  User,
  ExpFeatures,
  KubernetesProvider,
} from 'common/api/v1/types'
import { AppDispatch, GlobalState } from '../../store'
import { getSettings, saveImages, saveSettings, saveTls, setDevMode } from '../../redux/actions/settingsActions'
import { numericEnum, usePageParams } from '../../utils'
import { getExperimentalDevFeatures, getExperimentalExternalFeatures } from '../../utils/features'
import { ButtonsPane, Paper, Select, TextInput, Checkbox } from '../common/Form'
import Pendable from '../common/Pendable'
import Wrapper from '../common/Wrapper'
import FileInput from './FileInput'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { makeStyles, Theme } from '@material-ui/core/styles'
import cn from 'classnames'
import { useStyles as useCommonStyles } from '../common/Form/styles'
import FormControl from '@material-ui/core/FormControl'
import { ensureOperatorToken } from '../../redux/actions/apiTokensActions'

const useStyles = makeStyles((theme: Theme) => ({
  container: ({ margin }: { margin: string }) => ({
    margin: theme.spacing(0, -margin),
    width: `calc(100% + ${theme.spacing(2 * +margin)}px)`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  }),
  noValue: {
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(PADDING),
  },
  noMarginTop: {
    marginTop: 0,
  },
  backupList: {
    padding: theme.spacing(1),
  },
}))

const PADDING = 2

const useTabStyles = makeStyles((theme: Theme) => ({
  tab: {
    minWidth: 'auto',
    padding: theme.spacing(1),
  },
}))

const getTabProps = ({ type }: TabLabelProps) => {
  const classes = useTabStyles()
  return {
    label: (
      <div>
        <span>{type}</span>
      </div>
    ),
    className: cn(classes.tab),
    value: type,
  }
}

enum GlobalSettingsTabs {
  alarms = 'alarms',
  settings = 'settings',
  licenses = 'licenses',
  'tls certificate' = 'tls certificate',
  'system logo' = 'system logo',
}

interface TabLabelProps {
  type: GlobalSettingsTabs
}

const SettingsForm = ({ values }: FormikProps<GlobalSettings>) => {
  const commonPaperStyles = useCommonStyles().paper
  const { saving, devMode } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)
  const classes = useStyles({ margin: String(2) })
  const dispatch = useDispatch<AppDispatch>()

  return (
    <Grid container>
      <Grid item xs={12}>
        <Form id="settings-form" translate="no" noValidate>
          <Paper title="Settings" classes={`${classes.noMarginTop} ${commonPaperStyles}`}>
            <Select
              label="Edge API log level"
              name="logLevel"
              options={Object.entries(numericEnum(LogLevel)).map(([name, value]) => ({ name, value }))}
            />
            <TextInput name="defaultDelay" label="Default delay" required type="number" noNegative />
            <Select
              label="Default broadcast standard"
              name="defaultBroadcastStandard"
              options={[
                { name: 'DVB', value: 'dvb' },
                { name: 'ATSC', value: 'atsc' },
              ]}
            />
            <Checkbox
              name="ntpEnabled"
              label="NTP enabled"
              disabled={values.kubernetesProvider != KubernetesProvider.metal}
            />
            {values.ntpEnabled && <TextInput name="ntpServer" label="NTP server" />}
          </Paper>
          <Paper title="Optional features">
            {getExperimentalExternalFeatures().map(feature => (
              <Checkbox key={feature.name} label={feature.label} name={`expFeatures.${feature.name}`} />
            ))}
          </Paper>
          <Paper title="Backups">
            <Box className={classes.backupList}>
              <Typography component="span">Config Backups</Typography>
              <Tooltip title="View config backups" placement="top">
                <MuiLink href={'/backup/config'} target="_blank">
                  <IconButton edge="end" aria-label="View logs">
                    <OpenInNewIcon />
                  </IconButton>
                </MuiLink>
              </Tooltip>
            </Box>
          </Paper>
          {devMode && (
            <Paper title="Experimental dev features">
              <FormControl margin="normal">
                <Button onClick={() => dispatch(setDevMode(false))} id="disable-dev-mode-button" variant="outlined">
                  Disable dev mode
                </Button>
                <Button
                  style={{ marginTop: 16 }}
                  onClick={() => void dispatch(ensureOperatorToken())}
                  id="ensure-operator-token-button"
                  variant="contained"
                >
                  Ensure Edge operator token
                </Button>
                {getExperimentalDevFeatures().map(feature => (
                  <Checkbox key={feature.name} label={feature.label} name={`expFeatures.${feature.name}`} />
                ))}
              </FormControl>
            </Paper>
          )}
          <ButtonsPane
            main={{
              Save: { primary: true, savingState: saving, type: 'submit' },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

const TlsForm = () => {
  const commonPaperStyles = useCommonStyles().paper
  const { savingTls } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)
  const classes = useStyles({ margin: String(2) })

  return (
    <Grid container>
      <Grid item xs={12}>
        <Form translate="no" noValidate>
          <Paper title="Tls certificate" classes={`${classes.noMarginTop} ${commonPaperStyles}`}>
            <TextInput name="fingerprint" label="Fingerprint" disabled />
            <TextInput
              name="key"
              label="Key"
              required
              multiline
              tooltip="The server's secret key in plain-text (PEM format)"
            />
            <TextInput
              name="cert"
              label="Certificate"
              required
              multiline
              tooltip="The certificate from the Certificate Authority (CA) in plain-text (PEM format)"
            />
          </Paper>
          <ButtonsPane
            main={{
              Save: { primary: true, savingState: savingTls, type: 'submit' },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

const LicenseForm = () => {
  const commonPaperStyles = useCommonStyles().paper
  const { saving } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)
  const classes = useStyles({ margin: String(2) })

  return (
    <Grid container>
      <Grid item xs={12}>
        <Form id="licenses-form" translate="no" noValidate>
          <Paper title="Licenses" classes={`${classes.noMarginTop} ${commonPaperStyles}`}>
            <TextInput name="zixiFeederKey" label="Zixi feeder key" />
            <TextInput name="zixiReceiverKey" label="Zixi receiver key" />
          </Paper>

          <ButtonsPane
            main={{
              Save: { primary: true, savingState: saving, type: 'submit' },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

const AlarmsForm = () => {
  const commonPaperStyles = useCommonStyles().paper
  const { saving } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)
  const classes = useStyles({ margin: String(2) })

  return (
    <Grid container>
      <Grid item xs={12}>
        <Form id="alarms-form" translate="no" noValidate>
          <Paper title="Alarms" classes={`${classes.noMarginTop} ${commonPaperStyles}`}>
            <Checkbox
              name="showAllNimbra400Alarms"
              label="Show all Nimbra 400 alarms"
              tooltip="Include alarms for objects on connected Nimbra 400-series appliances not directly related to Nimbra Edge"
            />
          </Paper>

          <ButtonsPane
            main={{
              Save: { primary: true, savingState: saving, type: 'submit' },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

const defaultExpFeatures = Object.keys(ExpFeatures).reduce((a, key) => {
  return { ...a, [key]: false }
}, {})

export const Settings = () => {
  const commonPaperStyles = useCommonStyles().paper
  const classes = useStyles({ margin: String(2) })
  const dispatch = useDispatch<AppDispatch>()
  const [{ settingsTab = GlobalSettingsTabs.settings }, setPageParams] = usePageParams()
  useEffect(() => {
    dispatch(getSettings())
  }, [])
  const { settings, user, tls } = useSelector(
    ({ settingsReducer, userReducer }: GlobalState) => ({
      settings: settingsReducer.settings,
      tls: settingsReducer.tls,
      user: userReducer.user as User,
    }),
    shallowEqual,
  )
  const onSubmit = (settingsPayload: GlobalSettings) => {
    dispatch(saveSettings(settingsPayload))
  }
  const onImagesSubmit = () => {
    dispatch(saveImages())
  }
  const onTlsSubmit = (tlsPayload: TlsCertWrite) => {
    dispatch(saveTls(tlsPayload))
  }
  if (user && user.role !== Role.super) return null

  return (
    <Wrapper name="Global settings">
      <Paper classes={classes.paper}>
        <Tabs
          value={settingsTab}
          variant="fullWidth"
          onChange={(_, val) => {
            setPageParams({ settingsTab: val })
          }}
          className={classes.container}
        >
          <Tab key="tab-settings" {...getTabProps({ type: GlobalSettingsTabs.settings })} />
          <Tab key="tab-tlsCertificate" {...getTabProps({ type: GlobalSettingsTabs['system logo'] })} />
          <Tab key="tab-systemLogo" {...getTabProps({ type: GlobalSettingsTabs['tls certificate'] })} />
          <Tab key="tab-licenses" {...getTabProps({ type: GlobalSettingsTabs.licenses })} />
          <Tab key="tab-alarms" {...getTabProps({ type: GlobalSettingsTabs.alarms })} />
        </Tabs>
      </Paper>
      <Grid container spacing={0}>
        <Pendable pending={!settings}>
          {settingsTab === GlobalSettingsTabs.settings && (
            <Formik
              onSubmit={(values, actions) => {
                actions.setSubmitting(false)
                onSubmit(values)
              }}
              initialValues={
                {
                  ...settings,
                  expFeatures: Object.assign({}, defaultExpFeatures, settings?.expFeatures),
                } as GlobalSettings
              }
              component={SettingsForm}
            />
          )}
          {settingsTab === GlobalSettingsTabs['tls certificate'] && (
            <Formik
              onSubmit={({ key, cert }, actions) => {
                actions.setSubmitting(false)
                onTlsSubmit({ key, cert })
              }}
              enableReinitialize={true}
              initialValues={{ ...tls, cert: '', key: '' } as TlsCertWrite & TlsCertRead}
              component={TlsForm}
            />
          )}
          {settingsTab === GlobalSettingsTabs['system logo'] && (
            <>
              <Paper title="System Logo" classes={`${classes.noMarginTop} ${commonPaperStyles}`}>
                <Grid item xs={12}>
                  <FileInput label="Login page logo" name={ImageName.product} id="login-page-logo-file-picker" />
                  <FileInput
                    label="Top left logo"
                    name={ImageName.serviceProvider}
                    text="Best ratio is 5:3"
                    id="left-corner-file-picker"
                  />
                  <FileInput label="Favicon" name={ImageName.favicon} id="favicon-file-picker" />
                </Grid>
              </Paper>
              <ButtonsPane
                main={{
                  Save: { primary: true, onClick: onImagesSubmit, id: 'image-save' },
                }}
              />
            </>
          )}
          {settingsTab === GlobalSettingsTabs.licenses && (
            <Formik
              onSubmit={(values, actions) => {
                actions.setSubmitting(false)
                onSubmit(values)
              }}
              initialValues={
                {
                  ...settings,
                } as GlobalSettings
              }
              component={LicenseForm}
            />
          )}
          {settingsTab === GlobalSettingsTabs.alarms && (
            <Formik
              onSubmit={(values, actions) => {
                actions.setSubmitting(false)
                onSubmit(values)
              }}
              initialValues={
                {
                  ...settings,
                } as GlobalSettings
              }
              component={AlarmsForm}
            />
          )}
        </Pendable>
      </Grid>
    </Wrapper>
  )
}
