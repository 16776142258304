import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { Form, Formik, FormikProps } from 'formik'
import Grid from '@material-ui/core/Grid'
import { getService, updateService, clearService } from '../../redux/actions/serviceActions'
import { Role, Service } from 'common/api/v1/types'
import { AppDispatch, GlobalState } from '../../store'
import { ButtonsPane, Paper, SafeRouting, useStyles, Checkbox } from '../common/Form'
import Pendable from '../common/Pendable'
import Wrapper from '../common/Wrapper'
import { formTransform } from '../../utils'
import { Meta } from './meta'
import { EnrichedUser } from '../../api/nm-types'
import routes from '../../utils/routes'

const getInitialState = (service: Service) => ({
  ...service,
})

const ServiceForm = (formik: FormikProps<Service>, history: RouteComponentProps['history']) => {
  const classes = useStyles()
  const { user: currentUser } = useSelector(({ userReducer }: GlobalState) => userReducer, shallowEqual)
  const saving = useSelector(({ serviceReducer }: GlobalState) => serviceReducer.saving, shallowEqual)
  const user = currentUser as EnrichedUser

  useEffect(() => {
    if (saving === false) formik.setSubmitting(false)
  }, [saving])

  if (user.role !== Role.super) {
    history.goBack()
    return <></>
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <SafeRouting enabled={formik.dirty && !formik.isSubmitting} />
        <Form id="service-form" translate="no" noValidate>
          <Meta service={formik.values} />
          <Paper title="Settings" classes={classes.paper}>
            <Checkbox
              name="collectLogs"
              label="Collect logs"
              fullWidth={false}
              tooltip="Enabling log collection will ensure the logs are collected, indexed and stored for seven days"
              tooltipStyle={{ marginTop: 14, marginLeft: 0 }}
            />
          </Paper>

          <ButtonsPane
            main={{
              Cancel: {
                onClick: () => {
                  history.push(routes.services())
                },
              },
              Save: { savingState: !!saving, primary: true, type: 'submit' },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

export const Edit = ({ history, match }: RouteComponentProps<{ id?: string }>) => {
  const dispatch = useDispatch<AppDispatch>()
  const { serviceToEdit } = useSelector(({ serviceReducer }: GlobalState) => serviceReducer, shallowEqual)

  const onSubmit = (service: Service) => {
    if (serviceToEdit) {
      dispatch(updateService(service))
    }
  }

  useEffect(() => {
    match.params.id && dispatch(getService(match.params.id))
    return () => {
      dispatch(clearService())
    }
  }, [])

  if (!serviceToEdit) {
    return null
  }

  return (
    <Wrapper name="Services" entityName={serviceToEdit ? serviceToEdit.purpose : ' '}>
      <Grid container spacing={0}>
        <Pendable pending={!!match.params.id && !serviceToEdit}>
          <Formik
            onSubmit={values => {
              onSubmit(formTransform(values))
            }}
            initialValues={getInitialState(serviceToEdit)}
          >
            {formik => ServiceForm(formik, history)}
          </Formik>
        </Pendable>
      </Grid>
    </Wrapper>
  )
}
