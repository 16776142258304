import { makeStyles } from '@material-ui/core/styles'
import List from './List'
import TopBar from './TopBar'
import ErrorBoundary from '../common/ErrorBoundary'

const useStyles = makeStyles({
  wrapper: {
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
})

const StreamManagerMain = () => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <TopBar />
      <ErrorBoundary>
        <List />
      </ErrorBoundary>
    </div>
  )
}

export default StreamManagerMain
