const bigintRegex = /^bigint\(-?([\d]*)\)$/

export function bigIntReplacer(key: string, value: any) {
    void key
    if (typeof value == 'bigint') {
        return `bigint(${value.toString()})`
    }
    return value
}

export function bigIntAndDateReviver(this: any, key: string, value: any) {
    if (typeof value == 'string') {
        const match = bigintRegex.exec(value)
        if (match) {
            return BigInt(match[1])
        }
        if (key.endsWith('At') || key.endsWith('Date')) {
            return new Date(value)
        }
    }
    return value
}

export function jsonStringify(o: any) {
    return JSON.stringify(o, bigIntReplacer)
}

export function jsonParse<T>(s: string) {
    return JSON.parse(s, bigIntAndDateReviver) as T
}
