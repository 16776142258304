import { Action, AnyAction } from '@reduxjs/toolkit'

export interface TypedActionCreator<Type extends string> {
  (...args: any[]): Action<Type>
  type: Type
}

export const isOneOf = <ActionCreator extends TypedActionCreator<string>>(actions: ActionCreator[]) => (
  action: AnyAction,
): action is ReturnType<ActionCreator> => actions.map(({ type }) => type).includes(action.type)

export interface WithRedirectBack {
  redirect?: boolean
}
