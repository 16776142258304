import { merge } from 'lodash/fp'

import { createTheme } from '@material-ui/core/styles'

import commonSettings, { NimbraBlue, NimbraGrey } from './common'
export { NimbraBlue, NimbraGrey } from './common'

export default createTheme(
  merge(commonSettings, {
    palette: {
      type: 'dark',
      background: {
        default: NimbraGrey[1000],
        paper: NimbraGrey[900],
      },
    },
    typography: {
      fontFamily: "'Roboto', sans-serif",
    },
    overrides: {
      MuiToolbar: {
        regular: {
          backgroundColor: NimbraGrey[1000],
          color: '#ffffff',
        },
      },
      MuiButton: {
        outlinedPrimary: {
          color: '#ffffff',
        },
        outlinedSecondary: {
          color: '#ffffff',
        },
      },
      MuiListItem: {
        button: {
          '&:hover': {
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
          '&:focus': {
            backgroundColor: NimbraBlue[500],
          },
        },
      },
      MuiLink: {
        root: {
          color: '#ffffff',
        },
      },
      MuiFormHelperText: {
        root: {
          // Use existing space for helper text like validation errors to prevent shifting content below field
          marginTop: 0,
          height: 0,
        },
      },
      MuiAutocomplete: {
        popper: {
          border: `1px solid ${NimbraGrey[800]}`,
        },
      },
      MuiPopover: {
        paper: {
          border: `1px solid ${NimbraGrey[800]}`,
        },
      },
    },
  }),
)
