import Button from '@material-ui/core/Button'

import { ListUserSortableField, Role } from 'common/api/v1/types'
import { isEditableGroup, useConfirmationDialog, usePageParamsFiltered, useUser } from '../../utils'
import Table, { MoveToEntity, TableConfig } from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import Wrapper from '../common/Wrapper'
import { Link } from '../common/Link'
import routes from '../../utils/routes'
import { EnrichedUser, UsersRequestParams } from '../../api/nm-types'
import IconButton from '@material-ui/core/IconButton'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import Tooltip from '@material-ui/core/Tooltip'
import { useDispatch } from 'react-redux'
import { getUsers, impersonateUser } from '../../redux/actions/userActions'
import { Api, AppDispatch } from '../../store'
import { useAsync } from 'react-async-hook'
import { FailedFetchingContent } from '../common/FailedFetchingContent'

const { userApi } = Api

const RoleNum = Object.keys(Role).reduce((a, c, i) => {
  a[c as Role] = i
  return a
}, {} as Record<Role, number>)

export const ListView = () => {
  const dispatch = useDispatch<AppDispatch>()
  const searchParams = usePageParamsFiltered<UsersRequestParams>()
  const users = useAsync(() => getUsers(searchParams, { dispatch, userApi }), [JSON.stringify(searchParams)])

  const currentUser = useUser()

  const canEdit = (user: EnrichedUser) =>
    currentUser.role === Role.super ||
    (currentUser.role === Role.admin && currentUser.group === user.group && user.role !== Role.super)

  const canImpersonate = (user: EnrichedUser) => {
    if (user.id === currentUser.id) return false
    if (currentUser.role !== Role.super && user.group !== currentUser.group) return false
    return RoleNum[user.role] < RoleNum[currentUser.role]
  }

  const showImpersonationDialog = useConfirmationDialog()
  const onImpersonateButtonClicked = (user: EnrichedUser) =>
    showImpersonationDialog(
      () => void dispatch(impersonateUser(user.id)),
      <>
        Do you want to log in as <strong>{user.username}</strong>?
        <br />
        You will be logged out from your current session.
      </>,
    )

  const tableConfig: TableConfig<EnrichedUser, ListUserSortableField> = [
    {
      title: 'name',
      getValue: ({ username }) => username,
      sorting: { byParameter: ListUserSortableField.userName },
    },
    {
      title: 'group',
      getValue: ({ _group }) => (
        <Link
          to={routes.groupsUpdate({ id: _group.id })}
          underline="hover"
          available={isEditableGroup(_group.id, currentUser)}
        >
          {_group.name}
        </Link>
      ),
      sorting: { byParameter: ListUserSortableField.groupName },
    },
    {
      title: 'role',
      getValue: ({ role }) => role,
      sorting: { byParameter: ListUserSortableField.role },
    },
    {
      getValue: user => (
        <>
          <Tooltip title={`Log in as ${user.username}`} placement="top" style={{ marginRight: 16 }}>
            <span>
              <IconButton
                disabled={!canImpersonate(user)}
                onClick={() => canImpersonate(user) && onImpersonateButtonClicked(user)}
                edge="end"
                aria-label="Impersonate"
              >
                <SupervisorAccountIcon />
              </IconButton>
            </span>
          </Tooltip>

          <MoveToEntity url={routes.usersUpdate({ id: user.id })} disabled={!canEdit(user)} entityName="user" />
        </>
      ),
      props: { align: 'right' },
    },
  ]

  return (
    <Wrapper name="Users">
      <Table<EnrichedUser>
        emptyMessageComponent={
          users.error ? (
            <FailedFetchingContent inline message={'Failed to fetch users'} />
          ) : (
            <MissingContent message="No users available" buttonText="Add user" url={routes.usersNew()} />
          )
        }
        config={tableConfig}
        data={users.result?.items ?? []}
        id="users-table"
        pending={users.loading}
        actions={
          [Role.super, Role.admin].includes(currentUser.role)
            ? [
                <Link key="addUser" id="user-list-component-create-button" type="button" to={routes.usersNew()}>
                  <Button variant="contained" color="primary">
                    Add user
                  </Button>
                </Link>,
              ]
            : []
        }
        pagination={{ total: users.result?.total ?? 0, useUrlSearchParams: true }}
      />
    </Wrapper>
  )
}
