import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { ActiveService, ListResult } from 'common/api/v1/types'
import { ThunkApi } from '../../store'
import { withDefaultPagination } from '../../utils'
import { PaginatedRequestParams } from '../../api/nm-types'

export const getActiveServices = createAsyncThunk<ListResult<ActiveService>, Partial<PaginatedRequestParams>, ThunkApi>(
  'activeServices/getActiveServices',
  async (params, { extra: { api } }) => {
    return await api.activeServices.getActiveServices(withDefaultPagination(params))
  },
)

export const setSelectedActiveServices = createAction<Array<ActiveService>>('activeServices/setSelectedActiveServices')
export const clearActiveServices = createAction('activeServices/clearActiveServices')
