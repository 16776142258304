import { darken, alpha } from '@material-ui/core/styles'
import { makeStyles, Theme } from '@material-ui/core/styles'

/**
 * Helper to get snackbar colors
 * @param color: backgroundColor
 * @param theme: MaterialUI theme
 */
const getSnackbarStyle = (color: string, theme: Theme) => ({
  backgroundColor: color,
  color: theme.palette.common.white,
})
/**
 * Some common styles and styles for snackbars
 */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    overflowY: 'overlay' as 'scroll',
  },
  success: getSnackbarStyle(darken(theme.palette.success.light, 0.5), theme),
  warning: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.common.black,
  },
  error: getSnackbarStyle(darken(theme.palette.error.light, 0.5), theme),
  info: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[700]}`,
    color: theme.palette.common.white,
  },
  '@global': {
    body: {
      scrollbarWidth: 'thin',
      scrollbarColor: `${alpha(
        theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.common.black,
        0.4,
      )} transparent`,
    },
    '.MuiDialogActions-spacing': {
      padding: theme.spacing(2, 3),
    },
    '*::-webkit-scrollbar': {
      width: '12px',
      height: '12px',
    },
    '*::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: alpha(
        theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.common.black,
        0.4,
      ),
      borderRadius: '10px',
      border: `2px solid ${theme.palette.background.default}`,
    },
    '::-webkit-scrollbar-corner': { background: 'transparent' },
  },
}))

export const getAppClasses = () => useStyles()
