import { ListResult, Service } from 'common/api/v1/types'
import { MOCK_SERVICES, MOCK_DELAY_MS } from '../MockData'
import { IServiceApi } from './api'
import { listResult } from '../../utils'
import { ServicesRequestParams } from '../nm-types'

export class MockServiceApi implements IServiceApi {
  getService(id: string): Promise<Service> {
    void id
    throw new Error('Method not implemented.')
  }
  updateService(service: Service): Promise<Service> {
    void service
    throw new Error('Method not implemented.')
  }
  getServices(params: ServicesRequestParams): Promise<ListResult<Service>> {
    void params
    return new Promise(resolve => setTimeout(() => resolve(listResult(MOCK_SERVICES)), MOCK_DELAY_MS))
  }
}
