import { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { SnackbarProvider } from 'notistack'

import { CheckCircle, Warning, Error, Info } from '@mui/icons-material'
import FaviconPng from './img/favicon.png'

import { AppDispatch, GlobalState } from './store'
import { getAppClasses } from './Common'
import Snackbar from './components/common/Snackbar'
import { Login } from './components/login'
import LoggedIn from './components/loggedin'
import { ImageName, User } from 'common/api/v1/types'
import { init } from './redux/actions/uiActions'
import { useFetchedImage } from './components/common/LogoFetcher'
import { useHistory } from 'react-router-dom'

const iconProps = {
  style: {
    opacity: 0.6,
    marginRight: '12px',
  },
}

/**
 * Used just to add history to store middleware to use in redux epics
 */
const Initializer = () => {
  const history = useHistory()
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    dispatch(init(history))
  }, [dispatch])
  return null
}

function App() {
  const { source, type } = useFetchedImage(ImageName.favicon)

  useEffect(() => {
    if (source === '') return // fetch not complete yet

    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      if (source === null) {
        // use default
        link.href = FaviconPng
        link.type = 'image/png'
      } else {
        link.href = source
        link.type = type
      }
      document.getElementsByTagName('head')[0].appendChild(link)
    }
  }, [source])

  const classes = getAppClasses()
  const user = useSelector(({ userReducer }: GlobalState) => userReducer.user, shallowEqual) as User
  const isLoggedIn = 'id' in user
  return (
    <>
      <Initializer />

      <SnackbarProvider
        dense={!isLoggedIn}
        maxSnack={3}
        classes={{
          variantSuccess: classes.success,
          variantError: classes.error,
          variantWarning: classes.warning,
          variantInfo: classes.info,
        }}
        iconVariant={{
          success: <CheckCircle {...iconProps} />,
          error: <Error {...iconProps} />,
          warning: <Warning {...iconProps} />,
          info: <Info {...iconProps} />,
        }}
      >
        <>
          <Snackbar />
          {isLoggedIn ? <LoggedIn /> : <Login />}
        </>
      </SnackbarProvider>
    </>
  )
}

export default App
