import { createSlice } from '@reduxjs/toolkit'

import { AlarmWithImpact } from 'common/api/v1/types'
import { clearAlarms, getAlarms, readAlarmsInBackground, setCriticalAlarmsAmount } from '../actions/alarmsActions'

interface State {
  alarms: AlarmWithImpact[]
  alarmsForNotifications: AlarmWithImpact[]
  loading: boolean
  total: number
  loadingInBackground: boolean
  isCriticalAmount: boolean
}
export const initialStateAlarms: State = {
  alarms: [],
  alarmsForNotifications: [],
  loading: false,
  total: 0,
  loadingInBackground: false,
  isCriticalAmount: false,
}

const alarmsSlice = createSlice({
  name: 'alarms',
  initialState: initialStateAlarms,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(
        readAlarmsInBackground.pending,
        (
          state,
          {
            meta: {
              arg: { silent },
            },
          },
        ): State => ({
          ...state,
          loadingInBackground: !silent,
        }),
      )
      .addCase(readAlarmsInBackground.rejected, (state): State => ({ ...state, loadingInBackground: false }))
      .addCase(
        readAlarmsInBackground.fulfilled,
        (state, { payload: alarmsForNotifications }): State => ({
          ...state,
          alarmsForNotifications,
          loadingInBackground: false,
        }),
      )
      .addCase(
        getAlarms.pending,
        (
          state,
          {
            meta: {
              arg: { silent },
            },
          },
        ): State => ({
          ...state,
          loading: !silent,
        }),
      )
      .addCase(getAlarms.rejected, (state): State => ({ ...state, loading: false }))
      .addCase(
        getAlarms.fulfilled,
        (state, { payload: alarms }): State => ({
          ...state,
          alarms: alarms.items,
          total: alarms.total,
          loading: false,
        }),
      )
      .addCase(
        setCriticalAlarmsAmount.pending,
        (state, { meta: { arg: isCriticalAmount } }): State => ({
          ...state,
          isCriticalAmount,
        }),
      )
      .addCase(clearAlarms, (state): State => ({ ...state, alarms: [] }))
  },
})

export default alarmsSlice.reducer
