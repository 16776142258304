import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from '../../store'
import routes from '../../utils/routes'
import { Group, GroupInit, ListResult } from 'common/api/v1/types'

import { EnrichedGroup, GroupsRequestParams } from '../../api/nm-types'
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from './notificationActions'
import { withDefaultPagination } from '../../utils'

export const createGroup = createAsyncThunk<void, GroupInit, ThunkApi>(
  'group/createGroup',
  async (group, { dispatch, extra: { api, history } }) => {
    try {
      await api.groupsApi.createGroup(group)
      history().push(routes.groups())
      dispatch(enqueueSuccessSnackbar(`Created Group ${group.name}`))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'create group' }))
      throw err
    }
  },
)

export const removeGroup = createAsyncThunk<{ id: Group['id']; name: Group['name'] }, Group['id'], ThunkApi>(
  'groups/removeGroup',
  async (groupId, { dispatch, extra: { api, history } }) => {
    try {
      const res = await api.groupsApi.removeGroup(groupId)
      history().push(routes.groups())
      dispatch(enqueueSuccessSnackbar(`Removed Group: ${res.name}`))
      return res
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'delete group' }))
      throw err
    }
  },
)

export const updateGroup = createAsyncThunk<void, Group, ThunkApi>(
  'group/updateGroup',
  async (group, { dispatch, extra: { api, history } }) => {
    try {
      await api.groupsApi.updateGroup(group.id, {
        name: group.name,
        applianceSecret: group.applianceSecret || '',
      })
      history().push(routes.groups())
      dispatch(enqueueSuccessSnackbar(`Edited Group: ${group.name}`))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'update group' }))
      throw err
    }
  },
)

export const getGroups = createAsyncThunk<ListResult<EnrichedGroup>, Partial<GroupsRequestParams>, ThunkApi>(
  'group/getGroups',
  async (params, { extra: { api } }) => {
    return await api.groupsApi.getGroups(withDefaultPagination(params))
  },
)

export const getGroup = createAsyncThunk<Group, Group['id'], ThunkApi>(
  'group/getGroup',
  async (id, { extra: { api } }) => {
    return await api.groupsApi.getGroup(id)
  },
)

export const clearGroup = createAction('group/clearGroup')
export const clearGroups = createAction('group/clearGroups')
