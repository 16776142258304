import React from 'react'

import { GridItem, Paper, useStyles } from '../common/Form'
import DataSet from '../common/DataSet'
import { ExternalLink } from '../common/Link'
import { Service } from 'common/api/v1/types'

export interface MetaProps {
  service: Service
}

export const Meta: React.FunctionComponent<MetaProps> = ({ service }) => {
  const classes = useStyles()
  const logs = {
    Logs: (
      <ExternalLink underline="always" href={service.filterUrl}>
        Logs
      </ExternalLink>
    ),
  }
  const meta = {
    Purpose: service.purpose,
    Product: service.product,
    ...logs,
  }

  return (
    <Paper title="Meta data" classes={classes.paper} collapsible actionsPane={[]}>
      <GridItem lg={12} xl={12}>
        <DataSet values={meta} />
      </GridItem>
    </Paper>
  )
}
