import { Service, ListServiceSortableField } from 'common/api/v1/types'
import { usePageParamsFilteredSelector, useServicesSelector } from '../../utils'
import Table, { MoveToEntity, TableConfig } from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import Wrapper from '../common/Wrapper'
import IconButton from '@material-ui/core/IconButton'
import MuiLink from '@material-ui/core/Link'
import Tooltip from '@material-ui/core/Tooltip'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Circle from '@material-ui/icons/FiberManualRecord'
import routes from '../../utils/routes'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

const Toggle = ({ value }: { value: boolean }) => {
  return value ? <Circle /> : null
}

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    fontSize: theme.typography.body2.fontSize,
  },
  icon: {
    marginLeft: theme.spacing(1),
    transform: 'translateY(3px)',
  },
}))

export const ListView = () => {
  const { services, total, loading } = usePageParamsFilteredSelector(useServicesSelector)
  const classes = useStyles()

  const tableConfig: TableConfig<Service, ListServiceSortableField> = [
    {
      title: 'purpose',
      getValue: ({ purpose, description }) => (
        <div>
          {purpose}
          {description && (
            <Tooltip title={description} classes={{ tooltip: classes.tooltip }} placement="top">
              <HelpOutlineIcon className={classes.icon} />
            </Tooltip>
          )}
        </div>
      ),
      sorting: { byParameter: ListServiceSortableField.purpose },
    },
    {
      title: 'product',
      getValue: ({ product }) => product,
      sorting: { byParameter: ListServiceSortableField.product },
    },
    {
      title: 'collect logs',
      getValue: ({ collectLogs }) => <Toggle value={collectLogs} />,
    },
    {
      getValue: svc => {
        return (
          <div style={{ display: 'flex' }}>
            <MoveToEntity url={routes.servicesUpdate({ id: svc.id })} entityName="service" />

            {svc.filterUrl && (
              <Tooltip title="View logs" placement="top">
                <span>
                  <MuiLink href={svc.filterUrl} target="_blank">
                    <IconButton edge="end" aria-label="View logs">
                      <OpenInNewIcon />
                    </IconButton>
                  </MuiLink>
                </span>
              </Tooltip>
            )}
          </div>
        )
      },
      props: { align: 'right' },
    },
  ]

  return (
    <Wrapper name="Services">
      <Table<Service>
        emptyMessageComponent={<MissingContent message="No services available" />}
        config={tableConfig}
        data={services}
        id="services-table"
        pending={loading}
        actions={[]}
        pagination={{ total, useUrlSearchParams: true }}
      />
    </Wrapper>
  )
}
