import { createSlice } from '@reduxjs/toolkit'

import { AuditLog } from 'common/api/v1/types'
import { getAudit } from '../actions/auditActions'
import { logoutUser } from '../actions/userActions'

interface State {
  audit: Array<AuditLog>
  total: number
  loading: boolean
}
export const initialStateAudit: State = {
  audit: [],
  total: 0,
  loading: false,
}

const auditSlice = createSlice({
  name: 'audit',
  initialState: initialStateAudit,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAudit.pending, (state): State => ({ ...state, loading: true }))
      .addCase(
        getAudit.fulfilled,
        (state, { payload: { items: audit, total } }): State => ({
          ...state,
          loading: false,
          audit,
          total,
        }),
      )
      .addCase(getAudit.rejected, (state): State => ({ ...state, loading: false }))
      .addCase(logoutUser.fulfilled, (): State => initialStateAudit)
  },
})

export default auditSlice.reducer
