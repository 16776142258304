import { VideonPortMode } from 'common/api/v1/types'
import Typography from '@material-ui/core/Typography'
import { MenuItem, FormControl, InputLabel, Select as MUISelect } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useFormikContext } from 'formik'
import cn from 'classnames'
import { GridItem, Paper, useStyles } from '../../../common/Form'
import { useInterfaceSectionStyles } from '../../../common/Interface/Base'

interface VideonPortFormProps {
  namePrefix: string
  modes?: VideonPortMode[]
  limitToMode?: VideonPortMode
}

const options: { [key in VideonPortMode]: string } = {
  videonSdi: 'sdi',
  videonHdmi: 'hdmi',
  videonAuto: 'auto',
}

const richOptions = Object.keys(options).map(value => ({
  value,
  name: options[value as keyof typeof options],
}))

// const VideonPortForm = ({ namePrefix, modes }: VideonPortFormProps) => (
//   <>
//     <Select
//       label="Mode"
//       name={`${namePrefix}.mode`}
//       required
//       newLine
//       options={richOptions}
//       validators={{
//         oneOf: { validValues: new Set(Object.values(VideonPortMode)) },
//       }}
//     />
//   </>
// )

const useFieldStyles = makeStyles((theme: Theme) => ({
  warningText: {
    marginLeft: theme.spacing(1),
    color: theme.palette.warning.light,
  },
}))

const VideonPortForm = ({ namePrefix, limitToMode }: VideonPortFormProps) => {
  const localClasses = useFieldStyles()
  const classes = useStyles()
  const interfaceClasses = useInterfaceSectionStyles()
  const { setFieldValue, getFieldProps } = useFormikContext()
  const options = richOptions.map(o => ({
    ...o,
    disabled: Boolean(limitToMode && limitToMode != o.value),
  }))
  const name = `${namePrefix}.mode`
  const value = (getFieldProps(name) && getFieldProps(name).value) || ''
  return (
    <Paper classes={cn(classes.paper, interfaceClasses.interfaceSectionPaper1)}>
      <GridItem newLine>
        <FormControl variant="outlined" style={{ width: '100%' }}>
          <InputLabel>Mode</InputLabel>
          <MUISelect
            name={name}
            label={'Mode'}
            disabled={false}
            fullWidth
            value={value}
            onChange={event => {
              setFieldValue(name, event.target.value)
            }}
          >
            {options.map(mode => (
              <MenuItem key={mode.value} value={mode.value} disabled={mode.disabled}>
                <div>
                  <Typography>
                    {mode.name}
                    {mode.disabled && (
                      <span className={localClasses.warningText}>
                        (All active inputs on a videon appliance must have the same mode)
                      </span>
                    )}
                  </Typography>
                </div>
              </MenuItem>
            ))}
          </MUISelect>
        </FormControl>
      </GridItem>
    </Paper>
  )
}

export default VideonPortForm
