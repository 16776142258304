import { Fragment } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { Alarm, Appliance, ApplianceType, Group, PhysicalPort, SharedPort, Network } from 'common/api/v1/types'
import { Autocomplete, GridItem, Paper } from '../../../common/Form'
import { Api } from '../../../../store'
import { getInterfaceName, isEditableInterface, useUser } from '../../../../utils'
import { FormikProps } from 'formik'
import { Grid } from '@material-ui/core'

export interface ISharedPort extends SharedPort {
  name: PhysicalPort['name']
  _owner: Group
  _alarmsThatDisablePort?: Alarm[]
  _address?: string
  _publicAddress?: string
  _interRegionPublicAddress?: string
  _networks?: Network[]
}

interface IApplianceFormInterfacesProps {
  ports: ISharedPort[]
  appliance: Appliance
  formik: FormikProps<any>
}

const { groupsApi, networksApi } = Api

const PortAlarms = ({ alarms }: { alarms: Alarm[] | undefined }) => {
  if (!alarms) {
    return <></>
  }

  return (
    <>
      {alarms.map(alarm => (
        <GridItem key={alarm.id} xs={4} lg={4} xl={4}>
          <Typography component="div" variant="body2" color="textSecondary">
            {alarm.text}
          </Typography>
        </GridItem>
      ))}
    </>
  )
}

const Interfaces = ({ ports, appliance, formik }: IApplianceFormInterfacesProps) => {
  const isCoreNode = appliance.type === ApplianceType.core
  const isThumbNode = appliance.type === ApplianceType.thumb

  const user = useUser()
  const applianceOwnerId = typeof appliance.owner === 'string' ? appliance.owner : appliance.owner?.id
  const isUserAllowedToEdit = applianceOwnerId && isEditableInterface(applianceOwnerId, user)

  const portIsDisabled = ({ _alarmsThatDisablePort }: ISharedPort) =>
    !isUserAllowedToEdit ||
    isCoreNode ||
    isThumbNode ||
    (_alarmsThatDisablePort !== undefined && _alarmsThatDisablePort.length > 0)

  return (
    <Paper title="Interfaces" collapsible>
      <GridItem lg={12} xl={12}>
        <List style={{ width: '100%' }}>
          {ports.map((port, ind) => (
            <Fragment key={port.port}>
              <ListItem>
                <Grid container spacing={2}>
                  <GridItem lg={12} xl={12}>
                    <ListItemText
                      disableTypography
                      primary={<Typography>{getInterfaceName({ ...port, appliance })}</Typography>}
                      secondary={
                        <>
                          <Typography component="div" variant="body2" color="textSecondary">
                            {port._address}
                          </Typography>
                          {!!port._publicAddress && (
                            <Typography component="div" variant="body2" color="textSecondary">
                              Public address: {port._publicAddress}
                            </Typography>
                          )}
                          {!!port._interRegionPublicAddress && (
                            <Typography component="div" variant="body2" color="textSecondary">
                              Inter region address: {port._interRegionPublicAddress}
                            </Typography>
                          )}
                        </>
                      }
                    />
                    <PortAlarms alarms={port._alarmsThatDisablePort} />
                  </GridItem>

                  <Autocomplete<Group>
                    name={`ports.${ind}.owner`}
                    label="Owner group"
                    formik={formik}
                    api={groupsApi.getPureGroups.bind(groupsApi)}
                    defaultOption={port.owner ? { id: port.owner, name: port._owner.name } : undefined}
                    getOptionValue={option => option.id}
                    getOptionLabel={option => option.name}
                    optionComparator={(o1, o2) => o1.id == o2.id}
                    required={true}
                    disabled={portIsDisabled(port)}
                    xs={4}
                  />

                  <Autocomplete<Network>
                    name={`ports.${ind}._networks`}
                    label="Networks"
                    formik={formik}
                    api={networksApi.getNetworks.bind(networksApi)}
                    defaultOption={port._networks}
                    getOptionValue={option => option.id}
                    getOptionLabel={option => option.name}
                    optionComparator={(o1, o2) => o1.id == o2.id}
                    multiple
                    xs={4}
                    tooltip="Interfaces within the same network will be connected using their private IP addresses"
                    disabled={!isUserAllowedToEdit}
                  />
                </Grid>
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
      </GridItem>
    </Paper>
  )
}

export default Interfaces
