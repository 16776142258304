import { FormikProps } from 'formik'

import { CoaxOutputPort, CoaxPortMode, Output, Output3gLevel } from 'common/api/v1/types'

import { Select } from '../../../common/Form'
import { CommonFields } from './IpPortForm'
import { get } from 'lodash'
import { useEffect } from 'react'

export enum CoaxFields {
  output3gLevel = 'output3gLevel',
}
export const coaxDefaults = { [CoaxFields.output3gLevel]: Output3gLevel.B }
interface CoaxPortFormProps {
  namePrefix: string
  form: FormikProps<Output>
}

export const getCoaxPortFormFields = (port: CoaxOutputPort) => {
  const fields: string[] = [CommonFields.id, CommonFields.physicalPort, CommonFields.copies, CommonFields.mode]
  if (port.mode === CoaxPortMode.sdi) {
    fields.push(CoaxFields.output3gLevel)
  }
  return fields
}

const CoaxPortForm = ({ namePrefix, form }: CoaxPortFormProps) => {
  const modeKey = `${namePrefix}.mode`
  const port: CoaxOutputPort = get(form.values, namePrefix)
  const modeOptions = Object.values(CoaxPortMode).sort((m1, m2) => m1.localeCompare(m2))

  useEffect(() => {
    const isValidMode = modeOptions.includes(port.mode)
    if (!isValidMode && (port.mode as string) !== '') {
      form.setFieldValue(modeKey, '')
    }
  }, [modeKey, port.mode, modeOptions])

  return (
    <>
      <Select
        label="Mode"
        name={modeKey}
        required
        newLine
        options={modeOptions}
        validators={{
          oneOf: { validValues: new Set(modeOptions) },
        }}
      />

      {port.mode === CoaxPortMode.sdi && (
        <Select
          label="Output3gLevel"
          name={`${namePrefix}.${CoaxFields.output3gLevel}`}
          required
          options={Object.values(Output3gLevel)}
        />
      )}
    </>
  )
}

export default CoaxPortForm
