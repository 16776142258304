import {
  CoaxPortMode,
  IpPortMode,
  VaAsiInputPipe,
  VaAsiOutputPipe,
  VaInputPipe,
  VaOutputPipe,
  VaPullInputPipe,
  VaPullOutputPipe,
  VaSdiInputPipe,
  VaSdiOutputPipe,
  VaSrtInputPipe,
  VaSrtOutputPipe,
  VaUdpInputPipe,
  VaUdpOutputPipe,
} from 'common/api/v1/types'
import DataSet from '../../common/DataSet'
import { OutputStatistics, packets, integer } from './Statistics'
import { applyUnit, makeFormatter } from './utils'
import { formatBitrate } from 'common/api/v1/helpers'

interface VaInputProp<T = VaInputPipe> {
  vaInputPipe: T
}

const VaUdpInputStatistics = ({ vaInputPipe }: VaInputProp<VaUdpInputPipe>) => {
  if (!vaInputPipe || !vaInputPipe.length || !vaInputPipe[0]) return null
  const { statistics } = vaInputPipe[0]
  const stat = makeFormatter(statistics)
  return (
    <DataSet
      values={{
        Bitrate: stat(s => formatBitrate(s?.udpStats?.bitrate)),
        'FEC bitrate': stat(s => formatBitrate(s?.fecRecv?.fecBitrate)),
        'FEC packet rate': stat(s => packets(s?.fecRecv?.fecRate)),
        'FEC packets received': stat(s => integer(s?.fecRecv?.fecPackets)),
        'Packets recovered by FEC': stat(s => integer(s?.fecRecv?.recovered)),
      }}
    />
  )
}

const VaSrtInputStatistics = ({ vaInputPipe }: VaInputProp<VaSrtInputPipe>) => {
  if (!vaInputPipe || !vaInputPipe.length || !vaInputPipe[0]) return null
  const { srtRecv } = vaInputPipe[0].statistics
  const stat = makeFormatter(srtRecv)

  return (
    <DataSet
      values={{
        'Packets received': stat(s => packets(s.received)),
        'Packets lost': stat(s => packets(s.lost)),
        'Packets dropped': stat(s => packets(s.dropped)),
        'Packets belated': stat(s => packets(s.belated)),
        'Packets retransmitted': stat(s => packets(s.retransmitted)),
        'Round trip time': stat(s => applyUnit(s.rtt, 'ms')),
        Bitrate: stat(s => formatBitrate(s.bitRate)),
        'Bytes received': stat(s => integer(s.bytes)),
        'Bytes lost': stat(s => integer(s.bytesLost)),
        'Bytes dropped': stat(s => integer(s.bytesDropped)),
      }}
    />
  )
}

const VaZixiInputStatistics = ({ vaInputPipe: [zixiSink] }: VaInputProp<VaPullInputPipe>) => {
  const stat = makeFormatter(zixiSink?.statistics)
  return (
    <DataSet
      values={{
        Reconnections: stat(s => integer(s.general.numConnects)),
        'Packets received': stat(s => packets(s.netRecv.numPackets)),
        'FEC packets received': stat(s => packets(s.fecRecv.fecPackets)),
        'Receive bitrate': stat(s => formatBitrate(s.netRecv.inputBitrate)),
        'Remote end Send bitrate': stat(s => formatBitrate(s.netSend.sendBitrate)),
        'Round trip time': stat(s => `${integer(s.netSend.rtt)} ms`),
        'Packet jitter': stat(s => `${integer(s.netRecv.jitter)} ms`),
        'Missing packets (%)': stat(s => integer(s.netRecv.packetLoss / 1000)),
        'Missing packets': stat(s => packets(s.netRecv.dropped)),
        'Packets recovered by FEC': stat(s => packets(s.fecRecv.recovered)),
        'Packets recovered by ARQ': stat(s => packets(s.arqRecv.recovered)),
        'Unrecovered packets': stat(s => packets(s.arqRecv.dropped)),
      }}
    />
  )
}

const VaSdiInputStatistics = ({ vaInputPipe: [sdiInput] }: VaInputProp<VaSdiInputPipe>) => {
  const stat = makeFormatter(sdiInput?.statistics)
  return (
    <DataSet
      values={{
        'Frames received': stat(s => integer(s.netRecv.numPackets)),
      }}
    />
  )
}

const VaAsiInputStatistics = ({ vaInputPipe: [asiInput] }: VaInputProp<VaAsiInputPipe>) => {
  const stat = makeFormatter(asiInput?.statistics)
  return (
    <DataSet
      values={{
        Reconnections: stat(s => integer(s.general.numConnects)),
        Bitrate: stat(s => formatBitrate(s.netRecv.inputBitrate)),
      }}
    />
  )
}

export const vaInputStatistics = (mode: CoaxPortMode | IpPortMode, vaInputPipe: VaInputPipe) => {
  switch (mode) {
    case CoaxPortMode.asi:
      return <VaAsiInputStatistics vaInputPipe={vaInputPipe as VaAsiInputPipe} />

    case CoaxPortMode.sdi:
      return <VaSdiInputStatistics vaInputPipe={vaInputPipe as VaSdiInputPipe} />

    case IpPortMode.udp:
    // falls through
    case IpPortMode.rtp:
      return <VaUdpInputStatistics vaInputPipe={vaInputPipe as VaUdpInputPipe} />

    case IpPortMode.srt:
      return <VaSrtInputStatistics vaInputPipe={vaInputPipe as VaSrtInputPipe} />

    case IpPortMode.zixi:
      return <VaZixiInputStatistics vaInputPipe={vaInputPipe as VaPullInputPipe} />

    default:
      return null
  }
}

interface VaOutputProp<T = VaOutputPipe> {
  vaOutputPipe: T
}
const VaUdpOutputStatistics = ({ vaOutputPipe: [, udpSource] }: VaOutputProp<VaUdpOutputPipe>) => {
  const stat = makeFormatter(udpSource?.statistics)
  return (
    <DataSet
      values={{
        Bitrate: stat(s => formatBitrate(s.udpStats.bitrate)),
        'FEC send bitrate': stat(s => formatBitrate(s.fecSend.fecBitrate)),
        'FEC packet rate': stat(s => packets(s.fecSend.fecRate)),
        'FEC packets sent': stat(s => packets(s.fecSend.fecPackets)),
      }}
    />
  )
}

const VaSrtOutputStatistics = ({ vaOutputPipe: [, srtSource] }: VaOutputProp<VaSrtOutputPipe>) => {
  const stat = makeFormatter(srtSource?.statistics)
  return (
    <DataSet
      values={{
        'Packets sent': stat(s => packets(s.srtSend.sent)),
        'Packets lost': stat(s => packets(s.srtSend.lost)),
        'Packets dropped': stat(s => packets(s.srtSend.dropped)),
        'Packets retransmitted': stat(s => packets(s.srtSend.retransmitted)),
        'Round trip time': stat(s => applyUnit(s.srtSend.rtt, 'ms')),
        Bitrate: stat(s => formatBitrate(s.srtSend.bitRate)),
        'Bytes sent': stat(s => integer(s.srtSend.bytes)),
        'Bytes dropped': stat(s => integer(s.srtSend.bytesDropped)),
      }}
    />
  )
}

const VaZixiOutputStatistics = ({ vaOutputPipe: [, pullSource] }: VaOutputProp<VaPullOutputPipe>) => {
  const stat = makeFormatter(pullSource?.statistics)
  return (
    <DataSet
      values={{
        Reconnections: stat(s => integer(s.general.numConnects)),
        'Packets sent': stat(s => packets(s.netSend.sentPackets)),
        'FEC packets sent': stat(s => packets(s.fecSend.fecPackets)),
        'Send bitrate': stat(s => formatBitrate(s.netSend.sendBitrate)),
        'Remote end Receive bitrate': stat(s => formatBitrate(s.netRecv.inputBitrate)),
        'Round trip time': stat(s => `${integer(s.netSend.rtt)} ms`),
        'Packet jitter': stat(s => `${integer(s.netRecv.jitter)} ms`),
        'Missing packets (%)': stat(s => integer(s.netRecv.packetLoss / 1000)),
        'Missing packets': stat(s => packets(s.netRecv.dropped)),
        'Packets recovered by FEC': stat(s => packets(s.fecRecv.recovered)),
        'Packets recovered by ARQ': stat(s => packets(s.arqRecv.recovered)),
        'Unrecovered packets': stat(s => packets(s.arqRecv.dropped)),
      }}
    />
  )
}

const VaAsiOutputStatistics = ({ vaOutputPipe: [, asiSource] }: VaOutputProp<VaAsiOutputPipe>) => {
  const stat = makeFormatter(asiSource?.statistics)
  return (
    <DataSet
      values={{
        Reconnections: stat(s => integer(s.general.numConnects)),
        'Packets sent': stat(s => packets(s.netSend.sentPackets)),
        Bitrate: stat(s => formatBitrate(s.netSend.sendBitrate)),
      }}
    />
  )
}

const VaSdiOutputStatistics = ({ vaOutputPipe: [, decoder] }: VaOutputProp<VaSdiOutputPipe>) => {
  const stat = makeFormatter(decoder?.statistics)
  return (
    <DataSet
      values={{
        'Decoded frames': stat(s => integer(s.decFrames)),
        'Dropped frames': stat(s => integer(s.droppedFrames)),
        'Repeated frames': stat(s => integer(s.repeatedFrames)),
      }}
    />
  )
}

export const VaOutputStatistics = ({ output }: OutputStatistics) => {
  const vaOutputPipe = output.metrics?.vaOutputPipe

  if (!vaOutputPipe || !output.ports || !output.ports.length || !output.ports[0]) {
    return null
  }
  const port = output.ports[0]

  const makeComponent = () => {
    switch (port.mode) {
      case CoaxPortMode.asi:
        return <VaAsiOutputStatistics vaOutputPipe={vaOutputPipe as VaAsiOutputPipe} />

      case CoaxPortMode.sdi:
        return <VaSdiOutputStatistics vaOutputPipe={vaOutputPipe as VaSdiOutputPipe} />

      case IpPortMode.udp:
      // falls through
      case IpPortMode.rtp:
        return <VaUdpOutputStatistics vaOutputPipe={vaOutputPipe as VaUdpOutputPipe} />

      case IpPortMode.srt:
        return <VaSrtOutputStatistics vaOutputPipe={vaOutputPipe as VaSrtOutputPipe} />

      case IpPortMode.zixi:
        return <VaZixiOutputStatistics vaOutputPipe={vaOutputPipe as VaPullOutputPipe} />

      default:
        return null
    }
  }

  const component = makeComponent()
  return component
}
