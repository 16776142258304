import { IInputApi, InputApi } from './inputs/api'
import { IOutputApi, OutputApi } from './outputs/api'
import { MockInputApi } from './inputs/devApi'
import { MockOutputApi } from './outputs/devApi'
import { IUserApi, UserApi } from './user/api'
import { IServiceApi, ServiceApi } from './service/api'
import { MockServiceApi } from './service/devApi'
import { MockUserApi } from './user/devApi'
import { AppliancesApi, IApplianceApi } from './appliances/api'
import { MockApplianceApi } from './appliances/devApi'
import { IPortsApi, PortsApi } from './ports/api'
import { MockPortsApi } from './ports/devApi'
import { IGroupsApi, GroupsApi } from './groups/api'
import { MockGroupsApi } from './groups/devApi'
import { IAlarmApi, AlarmsApi } from './alarms/api'
import { MockAlarmApi } from './alarms/devApi'
import { BitratesApi, IBitratesApi } from './bitrates/api'
import { MockBitratesApi } from './bitrates/devApi'
import { BillingApi, IBillingApi } from './billing/api'
import { UsageApi, IUsageApi } from './usage/api'
import { GraphApi, IGraphApi } from './graph/api'
import { MockGraphApi } from './graph/devApi'
import { ActiveServicesApi, IActiveServicesApi } from './activeServices/api'
import { MockActiveServicesApi } from './activeServices/devApi'
import { ILocationApi, LocationApi } from './location/api'
import { IRegionApi, RegionApi } from './region/api'
import { MockRegionApi } from './region/devApi'
import { IOutputListApi, OutputListApi } from './outputLists/api'
import { MockOutputListApi } from './outputLists/devApi'
import { IGroupListApi, GroupListApi } from './groupLists/api'
import { MockGroupListApi } from './groupLists/devApi'
import { IAuditApi, AuditApi } from './audit/api'
import { MockAuditApi } from './audit/devApi'
import { ISettingsApi, SettingsApi } from './settings/api'
import { MockSettingsApi } from './settings/devApi'
import { IImageApi, ImageApi } from './image/api'
import { MockImageApi } from './image/devApi'
import { ITlsApi, TlsApi } from './tls/api'
import { MockTlsApi } from './tls/devApi'
import { IBuildInfoApi, BuildInfoApi } from './buildInfo/api'
import { EdgeClient } from 'common/generated/edgeClient'
import { MockBillingApi } from './billing/devApi'
import { MockUsageApi } from './usage/devApi'
import { MockBuildInfoApi } from './buildInfo/devApi'
import { AddressMappingApi, IAddressMappingApi } from './addressMappings/api'
import { MockAddressMappingApi } from './addressMappings/devApi'
import { IKubernetesApi, KubernetesApi } from './kubernetes/api'
import { MockKubernetesApi } from './kubernetes/devApi'
import { INetworksApi, NetworksApi } from './networks/api'
import { MockNetworksApi } from './networks/devApi'
import { IApiTokenApi, ApiTokenApi } from './apiTokens/api'
import { MockApiTokenApi } from './apiTokens/devApi'

export interface IApi {
  activeServices: IActiveServicesApi
  addressMappingsApi: IAddressMappingApi
  alarmsApi: IAlarmApi
  appliancesApi: IApplianceApi
  apiTokensApi: IApiTokenApi
  auditApi: IAuditApi
  billingApi: IBillingApi
  bitratesApi: IBitratesApi
  graphApi: IGraphApi
  groupListsApi: IGroupListApi
  groupsApi: IGroupsApi
  imageApi: IImageApi
  inputApi: IInputApi
  locationApi: ILocationApi
  outputApi: IOutputApi
  outputListsApi: IOutputListApi
  portsApi: IPortsApi
  regionApi: IRegionApi
  settingsApi: ISettingsApi
  tlsApi: ITlsApi
  usageApi: IUsageApi
  userApi: IUserApi
  serviceApi: IServiceApi
  buildInfoApi: IBuildInfoApi
  kubernetesApi: IKubernetesApi
  networksApi: INetworksApi
}

export class DevApi implements IApi {
  public readonly activeServices = new MockActiveServicesApi()
  public readonly addressMappingsApi = new MockAddressMappingApi()
  public readonly apiTokensApi = new MockApiTokenApi()
  public readonly alarmsApi = new MockAlarmApi()
  public readonly appliancesApi = new MockApplianceApi()
  public readonly auditApi = new MockAuditApi()
  public readonly billingApi = new MockBillingApi()
  public readonly usageApi = new MockUsageApi()
  public readonly bitratesApi = new MockBitratesApi()
  public readonly graphApi = new MockGraphApi()
  public readonly groupListsApi = new MockGroupListApi()
  public readonly imageApi = new MockImageApi()
  public readonly inputApi = new MockInputApi()
  public readonly locationApi = new LocationApi()
  public readonly groupsApi = new MockGroupsApi()
  public readonly outputApi = new MockOutputApi()
  public readonly outputListsApi = new MockOutputListApi()
  public readonly portsApi = new MockPortsApi()
  public readonly regionApi = new MockRegionApi()
  public readonly settingsApi = new MockSettingsApi()
  public readonly tlsApi = new MockTlsApi()
  public readonly userApi = new MockUserApi()
  public readonly serviceApi = new MockServiceApi()
  public readonly buildInfoApi = new MockBuildInfoApi()
  public readonly kubernetesApi = new MockKubernetesApi()
  public readonly networksApi = new MockNetworksApi()
}

export class Api implements IApi {
  constructor(public readonly edgeClient: EdgeClient) {}
  public readonly activeServices = new ActiveServicesApi(this.edgeClient)
  public readonly addressMappingsApi = new AddressMappingApi(this.edgeClient)
  public readonly apiTokensApi = new ApiTokenApi(this.edgeClient)
  public readonly alarmsApi = new AlarmsApi(this.edgeClient)
  public readonly appliancesApi = new AppliancesApi(this.edgeClient)
  public readonly auditApi = new AuditApi(this.edgeClient)
  public readonly billingApi = new BillingApi(this.edgeClient)
  public readonly usageApi = new UsageApi(this.edgeClient)
  public readonly bitratesApi = new BitratesApi(this.edgeClient)
  public readonly graphApi = new GraphApi(this.edgeClient)
  public readonly groupListsApi = new GroupListApi(this.edgeClient)
  public readonly imageApi = new ImageApi(this.edgeClient)
  public readonly inputApi = new InputApi(this.edgeClient)
  public readonly locationApi = new LocationApi()
  public readonly groupsApi = new GroupsApi(this.edgeClient)
  public readonly outputApi = new OutputApi(this.edgeClient)
  public readonly outputListsApi = new OutputListApi(this.edgeClient)
  public readonly portsApi = new PortsApi(this.edgeClient)
  public readonly regionApi = new RegionApi(this.edgeClient)
  public readonly settingsApi = new SettingsApi(this.edgeClient)
  public readonly tlsApi = new TlsApi(this.edgeClient)
  public readonly userApi = new UserApi(this.edgeClient)
  public readonly serviceApi = new ServiceApi(this.edgeClient)
  public readonly buildInfoApi = new BuildInfoApi(this.edgeClient)
  public readonly kubernetesApi = new KubernetesApi(this.edgeClient)
  public readonly networksApi = new NetworksApi(this.edgeClient)
}
