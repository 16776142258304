import { useDispatch } from 'react-redux'
import cn from 'classnames'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { ActiveService, InputAdminStatus, OutputAdminStatus } from 'common/api/v1/types'
import { setSelectedActiveServices } from '../../../redux/actions/activeServicesActions'
import { usePageParamsFilteredSelector, useActiveServiceSelector, isInput } from '../../../utils'
import { Table } from '../../common/Table'
import { InputIndicatorWrapper, OutputIndicatorWrapper } from '../../common/Indicator'
import { AppDispatch } from 'src/store'

const useStyles = makeStyles((theme: Theme) => ({
  streamName: {
    fontWeight: 'bold',
  },
  selected: {
    background: `${theme.palette.primary.main} !important`,
  },
  row: {
    cursor: 'pointer',
  },
  table: {
    maxHeight: '50vh',
    overflow: 'auto',
  },
}))
/**
 * List of active services for the Geo Overview page
 */
const ActiveServices = () => {
  const classes = useStyles()
  const dispatch = useDispatch<AppDispatch>()
  const { activeServices, total, loading, selected } = usePageParamsFilteredSelector(useActiveServiceSelector)

  const setSelected = (activeServices: Array<ActiveService>) => dispatch(setSelectedActiveServices(activeServices))

  return (
    <div className={classes.table}>
      <Table
        data={activeServices}
        isSmall
        noHeader
        pending={loading}
        emptyMessageComponent="No active services"
        pagination={{ total, useUrlSearchParams: true }}
        rowProps={service => ({
          onClick: () =>
            setSelected(!selected.length || !selected.find(({ id }) => id === service.id) ? [service] : []),
          className: cn(classes.row, selected.find(({ id }) => id === service.id) && classes.selected),
        })}
        config={[
          {
            getValue: item => (
              <>
                <Typography component="span" className={classes.streamName}>
                  {isInput(item) ? item.name : item.activeServiceInput.name}
                </Typography>
                {!isInput(item) && (
                  <Typography color="textSecondary" variant="body2" component="span">
                    {` / ${item.name}`}
                  </Typography>
                )}
              </>
            ),
          },
          {
            getValue: item =>
              isInput(item) ? (
                <InputIndicatorWrapper status={item.health} disabled={item.adminStatus === InputAdminStatus.off} />
              ) : (
                <OutputIndicatorWrapper status={item.health} disabled={item.adminStatus === OutputAdminStatus.off} />
              ),
          },
          {
            getValue: item => (
              <Typography color="textSecondary" variant="body2">
                {isInput(item) ? item.ownerName : item.groupName}
              </Typography>
            ),
            props: {
              align: 'right',
              noTypography: true,
            },
          },
        ]}
      />
    </div>
  )
}

export default ActiveServices
