import { Pid as TPid, TransportStream } from '../../tr101Types'
import { ApplianceConfiguration as TApplianceConfiguration } from '../../messages'
import { RistInputMultipathState, RistOutputMultipathState, UdpInputStatusCode, UdpOutputStatusCode } from '../../rist'
export { RistInputMultipathState, UdpOutputStatusCode }
import { RtmpOutputState } from '../../rtmp'
import { K8sService } from '../../k8s/services'
import { KubernetesRoles } from '../../k8s/labels'

export type Pid = TPid
export type ApplianceConfiguration = TApplianceConfiguration

export interface SortOrder<T extends string = string> {
    field: T
    descending: boolean
}

export enum LogLevel {
    fatal = 0,
    error = 1,
    warn = 2,
    info = 3,
    debug = 4,
    trace = 5,
}

// Key is what we call it in NM to be consistent with LogLevel, and the value is what the ristserver accepts
export enum RistserverLogLevel {
    fatal = 'critical',
    error = 'error',
    warn = 'warning',
    info = 'info',
    debug = 'debug',
    trace = 'trace',
}

export enum RistProfile {
    simple = 'simple',
    main = 'main',
}

export enum SrtMode {
    listener = 'listener',
    caller = 'caller',
    rendezvous = 'rendezvous',
}

export enum ZixiMode {
    pull = 'pull',
    push = 'push',
}

export enum SrtKeylen {
    aes128 = '16',
    aes192 = '24',
    aes256 = '32',
    none = '-1',
}

export enum SrtRateLimiting {
    notEnforced = 'Not enforced',
    absolute = 'Absolute',
    relativeToInput = 'Relative to input',
}

export interface ListResultError {
    message: string
}
export interface ListResult<T> {
    items: T[]
    total: number
    sortOrder?: SortOrder
    skip?: number
    limit?: number
    errors?: ListResultError[]
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of audit-log entries can be filtered
 */
export interface AuditFilter {
    method?: string
    error?: boolean
    pathname?: string
    operation?: string
    username?: string
    entity?: string
    entityId?: string
    entityName?: string
    fromDate?: Date
    toDate?: Date
}

export enum AuditOperation {
    create = 'create',
    delete = 'delete',
    update = 'update',
    login = 'login',
    logout = 'logout',

    switchInput = 'switchInput',
    updateInputRecipients = 'updateInputRecipients',

    toggle = 'toggle',
}

export interface AuditRefData {
    inputName?: string
    action?: 'enable' | 'disable' | undefined
}

/**
 * @OAS_DESCRIPTION Contains information about actions performed in the system, e.g. who did what and when, etc.
 */
export interface AuditLog {
    id: string
    operation: AuditOperation
    entity: string
    entityId?: string
    entityName: string
    // pathname: string
    // query: string
    userId: string
    username: string
    clientIp?: string // Only visible for super user
    // request?: string
    // response?: string
    error?: string
    executionTimeMs?: number
    createdAt: Date
    updatedAt?: Date
    authtoken?: string
    impersonatorId?: string
    impersonatorUsername?: string

    // Allows returning arbitraty reference data used for generating
    // a textual description of the operation in the audit log
    refData: AuditRefData
}
export interface Entity {
    id: string
}

export enum AlarmCause {
    INTERFACE_REMOVED_BUT_IN_USE = 'Interface removed but still in use',
    CORE_APPLIANCE_PUBLIC_IP_MISSING = 'Appliance needs public ip mapping', // Core appliance or external thumb
    CORE_INPUT_INVALID_ADDRESS = 'Core input configured on an address that is not available',
    CORE_OUTPUT_INVALID_ADDRESS = 'Core output configured on an address that is not available',
    FIREWALL_NFT_ERROR = 'Failed to apply firewall settings using nftables',
    MPTS_SUPPORT_ERROR = 'Failed to run mpts demux binary',
    MPTS_STARTUP_ERROR = 'Unknown error when starting the mpts demux binary',
    MPTS_UNEXPECTED_EXIT = 'The mpts demux binary unexpectedly exited',
    MPTS_CALL_TIMEOUT = 'The mpts demux binary did not give a timely response to an api call',
    BACKUP_JOB_FAILED = 'Backup job failed',
    RTMP_RECEIVER_EXITED = 'RTMP receiver exited unexpectedly',
    RTMP_SENDER_EXITED = 'RTMP sender exited unexpectedly',

    // Nimbra 400 alarms
    VA_LOSS_OF_SIGNAL = 'Loss of signal',
}

export enum AlarmSeverityLevels {
    critical = 'critical',
    major = 'major',
    minor = 'minor',
    warning = 'warning',
}

export enum VaAlarmOwnSeverityLevels {
    cleared = 'cleared',
}

export enum AlarmType {
    'va' = 'va',
    'edge' = 'edge',
    'backend' = 'backend',
    'podController' = 'pod-controller',
}

/**
 * @OAS_DESCRIPTION An alarm is created/triggered when a situation that needs supervision arises.
 * The alarm contains detailed information about what needs to be addressed.
 */
export interface Alarm {
    active: boolean
    alarmCause: string
    alarmSeverity: AlarmSeverityLevels | VaAlarmOwnSeverityLevels
    applianceId?: string
    applianceName?: string
    channelId?: number
    groupId?: string
    id: string
    inputId?: string
    object: string
    objectName: string
    objectPurpose?: string
    outputId?: string
    physicalPortId?: string
    streamId?: number
    text?: string
    time: string
    type: AlarmType
    clearExplicitly?: boolean
    region?: string
}

export interface PodControllerAlarm extends Alarm {
    id: string
    active: boolean
    alarmCause: string
    alarmSeverity: AlarmSeverityLevels
    object: string
    objectName: string
    type: AlarmType.podController
    text: string
    time: string
    region: string
}

export type AlarmWithImpact = WithImpact<Alarm>

export interface SdiInput {
    physicalPort: string
}

export interface SdiOutput {
    physicalPort: string
}

export interface SupportedAudioCodec {
    name: AudioCodec
    bitrates: number[]
}

export interface AudioFeatures {
    layout: string[]
    maxAudioStreams: number
    codecs: SupportedAudioCodec[]
}

export interface VideoFeatures {
    codecs: SupportedVideoCodec[]
    latencyModes?: { name: string; value: string }[]
    scalingModes?: { name: string; value: string }[]
    flags?: { name: string; value: VideoFlags; disabledForCodecs: VideoCodec[] }[]
}

export interface IntegerRangeSpec {
    min: number
    max: number
}

export interface SupportedVideoCodec {
    name: VideoCodec
    profiles?: { name: string; value: string }[]
    bitrate: IntegerRangeSpec
}

export interface EncoderFeatures {
    audio: AudioFeatures
    video: VideoFeatures
}

export interface FecLimits {
    maxSize: number
    colRange: IntegerRangeSpec
    rowRange: IntegerRangeSpec
}

export interface ApplianceFeatures {
    coaxPorts?: number
    coaxModes?: CoaxPortMode[]
    encoder?: EncoderFeatures
    fec?: FecLimits
}

export enum ApplianceType {
    nimbraVAdocker = 'nimbraVAdocker',
    nimbra410 = 'nimbra410',
    nimbra412 = 'nimbra412',
    nimbra414 = 'nimbra414',
    nimbraVA225 = 'nimbraVA225',
    nimbraVA220 = 'nimbraVA220',
    edgeConnect = 'edgeConnect',
    core = 'core',
    thumb = 'thumb',
    videon = 'videon',
}

export type Latitude = number
export type Longitude = number
export type Coordinates = [Latitude, Longitude]
export type CoordinatesMapbox = [Longitude, Latitude]

export interface GeoLocation {
    location: string
    coordinates: Coordinates
}

/**
 * @OAS_DESCRIPTION An appliance, e.g. EdgeConnect, is a piece of software that runs on a physical or virtual machine (multiple appliances can run on the same machine).
 * An appliance registers itself with the Edge backend.
 * After registration it is possible to create inputs and outputs on the ports (network interfaces) on the appliance.
 */
export interface Appliance extends Entity {
    name: string
    hostname: string
    type: ApplianceType
    serial: string
    contact: string
    version: ApplianceVersion
    owner: string | Group
    physicalPorts: PhysicalPort[]
    alarms: Alarm[]
    features: ApplianceFeatures
    logLevel: LogLevel
    ristserverLogLevel: RistserverLogLevel
    lastMessageAt?: Date
    lastRegisteredAt?: Date
    health?: ApplianceStatus
    region: Pick<Region, 'id' | 'name'>
    secondaryRegion?: Pick<Region, 'id' | 'name'>
    geoLocation?: GeoLocation
    settings?: ApplianceSettings
}

export interface ApplianceSettings {
    useDynamicTunnelClientSourceAddress?: boolean
}

export interface RegisterVideonAppliance {
    type: ApplianceType
    name: string
    region: string
    secondaryRegion?: string

    configuration: {
        ipAddress: string
        subnetMask: string
        gateway: string
        dnsServer: string
    }
}

export type ManualApplianceRegistration = RegisterVideonAppliance

export interface ApplianceStatus {
    title: string
    state: ApplianceConnectionState
}

export interface ServiceStatus {
    title: string
    state: ServiceState
}

export enum ServiceState {
    ok = 'ok',
}

export enum ApplianceConnectionState {
    connected = 'connected',
    missing = 'missing',
    neverConnected = 'neverConnected',
}

export interface ApplianceVersion {
    vaVersion?: string
    controlImageVersion?: string
    controlSoftwareVersion?: string
    dataImageVersion?: string
    dataSoftwareVersion?: string
}

export interface NanoTimestampRangeFilter {
    start: number
    end: number
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of groups can be filtered.
 */
export interface GroupFilter extends SearchableFilter {
    id?: string
    ids?: string[]
    name?: string
    excludeIds?: string[]

    /// Return the groups with access to ANY (i.e. not all) of the received input ids
    withAccessToAnyInputs?: string[]

    /// Return the groups with no access to any one of the received input ids
    withoutAccessToAnyInputs?: string[]
}

export enum PortType {
    'ip' = 'ip',
    'coax' = 'coax',
    'videon' = 'videon',
}

export interface PhysicalPortListItem extends PhysicalPort {
    logicalPorts: Array<{ id: string; direction: 'input' | 'output' }>
}

export interface Address {
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    interRegionPublicAddress?: string

    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    publicAddress?: string

    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    internalAddress?: string

    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    address: string
    netmask: string
}

/**
 * @OAS_DESCRIPTION A PhysicalPort represents a NIC (network interface card) on an appliance.
 */
export interface PhysicalPort extends Entity {
    addresses: Array<Address>
    appliance: Pick<Appliance, 'id' | 'name' | 'type'>
    index?: string // Only used for addressing SDI ports on VA (even though ethernet ports are also given an index)
    mac: string // Empty string for SDI ports
    inputs?: Array<Input['id']>
    name: string
    outputs?: Array<Output['id']>
    owner: string
    portType: PortType
    networks: Array<Network>
}

export interface SharedPort {
    // refers to physicalPort.id
    port: string
    // refers to affiliate.id
    owner: string
    // refers to network.id
    networks?: string[]
}

export interface IngestTransformBase {
    type: string
}

export interface MptsDemuxTransform extends IngestTransformBase {
    type: 'mpts-demux'
    services: number[]
}

export type IngestTransform = MptsDemuxTransform

/**
 * @OAS_DESCRIPTION Contains data used to create a new Input.
 */
export interface InputInit {
    name: string
    adminStatus: InputAdminStatus
    bufferSize?: number
    ports?: InputPort[]
    maxBitrate?: number | null
    thumbnailMode: ThumbnailMode
    encoderSettings?: EncoderSettings
    videoPreviewMode?: VideoPreviewMode
    // Non-present/omitted value is interpreted as "true" for backward compatibility.
    tr101290Enabled?: boolean
    broadcastStandard?: BroadcastStandard

    deriveFrom?: {
        parentInput: string
        delay: number
        ingestTransform?: IngestTransform
    }
}

/**
 * @OAS_DESCRIPTION Contains data used to update an existing Input.
 */
export type InputUpdate = Pick<
    Input,
    | 'name'
    | 'tr101290Enabled'
    | 'broadcastStandard'
    | 'adminStatus'
    | 'videoPreviewMode'
    | 'encoderSettings'
    | 'ports'
    | 'thumbnailMode'
    | 'maxBitrate'
    | 'bufferSize'
    | 'deriveFrom'
>

export type InputAdminStatusUpdate = Pick<Input, 'id' | 'adminStatus'>
export type OutputAdminStatusUpdate = Pick<Output, 'id' | 'adminStatus'>

/**
 * @OAS_DESCRIPTION Contains data used to switch input of an existing Output.
 */
export interface OutputSwitchInput {
    input: Output['input'] | null
}

export enum InputAdminStatus {
    off = 0,
    on = 1,
}

export enum ThumbnailMode {
    none = 0,
    // edge = 1,
    core = 2,
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of alarms can be filtered.
 */
export interface AlarmFilter extends SearchableFilter {
    inputIds?: string[]
    includeCleared?: boolean
    applianceId?: string
}

export type AlarmImpact = {
    affectedInput?: string
    affectedOutput?: string
    _input?: Input
    _output?: Output
}

export type WithImpact<T extends Record<string, any>> = T & AlarmImpact

/**
 * @OAS_DESCRIPTION Parameters on which a list of inputs can be filtered.
 */
export interface InputFilter extends SearchableFilter {
    id?: string
    ids?: string[]
    inputName?: string
    group?: string
    parentId?: string
    applianceId?: string
    applianceIds?: string[]
    canSubscribe?: boolean
    tr101290Window?: MetricWindow
    derived?: boolean
}

export enum VideoPreviewMode {
    off = 'off',
    ondemand = 'on demand',
    alwayson = 'always on',
}

/**
 * @OAS_DESCRIPTION Represents a stream received by the associated appliance and ingested into the Edge cluster.
 * An Input can be assigned to one or multiple Outputs for distribution.
 */
export interface Input extends InputInit, Entity {
    channelId: number
    canSubscribe: boolean
    previewUrl?: string // hls preview url
    owner?: string
    alarms?: AlarmWithImpact[]
    metrics?: InputMetrics
    tsInfo?: TransportStream[]
    health?: InputStatus
    numOutputs?: number
    numSharedGroups?: number
    appliances?: LimitedAppliance[]
    downstreamAppliances: LimitedAppliance[]
    createdAt: Date
    updatedAt: Date
    /// true if this input is misconfigured, e.g. 'localIp' does not exist on the associated appliance
    misconfigured?: boolean
    previewSettings?: {
        mode: VideoPreviewMode
    }

    derivedInputs?: LimitedInput[]
}

export interface LimitedInput extends Pick<Input, 'id' | 'name'> {}

export interface DeletedInput {
    id: Input['id']
    name: Input['name']
}

export type ApiInput = Input

export enum OutputRedundancyMode {
    none = 0,
    failover = 1,
    active = 2,
}

/**
 * @OAS_DESCRIPTION Contains data used to create a new Output.
 */
export interface OutputInit {
    adminStatus: OutputAdminStatus
    redundancyMode?: OutputRedundancyMode
    group?: string
    object?: 'output'
    input?: string
    delay?: number
    name: string
    ports: OutputPort[]
}

/**
 * @OAS_DESCRIPTION Output enabled/disabled status.
 */
export enum OutputAdminStatus {
    off = 0,
    on = 1,
}

/**
 * @OAS_DESCRIPTION Represents a stream egressed by the associated appliance and sent to a specified destination.
 * An Output can receive data from one Input.
 */
export interface Output extends Entity, OutputInit {
    createdAt: Date
    updatedAt: Date
    group: string
    alarms?: AlarmWithImpact[]
    metrics?: OutputMetrics
    tsInfo?: TransportStream[]
    health?: OutputStatus
    appliances: LimitedAppliance[]
    upstreamAppliances: LimitedAppliance[]
    /// true if this output is misconfigured, e.g. 'localIp' does not exist on the associated appliance
    misconfigured?: boolean
}

/**
 * @OAS_DESCRIPTION Contains data used to update an existing Output.
 */
export type OutputUpdate = Pick<Output, 'name' | 'input' | 'ports' | 'delay' | 'adminStatus' | 'redundancyMode'>
export interface OutputWithAppliance extends Output {
    _appliance: Appliance
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of outputs can be filtered.
 */
export interface OutputFilter extends SearchableFilter {
    id?: string
    ids?: string[]
    input?: string
    group?: string
    appliance?: string
    appliances?: string[]
    adminStatus?: OutputAdminStatus
    /// hasInput: If true: only fetch outputs that have an input. If false: only fetch outputs that have no inputs.
    hasInput?: boolean
    /// belongingToInputIds: If populated: only fetch outputs belonging to the specified input ids
    belongingToInputIds?: string[]
    /// notBelongingToInputIds: If populated: only fetch outputs NOT belonging to the specified input ids
    notBelongingToInputIds?: string[]

    /// The desired tr101290-metric window to fetch
    inputTr101290Window?: MetricWindow
}

export interface SearchableFilter {
    searchName?: string
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of appliances can be filtered.
 */
export interface ApplianceFilter extends SearchableFilter {
    id?: string
    ids?: string[]
    core?: boolean
    type?: ApplianceType
    types?: ApplianceType[]
    group?: string
    serials?: string[]
    serial?: string
    region?: string
    regionName?: string
    secondaryRegionName?: string
    regions?: string[]
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of address mappings can be filtered.
 */
export interface IpMappingFilter extends SearchableFilter {
    privateIp?: string
    region?: string
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of tunnels can be filtered.
 */
export interface TunnelFilter {
    ids?: string[]
    client?: string
    clientType?: ApplianceType
    clients?: string[]
    appliance?: string
    server?: string
    serverType?: ApplianceType
    servers?: string[]
    type?: TunnelType
    input?: string
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of ports can be filtered.
 */
export interface PortFilter extends SearchableFilter {
    id?: string
    core?: boolean
    appliance?: string
    applianceType?: ApplianceType
    owner?: Group['id']
    portType?: PortType
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of output recipient lists can be filtered.
 */
export interface OutputRecipientListFilter extends SearchableFilter {
    id?: string
    name?: string
    group?: string
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of group recipient lists can be filtered.
 */
export interface GroupRecipientListFilter extends SearchableFilter {
    id?: string
    name?: string
    group?: string
    withoutAccessToAnyInputs?: string[]
}

export interface RegisteredRouteList {
    pathTemplate: string
    method: string
    covered: boolean
}

const SDI_AUDIO_PAIRS = [1, 2, 3, 4, 5, 6, 7, 8] as const
export const sdiAudioPairs = [...SDI_AUDIO_PAIRS] // get the not ReadOnly array
export type SdiAudioPair = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8

export enum AudioLayout {
    mono = 'mono',
    stereo = 'stereo',
}

export enum AudioCodec {
    mpeg2 = 'mpeg-2',
    aacLc = 'aac-lc',
    heAac = 'he-aac',
    aes3 = 'aes3',
    ac3pt = 'ac3pt',
}

export enum AudioSystemStandard {
    systemA = 'System A',
    systemB = 'System B',
}

export type AudioStream = GenericAudioStream<Exclude<AudioCodec, AudioCodec.ac3pt>> | Ac3AudioStream

export interface GenericAudioStream<Codec extends AudioCodec> {
    type: AudioLayout
    codec: Codec
    pair: SdiAudioPair
    bitrate: number
}

export interface Ac3AudioStream extends GenericAudioStream<AudioCodec.ac3pt> {
    codec: AudioCodec.ac3pt
    systemStandard: AudioSystemStandard
}

export interface AncSettings {
    forward: boolean
    supervise: boolean
}

export interface Smpte2038Configuration {
    '1': AncSettings
    '2': AncSettings
    '3': AncSettings
    '4': AncSettings
    '5': AncSettings
    '6': AncSettings
    '7': AncSettings
    '8': AncSettings
    '9': AncSettings
    '10': AncSettings
    '11': AncSettings
    '12': AncSettings
    '13': AncSettings
    '14': AncSettings
    '15': AncSettings
    '16': AncSettings
    '17': AncSettings
    '18': AncSettings
    '19': AncSettings
    '20': AncSettings
    '21': AncSettings
    '22': AncSettings
    '23': AncSettings
}

export enum VideoCodec {
    h264 = 'h.264',
    h265 = 'h.265',
}

export type EncryptionType = 'aes128' | 'aes192' | 'aes256'

export interface EncryptionSettings {
    type: EncryptionType
    key: string
}

export interface EncoderSettings {
    videoCodec: VideoCodec
    totalBitrate: number
    gopSizeFrames: number
    audioStreams: AudioStream[]
    enableScte35?: boolean
    enableSmpte2038?: boolean
    smpte2038?: Smpte2038Configuration
    encrypt?: EncryptionSettings

    latencyMode?: string
    scalingMode?: string
    videoFlags?: { [key: string]: boolean }
    profile?: string
}

export enum Nimbra400VideoFlags {
    smpte12mTimecode = 'smpte12mTimecode',
}

export enum VideonVideoFlags {
    limitTo30Fps = 'limitTo30Fps',
    klvTimeCodeInsertion = 'klvTimeCodeInsertion',
}

export type VideoFlags = Nimbra400VideoFlags | VideonVideoFlags

export type PortMode = IpPortMode | CoaxPortMode | VideonPortMode

/**
 * @OAS_DESCRIPTION Supported transport protocols over IP.
 */
export enum IpPortMode {
    'rist' = 'rist',
    'udp' = 'udp',
    'rtp' = 'rtp',
    'srt' = 'srt',
    'zixi' = 'zixi',
    'rtmp' = 'rtmp',
    'generator' = 'generator',
}

/**
 * @OAS_DESCRIPTION Supported video ports
 */
export enum CoaxPortMode {
    // 'hdmi' = 'hdmi',
    'sdi' = 'sdi',
    'asi' = 'asi',
}

export enum VideonPortMode {
    'videonSdi' = 'videonSdi',
    'videonHdmi' = 'videonHdmi',
    'videonAuto' = 'videonAuto',
}

export enum Output3gLevel {
    'A' = 'A',
    'B' = 'B',
}

export enum OutputPortFec {
    '1D' = '1D',
    '2D' = '2D',
}

export interface IpPortAddress {
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    address: string
    /**
     * @DATA_FORMAT PORT
     */
    port: number
}

export interface IpPortListenAddress extends IpPortAddress {
    /**
     * @DATA_FORMAT PORT_LISTENING
     */
    port: number

    /**
     * @DATA_FORMAT IPV4_CIDR_BLOCK
     */
    whitelistCidrBlock?: string
}

export interface IpPortSourceAddress {
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    sourceAddress?: string
    localPort?: number
}

export interface IpMulticastAddress {
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    multicastAddress?: string

    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    multicastSource?: string
}

export interface UdpOutputPortCommon
    extends PortBase,
        IpPortAddress,
        IpMulticastAddress,
        IpPortSourceAddress,
        RegionalPort {
    ttl?: number
}

export interface UdpInputPortCommon extends PortBase, IpPortListenAddress, IpMulticastAddress, RegionalPort {}

export interface RistSimpleProfileInputPort extends PortBase, IpPortAddress, RegionalPort {
    mode: IpPortMode.rist
    profile: RistProfile.simple
    /**
     * @DATA_FORMAT PORT_LISTENING_EVEN
     */
    port: number

    /**
     * @DATA_FORMAT IPV4_CIDR_BLOCK
     */
    whitelistCidrBlock?: string
}

export interface RistSimpleProfileOutputPort extends PortBase, IpPortAddress, IpPortSourceAddress, RegionalPort {
    mode: IpPortMode.rist
    profile: RistProfile.simple
    /**
     * @DATA_FORMAT PORT_EVEN
     */
    port: number
}

export interface UdpInputPort extends UdpInputPortCommon, IngestTransformMixin {
    mode: IpPortMode.udp
}

export type GeneratorTimestampResolution = 'seconds' | 'milliseconds'
export type GeneratorResolutionPreset = '720p' | '1080p'
export type GeneratorFrameRate = '30' | '60' | '25' | '50' | '29.97' | '59.94'

export interface GeneratorInputPort extends PortBase, IpPortListenAddress {
    mode: IpPortMode.generator
    audioOnly?: boolean
    timestampResolution?: GeneratorTimestampResolution
    resolution?: GeneratorResolutionPreset
    frameRate?: GeneratorFrameRate
}

export interface UdpOutputPort extends UdpOutputPortCommon {
    mode: IpPortMode.udp
}

export interface RtpInputPort extends UdpInputPortCommon {
    mode: IpPortMode.rtp
    fec?: boolean

    // Used to distinguish priority between non-binary-equal RTP streams.
    // If omitted the streams must be binary-equal and will be used for SMPTE 2022-7 redundancy
    failoverPriority?: number
}

export interface RtmpInputPort extends PortBase, IpPortListenAddress, RegionalPort {
    mode: IpPortMode.rtmp
}

export interface RtmpOutputPort extends PortBase, RegionalPort {
    mode: IpPortMode.rtmp
    rtmpDestinationAddress: string
}

export interface RtpOutputPort extends UdpOutputPortCommon {
    mode: IpPortMode.rtp
    // Only applicable for VA-appliances
    fec?: OutputPortFec
    // Only applicable for VA-appliances
    fecRows?: number
    // Only applicable for VA-appliances
    fecCols?: number
}

export interface SrtPortBase extends PortBase, RegionalPort {
    mode: IpPortMode.srt
    /**
     * @DATA_FORMAT ALPHANUMERIC
     */
    passphrase?: string // Used when pbkeylen is not 'none'
    latency: number
    ipttl?: number
    mss?: number
}

export interface SrtInputPortBase extends SrtPortBase {
    reducedBitrateDetection: boolean
    reducedBitrateThreshold?: number // Used when reducedBitrateDetection is true
    unrecoveredPacketsDetection: boolean
    unrecoveredPacketsThreshold?: number // Used when unrecoveredPacketsDetection is true
}

export interface SrtCallerPort {
    srtMode: SrtMode.caller

    // Only applicable for E-C appliances (i.e. not VA)
    streamId?: string

    // IPV4_OR_HOSTNAME
    remoteIp: string
    /**
     * @DATA_FORMAT PORT
     */
    remotePort: number
    /**
     * @DATA_FORMAT PORT
     */
    localPort?: number // Used to enforce the local outgoing port. Incoming traffic will also be received on this local port.
}

export interface SrtListenerPort {
    srtMode: SrtMode.listener
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    localIp: string
    /**
     * @DATA_FORMAT PORT_LISTENING
     */
    localPort: number

    /**
     * @DATA_FORMAT IPV4_CIDR_BLOCK
     */
    whitelistCidrBlock?: string
}

export interface SrtRendezvousPort {
    srtMode: SrtMode.rendezvous
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    localIp: string

    // IPV4_OR_HOSTNAME
    remoteIp: string
    /**
     * @DATA_FORMAT PORT_LISTENING
     */
    remotePort: number // Specifies both local and remote port. Note that the local port is this way both listening port and outgoing port.

    /**
     * @DATA_FORMAT IPV4_CIDR_BLOCK
     */
    whitelistCidrBlock?: string
}

export interface SrtCallerInputPort extends SrtCallerPort, SrtInputPortBase {}

export interface SrtListenerInputPort extends SrtListenerPort, SrtInputPortBase {}

export interface SrtRendezvousInputPort extends SrtRendezvousPort, SrtInputPortBase {}

export interface SrtCallerOutputPort extends SrtCallerPort, SrtOutputPortBase {}

export interface SrtListenerOutputPort extends SrtListenerPort, SrtOutputPortBase {}

export interface SrtRendezvousOutputPort extends SrtRendezvousPort, SrtOutputPortBase {}

export type SrtInputPort = SrtCallerInputPort | SrtListenerInputPort | SrtRendezvousInputPort
export type SrtOutputPort = SrtCallerOutputPort | SrtListenerOutputPort | SrtRendezvousOutputPort

export interface SrtOutputPortBase extends SrtPortBase {
    pbkeylen: SrtKeylen
    rateLimiting: SrtRateLimiting
    maxBw?: number // Used when rateLimiting is absolute
    inputBw?: number // Used when rateLimiting is not relativeToInput
    oheadBw?: number // Used when rate limiting is relativeToInput
}

export interface ZixiPortBase extends PortBase {
    mode: IpPortMode.zixi
    zixiMode: ZixiMode
    streamId: string
    password?: string
    unrecoveredPacketsDetection: boolean
    unrecoveredPacketsThreshold?: number // Used when unrecoveredPacketsDetection is true
}

export interface ZixiInputPortBase extends ZixiPortBase {
    decryptKey?: string
    decryptType: ZixiDecryptType
    reducedBitrateDetection: boolean // Only applicable for VA appliances
    reducedBitrateThreshold?: number // Used when reducedBitrateDetection is true
}

export enum ZixiDecryptType {
    none = 'none',
    aes128 = 'aes128',
    aes192 = 'aes192',
    aes256 = 'aes256',
}

export enum ZixiLinkMode {
    single = 'single',
    standby = 'standby',
    bonded = 'bonded',
}

export interface ZixiLink {
    rateLimit?: number

    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    localIp: string

    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    remoteIp: string
    /**
     * @DATA_FORMAT PORT
     */
    remotePort: number
}

export type ZixiLinkSet = [ZixiLink] | [ZixiLink, ZixiLink] | [ZixiLink, ZixiLink, ZixiLink]

/// Mandatory settings for VA
interface ZixiFecSettings {
    fecLatency?: number
    optimizeFec?: boolean
    maxFecOverhead?: number
}

export interface ZixiPullInputPort extends ZixiInputPortBase, ZixiFecSettings {
    zixiMode: ZixiMode.pull
    retransmitBuf: number
    // IPV4_OR_HOSTNAME
    remotePrimaryIp: string
    // IPV4_OR_HOSTNAME
    remoteSecondaryIp?: string

    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    localIp?: string

    /**
     * @DATA_FORMAT PORT
     */
    pullPort: number
}

export interface ZixiPushInputPort extends ZixiInputPortBase {
    zixiMode: ZixiMode.push
}

export interface ZixiPullOutputPort extends ZixiPortBase {
    zixiMode: ZixiMode.pull
}

export interface ZixiPushOutputPort extends ZixiPortBase, ZixiFecSettings {
    zixiMode: ZixiMode.push
    linkMode: ZixiLinkMode
    linkSet1: ZixiLinkSet
    linkSet2?: ZixiLinkSet
    retransmitBuf: number
    // maxBitrateMbps is only applicable for zixi-feeder-push outputs, which can currently only be set up on core appliances
    maxBitrateMbps?: number
}

export type ZixiInputPort = ZixiPullInputPort | ZixiPushInputPort
export type ZixiOutputPort = ZixiPullOutputPort | ZixiPushOutputPort

export interface AllocatePortInRegionRequest {
    regionId: string

    // Should be populated if allocating an interface for an output.
    // If populated: will allocate an interface on the same appliance as the input, if possible.
    inputId?: string

    // If populated: will not allocate an interface on any of the excluded appliances.
    excludedApplianceIds?: string[]
}
export interface AllocatePortOnApplianceRequest {
    // If populated: will allocate an interface on applianceId
    applianceId: string
}

export type AllocatePortRequest = AllocatePortInRegionRequest | AllocatePortOnApplianceRequest

export interface AllocatedPhysicalPort {
    id: string
    physicalPort: LimitedPhysicalPort & { appliance: Pick<Appliance, 'id' | 'name' | 'type'> & { region: string } }
    portNumber: number
    createdAt: Date
    expiresAt: Date
}

/**
 * @OAS_DESCRIPTION A subset of a PhysicalPort.
 */
export interface LimitedPhysicalPort {
    id: string
    name: string
    addresses: Address[]
    appliance: Pick<Appliance, 'id' | 'name' | 'type'>
    portType: PortType
}

export interface PortBase {
    id?: string
    mode: string
    physicalPort: string
    copies?: number
    portIndex?: number
}

export interface RegionalPort extends PortBase {
    // allocatedPortId is only present when creating/updating a regional input/output (not when fetching)
    allocatedPortId?: string

    region?: {
        id: string
        name: string
        // Present when fetching a regional port
        allocatedPort?: LimitedPhysicalPort & { portNumber: number }
    }
}

export interface SdiInputPort extends PortBase {
    mode: CoaxPortMode.sdi
}

export interface VideonSdiInputPort extends PortBase {
    mode: VideonPortMode.videonSdi
}

export interface VideonHdmiInputPort extends PortBase {
    mode: VideonPortMode.videonHdmi
}

export interface VideonAutoInputPort extends PortBase {
    mode: VideonPortMode.videonAuto
}

export interface SdiOutputPort extends PortBase {
    mode: CoaxPortMode.sdi
    output3gLevel?: Output3gLevel
}

export interface AsiInputPort extends PortBase {
    mode: CoaxPortMode.asi
}

export interface AsiOutputPort extends PortBase {
    mode: CoaxPortMode.asi
}

export interface GroupBase {
    name: string
    applianceSecret: string
}

/**
 * @OAS_DESCRIPTION Data required when creating a new group.
 */
export interface GroupInit extends GroupBase {
    adminUsername?: string
    adminPassword?: string
}

export interface GroupUpdate extends GroupBase {}

/**
 * @OAS_DESCRIPTION A group is a grouping of related items, such as users, appliances, inputs and outputs, etc.
 */
export interface Group extends Entity, Omit<GroupBase, 'applianceSecret'> {
    applianceSecret?: string
    parent?: string
}

export type IpInputPort =
    | UdpInputPort
    | RtpInputPort
    | RtmpInputPort
    | RistSimpleProfileInputPort
    | SrtInputPort
    | ZixiInputPort
    | GeneratorInputPort

export type CoaxInputPort = SdiInputPort | AsiInputPort

export type VideonInputPort = VideonSdiInputPort | VideonHdmiInputPort | VideonAutoInputPort

export type InputPort = IpInputPort | CoaxInputPort | VideonInputPort

export type IpOutputPort =
    | UdpOutputPort
    | RtpOutputPort
    | RistSimpleProfileOutputPort
    | SrtOutputPort
    | ZixiOutputPort
    | RtmpOutputPort

export type CoaxOutputPort = SdiOutputPort | AsiOutputPort

export interface IngestTransformMixin {
    ingestTransform?: IngestTransform
}

export type OutputPort = IpOutputPort | CoaxOutputPort

export enum Role {
    basic = 'basic',
    admin = 'admin',
    super = 'super',
}

export interface LoginRequest {
    username: string
    password: string
}

export interface LoginResult {
    user: {
        id: string
        object: 'user'
        username: string
        role: Role
        group: string
        impersonatedBy?: { id: string; username: string }
    }
}

export interface NewUser {
    username: string
    password: string
    role: Role
    group: string
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of users can be filtered.
 */
export interface UserFilter extends SearchableFilter {
    id?: string
    username?: string
    group?: string
}

export interface User extends Entity, Omit<NewUser, 'password'> {
    impersonatedBy?: { id: string; username: string }
}

export interface UserUpdate extends Partial<NewUser> {
    role: Role
}

export interface ApiTokenInit {
    name: string
    role: Role
    expiresAt: Date
}

export interface ApiToken extends Entity, ApiTokenInit {}

export interface ApiTokenCreated extends ApiToken {
    token: string // Only returned once on creation
}

export interface ApiTokenFilter extends SearchableFilter, Partial<Pick<ApiToken, 'id' | 'name'>> {}

export interface LocalBuildInfo {
    ciBuild: false
    buildTime: string
    release: string
}

export interface CiBuildInfo {
    ciBuild: true
    buildTime: string
    release: string
    ciProjectName: string
    ciJobId: number
    ciCommitShortSha: string
}

export type BuildInfo = LocalBuildInfo | CiBuildInfo

export type VaInputPipe =
    | VaUdpInputPipe
    | VaSdiInputPipe
    | VaAsiInputPipe
    | VaRistInputPipe
    | VaPullInputPipe
    | VaPushInputPipe
    | VaSrtInputPipe

export type VaOutputPipe =
    | VaUdpOutputPipe
    | VaSdiOutputPipe
    | VaAsiOutputPipe
    | VaRistOutputPipe
    | VaPullOutputPipe
    | VaPushOutputPipe
    | VaSrtOutputPipe

/** ############### VA OBJECT TYPES BEGIN ############### */

export type VaObject =
    | VaEdgeSinkObject
    | VaEdgeSourceObject
    | VaUdpSinkObject
    | VaUdpSourceObject
    | VaRistSinkObject
    | VaRistSourceObject
    | VaSdiInputObject
    | VaSdiOutputObject
    | VaAsiInputObject
    | VaAsiOutputObject
    | VaEncoderObject
    | VaDecoderObject
    | VaSrtSinkObject
    | VaSrtSourceObject
    | VaPullSinkObject
    | VaPushSinkObject
    | VaPullSourceObject
    | VaPushSourceObject

export interface VaBaseObject {
    inputFrom: string
    type: VaObjectInputType | VaObjectOutputType
    name: string
    logicalPort: string
}

export interface VaSdiObject extends VaBaseObject {
    type: VaObjectInputType.sdiInput | VaObjectOutputType.sdiOutput
    statistics: {
        general: GeneralStats
        netRecv: NetRecv
        netSend: NetSend
    }
}

export interface VaSdiInputObject extends VaSdiObject {
    type: VaObjectInputType.sdiInput
}

export interface VaSdiOutputObject extends VaSdiObject {
    type: VaObjectOutputType.sdiOutput
}

export interface VaAsiObject extends VaBaseObject {
    type: VaObjectInputType.asiInput | VaObjectOutputType.asiOutput
    statistics: {
        general: GeneralStats
        netRecv: NetRecv
        netSend: NetSend
    }
}

export interface VaAsiInputObject extends VaAsiObject {
    type: VaObjectInputType.asiInput
    statistics: VaAsiObject['statistics'] & {
        tr101: Tr101290
    }
}

export interface VaAsiOutputObject extends VaAsiObject {
    type: VaObjectOutputType.asiOutput
}

export interface VaEncoderObject extends VaBaseObject {
    type: VaObjectInputType.encoder
    statistics: {
        curTsBitrate: number
        encFrames: number
        tr101: Tr101290
    }
}

export interface VaDecoderObject extends VaBaseObject {
    type: VaObjectOutputType.decoder
    statistics: {
        curTsBitrate: number
        decFrames: number
        droppedFrames: number
        repeatedFrames: number
    }
}

export interface VaEdgeSinkObject extends VaBaseObject {
    type: VaObjectOutputType.edgeSink
    statistics: {
        fecRecv: FecRecv
        udpStats: UdpStats
        tr101: Tr101290
    }
}

export interface VaEdgeSourceObject extends VaBaseObject {
    type: VaObjectInputType.edgeSource
    statistics: {
        fecSend: FecSend
        udpStats: UdpStats
    }
}

export interface VaUdpSinkObject extends VaBaseObject {
    type: VaObjectInputType.udpSink
    statistics: {
        fecRecv: FecRecv
        udpStats: UdpStats
        tr101: Tr101290
    }
}

export interface VaUdpSourceObject extends VaBaseObject {
    type: VaObjectOutputType.udpSource
    statistics: {
        fecSend: FecSend
        udpStats: UdpStats
    }
}

/// TODO: To be removed. Rist-objects are deprecated and are scheduled for removal from Nimbra 400/VA in September 2020
export interface VaRistSinkObject extends VaBaseObject {
    type: VaObjectInputType.ristSink
    statistics: {
        ristRecv: RistRecv
        udpStats: UdpStats
        tr101: Tr101290
    }
}

/// TODO: To be removed. Rist-objects are deprecated and are scheduled for removal from Nimbra 400/VA in September 2020
export interface VaRistSourceObject extends VaBaseObject {
    type: VaObjectOutputType.ristSource
    statistics: {
        ristSend: RistSend
        udpStats: UdpStats
    }
}

export interface VaSrtSinkObject extends VaBaseObject {
    type: VaObjectInputType.srtSink
    statistics: {
        srtRecv: SrtRecv
        udpStats: UdpStats
        tr101: Tr101290
    }
}

export interface VaSrtSourceObject extends VaBaseObject {
    type: VaObjectOutputType.srtSource
    statistics: {
        srtSend: SrtSend
        udpStats: UdpStats
    }
}

export interface VaPullSinkObject extends VaBaseObject {
    type: VaObjectInputType.pullSink
    statistics: {
        general: GeneralStats
        arqRecv: ArqRecv
        arqSend: ArqSend
        fecRecv: FecRecv
        fecSend: FecSend
        netRecv: NetRecv
        netSend: NetSend
        tr101: Tr101290
    }
}

export interface VaPushSinkObject extends VaBaseObject {
    type: VaObjectInputType.pushSink
    statistics: {
        general: GeneralStats
        arqRecv: ArqRecv
        arqSend: ArqSend
        fecRecv: FecRecv
        fecSend: FecSend
        netRecv: NetRecv
        netSend: NetSend
        tr101: Tr101290
    }
}

export interface VaPullSourceObject extends VaBaseObject {
    type: VaObjectOutputType.pullSource
    statistics: {
        general: GeneralStats
        arqRecv: ArqRecv
        arqSend: ArqSend
        fecRecv: FecRecv
        fecSend: FecSend
        netRecv: NetRecv
        netSend: NetSend
    }
}

export interface VaPushSourceObject extends VaBaseObject {
    type: VaObjectOutputType.pushSource
    statistics: {
        general: GeneralStats
        arqRecv: ArqRecv
        arqSend: ArqSend
        fecRecv: FecRecv
        fecSend: FecSend
        netRecv: NetRecv
        netSend: NetSend
    }
}

/** ############### VA OBJECT TYPES END ############### */

/** ############### VA STATS TYPES BEGIN ############### */

export interface GeneralStats {
    /// "Reconnections" in VA GUI
    numConnects: number
    numDisconnects: number
}

export interface UdpStats {
    bitrate: number
}

export interface ArqRecv {
    recovered: number
    retransmits: number
    dropped: number
    overflow: number
    erroredSeconds: number
    retransBitrate: number
    almostDropped: number
    retransReqs: number
    duplicates: number
}

export interface ArqSend {
    retransRate: number
    retransBitrate: number
    missedReqs: number
    ignoredReqs: number
    retransPackets: number
}

export interface FecRecv {
    recovered: number
    fecBitrate: number
    fecPackets: number
    fecRate: number
}

export interface FecSend {
    fecBitrate: number
    fecPackets: number
    fecRate: number
}

export interface NetRecv {
    latency: number
    inputBitrate: number
    burstLoss: number
    receivedBytes: number
    dropped: number
    jitter: number
    jitterRatio: number
    outOfOrder: number
    overflow: number
    numPackets: number
    packetRate: number
    packetLoss: number
    erroredSeconds: number
    unavailableSeconds: number
}

export interface NetSend {
    sendBitrate: number
    sentBytes: number
    sendLimit: number
    sentPackets: number
    rtt: number
    sendErrors: number
}

/// TODO: To be removed. Rist-objects are deprecated and are scheduled for removal from Nimbra 400/VA in September 2020
export interface RistRecv {
    recovered: number
    memData: number
    memText: number
    memResident: number
    memSize: number
    dropped: number
    rtt: number
    received: number
    retransReqs: number
    duplicates: number
    unrecovered: number
}

/// TODO: To be removed. Rist-objects are deprecated and are scheduled for removal from Nimbra 400/VA in September 2020
export interface RistSend {
    resent: number
    memData: number
    memText: number
    memResident: number
    memSize: number
    rtt: number
    sent: number
}

export interface SrtRecv {
    memData: number
    memText: number
    memResident: number
    memSize: number
    dropped: number
    rtt: number
    belated: number
    received: number
    bytesLost: number
    bytesDropped: number
    bandwidth: number
    bytes: number
    lost: number
    retransmitted: number
    bitRate: number
}

export interface SrtSend {
    memData: number
    memText: number
    memResident: number
    memSize: number
    dropped: number
    rtt: number
    bytesDropped: number
    bandwidth: number
    bytes: number
    lost: number
    sent: number
    retransmitted: number
    bitRate: number
}

export interface Tr101290 {
    prio1: Tr101290Prio1
    prio2: Tr101290Prio2
}

export type Tr101290Prio1 = {
    TS_sync_loss: number
    Sync_byte_error: number
    PAT_error: number
    Continuity_count_error: number
    PMT_error: number
    PID_error: number
}
export type Tr101290Prio2 = {
    Transport_error: number
    CRC_error: number
    PCR_error: number
    PCR_accuracy_error: number
    PTS_error: number
    CAT_error: number
}

export type VaUdpInputPipe = [VaUdpSinkObject, VaEdgeSourceObject]
export type VaUdpOutputPipe = [VaEdgeSinkObject, VaUdpSourceObject]

export type VaSdiInputPipe = [VaSdiInputObject, VaEncoderObject, VaEdgeSourceObject]
export type VaSdiOutputPipe = [VaEdgeSinkObject, VaDecoderObject, VaSdiOutputObject]

export type VaAsiInputPipe = [VaAsiInputObject, VaEdgeSourceObject]
export type VaAsiOutputPipe = [VaEdgeSinkObject, VaAsiOutputObject]

export type VaPullInputPipe = [VaPullSinkObject, VaEdgeSourceObject]
export type VaPullOutputPipe = [VaEdgeSinkObject, VaPullSourceObject]

export type VaPushInputPipe = [VaPushSinkObject, VaEdgeSourceObject]
export type VaPushOutputPipe = [VaEdgeSinkObject, VaPushSourceObject]

export type VaSrtInputPipe = [VaSrtSinkObject, VaEdgeSourceObject]
export type VaSrtOutputPipe = [VaEdgeSinkObject, VaSrtSourceObject]

/// TODO: To be removed. Rist-objects are deprecated and are scheduled for removal from Nimbra 400/VA in September 2020
export type VaRistInputPipe = [VaRistSinkObject, VaEdgeSourceObject]
export type VaRistOutputPipe = [VaEdgeSinkObject, VaRistSourceObject]

export enum VaObjectInputType {
    sdiInput = 'sdi-input',
    encoder = 'encoder',
    udpSink = 'udp-sink',
    ristSink = 'rist-sink',
    srtSink = 'srt-sink',
    edgeSource = 'edge-source',
    asiInput = 'asi-input',
    pullSink = 'pull-sink',
    pushSink = 'push-sink',
}

export enum VaObjectOutputType {
    sdiOutput = 'sdi-output',
    decoder = 'decoder',
    udpSource = 'udp-source',
    ristSource = 'rist-source',
    srtSource = 'srt-source',
    edgeSink = 'edge-sink',
    asiOutput = 'asi-output',
    pullSource = 'pull-source',
    pushSource = 'push-source',
}

/** ############### VA STATS TYPES END ############### */

export type StringPropsAsNumbers<T> = {
    [k in keyof T]: k extends string ? number : never
}

export interface Tr101290Metrics {
    prio1: StringPropsAsNumbers<Tr101290Prio1>
    prio2: StringPropsAsNumbers<Tr101290Prio2>
    time: Date
    window: MetricWindow
    applianceId: string
    applianceName: string
    applianceType: string
    inputId: string
    inputName: string
    type: string
}

export interface InputMetrics {
    vaInputPipe?: VaInputPipe
    ristMetrics: StreamMetrics[]
    tr101290Metrics: Tr101290Metrics[]
}

/**
 * RistMetricType: the possible types of metric objects emitted from the ristServer
 */
export enum RistMetricType {
    ristInput = 'ristInput',
    ristOutput = 'ristOutput',
    udpInput = 'udpInput',
    udpOutput = 'udpOutput',
    rtpInput = 'rtpInput',
    rtpOutput = 'rtpOutput',
    channel = 'channel',
}

export enum ZixiMetricType {
    zixiOutput = 'zixiOutput',
    zixiInput = 'zixiInput',
}

export enum SrtMetricType {
    srtOutput = 'srtOutput',
    srtInput = 'srtInput',
}

export enum RtmpMetricType {
    rtmpInput = 'rtmpInput',
    rtmpOutput = 'rtmpOutput',
}

export enum MptsMetricType {
    mptsDemux = 'mptsDemux',
    // mptsInput = 'mptsInput',
    // mptsOutput = 'mptsOutput',
}

export enum RistSimpleProfileMetricType {
    ristSimpleInput = 'ristSimpleInput',
    ristSimpleOutput = 'ristSimpleOutput',
}

export enum VideonMetricType {
    'videonAutoInput' = 'videonAutoInput',
    'videonHdmiInput' = 'videonHdmiInput',
    'videonSdiInput' = 'videonSdiInput',
}

export type StreamMetricType =
    | RistMetricType
    | RistSimpleProfileMetricType
    | ZixiMetricType
    | SrtMetricType
    | RtmpMetricType
    | MptsMetricType

export type RistOutputMetricType =
    | RistMetricType.ristInput
    | RistMetricType.ristOutput
    | RistMetricType.udpOutput // type 'udpOutput' also covers SRT and Zixi outputs, since they are fetched from the ristserver as udp outputs streams and then fed to zixi/srt processes
    | RistMetricType.rtpOutput
    | RistSimpleProfileMetricType.ristSimpleOutput

export interface ChannelMetrics extends StreamMeasurement<RistMetricType.channel> {
    retransmitBudget: number
    retransmitRoundtripMs: number
    resetCount: number
    maxReorderTimeMs: number
}

export interface RistInputWindow1mMetrics extends StreamMeasurement<RistMetricType.ristInput, MetricWindow.m1> {
    packetsLost: number
}

export interface RistSimpleProfileInputMetrics extends StreamMeasurement<RistSimpleProfileMetricType.ristSimpleInput> {
    packetsLost: number
    bytesReceived: number
    packetsReceived: number
    receiveBitrate: number
    receivePacketrate: number
    rtpPacketsReceived: number
    retransmitReceivePacketrate: number
    retransmitReceiveBitrate: number
    packetSendErrors: number
    packetsDiscarded: number
    roundtripMs: number
    propagationDelayMs: number
    longestBurstLoss: number
    unsupportedRtcpPacketsReceived: number
    malformedRtcpPacketsReceived: number
}

export interface RistSimpleInputWindow1mMetrics
    extends StreamMeasurement<RistSimpleProfileMetricType.ristSimpleInput, MetricWindow.m1> {
    packetsLost: number
}

export interface RistInputMetrics extends StreamMeasurement<RistMetricType.ristInput> {
    packetSendErrors: number
    packetsDiscarded: number
    unsupportedRtcpPacketsReceived: number
    packetsLost: number
    bytesReceived: number
    packetsReceived: number
    receiveBitrate: number
    receivePacketrate: number
    rtpPacketsReceived: number
    retransmitReceivePacketrate: number
    retransmitReceiveBitrate: number
    roundtripMs: number
    propagationDelayMs: number
    longestBurstLoss: number
    malformedRtcpPacketsReceived: number
    multipathState?: RistInputMultipathState
}

export interface RtpInputMetrics extends StreamMeasurement<RistMetricType.rtpInput> {
    packetsDiscarded: number
    receiveBitrate: number
    receivePacketrate: number
}

export interface RtpOutputMetrics extends StreamMeasurement<RistMetricType.rtpOutput> {
    packetsLost: number
    sendBitrate: number
    sendPacketrate: number
    lostPacketrate: number
    isEgress: true
}

export interface UdpInputMetrics extends StreamMeasurement<RistMetricType.udpInput> {
    // Number of malformed *RTP* packets. Not included in `packetsReceived`.
    packetsDiscarded: number
    // Number of packets discarded because the input wasn't active.
    packetsWhileInactive: number
    receiveBitrate: number
    receivePacketrate: number
    status?: UdpInputStatusCode
}

export interface UdpOutputMetrics extends StreamMeasurement<RistMetricType.udpOutput> {
    packetsLost: number
    sendBitrate: number
    sendPacketrate: number
    lostPacketrate: number
    playoutMarginMs: number
    status?: UdpOutputStatusCode
}

export interface UdpOutput1mMetrics extends StreamMeasurement<RistMetricType.udpOutput, MetricWindow.m1> {
    packetsLost: number
}

export interface RistOutputMetrics extends StreamMeasurement<RistMetricType.ristOutput> {
    bytesSent: number
    packetSendErrors: number
    packetsSent: number
    sendBitrate: number
    sendPacketrate: number
    retransmitSendPacketrate: number
    retransmitSendBitrate: number
    rtpPacketsSent: number
    malformedRtcpPacketsReceived: number
    unsupportedRtcpPacketsReceived: number
    reportedPacketLossPercent: number
    roundtripMs: number

    multipathState?: RistOutputMultipathState
}

export interface RistSimpleProfileOutputMetrics
    extends StreamMeasurement<RistSimpleProfileMetricType.ristSimpleOutput> {
    bytesSent: number
    packetSendErrors: number
    packetsSent: number
    sendBitrate: number
    sendPacketrate: number
    retransmitSendBitrate: number
    retransmitSendPacketrate: number
    roundtripMs: number
    rtpPacketsSent: number
    malformedRtcpPacketsReceived: number
    unsupportedRtcpPacketsReceived: number
    reportedPacketLossPercent: number
    isEgress: true
}

export interface ZixiFeederSinkStats {
    inBitrate: number
    outBitrate: number
    rtt: number
    jitter: number
    totalPackets: number
    packetRate: number
    droppedPackets: number
    recoveredPackets: number
    notRecoveredPackets: number
    failedSends: number
    fecPackets: number
    fecRecovered: number
    arqRequests: number
    arqRecovered: number
    arqDuplicates: number
    overflows: number
}

export interface ZixiOutputMetrics extends StreamMeasurement<ZixiMetricType.zixiOutput, MetricWindow.s10> {
    bitrate: number
    connectionStatus: string
    sinkStats: ZixiFeederSinkStats
    error?: string
}

export interface ZixiInputMetrics extends StreamMeasurement<ZixiMetricType.zixiInput, MetricWindow.s10> {
    bitrate: number
    rtt: number
    connectionStatus: string
}

export interface SrtInputMetrics extends StreamMeasurement<SrtMetricType.srtInput, MetricWindow.s10> {
    bitrate: number
    rtt: number
    remoteAddress?: string
    packetsDropped: number
    packetsLost: number
    packetsRetransmitted: number
    msRecvBuffer: number
}

export interface SrtOutputMetrics extends StreamMeasurement<SrtMetricType.srtOutput, MetricWindow.s10> {
    bitrate: number
    rtt: number
    remoteAddress?: string
    packetsDropped: number
    packetsRetransmitted: number
    msSendBuffer: number
}

export interface RtmpInputMetrics extends StreamMeasurement<RtmpMetricType.rtmpInput, MetricWindow.s10> {
    receiveBitrateKbps: number
}

export interface RtmpOutputMetrics extends StreamMeasurement<RtmpMetricType.rtmpOutput, MetricWindow.s10> {
    state: RtmpOutputState
    restartCount: number
    sendBitrateKbps: number
}

export interface MptsDemuxMetrics extends StreamMeasurement<MptsMetricType.mptsDemux, MetricWindow.s10> {
    parentInputId: string
    parentStreamId: number

    parentUdpPackets: number
    parentUdpPacketsRate: number
    parentTruncatedBytes: number
    parentTruncatedBytesRate: number
    parentTsPackets: number
    parentTsPacketsRate: number

    udpPackets: number
    udpPacketsRate: number
    tsPackets: number
    tsPacketsRate: number
}

export enum MetricWindow {
    s10 = '10 seconds',
    m1 = '1 minute',
    m15 = '15 minutes',
    h1 = '1 hour',
    h24 = '24 hours',
}

export interface StreamMeasurement<T extends StreamMetricType, TWindow extends MetricWindow = MetricWindow.s10> {
    sampledAt: Date
    applianceId: string
    applianceName: string
    applianceType: string
    streamId?: number
    type: T
    window: TWindow
    inputId: string
    /// Note 13/11/2020: 'isEgress' is only applicable for 'ristServer' types (i.e. other egress-nodes such as the zixi-feeder are not tagged with this)
    // i.e. isEgress means the last node leaving the ristServer.
    isEgress?: boolean
    outputId?: string
    serverApplianceId?: string
    clientApplianceId?: string
}

export interface RistMeasurement<
    T extends RistMetricType | RistSimpleProfileMetricType,
    TWindow extends MetricWindow = MetricWindow.s10
> extends StreamMeasurement<T, TWindow> {}

export type AnyRistServerInputMetric =
    | RistInputMetrics
    | UdpInputMetrics
    | RtpInputMetrics
    | RistSimpleProfileInputMetrics

export type AnyRistServerOutputMetric =
    | RistOutputMetrics
    | UdpOutputMetrics
    | RtpOutputMetrics
    | RistSimpleProfileOutputMetrics

export type AnyRistServerPortMode = IpPortMode.udp | IpPortMode.rtp | IpPortMode.rist

export type RistServerOutputMetrics =
    | UdpOutputMetrics
    | RistOutputMetrics
    | RistSimpleProfileOutputMetrics
    | RtpOutputMetrics
    | UdpOutput1mMetrics

export type NonRistServerOutputMetrics = RtmpOutputMetrics | ZixiOutputMetrics | SrtOutputMetrics

export type StreamOutputMetrics = RistServerOutputMetrics | NonRistServerOutputMetrics

export type StreamMetrics =
    | AnyRistServerInputMetric
    | RtmpInputMetrics
    | ChannelMetrics
    | SrtInputMetrics
    | ZixiInputMetrics
    | RistInputWindow1mMetrics
    | RistSimpleInputWindow1mMetrics
    | StreamOutputMetrics
    | MptsDemuxMetrics

export type RistServerEgressMetric = RistSimpleProfileOutputMetrics | RtpOutputMetrics | UdpOutputMetrics

export interface OutputMetrics {
    vaOutputPipe?: VaOutputPipe
    ristMetrics: StreamMetrics[]
    tr101290Metrics: Tr101290Metrics[]
}

export type Timestamp = string
export type Bitrate = number
export type BitrateSample = [Timestamp, Bitrate]

export interface MaxBitrates {
    inputMaxBitrates: BitrateSample[]
    outputMaxBitrates: BitrateSample[]
}

export interface GroupInputPermission {
    type?: 'group'
    groupId: string
    accessType: InputAccessType
}

export interface GroupListInputPermission {
    type: 'groupList'
    groupListId: string
    accessType: InputAccessType
}

/**
 * @OAS_DESCRIPTION Describes the kind of access a group has to an input.
 */
export enum InputAccessType {
    view = 1,
    pull = 2,
}

export enum ExpFeatures {
    // These currently need to be  abc = 'abc' and not abc = 'friendly name for abc'
    OASResponseValidation = 'OASResponseValidation',
    ExtMptsDerivedInput = 'ExtMptsDerivedInput',
}
export type ExperimentalFeatures = Partial<Record<ExpFeatures, boolean>>

export enum KubernetesProvider {
    metal = 'metal',
    alibaba = 'alibaba', // eks
    aws = 'aws', // eks
    google = 'google',
    azure = 'azure', // aks
    oracle = 'oracle', //oks
}

export enum BroadcastStandard {
    dvb = 'dvb',
    atsc = 'atsc',
}
export interface GlobalSettings {
    kubernetesProvider?: KubernetesProvider
    vaUpdateUrl: string
    logLevel: LogLevel
    ristTunnelBasePort: number
    defaultDelay: number
    defaultBroadcastStandard: BroadcastStandard
    zixiFeederKey: string
    zixiReceiverKey: string
    ntpServer: string
    ntpEnabled: boolean
    showAllNimbra400Alarms: boolean
    expFeatures: ExperimentalFeatures
}

export interface TlsCertRead {
    fingerprint: string
    createdAt: Date
    expiresAt: Date
}

export interface TlsCertWrite {
    key: string
    cert: string
}

export interface StreamStatus<TEnum> {
    title: string
    state: TEnum
}

export interface InputStatus extends StreamStatus<InputOperStatus> {}

export interface OutputStatus extends StreamStatus<OutputOperStatus> {}

export enum InputOperStatus {
    notConfigured = 'notConfigured',
    metricsMissing = 'metricsMissing',
    allOk = 'allOk',
    inputError = 'inputError',
    tr101290Priority1Error = 'tr101290Priority1Error',
    transportError = 'transportError',
    alarm = 'alarm',
    reducedRedundancy = 'reducedRedundancy',
}

export enum OutputOperStatus {
    notConfigured = 'notConfigured',
    metricsMissing = 'metricsMissing',
    reducedRedundancy = 'reducedRedundancy',
    allOk = 'allOk',
    inputError = 'inputError',
    outputError = 'outputError', // transport error or appliance alarm
    alarm = 'alarm',
}

export interface BatchDeleteResult {
    deleted: string[]
    notDeleted: string[]
    // This is a hack to make the audit log populate the entity_name column for
    // batch operations with a comma-separated list of names
    names?: string[]
}

export interface SuccessResult {
    success: true
}

export interface CommandReceipt {
    command: {
        id: string
        name: string
    }
}

export type UpgradeCommandReceipt =
    | {
          command: {
              id: string
              name: string
              appliance: string
              updateUrl: string
          }
      }
    | {
          errorMessage: string
      }

export interface BaseAlarm {
    id: string
    objectId: string
    inputName: string
    instanceId: string
    type: string
    severity: AlarmSeverityLevels
    message: string
    raiseTime: string
    acknowledged: boolean
}

export interface ActiveAlarm extends BaseAlarm {
    active: true
    clearedByInstance: false
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of graph nodes can be filtered.
 */
export interface InputGraphFilter extends SearchableFilter {
    input?: string
    output?: string
    inputTr101290Window?: MetricWindow
}

export enum TunnelType {
    // Used by external appliances (edgeConnect, VA, etc) connecting to core nodes.
    // Uses "public" ip mapping.
    external = 1,

    // Used for communication between core appliances (video and thumb) within the same region.
    // Uses "internal" ip mapping.
    internal = 2,

    // Used for communication between different regions.
    // Uses "inter region" ip mapping.
    interRegion = 3,
}

export interface Tunnel {
    id: number
    type: TunnelType
    client: string
    clientName: string
    server: string
    serverName: string
    clientLocalIp: string
    clientLocalPort: number
    clientRemoteIp: string
    clientRemotePort: number
    serverLocalIp: string
    serverLocalPort: number
    // serverPublicIp can potentially be null for internal region thumb instances
    // where no instance metadata is available and no public ip mapping or internal ip mapping
    // is set. We might want to disallow such registrations in the future or disable the appliance
    // until a configuration is provided.
    serverPublicIp: string | null
    inputs: string[]
    network?: Network
}

export enum UsageDetailType {
    'input' = 'input',
    'output' = 'output',
}

export interface ScaleOperation {
    count: number
    // Time in seconds to wait for status to reflect the update
    waitSeconds?: number
}

export interface ScaleStatus {
    replicas: number
    currentReplicas: number
}

export interface ImageTagOperation {
    tag: string
}

export interface GetImageTagResult {
    container: string
    tag?: string
}

export enum ContainerTerminatedReason {
    completed = 'Completed',
}

export interface ContainerState {
    running?: {
        startedAt?: Date
    }
    terminated?: {
        reason?: ContainerTerminatedReason | string
    }
}

export interface ContainerStatus {
    containerID?: string
    image: string
    imageID: string
    lastState?: object
    name: string
    ready: boolean
    restartCount: number
    state?: ContainerState
}

export interface PodStatus {
    containerStatuses?: Array<ContainerStatus>
}

export interface PvcRequests {
    storage: string
}

export interface PvcResources {
    requests: PvcRequests
}

export interface PvcSpec {
    accessModes: string[]
    resources: PvcResources
    storageClassName: string
    volumeMode: string
    volumeName: string
}

export interface PvcCapacity {
    storage: string
}

export interface PvcStatus {
    accessModes: string[]
    capacity: PvcCapacity
    phase: string
}

export interface Pvc {
    apiVersion: string
    kind: string
    metadata: {
        name: string
        uid: string
        namespace: string
        labels: {
            [key: string]: string
        }
    }
    spec: PvcSpec
    status: PvcStatus
}

export interface PodPersistentVolumeClaim {
    claimName: string
}

export interface Volume {
    name: string
    persistentVolumeClaim?: PodPersistentVolumeClaim
}

export interface Pod {
    metadata?: {
        generateName?: string
        labels?: {
            // key: 'app.kubernetes.io/name'
            [key: string]: string
        }
        name?: string
    }
    spec?: {
        volumes?: Volume[]
    }
    status?: PodStatus
}

export interface KubernetesNode {
    name: string
    status: string
    externalIP: string
    internalIP: string
    hostname: string
    roles: KubernetesRoles[]
    kubeletVersion?: string
    labels: {
        [key: string]: string
    }
    region: {
        id: string
        name: string
        external: ExternalRegionMode
    }
}

type Gigabyte = number
type CpuUnit = number
export interface KubernetesResourceUsage {
    cpu: {
        capacity: CpuUnit
        usage: CpuUnit
    }
    memory: {
        capacity: Gigabyte
        usage: Gigabyte
    }
    name: string | undefined
}

export interface KubernetesNodeResourceUsage extends KubernetesResourceUsage {
    region: Region['id']
}

export enum ListKubernetesNodeSortableField {
    name = 'name',
    region = 'region',
    status = 'status',
}

export interface KubernetesNodeFilter extends SearchableFilter {
    name?: string
}

export interface UpdateKubernetesNodePayload {
    roles: KubernetesRoles[]
}

export interface CoreSecret {
    secret: string
}

export interface InternalApplianceStatus {
    id: string
    name: string
    status: ApplianceStatus
}

export enum ApplianceRealm {
    core = 'core',
    edge = 'edge',
}

export interface Message<T> {
    type: string
    data: T
}

export enum ContentType {
    thumb = 'thumb',
    hls = 'hls',
}

/**
 * @OAS_DESCRIPTION Data required when creating a new region.
 */
export interface RegionInit {
    name: string
    external: ExternalRegionMode
}

export enum ExternalRegionMode {
    // The region is outside the core cluster, without federation (separate k8s cluster)
    external = 1,
}

/**
 * @OAS_DESCRIPTION A region represents an Edge cluster.
 * Appliances have a primary region and an optional secondary region.
 */
export interface Region extends Entity, RegionInit {
    default_region: boolean | null
    external: ExternalRegionMode
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of regions can be filtered.
 */
export interface RegionFilter extends SearchableFilter {
    name?: string
    id?: string
    ids?: string[]
}

export interface RpcSession {
    id: string
    appliance: string
    initializingHost: string
    connectedHost: string
    createdAt: Date
    createdBy: string
    connectedAt: Date
    disconnectedAt: Date
    updatedAt: Date
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of rpc sessions can be filtered.
 */
export interface RpcSessionFilter {
    id?: string
    appliance?: string
}

export interface ThumbRoute {
    type: ContentType.thumb | ContentType.hls
    location: string
    host: string
}

export interface UpdateAppliancePayload {
    ports?: Array<SharedPort>
    region?: Pick<Region, 'id' | 'name'>
    secondaryRegion?: Pick<Region, 'id' | 'name'> | null
    geoLocation?: GeoLocation | null
    logLevel?: LogLevel
    ristserverLogLevel?: RistserverLogLevel
    settings?: Appliance['settings']
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of active services can be filtered.
 */
export interface ActiveServiceFilter extends SearchableFilter {}

export enum ActiveServiceType {
    Input,
    Output,
}

export interface ActiveServiceAppliance extends Pick<Appliance, 'id' | 'name' | 'region'> {
    coreNode: GeoCoreNode
    geoLocation: NonNullable<Appliance['geoLocation']>
}

export interface ActiveServiceInput
    extends Pick<Input, 'id' | 'name' | 'ports' | 'metrics' | 'health' | 'adminStatus'> {
    appliance: ActiveServiceAppliance
    ownerName: string
    type: ActiveServiceType
}

export interface ActiveServiceOutput
    extends Pick<Output, 'id' | 'name' | 'ports' | 'metrics' | 'health' | 'adminStatus'> {
    appliance: ActiveServiceAppliance
    groupName: string
    type: ActiveServiceType
    activeServiceInput: ActiveServiceInput
}

export type ActiveService = ActiveServiceInput | ActiveServiceOutput

export interface OutputRecipientList extends Entity {
    name: string
    group: string
    description?: string

    // Only populated when "enriched" in handler listOutputsAndEnrichedOutputRecipientLists()
    _hasOutputsInUse?: boolean
}

export interface OutputRecipientListInit extends Omit<OutputRecipientList, 'id'> {
    addOutputs: string[]
}

export interface OutputRecipientListUpdate extends Omit<OutputRecipientListInit, 'group'> {
    removeOutputs: string[]
}

export interface OutputRecipientInit {
    output: string
    outputRecipientList: string
}

export interface OutputRecipient extends OutputRecipientInit {
    createdAt: Date
}

export type OutputOrRecipientList = Output | OutputRecipientList

export interface SendToOutputRecipientListParams {
    outputRecipientLists: string[]
}

export interface GroupRecipientList extends Entity {
    name: string
    group: string
    description?: string
}

export interface GroupRecipientListInit extends Omit<GroupRecipientList, 'id'> {
    addGroups: string[]
}

export interface GroupRecipientListUpdate extends Omit<GroupRecipientListInit, 'group'> {
    removeGroups: string[]
}

export interface GroupRecipientInit {
    group: string
    groupRecipientList: string
}

export interface GroupRecipient extends GroupRecipientInit, Entity {
    createdAt: Date
}

export enum EntityType {
    activeService = 'activeService',
    alarm = 'alarm',
    appliance = 'appliance',
    auditLog = 'audit-log',
    distribution = 'distribution',
    input = 'input',
    group = 'group',
    groupAndRecipientList = 'groupAndRecipientList',
    groupRecipientList = 'groupRecipientList',
    output = 'output',
    outputAndRecipientList = 'outputAndRecipientList',
    outputRecipientList = 'outputRecipientList',
    port = 'port',
    region = 'region',
    user = 'user',
    ipMapping = 'ip-mapping',
}

// IDEA: In the future all the objects may use the same property to determine object type
export type GroupOrGroupRecipientList =
    | (Group & { object: EntityType.group })
    | (GroupRecipientList & { object: EntityType.groupRecipientList })

export interface GroupListAccess {
    groupRecipientList: string
    accessType: InputAccessType
}

export interface ShareToGroupRecipientListParams {
    groupRecipientLists: GroupListAccess[]
}

export interface EchoRequest {
    message: string
}

export interface GeoCoreNode extends LimitedAppliance {
    geoLocation: NonNullable<Appliance['geoLocation']>
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of geo appliances can be filtered.
 */
export interface GeoApplianceFilter extends SearchableFilter {}

export type GeoAppliance =
    | GeoCoreNode
    | (Appliance & {
          geoLocation: NonNullable<Appliance['geoLocation']>
      })

/// Allowed names of images that may exist in database.
export enum ImageName {
    product = 'product',
    serviceProvider = 'serviceProvider',
    favicon = 'favicon',
}

export type UpdateImageRequest = {
    name: string

    /// image param. May be either:
    /// - the base64 image data directly: 'iVBORw0KGgo...',
    /// - or metadata + base64 image data: 'data:image/png;base64,iVBORw0KGgo...'
    image: string
}

export type ImageDetails = {
    id: string
    name: keyof typeof ImageName
    type: string
}

export interface SanityStatus {
    sane: boolean
}

export interface SleepResult {
    didThrow: boolean
    error: any
}

export enum ListPortSortableField {
    portName = 'portName',
    address = 'address',
    publicAddress = 'publicAddress',
    applianceName = 'applianceName',
    ownerGroupName = 'ownerGroupName',
}

export enum ListAlarmSortableField {
    time = 'time',
    applianceName = 'applianceName',
    alarmCause = 'alarmCause',
    text = 'text',
}

export enum ListUserSortableField {
    userName = 'userName',
    groupName = 'groupName',
    role = 'role',
}

export enum ListApiTokenSortableField {
    name = 'name',
    userName = 'userName',
    groupName = 'groupName',
    role = 'role',
    expiresAt = 'expiresAt',
}

export enum ListInputSortableField {
    inputName = 'inputName',
    applianceName = 'applianceName',
    protocol = 'protocol',
    creationDate = 'creationDate',
    lastModified = 'lastModified',
    accessType = 'accessType',
    ownerGroupName = 'ownerGroupName',
}

export enum ListOutputSortableField {
    id = 'id',
    outputName = 'outputName',
    applianceName = 'applianceName',
    protocol = 'protocol',
    creationDate = 'creationDate',
    lastModified = 'lastModified',
    serviceOverview = 'serviceOverview',
    inputName = 'inputName',
}

export enum ListGroupSortableField {
    groupName = 'groupName',
    numberOfUsers = 'numberOfUsers',
    numberOfAppliances = 'numberOfAppliances',
    numberOfPorts = 'numberOfPorts',
}

export enum ListApplianceSortableField {
    applianceName = 'applianceName',
    applianceType = 'applianceType',
    regionName = 'regionName',
    secondaryRegionName = 'secondaryRegionName',
    ownerGroupName = 'ownerGroupName',
    lastSeen = 'lastSeen',
    numActiveAlarms = 'numActiveAlarms',
}

export enum ListIpMappingSortableField {
    regionName = 'regionName',
    applianceName = 'applianceName',
    interfaceName = 'interfaceName',
    privateAddress = 'privateAddress',
    publicAddress = 'publicAddress',
    internalAddress = 'internalAddress',
    interRegionAddress = 'interRegionAddress',
}

export interface BillingReport {
    systemName: string
    startDate: Date
    endDate: Date
    periods: BillingPeriod[]
    summary: Billing
}

export interface BillingPeriod extends Billing {
    startDate: Date
    endDate: Date

    maxConcurrencyDate: Date
    details: BillingDetail[]
}

export type BillingDetail = IngressBillingDetail | EgressBillingDetail
export interface BillingDetailBase {
    startDate: Date
    endDate: Date
    inputId: string
    inputName: string
    credits: number

    inputServiceCount?: number
}
export interface IngressBillingDetail extends BillingDetailBase {
    inputRedundancy: boolean
    inputMerge: boolean // 2022-7 hitless merge
    inputPortCount: number
    ingressCredits: number
}
export interface EgressBillingDetail extends BillingDetailBase {
    outputId: string
    outputName: string

    outputRedundancy: boolean
    outputPortCount: number
    egressCredits: number
}

export interface Billing {
    ingressCredits: number
    egressCredits: number
    totalCredits: number
}

export interface UsageReport {
    systemName: string
    startDate: Date
    endDate: Date
    ingressTrafficGB: number
    egressTrafficGB: number
    details: UsageDetail[]
    total: number
}

export interface UsageDetail {
    startDate: Date
    endDate: Date | null
    type: UsageDetailType
    inputId: string
    inputName: string
    inputGroup: string
    inputRegion: string
    inputApplianceName: string
    inputIsSmtpe_2022_7_Enabled: boolean
    inputProtocol: PortMode
    inputRedundancyEnabled: boolean
    ingressTrafficGB?: number
    egressTrafficGB?: number
    outputId?: string
    outputName?: string
    outputGroup?: string
    outputRegion?: string
    outputApplianceName?: string
    outputPortCount?: number
    outputProtocol?: PortMode
    outputRedundancyEnabled?: boolean
}

export interface GraphEdge<TData = any> {
    source: string
    target: string
    logicalPortId?: string
    streamId?: number
    data?: TData
}

export type GraphNodeEntityType = Extract<EntityType, EntityType.appliance | EntityType.input | EntityType.output>
export interface EntityReference {
    id: string
    type: GraphNodeEntityType
}

export interface NodeEntityReference {
    id: string
    type: GraphNodeType
}

export interface GraphNode<TData = GraphNodeData> {
    name: string
    data: TData
}

export interface GraphNodeData extends NodeEntityReference {
    region: string
    secondaryRegion?: string
}

export type LimitedAppliance = Pick<Appliance, 'id' | 'name' | 'type' | 'region' | 'secondaryRegion'>

export interface ServiceOverview {
    graph: InputGraph
    input: Input
    objects: {
        appliances: Array<Appliance | LimitedAppliance>
        outputs: ListResult<Output>
        tunnels: Tunnel[]
    }
}

export interface InputGraph<TNode = GraphNode, TEdge = GraphEdge> {
    nodes: {
        [name: string]: TNode
    }
    edges: Array<TEdge>
}

export enum GraphNodeType {
    // An input
    input = 'input',
    inputPort = 'inputPort',

    connection = 'connection',

    // The edge-appliance where the input resides
    inputEdgeAppliance = 'inputEdgeAppliance',

    // The core-appliance where the input resides
    inputRegionCore = 'inputRegionCore',

    // An output
    output = 'output',
    outputPort = 'outputPort',

    // The core-appliance where the output resides
    outputRegionCore = 'outputRegionCore',

    // The edge-appliance, in the same region as the input, where the output resides
    inputRegionOutputEdgeAppliance = 'inputRegionOutputEdgeAppliance',

    // The edge-appliance, in a different region than the input, where the output resides
    outputRegionOutputEdgeAppliance = 'outputRegionOutputEdgeAppliance',
}

export interface IpMapping {
    /**
     * @DATA_FORMAT IPV4
     */
    privateIp: string
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    publicIp?: string
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    internalIp?: string
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    interRegionIp?: string
}

export interface IpMappingRead extends IpMapping {
    /**
     * @DATA_FORMAT IPV4
     */
    privateIp: string
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    publicIp?: string
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    internalIp?: string
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    interRegionIp?: string
    region: {
        id: string
        name: string
    }
    nic?: {
        id: string
        name: string
    }
    appliance?: {
        id: string
        name: string
    }
}

export interface IpMappingWrite extends IpMapping {
    /**
     * @DATA_FORMAT UUID
     */
    region: string
    /**
     * @DATA_FORMAT IPV4
     */
    privateIp: string
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    publicIp?: string
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    internalIp?: string
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    interRegionIp?: string
}

export interface InputRecipientsUpdate {
    outputRecipientLists?: string[]
    addOutputIds?: string[]
    removeOutputIds?: string[]
}

export interface Network {
    id: string
    name: string
    enabled: boolean
}

export interface NetworkFilter extends SearchableFilter {
    ids?: string[]
    port?: string
}

export enum NetworkSortableField {
    id = 'id',
    name = 'name',
}
export interface Service {
    id: string
    purpose: string
    product: K8sService
    description?: string
    filterUrl?: string
    collectLogs: boolean
}

export enum ListServiceSortableField {
    purpose = 'purpose',
    product = 'product',
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of services can be filtered.
 */
export interface ServiceFilter extends SearchableFilter {
    id?: string
    product?: string
}

export interface GrafanaDashboard {
    id: number
    uid: string
    title: string
    uri: string
    url: string
    slug: string
    type: string
    tags: string[]
    isStarred: boolean
    sortMeta: number
}

export interface GetUsageReportFilter {
    startDate: Date
    endDate: Date
    groupId?: string
    limit?: number
    skip?: number
    type?: 'ingress' | 'egress'
    format?: 'csv'
}
