/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useState, useContext, FC } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Button, { ButtonProps } from '@material-ui/core/Button'

interface ActionButtonProps extends ButtonProps {
  text?: string
}
interface Actions {
  cancel?: ActionButtonProps
  ok?: ActionButtonProps
}
interface ContextProps {
  showConfirmation: (
    callback: () => void,
    comp: React.ReactNode,
    buttonProps?: Actions,
    cancelCallback?: () => void,
  ) => void
  hideConfirmation: () => void
  isShown: boolean
  onOkAction?: (() => void) | undefined
  onCancelAction?: (() => void) | undefined
  comp?: React.ReactNode
  buttonProps?: Actions
}
export const ConfirmDialogContext = createContext<ContextProps>({
  showConfirmation: () => {},
  hideConfirmation: () => {},
  isShown: false,
  onOkAction: () => {},
  onCancelAction: () => {},
  comp: undefined,
  buttonProps: undefined,
})

/**
 * Common confirm dialog to be shown
 */
const ConfirmDialog = () => {
  const { hideConfirmation, isShown, onOkAction, onCancelAction, comp, buttonProps } = useContext(ConfirmDialogContext)
  const { text: textOk = 'OK', ...restOk } = (buttonProps || { ok: { text: 'OK' } }).ok || {}
  const { text: textCancel = 'Cancel', ...restCancel } = (buttonProps || { cancel: { text: 'Cancel' } }).cancel || {}

  return (
    <Dialog
      open={isShown}
      onClose={(_event, reason) => {
        if (reason === 'escapeKeyDown') {
          hideConfirmation()
        }
      }}
      data-test-id="confirm-dialog"
    >
      <DialogContent>
        <DialogContentText component="div">{comp}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            onCancelAction && onCancelAction()
            hideConfirmation()
          }}
          data-test-id="cancel-btn"
          {...restCancel}
        >
          {textCancel}
        </Button>
        <Button
          data-test-id="ok-btn"
          variant="contained"
          color="primary"
          onClick={() => {
            onOkAction && onOkAction()
            hideConfirmation()
          }}
          {...restOk}
        >
          {textOk}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

/**
 * Provider to use to show simple confirmation dialog
 * To invoke call useConfirmationDialog from common utils
 */
export const ConfirmDialogProvider: FC<unknown> = ({ children }) => {
  const [state, setState] = useState<
    Pick<ContextProps, 'isShown' | 'onOkAction' | 'comp' | 'buttonProps' | 'onCancelAction'>
  >({
    isShown: false,
    onOkAction: undefined,
    comp: undefined,
    buttonProps: undefined,
  })
  const value = {
    showConfirmation: (
      onOkAction: () => void,
      comp: React.ReactNode,
      buttonProps?: Actions,
      onCancelAction?: () => void,
    ) =>
      setState({
        isShown: true,
        onOkAction,
        onCancelAction,
        comp,
        buttonProps,
      }),
    hideConfirmation: () =>
      setState({
        isShown: false,
        onOkAction: state.onOkAction,
        comp: state.comp,
        buttonProps: state.buttonProps,
      }),
    ...state,
  }
  return (
    <ConfirmDialogContext.Provider value={value}>
      {children}
      <ConfirmDialog />
    </ConfirmDialogContext.Provider>
  )
}
