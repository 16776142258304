import { createSlice } from '@reduxjs/toolkit'

import { IpMappingRead } from 'common/api/v1/types'
import {
  clearEditMapping,
  getIpMappings,
  removeIpMapping,
  createIpMapping,
  getIpMapping,
  updateIpMapping,
} from '../actions/addressMappingActions'

import { logoutUser } from '../actions/userActions'
import { isOneOf } from '../actions'

interface State {
  loading: boolean
  ipMapping?: IpMappingRead
  ipMappings: Array<IpMappingRead>
}
export const initialStateAddressMapping: State = {
  loading: false,
  ipMappings: [],
}

const addressMappingSlice = createSlice({
  name: 'addressMappings',
  initialState: initialStateAddressMapping,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(logoutUser.fulfilled, (): State => initialStateAddressMapping)
      .addCase(clearEditMapping, (state): State => ({ ...state, ipMapping: undefined }))
      .addCase(
        getIpMapping.fulfilled,
        (state, { payload: mapping }): State => ({
          ...state,
          loading: false,
          ipMapping: mapping,
        }),
      )
      .addCase(
        getIpMappings.fulfilled,
        (state, { payload: ipMappings }): State => ({
          ...state,
          ipMappings,
          loading: false,
        }),
      )
      .addMatcher(
        isOneOf([
          createIpMapping.pending,
          updateIpMapping.pending,
          removeIpMapping.pending,
          getIpMappings.pending,
          getIpMapping.pending,
        ]),
        (state): State => ({ ...state, loading: true }),
      )
      .addMatcher(
        isOneOf([
          createIpMapping.rejected,
          updateIpMapping.rejected,
          removeIpMapping.rejected,
          getIpMappings.rejected,
          getIpMapping.rejected,
        ]),
        (state): State => ({ ...state, loading: false }),
      )
  },
})

export default addressMappingSlice.reducer
