import { IKubernetesApi } from './api'
import { MOCK_DELAY_MS } from '../MockData'
import {
  ListResult,
  KubernetesNode,
  UpdateKubernetesNodePayload,
  ExternalRegionMode,
  KubernetesNodeResourceUsage,
} from 'common/api/v1/types'
import { KubernetesRoles } from 'common/k8s/labels'

export class MockKubernetesApi implements IKubernetesApi {
  getKubernetesNodes(): Promise<ListResult<KubernetesNode>> {
    return new Promise(resolve =>
      setTimeout(
        () =>
          resolve({
            items: [
              {
                name: 'Test1',
                status: 'Ready',
                labels: {},
                roles: [KubernetesRoles.video],
                externalIP: '8.8.8.8',
                internalIP: '192.168.1.1',
                hostname: 'neti',
                region: { id: '36', name: 'southpark', external: ExternalRegionMode.external },
              },
            ],
            total: 1,
          }),
        MOCK_DELAY_MS,
      ),
    )
  }

  getKubernetesNode(_regionId: string, name: KubernetesNode['name']): Promise<KubernetesNode> {
    return Promise.resolve({
      name: name,
      status: 'Ready',
      labels: {},
      roles: [KubernetesRoles.video],
      externalIP: '1.1.1.1',
      internalIP: '2.2.2.2',
      hostname: 'neti',
      region: {
        id: '36',
        name: 'southpark',
        external: ExternalRegionMode.external,
      },
    })
  }

  updateKubernetesNode(
    _regionId: string,
    name: KubernetesNode['name'],
    update: UpdateKubernetesNodePayload,
  ): Promise<KubernetesNode> {
    return Promise.resolve({
      name,
      status: 'Ready',
      labels: {},
      roles: update.roles,
      externalIP: '1.1.1.1',
      internalIP: '2.2.2.2',
      hostname: 'neti',
      region: {
        id: '36',
        name: 'southpark',
        external: ExternalRegionMode.external,
      },
    })
  }

  getResourceUsage(): Promise<KubernetesNodeResourceUsage[]> {
    return Promise.resolve([] as KubernetesNodeResourceUsage[])
  }
}
