import { NewUser, ListResult, User } from 'common/api/v1/types'
import { MOCK_USER, MOCK_USERS, MOCK_DELAY_MS, MOCK_GROUPS } from '../MockData'
import { IUserApi } from './api'
import { listResult } from '../../utils'
import { EnrichedUser } from '../nm-types'
import { clearState } from '../../localstorage'

export class MockUserApi implements IUserApi {
  createUser(user: NewUser): Promise<User> {
    return new Promise(resolve =>
      setTimeout(() => resolve({ ...MOCK_USER, username: user.username, group: user.group }), MOCK_DELAY_MS),
    )
  }

  loginUser(): Promise<EnrichedUser> {
    return new Promise(resolve =>
      setTimeout(() => resolve(Object.assign({}, MOCK_USER as EnrichedUser)), MOCK_DELAY_MS),
    )
  }

  logoutUser(): Promise<boolean> {
    return new Promise(function(resolve, reject) {
      try {
        clearState()
        return resolve(true)
      } catch (error) {
        return reject(`failed removing, ${error}`)
      }
    })
  }

  async impersonateUser(userID: User['id']): Promise<EnrichedUser> {
    const response: EnrichedUser = { ...MOCK_USER, id: userID, _group: MOCK_GROUPS[0] }
    return new Promise(resolve => setTimeout(() => resolve(Object.assign({}, response)), MOCK_DELAY_MS))
  }

  async stopImpersonation(): Promise<EnrichedUser> {
    const response: EnrichedUser = { ...MOCK_USER, _group: MOCK_GROUPS[0] }
    return new Promise(resolve => setTimeout(() => resolve(Object.assign({}, response)), MOCK_DELAY_MS))
  }

  removeUser(userID: string): Promise<{ id: string }> {
    return new Promise(resolve => setTimeout(() => resolve({ id: userID }), MOCK_DELAY_MS))
  }

  updateUser(user: User): Promise<User> {
    return new Promise(resolve => setTimeout(() => resolve({ ...user }), MOCK_DELAY_MS))
  }

  readUsers(): Promise<User[]> {
    return new Promise(resolve => setTimeout(() => resolve(MOCK_USERS), MOCK_DELAY_MS))
  }

  getUser(): Promise<EnrichedUser> {
    return new Promise(resolve => setTimeout(() => resolve(MOCK_USERS[0] as EnrichedUser), MOCK_DELAY_MS))
  }

  getUsers(): Promise<ListResult<EnrichedUser>> {
    return new Promise(resolve =>
      setTimeout(
        () => resolve(listResult(MOCK_USERS.map(user => ({ ...user, _group: MOCK_GROUPS[0] })))),
        MOCK_DELAY_MS,
      ),
    )
  }
}
