import React from 'react'
import { DispatchProp, useDispatch } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import SettingsIcon from '@material-ui/icons/Settings'
import InputIcon from '@material-ui/icons/Input'
import DeleteIcon from '@material-ui/icons/Delete'
import InfoIcon from '@material-ui/icons/Info'
import CallSplitIcon from '@material-ui/icons/CallSplit'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { EnrichedInput } from '../../../api/nm-types'
import { getAppClasses } from '../../../Common'
import { ExperimentalFeatures, InputAdminStatus, Role, User } from 'common/api/v1/types'
import routes from '../../../utils/routes'
import { DraftActions, draftInputs, enableInputs } from '../../../redux/actions/inputsActions'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import StopIcon from '@material-ui/icons/Stop'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { AppDispatch } from 'src/store'

interface ActionMenuProps {
  input: EnrichedInput
  user: User
  dispatch: DispatchProp['dispatch']
  experimentalFeatures?: ExperimentalFeatures
}

interface ToggleAdminStatusButtonProps {
  input: EnrichedInput
  handleItemClick: (event: React.MouseEvent<HTMLElement>) => void
}

const useStyles = makeStyles(() => ({
  actionSpace: {
    display: 'flex',
  },
  negativeMarginLeft: {
    marginLeft: '-8px',
  },
}))

const ToggleAdminStatusButton = ({ input, handleItemClick }: ToggleAdminStatusButtonProps) => {
  const dispatch = useDispatch<AppDispatch>()

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    handleItemClick(event)

    if (input.adminStatus === InputAdminStatus.on) {
      dispatch(draftInputs({ action: DraftActions.disable, inputs: [input] }))
    } else {
      dispatch(enableInputs([input.id]))
      dispatch(draftInputs({ inputs: [] }))
    }
  }

  return (
    <MenuItem data-test-id="toggle-admin-status-input" aria-label="toggle admin status" onClick={onClick}>
      <ListItemIcon>{input.adminStatus === InputAdminStatus.on ? <StopIcon /> : <PlayArrowIcon />}</ListItemIcon>
      <ListItemText primary={input.adminStatus === InputAdminStatus.on ? 'Disable' : 'Enable'} />
    </MenuItem>
  )
}

export const ActionMenu = (props: ActionMenuProps) => {
  const { input, dispatch, user, experimentalFeatures } = props
  const isOwner = user.group === input.owner || user.role === Role.super
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const classes = getAppClasses()
  const history = useHistory()
  const styles = useStyles()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleItemClick = (event: React.MouseEvent<HTMLElement>, route?: string) => {
    event.stopPropagation()
    setAnchorEl(null)
    if (route) {
      history.push(route)
    }
  }

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <div className={styles.actionSpace}>
      <Tooltip title={'Overview'} placement="top">
        <IconButton
          aria-label="service overview button"
          onClick={e => handleItemClick(e, routes.service({ id: input.id }))}
          data-test-id={`service-overview-btn`}
          disabled={!input.ports?.length && !input.deriveFrom}
        >
          <InfoIcon />
        </IconButton>
      </Tooltip>
      <IconButton
        aria-label="open input action menu"
        data-test-id="open-input-action-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={styles.negativeMarginLeft}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        data-test-id="input-action-menu"
        data-is-open={open ? 'true' : 'false'}
        keepMounted
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '35ch',
          },
        }}
      >
        {isOwner && (
          <MenuItem
            onClick={e => handleItemClick(e, routes.inputsUpdate({ id: input.id }))}
            data-test-id="edit-input"
            aria-label="edit"
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Edit" />
          </MenuItem>
        )}
        {isOwner && (
          <MenuItem
            aria-label="share"
            data-test-id={`share-input`}
            disabled={!isOwner}
            onClick={e => {
              handleItemClick(e)
              dispatch(draftInputs({ inputs: [input], action: DraftActions.share }))
            }}
          >
            <ListItemIcon>
              <GroupAddIcon />
            </ListItemIcon>
            <ListItemText primary="Share" />
          </MenuItem>
        )}
        {isOwner && <ToggleAdminStatusButton input={input} handleItemClick={handleItemClick} />}
        <MenuItem
          aria-label="send to output"
          data-test-id={`send-to-output`}
          disabled={!input.canSubscribe}
          onClick={e => {
            handleItemClick(e)
            dispatch(draftInputs({ inputs: [input], action: DraftActions.send }))
          }}
        >
          <ListItemIcon>
            <InputIcon />
          </ListItemIcon>
          <ListItemText primary="Send to output" />
        </MenuItem>
        {experimentalFeatures && experimentalFeatures.ExtMptsDerivedInput && !input.deriveFrom && isOwner && (
          <MenuItem onClick={e => handleItemClick(e, routes.inputsDerive({ id: input.id }))} data-test-id="derive-from">
            <ListItemIcon>
              <CallSplitIcon />
            </ListItemIcon>
            <ListItemText primary="Create derived input" />
          </MenuItem>
        )}
        {isOwner && (
          <MenuItem
            aria-label="delete"
            data-test-id={`delete-input`}
            className={classes.error}
            onClick={e => {
              handleItemClick(e)
              dispatch(draftInputs({ inputs: [input], action: DraftActions.delete }))
            }}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}
