import React from 'react'
import { Field, FieldAttributes, FieldProps, getIn } from 'formik'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import { omit } from 'lodash/fp'

import GridItem, { GridItemProps, omitGridProps } from './GridItem'
import BackendValidation from './BackendValidation'
import { PasswordField } from './PasswordField'
import { validate } from '../../../utils'

const Comp = (props: FieldProps & TextFieldProps) => {
  const fieldError = getIn(props.form.errors, props.field.name)
  const showError = getIn(props.form.touched, props.field.name) && Boolean(fieldError)
  const muiProps: TextFieldProps & Pick<TextInputProps, 'showPassword'> = {
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    ...props.field,
    ...props,
  }

  return (
    <>
      {props.type === 'password' ? <PasswordField {...muiProps} /> : <TextField {...muiProps} />}
      <BackendValidation form={props.form} name={props.field.name} />
    </>
  )
}

interface TextInputProps extends GridItemProps {
  name?: string
  label?: string
  multiline?: boolean
  noNegative?: boolean
  validators?: Parameters<typeof validate>[0]
  showPassword?: { show: boolean; id: string }
}

/**
 * Simple text input (string, number, email, password etc.)
 * @param props {
 *   name: formik's name
 *   label?: label to show
 *   multiline?: if it is string field and we need it to be multiline
 *   noNegative?: for number fields we can forbid setting negative values by keyboard arrows, and also it adds validation rule
 *   validators?: validateJs validations plus self defined ones from utils/validation
 *   required?: adds validation
 *   showPassword?: If true, the password is visible. It's valid only when type=password.
 * }
 * @constructor
 */
const TextInput: React.FunctionComponent<FieldAttributes<TextInputProps>> = props => {
  const { validators = {} } = props
  if (props.required) validators.presence = true
  if (props.noNegative) {
    validators.number = {
      greaterThanOrEqualTo: 0,
      message: 'Can not be negative',
      ...validators.number,
    }
  }
  return (
    <GridItem {...props}>
      <Field
        label={props.name}
        component={Comp}
        fullWidth
        variant="outlined"
        margin="normal"
        autoComplete="off"
        validate={validate(validators)}
        {...(props.noNegative ? { inputProps: { min: '0' } } : undefined)}
        {...omitGridProps(omit(['noNegative', 'validators'], props))}
        data-test-id={props.name ? `textinput-${props.name}` : undefined}
      />
    </GridItem>
  )
}

export default TextInput
