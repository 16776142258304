import { merge } from 'lodash/fp'

import { createTheme } from '@material-ui/core/styles'

import common from './common'
export { NimbraBlue, NimbraGrey } from './common'

export default createTheme(
  merge(common, {
    palette: {
      type: 'light',
      background: {
        default: '#F5F5F5',
        paper: '#E8E8E8',
      },
    },
    overrides: {
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: '#ffffff',
          color: '#757575',
        },
      },
      MuiToolbar: {
        regular: {
          backgroundColor: 'transparent' as const,
          color: '#000000',
        },
      },
      MuiLink: {
        root: {
          color: '#757575',
        },
      },
    },
  }),
)
