import { makeStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Wrapper from '../../common/Wrapper'
import { Paper } from '../../common/Form'

import ActiveServices from './ActiveServices'

const PADDING = 2
const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(1, PADDING, 3, PADDING),
    overflow: 'hidden',
  },
}))

const MapContainer = () => {
  const classes = useStyles()

  return (
    <Wrapper name="Event overview">
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <Paper classes={classes.paper} title="Active services">
            <Grid item xs={12}>
              <ActiveServices />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

export default MapContainer
