import { createSlice } from '@reduxjs/toolkit'
import {
  clearOutput,
  clearOutputs,
  createOutput,
  getOutputs,
  getOutput,
  getOutputsWithLists,
  getTotalOutputCount,
  updateOutput,
  registerOutputObserver,
  unregisterOutputObserver,
  getBareOutputs,
  Draft,
  removeOutputs,
  draftOutputs,
  enableOutputs,
  disableOutputs,
  setInputOfOutput,
} from '../actions/outputsActions'
import { logoutUser } from '../actions/userActions'

import { OutputRecipientList, Output } from 'common/api/v1/types'
import { get } from 'lodash/fp'
import { EnrichedOutput, EnrichedOutputWithPorts } from '../../api/nm-types'
import { isOneOf } from '../actions'
import { getService } from '../actions/serviceOverviewActions'
import { isOutput } from '../../utils'

interface State {
  formErrors?: Array<{ name: string; reason: string }>
  loading: boolean
  outputs: Array<EnrichedOutput>
  outputsWithLists: Array<EnrichedOutput | OutputRecipientList>
  output?: EnrichedOutputWithPorts
  outputsToObserve: { [outputId: string]: number }
  saving?: boolean
  total: number
  draft: Draft
}
const initialStateOutputs: State = {
  loading: false,
  outputs: [],
  outputsWithLists: [],
  outputsToObserve: {},
  total: 0,
  draft: { outputs: [] },
}

const outputsSlice = createSlice({
  name: 'outputs',
  initialState: initialStateOutputs,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(createOutput.fulfilled, (state): State => ({ ...state, saving: undefined, formErrors: undefined }))
      .addCase(
        updateOutput.fulfilled,
        (state, { payload: updatedOutput }): State => ({
          ...state,
          saving: undefined,
          outputs: state.outputs.map(outp => (outp.id === updatedOutput.id ? { ...updatedOutput } : outp)),
        }),
      )
      .addCase(
        getOutputs.fulfilled,
        (state, { payload: { items: outputs, total } }): State => ({
          ...state,
          loading: false,
          outputs,
          total,
        }),
      )
      .addCase(
        getOutputsWithLists.fulfilled,
        (state, { payload: { items: outputsWithLists, total } }): State => ({
          ...state,
          loading: false,
          outputsWithLists,
          total,
        }),
      )
      .addCase(
        getTotalOutputCount.fulfilled,
        (state, { payload: total }): State => ({ ...state, total, loading: false }),
      )
      .addCase(getOutput.fulfilled, (state, { payload: output }): State => ({ ...state, output }))
      .addCase(clearOutput, (state): State => ({ ...state, output: undefined, formErrors: undefined }))
      .addCase(clearOutputs, (state): State => ({ ...state, outputs: [], outputsWithLists: [] }))
      .addCase(
        draftOutputs,
        (state, { payload: draft }): State => ({
          ...state,
          draft,
        }),
      )
      .addCase(logoutUser.fulfilled, (): State => initialStateOutputs)
      .addCase(
        registerOutputObserver.pending,
        (
          state,
          {
            meta: {
              arg: { outputId },
            },
          },
        ): State => ({
          ...state,
          outputsToObserve: {
            ...state.outputsToObserve,
            [outputId]: (state.outputsToObserve[outputId] || 0) + 1,
          },
        }),
      )
      .addCase(
        unregisterOutputObserver,
        (state, { payload: { outputId } }): State => {
          const newObservers = { ...state.outputsToObserve }
          const numberOfObservers = state.outputsToObserve[outputId] - 1
          if (numberOfObservers < 1) {
            delete newObservers[outputId]
          } else {
            newObservers[outputId] = numberOfObservers
          }
          return { ...state, outputsToObserve: newObservers }
        },
      )
      .addCase(
        getBareOutputs.fulfilled,
        (state, { payload: bareOutputs }): State => {
          const updateOutputMetrics = <T extends Output>(enrichedOutput: T): T => {
            const bareOutput = bareOutputs.items.find(o => o.id == enrichedOutput.id)
            return bareOutput
              ? { ...enrichedOutput, metrics: bareOutput.metrics, health: bareOutput.health }
              : enrichedOutput
          }

          return {
            ...state,
            outputs: state.outputs.map(updateOutputMetrics),
            output: state.output ? updateOutputMetrics(state.output) : undefined,
            outputsWithLists: state.outputsWithLists.reduce((acc, outputOrRecipientList) => {
              acc.push(
                isOutput(outputOrRecipientList) ? updateOutputMetrics(outputOrRecipientList) : outputOrRecipientList,
              )
              return acc
            }, [] as Array<EnrichedOutput | OutputRecipientList>),
          }
        },
      )
      .addCase(getService.fulfilled, (state, { payload: { objects: { outputs } } }) => ({
        ...state,
        outputs: outputs.items,
      }))
      .addCase(
        enableOutputs.fulfilled,
        (state, { payload: enableOutputs }): State => {
          return {
            ...state,
            outputs: state.outputs.map(output => {
              const enabledOutput = enableOutputs.find(o => o.id === output.id)
              if (enabledOutput) {
                return { ...output, adminStatus: enabledOutput.adminStatus }
              }
              return output
            }),
          }
        },
      )
      .addCase(
        disableOutputs.fulfilled,
        (state, { payload: disableOutputs }): State => {
          return {
            ...state,
            outputs: state.outputs.map(output => {
              const disabledOutput = disableOutputs.find(o => o.id === output.id)
              if (disabledOutput) {
                return { ...output, adminStatus: disabledOutput.adminStatus }
              }
              return output
            }),
          }
        },
      )
      .addCase(
        setInputOfOutput.fulfilled,
        (state, { payload: updatedOutput }): State => ({
          ...state,
          saving: undefined,
          outputs: state.outputs.map(outp => (outp.id === updatedOutput.id ? { ...updatedOutput } : outp)),
        }),
      )
      .addMatcher(
        isOneOf([getOutputs.pending, removeOutputs.pending, getOutputsWithLists.pending, getTotalOutputCount.pending]),
        (state): State => ({ ...state, loading: true }),
      )
      .addMatcher(
        isOneOf([createOutput.pending, updateOutput.pending, setInputOfOutput.pending]),
        (state): State => ({ ...state, saving: true, formErrors: undefined }),
      )
      .addMatcher(
        isOneOf([
          removeOutputs.rejected,
          removeOutputs.fulfilled,
          getOutputsWithLists.rejected,
          getTotalOutputCount.rejected,
        ]),
        (state): State => ({ ...state, loading: false }),
      )
      .addMatcher(
        isOneOf([createOutput.rejected, updateOutput.rejected, setInputOfOutput.rejected]),
        (state, { payload }): State => ({
          ...state,
          saving: false,
          formErrors: get('errorInfo.origin.data.detail', payload),
        }),
      )
  },
})

export default outputsSlice.reducer
