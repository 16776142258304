import { useEffect, useState } from 'react'
import { get } from 'lodash'
import { FormikProps } from 'formik'
import Button from '@material-ui/core/Button'

import {
  AudioCodec,
  AudioStream,
  AudioSystemStandard,
  InputInit,
  sdiAudioPairs,
  SupportedAudioCodec,
} from 'common/api/v1/types'
import { GridItem, Paper, Select, useStyles } from '../../common/Form'

interface AudioStreamFormProps {
  namePrefix: string
  index: number
  supportedCodecs: SupportedAudioCodec[]
  remove: (ind: number) => void
  form: FormikProps<InputInit>
}
const AudioStreamForm = ({ index, namePrefix, supportedCodecs, remove, form }: AudioStreamFormProps) => {
  const classes = useStyles()
  const selectedCodec: AudioStream['codec'] = get(form, `values.${namePrefix}.codec`)
  const [bitrates, setBitrates] = useState<Array<number>>([])
  const [firstOpen, setFirstOpen] = useState(true)
  useEffect(() => {
    const bitrates = supportedCodecs.find(({ name }: SupportedAudioCodec) => name === selectedCodec)?.bitrates || []
    setBitrates(bitrates)

    if (!firstOpen) {
      const bitrate: AudioStream['bitrate'] = get(form, `values.${namePrefix}.bitrate`)
      if (!bitrates.includes(bitrate))
        form.setFieldValue(`${namePrefix}.bitrate`, bitrates && bitrates.length === 1 ? bitrates[0] : '')
    } else {
      setFirstOpen(false)
    }
  }, [get(form, `values.${namePrefix}.codec`), supportedCodecs])

  return (
    <Paper classes={classes.paper}>
      <Select label="Audio Codec" name={`${namePrefix}.codec`} required options={supportedCodecs.map(c => c.name)} />
      <Select label="Audio Pair" name={`${namePrefix}.pair`} required options={sdiAudioPairs} />
      {!!selectedCodec && (
        <Select
          label="Audio Bitrate"
          name={`${namePrefix}.bitrate`}
          required
          options={bitrates}
          disabled={bitrates.length === 1}
        />
      )}
      {!!selectedCodec && selectedCodec == AudioCodec.ac3pt && (
        <Select
          label="System standard"
          name={`${namePrefix}.systemStandard`}
          required
          options={Object.values(AudioSystemStandard)}
          disabled={bitrates.length === 1}
        />
      )}
      <GridItem newLine>
        <Button variant="outlined" color="primary" onClick={() => remove(index)}>
          Remove Audio Stream
        </Button>
      </GridItem>
    </Paper>
  )
}

export default AudioStreamForm
