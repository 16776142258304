import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Button from '@material-ui/core/Button'
import { AppDispatch, GlobalState } from '../../../store'
import { disableInputs, DraftActions, draftInputs, removeInputs } from '../../../redux/actions/inputsActions'
import { pluralize } from '../../../utils'

interface CommonActionsProps {
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
}

const DeleteDialog = ({ setSelected }: CommonActionsProps) => {
  const { inputs, action } = useSelector(({ inputsReducer }: GlobalState) => inputsReducer.draft, shallowEqual)
  const dispatch = useDispatch<AppDispatch>()
  if (!inputs.length) return null

  const onCancelClick = () => dispatch(draftInputs({ inputs: [] }))
  const onOkClick = () => {
    dispatch(draftInputs({ inputs: [] }))
    if (action === DraftActions.delete) {
      dispatch(removeInputs(inputs.map(({ id }) => id)))
    }
    if (action === DraftActions.disable) {
      dispatch(disableInputs(inputs.map(({ id }) => id)))
      setSelected([])
    }
  }

  const isInputInUse = inputs.some(input => !!input.numOutputs)
  return (
    <Dialog
      open={inputs.length > 0 && (action === DraftActions.delete || action === DraftActions.disable)}
      data-test-id="confirm-dialog"
    >
      <DialogContent>
        <DialogContentText component="div">
          {!isInputInUse &&
            `Are you sure you want to ${action} ${
              inputs.length > 1 ? pluralize(inputs.length, 'input') : inputs[0].name
            }?`}

          {isInputInUse && (
            <>
              {inputs.length > 1
                ? `Some inputs are in use - are you sure you want to ${action} them?`
                : `${inputs[0].name} is in use - are you sure you want to ${action} it?`}
              {inputs.length > 1 && (
                <ul>
                  {inputs.map(item => (
                    <li key={item.id}>
                      {item.name}
                      {!!item.numOutputs && ` (in use by ${pluralize(item.numOutputs, 'output')})`}
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onCancelClick} data-test-id="cancel-btn">
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={onOkClick} data-test-id="ok-btn">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteDialog
