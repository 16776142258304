import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { merge } from 'lodash'
import { Form, Formik, FormikProps } from 'formik'
import * as uuid from 'uuid'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Group, GroupInit, Role } from 'common/api/v1/types'
import { AppDispatch, GlobalState } from '../../../store'
import { ButtonsPane, Paper, SafeRouting, TextInput, useStyles } from '../../common/Form'
import Pendable from '../../common/Pendable'
import { formTransform, useConfirmationDialog, useUser } from '../../../utils'
import { createGroup, removeGroup, updateGroup, getGroup, clearGroup } from '../../../redux/actions/groupActions'

import Lists from './Lists'
import Wrapper from '../../common/Wrapper'
import { generatePassword } from '../../../utils/password'
import routes from '../../../utils/routes'

const useOwnStyles = makeStyles((theme: Theme) => ({
  warning: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
}))

const getInitialState = (group?: Group) =>
  merge(
    {
      name: '',
      applianceSecret: '',
      adminUsername: '',
      adminPassword: '',
    },
    group,
  )

const isEditing = (values: Group | GroupInit): values is Group => 'id' in values && !!values.id

const GroupForm = (history: RouteComponentProps['history']) => ({
  setFieldValue,
  values,
  setStatus,
  dirty,
  isSubmitting,
  setSubmitting,
}: FormikProps<Group | GroupInit>) => {
  const classes = useStyles()
  const ownClasses = useOwnStyles()
  const dispatch = useDispatch<AppDispatch>()
  const setConfirmation = useConfirmationDialog()
  const { formErrors, saving } = useSelector(({ groupsReducer }: GlobalState) => groupsReducer, shallowEqual)
  const user = useUser()
  useEffect(() => {
    setStatus(
      Array.isArray(formErrors) ? formErrors.reduce((acc, item) => ({ ...acc, [item.name]: item.reason }), {}) : {},
    )
  }, [formErrors])
  useEffect(() => {
    if (saving === false) setSubmitting(false)
  }, [saving])
  const setSecret = () => setFieldValue('applianceSecret', uuid.v4())

  const onRefreshClick = isEditing(values)
    ? () =>
        setConfirmation(
          () => setSecret(),
          <>
            <span className={ownClasses.warning}>Warning!</span>
            <span>
              {' '}
              New appliances will need to use the new secret to register. Previously registered appliances can still use
              the secret they were originally registered with.
            </span>
          </>,
        )
    : () => setSecret()
  const deleteGroup = () =>
    isEditing(values) &&
    setConfirmation(
      () => void dispatch(removeGroup(values.id)),
      `Are you sure you want to delete group ${values.name}?`,
    )

  if (user.role !== Role.super && (user.role !== Role.admin || user.group !== (values as Group).id)) {
    history.goBack()
    return null
  }

  const [showPassword, setShowPassword] = useState({ show: false, id: '' })
  const onGeneratePasswordClick = () => {
    const adminPassword = generatePassword(12, 12)
    setFieldValue('adminPassword', adminPassword)
    setShowPassword({ show: true, id: adminPassword })
  }
  const onAdminAccountCollapseChange = (collapsed: boolean) => {
    if (collapsed) {
      setFieldValue('adminPassword', '')
      setFieldValue('adminUsername', '')
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <SafeRouting enabled={dirty && !isSubmitting} />
        <Form id="group-form" translate="no" noValidate>
          <Paper
            title="Group meta data"
            classes={classes.paper}
            actionsPane={[
              values.applianceSecret && navigator.clipboard ? (
                {
                  props: { variant: 'outlined' },
                  title: 'Copy secret',
                  onClick: () => void navigator.clipboard.writeText(values.applianceSecret || ''),
                }
              ) : (
                <></>
              ),
              { id: 'button-refresh-secret', title: 'Generate new secret', onClick: onRefreshClick },
            ]}
          >
            <TextInput name="name" label="Name" required autoFocus />
            <TextInput name="applianceSecret" label="Secret" required disabled />
          </Paper>

          {!isEditing(values) && (
            <Paper
              title="Create Group Admin"
              classes={classes.paper}
              collapsed
              collapsible
              actionsPane={[
                { id: 'button-generate-password', title: 'Generate password', onClick: onGeneratePasswordClick },
              ]}
              onCollapseChange={onAdminAccountCollapseChange}
            >
              <TextInput
                name="adminUsername"
                label="Username"
                required={!!values.adminPassword && values.adminPassword.length > 0}
                type="email"
                autoComplete="off"
                validators={values.adminUsername ? { email: {} } : {}}
              />
              <TextInput
                name="adminPassword"
                label="Password"
                autoComplete="new-password"
                required={!!values.adminUsername && values.adminUsername.length > 0}
                type="password"
                showPassword={showPassword}
                validators={{
                  pwd: {},
                }}
              />
            </Paper>
          )}

          {isEditing(values) && <Lists groupId={values.id} />}
          <ButtonsPane
            main={{
              Cancel: {
                onClick: () => {
                  history.push(routes.groups())
                },
              },
              Save: { savingState: !!saving, primary: true, type: 'submit' },
            }}
            secondary={
              isEditing(values) && user.role === Role.super
                ? {
                    'Remove group': { onClick: deleteGroup },
                  }
                : undefined
            }
          />
        </Form>
      </Grid>
    </Grid>
  )
}

export const Edit = ({ history, match }: RouteComponentProps<{ id?: string }>) => {
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    match.params.id && dispatch(getGroup(match.params.id))
    return () => {
      dispatch(clearGroup())
    }
  }, [])
  const groupToEdit = useSelector(({ groupsReducer }: GlobalState) => groupsReducer.group, shallowEqual)
  const onSubmit = (group: Group | GroupInit) => {
    if (groupToEdit) dispatch(updateGroup(group as Group))
    else dispatch(createGroup(group as GroupInit))
  }

  return (
    <Wrapper name="Groups" entityName={groupToEdit ? groupToEdit.name : 'New'}>
      <Grid container spacing={0}>
        <Pendable pending={!!match.params.id && !groupToEdit}>
          <Formik
            onSubmit={values => {
              onSubmit(formTransform(values))
            }}
            initialValues={getInitialState(groupToEdit)}
            component={GroupForm(history)}
          />
        </Pendable>
      </Grid>
    </Wrapper>
  )
}
