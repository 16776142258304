import { FormikProps } from 'formik'

import { Output } from 'common/api/v1/types'

import { TextInput } from '../../../../common/Form'
import { createDefaultFiledValues } from '../../../../../utils'

export enum RtmpFields {
  rtmpDestinationAddress = 'rtmpDestinationAddress',
}

export const rtmpDefaults = createDefaultFiledValues(Object.keys(RtmpFields), [])
export const getRtmpFieldsToSave = () => Object.keys(RtmpFields)

interface RtmpFormProps {
  form: FormikProps<Output>
  namePrefix: string
}
const RtmpForm = ({ namePrefix }: RtmpFormProps) => {
  return (
    <>
      <TextInput
        name={`${namePrefix}.${RtmpFields.rtmpDestinationAddress}`}
        label="Destination"
        type="text"
        tooltip={`The RTMP server to publish to. E.g: "rtmp://server:port/appName/appInstance?key1=value1&key2=value2"`}
        required
        validators={{
          format: {
            prefixes: ['rtmp://', 'rtmps://'],
          },
        }}
        newLine
      />
    </>
  )
}

export default RtmpForm
