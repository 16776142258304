import { ListResult, ActiveService, ActiveServiceFilter } from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'
import { PaginatedRequestParams, singleSortQueryFromPaginatedRequestParams } from '../nm-types'

export interface IActiveServicesApi {
  getActiveServices(params: PaginatedRequestParams): Promise<ListResult<ActiveService>>
}

export class ActiveServicesApi implements IActiveServicesApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  /**
   * Returns paginated list for active services
   * @param searchName - term to search for
   * @param params
   */
  getActiveServices({ filter: searchName, ...params }: PaginatedRequestParams): Promise<ListResult<ActiveService>> {
    const filter: ActiveServiceFilter = { searchName }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    return this.edgeClient.listActiveServices(query)
  }
}
