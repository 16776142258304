import { IpMappingRead, ListIpMappingSortableField } from 'common/api/v1/types'
import IconButton from '@material-ui/core/IconButton'
import { Link } from '../common/Link'
import Table, { TableConfig } from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import Wrapper from '../common/Wrapper'
import SettingsIcon from '@material-ui/icons/Settings'
import Typography from '@material-ui/core/Typography'

import Button from '@material-ui/core/Button'
import routes from '../../utils/routes'
import { useAddressMappingSelector, usePageParamsFilteredSelector } from '../../utils'

export const ListView = () => {
  const { ipMappings, loading } = usePageParamsFilteredSelector(useAddressMappingSelector)

  const tableConfig: TableConfig<IpMappingRead, ListIpMappingSortableField> = [
    {
      title: 'region',
      getValue: ({ region }) =>
        (region && (
          <Typography variant="body2" color="textSecondary">
            {region.name}
          </Typography>
        )) ||
        null,
      sorting: {
        byParameter: ListIpMappingSortableField.regionName,
      },
    },
    {
      title: 'appliance',
      getValue: ({ appliance }) =>
        (appliance && (
          <Link to={routes.appliancesUpdate({ id: appliance.id })} underline="hover">
            <Typography variant="body2" color="textSecondary">
              {appliance.name}
            </Typography>
          </Link>
        )) ||
        null,
      sorting: {
        byParameter: ListIpMappingSortableField.applianceName,
      },
    },
    {
      title: 'interface',
      getValue: ({ nic }) =>
        (nic && (
          <Link to={routes.interfacesUpdate({ id: nic.id })} underline="hover">
            <Typography variant="body2" color="textSecondary">
              {nic.name}
            </Typography>
          </Link>
        )) ||
        null,
      sorting: {
        byParameter: ListIpMappingSortableField.interfaceName,
      },
    },
    {
      title: 'private address',
      getValue: ({ privateIp }) => privateIp,
      sorting: {
        byParameter: ListIpMappingSortableField.privateAddress,
      },
    },
    {
      title: 'public address',
      getValue: ({ publicIp }) => publicIp,
      sorting: {
        byParameter: ListIpMappingSortableField.publicAddress,
      },
    },
    {
      title: 'internal address',
      getValue: ({ internalIp }) => internalIp,
      sorting: {
        byParameter: ListIpMappingSortableField.internalAddress,
      },
    },
    {
      title: 'inter region address',
      getValue: ({ interRegionIp }) => interRegionIp,
      sorting: {
        byParameter: ListIpMappingSortableField.interRegionAddress,
      },
    },
    {
      getValue: mapping => (
        <Link
          to={routes.addressMappingsUpdate({ privateIp: mapping.privateIp, region: mapping.region.id })}
          tabIndex={-1}
        >
          <IconButton edge="end" data-test-id="edit-mapping">
            <SettingsIcon />
          </IconButton>
        </Link>
      ),
      props: { align: 'right' },
    },
  ]

  return (
    <Wrapper name="Address mappings">
      <Table<IpMappingRead>
        id="mappings"
        emptyMessageComponent={<MissingContent message="No addresses to map" />}
        config={tableConfig}
        data={ipMappings}
        pending={loading}
        actions={[
          <Link key="addMapping" id="mappings-table-create-button" to={routes.addressMappingsNew()}>
            <Button variant="contained" color="primary">
              Add mapping
            </Button>
          </Link>,
        ]}
      />
    </Wrapper>
  )
}
