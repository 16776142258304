import cn from 'classnames'

import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'

import { OutputRecipientList } from 'common/api/v1/types'

import { useStyles } from './style'

interface OutputProps {
  output: OutputRecipientList
  isHovered: boolean
}
const OutputList = ({ output, isHovered }: OutputProps) => {
  const classes = useStyles()

  return (
    <div className={classes.listContainer}>
      <div className={cn(classes.listSign, classes.itemStyle)} style={{ top: '16px', left: '-16px' }} />
      <div className={cn(classes.listSign, classes.itemStyle)} style={{ top: '8px', left: '-8px' }} />
      <div className={cn(classes.listItem, classes.itemStyle, isHovered && classes.itemHovered)} data-test-id="output">
        <Typography
          className={classes.title}
          variant="body1"
          color={isHovered ? 'primary' : 'textPrimary'}
          data-test-id="output-title"
        >
          {output.name}
        </Typography>
        <div className={classes.listContent}>
          <Icon>
            <VideoLibraryIcon />
          </Icon>
          <Typography>{output.description}</Typography>
        </div>
      </div>
    </div>
  )
}

export default OutputList
