import { BuildInfo } from 'common/api/v1/types'
import { getBuildInfo } from '../actions/buildInfoActions'
import { createSlice } from '@reduxjs/toolkit'

interface State {
  buildInfo?: BuildInfo
  loading: boolean
}

const initialState: State = {
  buildInfo: undefined,
  loading: false,
}

const buildInfoSlice = createSlice({
  name: 'buildInfo',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getBuildInfo.pending, (state): State => ({ ...state, loading: true }))
      .addCase(
        getBuildInfo.fulfilled,
        (state, { payload: buildInfo }): State => ({ ...state, loading: false, buildInfo }),
      )
      .addCase(getBuildInfo.rejected, (state): State => ({ ...state, loading: false }))
  },
})

export default buildInfoSlice.reducer
