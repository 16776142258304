import { useState } from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'

import { Input, OutputOperStatus, OutputRecipientList, OutputOrRecipientList, Output } from 'common/api/v1/types'
import { getOutputOperState } from 'common/api/v1/helpers'
import routes from '../../../../utils/routes'
import { inputOutputColor, isOutput } from '../../../../utils'
import { EnrichedOutput, OutputsRequestParams } from '../../../../api/nm-types'
import PaginatedList from '../../../common/SelfStatePaginatedList'
import Thumbnail from '../../../common/Thumbnail'
import { Api } from '../../../../store'
import { Table } from '../../../common/Table'
import { Link } from '../../../common/Link'
import { OutputHealthIndicator as HealthIndicator } from '../../../common/Indicator'

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    cursor: 'pointer',
  },
  thumbnail: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    minWidth: '80px',
    maxWidth: '120px',
    minHeight: '50px',
  },
  button: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  owner: {
    display: 'inline-block',
    width: '140px',
  },
  name: {
    fontWeight: 'bold',
  },
}))

const outputApi = Api.outputApi

interface AvailableGroupsProps {
  input: Input
  onSend: (output: OutputOrRecipientList) => void
  onCancel: (outputId: string, isList?: boolean) => void
  changes: { [key: string]: Output }
  listChanges: Array<OutputRecipientList['id']>
}
const AvailableOutputs = ({ input, onSend, onCancel, changes, listChanges }: AvailableGroupsProps) => {
  const classes = useStyles()
  const [hideFilter, setHideFilter] = useState(true)

  return (
    <div data-test-id="outputs-container">
      <Typography variant="h2">Add recipients</Typography>
      <PaginatedList<OutputsRequestParams, EnrichedOutput | OutputRecipientList>
        api={outputApi.getOutputsWithLists.bind(outputApi)}
        emptyMessage="no available outputs"
        hideSearch={hideFilter}
        notFoundMessage="no matching outputs"
        onListChange={({ filter, total = 0 }) => setHideFilter(!filter && total < 10)}
        otherParams={{ notInput: input.id }}
        searchPlaceholder="Find specific output…"
        List={({ list }) => (
          <Table
            data={list}
            isSmall
            hasBorders
            noHeader
            rowProps={item => ({
              onClick: () => (isOutput(item) ? !(item.id in changes) : !listChanges.includes(item.id)) && onSend(item),
              className: classes.row,
            })}
            config={[
              {
                getValue: item => (
                  <div className={classes.thumbnail}>
                    {isOutput(item) ? (
                      !!item._input && <Thumbnail input={item._input} outputId={item.id} linkToPlaylist={false} />
                    ) : (
                      <Icon>
                        <VideoLibraryIcon />
                      </Icon>
                    )}
                  </div>
                ),
              },
              {
                getValue: item =>
                  isOutput(item) ? (
                    <>
                      <Typography
                        component="div"
                        className={classes.name}
                        data-test-id="output-name"
                        {...inputOutputColor(item)}
                      >
                        {item.name}
                      </Typography>
                      {!!item._input && (
                        <Typography color="textSecondary" variant="body2" component="div">
                          {item._input.name}
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Typography data-test-id="output-name">{item.name}</Typography>
                  ),
              },
              {
                getValue: item =>
                  isOutput(item) ? (
                    getOutputOperState(item).state === OutputOperStatus.outputError ? (
                      <Link type="button" to={routes.alarms()}>
                        <HealthIndicator outputId={item.id} />
                      </Link>
                    ) : (
                      <HealthIndicator outputId={item.id} />
                    )
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      output list
                    </Typography>
                  ),
              },
              {
                getValue: item =>
                  isOutput(item) ? (
                    <div className={classes.owner}>
                      {item.id in changes ? (
                        <Button
                          className={classes.button}
                          onClick={() => onCancel(item.id)}
                          variant="outlined"
                          size="small"
                        >
                          Cancel sending
                        </Button>
                      ) : (
                        <Typography color="textSecondary" variant="body2">
                          {item._group?.name}
                        </Typography>
                      )}
                    </div>
                  ) : (
                    listChanges.includes(item.id) && (
                      <Button
                        className={classes.button}
                        onClick={() => onCancel(item.id, true)}
                        variant="outlined"
                        size="small"
                      >
                        Cancel sending
                      </Button>
                    )
                  ),
                props: {
                  align: 'right',
                  noTypography: true,
                },
              },
            ]}
          />
        )}
      />
    </div>
  )
}

export default AvailableOutputs
