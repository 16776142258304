import React from 'react'
import { Field, FieldProps } from 'formik'
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel'

import GridItem, { GridItemProps, omitGridProps } from './GridItem'
import BackendValidation from '../../common/Form/BackendValidation'

const Comp = (props: FieldProps & MuiCheckboxProps & CheckboxProps) => {
  const muiProps: MuiCheckboxProps = {
    ...props.field,
    ...props,
  }

  return (
    <>
      <FormControlLabel control={<MuiCheckbox {...muiProps} />} label={props.label || props.name} />
      <BackendValidation form={props.form} name={props.field.name} />
    </>
  )
}

interface CheckboxProps extends GridItemProps {
  name?: string
  label?: string
  control?: FormControlLabelProps['control']
  fullWidth?: boolean
  tooltipStyle?: React.CSSProperties
  disabled?: boolean
}

/**
 * Checkbox for forms
 * @param props {
 *   name?: if we use it as formik field - the name for the form
 *   label?: the label to show
 *   control?: if we don't want to have the value in formik state we have to define our own control to display it
 *   fullWidth?: if the element should take up the full width of the cell
 *   tooltipStyle?: custom styling for tooltip icon
 * }
 * @constructor
 */
const Checkbox = (props: CheckboxProps) => (
  <GridItem {...props} tooltipStyle={props.tooltipStyle}>
    <FormControl
      margin="normal"
      fullWidth={props.fullWidth === undefined ? true : props.fullWidth}
      style={{ position: 'relative', top: '6px' }}
    >
      {props.control ? (
        <FormControlLabel control={props.control} label={props.label || props.name} />
      ) : (
        <Field type="checkbox" component={Comp} {...omitGridProps(props)} />
      )}
    </FormControl>
  </GridItem>
)

export default Checkbox
