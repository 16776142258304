import { BuildInfo } from './api/v1/types'

const ciSomethingPattern = /^ci-([1-9][0-9]+)$/
const rSomethingPattern = /^R([1-9]+)\.([0-9]+)\.([0-9]+)(-rc[1-9][0-9]*)?$/

export const isVersionOutdated = (version: string, buildInfo: BuildInfo | undefined) => {
    if (buildInfo && areComparable(buildInfo.release, version)) {
        const edgeVersion = releaseTagToNumber(buildInfo.release)
        const softwareVersion = releaseTagToNumber(version)
        if (softwareVersion < edgeVersion) {
            return true
        }
    }
    return false
}

export function areComparable(releaseName1: string, releaseName2: string) {
    if (releaseName1.startsWith(releaseName2[0])) {
        // The releases need to be in the same 'series'
        if (isUnderstandableReleaseTag(releaseName1) && isUnderstandableReleaseTag(releaseName2)) {
            return true
        }
    }
    return false
}

export function isUnderstandableReleaseTag(releaseTag: string) {
    return isReleaseBuild(releaseTag) || isDevBuild(releaseTag)
}

export function isReleaseBuild(releaseTag: string) {
    return rSomethingPattern.test(releaseTag)
}

export function isDevBuild(releaseTag: string) {
    return ciSomethingPattern.test(releaseTag)
}

export function findComparableTag(refTag: string, candidateTags: string[]) {
    return candidateTags.find((t) => areComparable(refTag, t))
}

export function comparableReleaseTagToNumber(referenceReleaseTag: string, candidateTags: string[]): number {
    const relatedTag = findComparableTag(referenceReleaseTag, candidateTags)
    if (!relatedTag) {
        return NaN
    }
    return releaseTagToNumber(relatedTag)
}

export function releaseTagToNumber(releaseTag: string) {
    const rSomethingResult = releaseTag.match(rSomethingPattern)
    if (rSomethingResult) {
        const parts = rSomethingResult
            .slice(1)
            .filter((v) => v)
            .map((v) => v.replace('-rc', ''))
        return numberFromParts(parts)
    }

    const ciSomethingResult = releaseTag.match(ciSomethingPattern)
    if (ciSomethingResult) {
        return numberFromParts(ciSomethingResult.slice(1))
    }
    throw new Error(`Could not convert ${releaseTag} to number`)
}

export function numberFromParts(parts: string[]) {
    const maxParts = 4
    if (parts.length > maxParts) {
        throw new Error(`Cannot get version int from ${parts.length} parts`)
    }
    let resultNumber = 0
    let factor = Math.pow(10, 3 * maxParts)
    for (const n of parts) {
        resultNumber = resultNumber + factor * parseInt(n)
        factor = factor / 1000
    }
    return resultNumber
}
