import Button from '@material-ui/core/Button'

import { GroupRecipientList } from 'common/api/v1/types'
import { usePageParamsFilteredSelector, useGroupListsSelector } from '../../utils'
import Table, { MoveToEntity, TableConfig } from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import Wrapper from '../common/Wrapper'
import { Link } from '../common/Link'
import routes from '../../utils/routes'

export const ListView = () => {
  const { groupLists, total, loading } = usePageParamsFilteredSelector(useGroupListsSelector)

  const tableConfig: TableConfig<GroupRecipientList> = [
    {
      title: 'name',
      getValue: ({ name }) => name,
      sorting: { byParameter: 'name' },
    },
    {
      title: 'description',
      getValue: ({ description }) => description,
      sorting: { byParameter: 'description' },
    },
    {
      getValue: ({ id }) => <MoveToEntity url={routes.groupListsUpdate({ id })} entityName="group list" />,
      props: { align: 'right' },
    },
  ]

  return (
    <Wrapper name="Group lists">
      <Table<GroupRecipientList>
        id="grouplists-table"
        emptyMessageComponent={
          <MissingContent message="No group lists" buttonText="Add group list" url={routes.groupListsNew()} />
        }
        config={tableConfig}
        data={groupLists}
        pending={loading}
        actions={[
          <Link key="groupListCreate" type="button" to={routes.groupListsNew()}>
            <Button variant="contained" color="primary">
              Add group list
            </Button>
          </Link>,
        ]}
        pagination={{ total, useUrlSearchParams: true }}
      />
    </Wrapper>
  )
}
