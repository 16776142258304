import { IBitratesApi } from './api'
import { MOCK_DELAY_MS } from '../MockData'
import { MaxBitrates } from 'common/api/v1/types'

let timestamp = Number(new Date())
let val = 3000 + Math.random() * 2000

export class MockBitratesApi implements IBitratesApi {
  readBitrates(): Promise<MaxBitrates> {
    return new Promise(resolve =>
      setTimeout(() => {
        return resolve(
          [...Array(200).keys()].reduce<MaxBitrates>(
            acc => {
              timestamp++
              acc.inputMaxBitrates.push([new Date(timestamp).toISOString(), (val += (Math.random() - 0.5) * 100)])
              acc.outputMaxBitrates.push([new Date(timestamp).toISOString(), (val += (Math.random() - 0.5) * 100)])
              return acc
            },
            {
              inputMaxBitrates: [],
              outputMaxBitrates: [],
            },
          ),
        )
      }, MOCK_DELAY_MS),
    )
  }
}
