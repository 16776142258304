import axios from '../utils/edgeAxios'
import * as uuid from 'uuid'
import { EdgeClient } from 'common/generated/edgeClient'
import { RequestOptions, RequestResult } from 'common/network/request-options'
import { Method } from 'axios'

export class BrowserEdgeClient extends EdgeClient {
  constructor(params: { baseUrl: string; apiKey?: string }) {
    super(params)
  }

  protected async _request<T>({
    method,
    url,
    body,
    optionsOverrides,
  }: {
    method: string
    url: string
    body?: any
    optionsOverrides?: Partial<RequestOptions>
  }): Promise<RequestResult<T>> {
    try {
      const res = await request<T>({
        method,
        url,
        body,
        json: true,
        requestId: uuid.v4(),
        throwOnNon200: true,
        ...optionsOverrides,
      })
      return res
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Error: performing '${method.toUpperCase()}' towards '${url}': `, e)
      throw e
    }
  }
}

const request = async <T>(options: RequestOptions): Promise<RequestResult<T>> => {
  // eslint-disable-next-line no-console
  const { url, method, headers, body, json, requestId } = options
  const inferredHeaders: { [header: string]: string } = {}
  if (requestId) {
    inferredHeaders['x-request-id'] = requestId
  }
  if (json) {
    inferredHeaders['content-type'] = 'application/json'
  }

  const response = await axios.request({
    method: method as Method,
    url,
    headers: {
      ...inferredHeaders,
      ...headers,
    },
    data: body,
    // The number of milliseconds before the request times out and is aborted
    timeout: options.timeoutMs || 10_000,
  })
  return {
    body: response.data,
    headers: response.headers,
    status: response.status,
  }
}
