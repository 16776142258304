import React, { useEffect, useState } from 'react'
import cn from 'classnames'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { TypographyVariant as Variant } from '@material-ui/core/styles'
import Button, { ButtonProps } from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import FormControl from '@material-ui/core/FormControl'
import Grid, { GridProps } from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MuiPaper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

import { useStyles as useCommonStyles } from './styles'

const useStyles = makeStyles((theme: Theme) => ({
  paperTitle: {
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
  },
  clickable: {
    cursor: 'pointer',
  },
  grid: {
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    marginRight: theme.spacing(1),
    '& > div + div': {
      marginLeft: theme.spacing(2),
    },
  },
  buttonContainerAlt: {
    justifyContent: 'flex-start',
  },
  tooltip: {
    fontSize: theme.typography.body2.fontSize,
  },
  icon: {
    marginLeft: theme.spacing(1),
    transform: 'translateY(3px)',
  },
}))
interface Action {
  title: string
  onClick: () => void
  id?: string
  props?: ButtonProps
}
interface PaperProps {
  actionsPane?: Array<Action | React.ReactNode>
  classes?: string
  collapsible?: boolean
  collapsed?: boolean
  containerProps?: GridProps
  id?: string
  title?: string | React.ReactNode
  titleTypography?: Variant
  onCollapseChange?: (collapsed: boolean) => void
  tooltip?: string | React.ReactNode
}
/**
 * Some enhancements on the top of MaterialUI Paper
 * @param classes - css classes to apply for it
 * @param collapsible - should we show button which expands/collapses the paper
 * @param title - paper's title
 * @param children - content
 * @param collapsed - if it is collapsible we can make it collapsed by default
 * @param actionsPane - bottom line of buttons
 * @param containerProps - MaterialUI Grid properties
 * @param titleTypography - Typography variant for title
 * @param id
 */
const Paper: React.FunctionComponent<PaperProps> = ({
  classes,
  collapsible = false,
  title = '',
  children,
  collapsed: col = false,
  actionsPane = [],
  containerProps,
  titleTypography,
  id,
  onCollapseChange,
  tooltip,
}) => {
  const ownStyles = useStyles()
  const resultClasses = classes || useCommonStyles().paper
  const [collapsed, setCollapsed] = useState(false)
  useEffect(() => {
    setCollapsed(col)
  }, [col])
  const toggle = () => {
    if (collapsible) {
      setCollapsed(!collapsed)
    }
    if (onCollapseChange) {
      onCollapseChange(!collapsed)
    }
  }

  return (
    <MuiPaper className={resultClasses} data-test-id={id || `paper-${title}`}>
      {(title || collapsible) && (
        <Toolbar disableGutters variant="dense">
          <Typography
            variant={titleTypography || 'h2'}
            className={cn(ownStyles.paperTitle, collapsible && ownStyles.clickable)}
            onClick={toggle}
          >
            {title}{' '}
            {tooltip && (
              <Tooltip title={tooltip} classes={{ tooltip: ownStyles.tooltip }} placement="right">
                <HelpOutlineIcon className={ownStyles.icon} />
              </Tooltip>
            )}
          </Typography>
          {collapsible && (
            <Tooltip title={collapsed ? 'Expand' : 'Collapse'} placement="top">
              <IconButton onClick={toggle}>{collapsed ? <ExpandMore /> : <ExpandLess />}</IconButton>
            </Tooltip>
          )}
        </Toolbar>
      )}
      <Collapse in={!collapsed}>
        <Grid container spacing={2} className={ownStyles.grid} {...containerProps}>
          {children}
        </Grid>
        {Boolean(actionsPane.length) && (
          <Toolbar disableGutters variant="dense" className={ownStyles.buttonContainer}>
            {actionsPane.map((item, ind) => (
              <FormControl margin="normal" key={ind}>
                {React.isValidElement(item) ? (
                  item
                ) : (
                  <Button
                    id={item ? (item as Action).id : undefined}
                    variant="contained"
                    color="secondary"
                    onClick={(item as Action).onClick}
                    {...(item as Action).props}
                  >
                    {(item as Action).title}
                  </Button>
                )}
              </FormControl>
            ))}
          </Toolbar>
        )}
      </Collapse>
    </MuiPaper>
  )
}

export default Paper
