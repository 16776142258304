import { createSlice } from '@reduxjs/toolkit'

import { getServices, getService, clearServices, clearService, updateService } from '../actions/serviceActions'
import { isOneOf } from '../actions'
import { Service } from 'common/api/v1/types'

interface State {
  formErrors?: Array<{ name: string; reason: string }>
  draftService?: Service
  loading: boolean
  saving?: boolean
  total: number
  serviceToEdit?: Service
  services: Array<Service>
}

const initialStateService: State = { services: [], loading: false, total: 0 }

const serviceSlice = createSlice({
  name: 'service',
  initialState: initialStateService,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(
        getServices.fulfilled,
        (state, { payload: { items: services, total } }): State => ({
          ...state,
          loading: false,
          services,
          total,
        }),
      )
      .addCase(updateService.pending, (state): State => ({ ...state, saving: true }))
      .addCase(updateService.fulfilled, (state): State => ({ ...state, saving: undefined }))
      .addCase(clearServices, (state): State => ({ ...state, services: [] }))
      .addCase(clearService, (state): State => ({ ...state, serviceToEdit: undefined }))
      .addCase(
        getService.fulfilled,
        (state, { payload: service }): State => ({ ...state, serviceToEdit: service, loading: false }),
      )

      .addMatcher(isOneOf([getServices.pending]), (state): State => ({ ...state, loading: true }))
      .addMatcher(isOneOf([getServices.rejected]), (state): State => ({ ...state, loading: false }))
  },
})

export default serviceSlice.reducer
