import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import cn from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'

import nimbraEdgeLogo from '../../img/netinsight-logo-color-white@3x.png'
import faviconLogo from '../../img/favicon.png'
import { ImageName } from 'common/api/v1/types'
import { Api } from '../../store'
import { GlobalState } from '../../store'

const useStyles = makeStyles((theme: Theme) => ({
  topLeftContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '50px',
    height: '30px',
    position: 'relative',
    overflow: 'hidden',
  },
  topLeftImg: {
    height: '100%',
  },
  faviconContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '32px',
    height: '32px',
    position: 'relative',
    overflow: 'hidden',
  },
  faviconImg: {
    height: '100%',
  },
  topLeftDefault: {
    fontFamily: 'Roboto slab',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '90%',
  },
  loginContainer: {
    display: 'block',
    position: 'relative',
    width: '396px',
    margin: theme.spacing(2, 0),
  },
  loginImg: {
    height: '44px',
    margin: '0 auto',
    display: 'block',
  },
  draftImg: {
    background: theme.palette.background.default,
  },
}))

const api = Api
/**
 * hook to fetch the logo
 * @param name - logo type
 */
export const useFetchedImage = (name: ImageName) => {
  const [source, setSource] = useState<string | null>('')
  const [type, setType] = useState<string>('')
  useEffect(() => {
    api.imageApi
      .getImageDetails(name)
      .then(data => {
        setSource(`/api/media/${data.id}`)
        setType(data.type)
      })
      .catch(err => {
        if (err?.errorInfo?.errorCode == 404) setSource(null)
      })
  }, [])

  return { source, type }
}

interface LogoProps {
  image?: string
  isDraft?: boolean
}

/**
 * Component to show fetched/default left top corner logo
 * @param image - base64 image
 * @param isDraft - whether it is editing component, otherwise it just shows the logo
 */
export const TopLeftLogo: React.FunctionComponent<LogoProps> = ({ image, isDraft }) => {
  const classes = useStyles()
  const { source } = useFetchedImage(ImageName.serviceProvider)
  const { serviceProvider: deleted } = useSelector(
    ({ settingsReducer }: GlobalState) => settingsReducer.toDelete,
    shallowEqual,
  )

  if (source === '') return null // fetching not complete yet

  const showDefault = (isDraft && deleted) || (source === null && !image)
  const showSource = (!!source || !!image) && (!deleted || !isDraft)
  return (
    <div className={cn(classes.topLeftContainer, isDraft && classes.draftImg)} data-test-id="corner-logo">
      <img
        className={classes.topLeftImg}
        src={image || source || undefined}
        alt="Nimbra Edge"
        style={{ display: showSource ? 'block' : 'none' }}
      />
      <span className={classes.topLeftDefault} style={{ display: showDefault ? 'block' : 'none' }}>
        Nimbra Edge
      </span>
    </div>
  )
}
/**
 * Component to show fetched/default logo for the login page
 * @param image - base64 image
 * @param isDraft - whether it is editing component, otherwise it just shows the logo
 */
export const LoginLogo: React.FunctionComponent<LogoProps> = ({ image, isDraft }) => {
  const classes = useStyles()
  const { source } = useFetchedImage(ImageName.product)
  const { product: deleted } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer.toDelete, shallowEqual)

  if (source === '') return null // fetching not complete yet

  const showDefault = (isDraft && deleted) || (source === null && !image)
  const showSource = (!!source || !!image) && (!deleted || !isDraft)
  return (
    <div className={cn(classes.loginContainer, isDraft && classes.draftImg)}>
      <img
        className={classes.loginImg}
        src={image || source || undefined}
        alt="Nimbra Edge"
        style={{ display: showSource ? 'block' : 'none' }}
        data-test-id="service-provider-logo"
      />
      <img
        src={nimbraEdgeLogo}
        className={classes.loginImg}
        alt="Edge Network Manager logo"
        style={{ display: showDefault ? 'block' : 'none' }}
        data-test-id="default-logo"
      />
    </div>
  )
}

export const FaviconLogo: React.FunctionComponent<LogoProps> = ({ image, isDraft }) => {
  const classes = useStyles()
  const { source } = useFetchedImage(ImageName.favicon)
  const { favicon: deleted } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer.toDelete, shallowEqual)

  if (source === '') return null // fetching not complete yet

  // show original if no image provided by user, no image uploaded before, or user clicked restore default
  const providedSource = source || image
  const restoreDefault = deleted && isDraft
  const showDefault = restoreDefault || !providedSource

  return (
    <div className={cn(classes.faviconContainer, isDraft && classes.draftImg)}>
      <img
        className={classes.faviconImg}
        src={showDefault ? faviconLogo : providedSource}
        alt={source ? 'Nimbra Edge Favicon' : 'Edge Network Manager favicon'}
        data-test-id="favicon-logo"
      />
    </div>
  )
}
