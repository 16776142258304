import { Route } from 'react-router-dom'

import { List } from './List'
import { Edit } from './Edit'
import routes from '../../utils/routes'

export const Inputs = () => (
  <>
    <Route path={routes.inputsUpdate.route} component={Edit} />
    <Route path={routes.inputsNew.route} component={Edit} />
    <Route path={routes.inputsCopy.route} component={Edit} />
    <Route exact path={routes.inputs.route} component={List} />
  </>
)
