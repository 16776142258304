import React from 'react'
import { DispatchProp, useDispatch } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import SettingsIcon from '@material-ui/icons/Settings'
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'
import DeleteIcon from '@material-ui/icons/Delete'
import InfoIcon from '@material-ui/icons/Info'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { EnrichedOutput } from '../../../api/nm-types'
import { getAppClasses } from '../../../Common'
import { Output, OutputAdminStatus } from '../../../../../common/src/api/v1/types'
import routes from '../../../utils/routes'
import { DraftActions, draftOutputs, enableOutputs } from '../../../redux/actions/outputsActions'
import { OutputListDialog } from '.'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import StopIcon from '@material-ui/icons/Stop'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { AppDispatch } from 'src/store'

interface ActionMenuProps {
  output: EnrichedOutput
  dispatch: DispatchProp['dispatch']
  showDialog: (output: Output, type: OutputListDialog) => void
}

interface ToggleAdminStatusButtonProps {
  output: EnrichedOutput
  handleItemClick: (event: React.MouseEvent<HTMLElement>) => void
}

const useStyles = makeStyles(() => ({
  actionSpace: {
    display: 'flex',
  },
  negativeMarginLeft: {
    marginLeft: '-8px',
  },
}))

const ToggleAdminStatusButton = ({ output, handleItemClick }: ToggleAdminStatusButtonProps) => {
  const dispatch = useDispatch<AppDispatch>()

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    handleItemClick(event)

    if (output.adminStatus === OutputAdminStatus.on) {
      dispatch(draftOutputs({ action: DraftActions.disable, outputs: [output] }))
    } else {
      dispatch(enableOutputs([output.id]))
      dispatch(draftOutputs({ outputs: [] }))
    }
  }

  return (
    <MenuItem onClick={onClick} data-test-id="toggle-admin-status-output" aria-label="toggle admin status">
      <ListItemIcon>{output.adminStatus === OutputAdminStatus.on ? <StopIcon /> : <PlayArrowIcon />}</ListItemIcon>
      <ListItemText primary={output.adminStatus === OutputAdminStatus.on ? 'Disable' : 'Enable'} />
    </MenuItem>
  )
}

export const ActionMenu = (props: ActionMenuProps) => {
  const { output, dispatch, showDialog } = props
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const classes = getAppClasses()
  const history = useHistory()
  const styles = useStyles()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleItemClick = (event: React.MouseEvent<HTMLElement>, route?: string) => {
    event.stopPropagation()
    setAnchorEl(null)
    if (route) {
      history.push(route)
    }
  }

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <div className={styles.actionSpace}>
      <Tooltip title={'Overview'} placement="top">
        <IconButton
          onClick={e => handleItemClick(e, routes.service({ id: output._input?.id || '', outputId: output.id }))}
          data-test-id={`service-overview-btn`}
          disabled={!output._input}
        >
          <InfoIcon />
        </IconButton>
      </Tooltip>
      <IconButton
        aria-controls="output-action-menu"
        aria-haspopup="true"
        data-test-id="open-output-action-menu"
        onClick={handleClick}
        className={styles.negativeMarginLeft}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        data-test-id="output-action-menu"
        keepMounted
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '35ch',
          },
        }}
      >
        <MenuItem onClick={e => handleItemClick(e, routes.outputsUpdate({ id: output.id }))} data-test-id="edit-output">
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        <MenuItem
          onClick={e => {
            handleItemClick(e)
            showDialog(output, OutputListDialog.selectInput)
          }}
          data-test-id="switch-input"
        >
          <ListItemIcon>
            <VideocamOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Switch input" />
        </MenuItem>
        <ToggleAdminStatusButton output={output} handleItemClick={handleItemClick} />
        <MenuItem
          onClick={e => {
            handleItemClick(e)
            showDialog(output, OutputListDialog.addToOutputList)
          }}
          data-test-id="add-to-list"
        >
          <ListItemIcon>
            <VideoLibraryIcon />
          </ListItemIcon>
          <ListItemText primary="Add to list" />
        </MenuItem>
        <MenuItem
          className={classes.error}
          onClick={e => {
            handleItemClick(e)
            dispatch(draftOutputs({ outputs: [output], action: DraftActions.delete }))
          }}
          data-test-id="delete-single"
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>
    </div>
  )
}
