import { CircularProgress, FormControl, InputLabel, MenuItem, Select as MUISelect } from '@material-ui/core'
import { EnrichedInputPort, EnrichedOutputPort, EnrichedPhysicalPort } from '../../../api/nm-types'
import { useAppliancePorts, whyIsInterfaceUnavailable } from '../../../utils'
import Typography from '@material-ui/core/Typography'
import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { FieldArray, FieldArrayRenderProps, FormikProps } from 'formik'
import cn from 'classnames'
import { Api } from '../../../store'
import { Appliance, IpPortMode, PhysicalPort, PortMode, PortType } from 'common/api/v1/types'
import { GridItem, Paper, useStyles } from '../../common/Form'

import { PortForm as InputPortForm } from '../../inputs/Edit/PortForm'
import { initialPort as initialInputPort } from '../../inputs/Edit/InputForm'

import { PortForm as OutputPortForm } from '../../outputs/Edit/PortForm'
import { initialPort as initialOutputPort } from '../../outputs/Edit/OutputForm'
import DelayedAppearance from '../../common/DelayedAppearance'
import {
  areMultipleInterfacesPerApplianceSupported,
  formatInterfaceAddress,
  InterfaceSectionForm,
  useInterfaceSectionStyles,
} from './Base'

interface Props {
  namePrefix: string
  isInputForm: boolean
  inputId: string | undefined
  outputId: string | undefined
  selectedAppliance: Pick<Appliance, 'id' | 'name' | 'type'>
  ports: Array<EnrichedInputPort | EnrichedOutputPort>
  enforcedPortMode: PortMode | undefined
  isModeSelectionDisabled: boolean
}

const Component = <T extends InterfaceSectionForm>(
  props: FormikProps<T> & Props,
  ref: React.Ref<{
    onAddInterfaceButtonClicked: Function
  }>,
) => {
  const {
    namePrefix,
    isInputForm,
    inputId,
    outputId,
    selectedAppliance,
    ports,
    enforcedPortMode,
    isModeSelectionDisabled,
    values,
    setFieldValue,
    setFieldError,
  } = props
  const classes = useStyles()
  const interfaceClasses = useInterfaceSectionStyles()

  const primarySelectedInterface = ports[0]
  const areMultipleInterfacesSupported = areMultipleInterfacesPerApplianceSupported({
    isInput: isInputForm,
    appliance: selectedAppliance,
    portMode: primarySelectedInterface?.mode,
  })
  const appliancePorts: EnrichedPhysicalPort[] | undefined = useAppliancePorts(selectedAppliance, Api.portsApi)?.sort(
    (p1, p2) => {
      if (p1.portType != PortType.ip && p2.portType == PortType.ip) {
        return -1
      }
      if (p2.portType != PortType.ip && p1.portType == PortType.ip) {
        return 1
      }
      return 0
    },
  )

  useImperativeHandle(ref, () => ({
    onAddInterfaceButtonClicked: addNewInterface,
  }))

  const addNewInterface = () => {
    const firstAvailableInterface = appliancePorts?.find(
      p => whyIsInterfaceUnavailable(p, { id: values.id, name: values.name }) === undefined,
    )
    const defaultInterface: PhysicalPort | undefined = primarySelectedInterface?._port || firstAvailableInterface
    if (!defaultInterface) return
    const portInit = {
      physicalPortId: defaultInterface.id,
      port: defaultInterface,
      enforcedMode: areMultipleInterfacesSupported ? (primarySelectedInterface.mode as IpPortMode) : undefined,
    }
    const nextIndex = ports.length
    setFieldValue(
      `${namePrefix}.ports.${nextIndex}`,
      isInputForm ? initialInputPort(portInit) : initialOutputPort(portInit),
    )
    setFieldError(`${namePrefix}.ports.${nextIndex}`, '')
  }

  useEffect(() => {
    const shouldAutoAddNewInterfaceSection = ports.length == 0
    if (shouldAutoAddNewInterfaceSection) addNewInterface()
  }, [appliancePorts])

  return (
    <>
      {!appliancePorts && (
        <GridItem newLine>
          <DelayedAppearance gracePeriodMs={1000}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Typography component="div" variant="body1">
                Fetching interfaces...
              </Typography>
              <CircularProgress style={{ marginLeft: 20 }} />
            </div>
          </DelayedAppearance>
        </GridItem>
      )}

      {appliancePorts && appliancePorts.length === 0 && (
        <GridItem newLine>
          <Typography component="div" variant="body1">
            The selected appliance has no available interfaces.
          </Typography>
        </GridItem>
      )}

      {appliancePorts && appliancePorts.length > 0 && (
        <FieldArray
          name={`${namePrefix}.ports`}
          render={(formikArrayHelpers: FieldArrayRenderProps) => {
            return ports.map((currentPort, portIndex) => {
              // Prefer ports from 'appliancePorts' since they are always populated with _inputs, _outputs (used when checking port conflict validation)
              const portWithInputsAndOutputs = appliancePorts.find(p => p.id == currentPort.physicalPort)!
              const isPrimaryInterface = portIndex === 0
              const interfaceText = `Interface${isPrimaryInterface ? '' : `-${portIndex + 1}`}`
              const interfaceToolTip = isPrimaryInterface
                ? undefined
                : `Additional interface to ${isInputForm ? 'receive' : 'egress'} the same stream for redundancy.`
              return (
                <Paper
                  key={portIndex}
                  classes={cn(
                    classes.paper,
                    isPrimaryInterface
                      ? interfaceClasses.interfaceSectionPaper1
                      : interfaceClasses.interfaceSectionPaper2,
                  )}
                >
                  <GridItem tooltip={interfaceToolTip} newLine>
                    <FormControl key={portIndex} variant="outlined" style={{ width: '100%' }}>
                      <InputLabel id={`select-input-interface-${portIndex}-label`}>{interfaceText}</InputLabel>
                      <MUISelect
                        name={`${formikArrayHelpers.name}.${portIndex}.interface`}
                        label={interfaceText}
                        labelId={`select-input-interface-${portIndex}-label`}
                        disabled={false}
                        fullWidth
                        value={currentPort.physicalPort}
                        onChange={event => {
                          const newPort = appliancePorts.find(p => p.id === event.target.value)!
                          formikArrayHelpers.replace(portIndex, {
                            ...currentPort,
                            physicalPort: newPort.id,
                            _port: newPort,
                          })
                        }}
                      >
                        {appliancePorts.map((port, index) => {
                          const disabledReason = whyIsInterfaceUnavailable(port, {
                            id: values.id,
                            name: values.name,
                          })
                          const isDisabled = disabledReason !== undefined
                          const formattedAddress = port.addresses.map(formatInterfaceAddress)[0]
                          return (
                            <MenuItem key={index} value={port.id} disabled={isDisabled}>
                              <div>
                                <Typography>
                                  {port.name}
                                  {isDisabled && (
                                    <span className={interfaceClasses.warningText}>({disabledReason})</span>
                                  )}
                                </Typography>
                                {formattedAddress && (
                                  <Typography component="div" variant="body2" color="textSecondary">
                                    {formattedAddress}
                                  </Typography>
                                )}
                              </div>
                            </MenuItem>
                          )
                        })}
                      </MUISelect>
                    </FormControl>
                  </GridItem>

                  {isInputForm && (
                    <InputPortForm
                      inputId={inputId}
                      index={portIndex}
                      isModeDisabled={isModeSelectionDisabled}
                      namePrefix={`${formikArrayHelpers.name}.${portIndex}`}
                      inputPort={portWithInputsAndOutputs}
                      form={formikArrayHelpers.form}
                      onRemove={formikArrayHelpers.remove}
                    />
                  )}
                  {!isInputForm && (
                    <OutputPortForm
                      outputId={outputId}
                      index={portIndex}
                      namePrefix={`${formikArrayHelpers.name}.${portIndex}`}
                      outputPort={portWithInputsAndOutputs}
                      applianceFecLimits={portWithInputsAndOutputs._appliance?.features.fec}
                      form={formikArrayHelpers.form}
                      isModeDisabled={isModeSelectionDisabled}
                      onRemove={formikArrayHelpers.remove}
                      enforcedPortMode={enforcedPortMode}
                    />
                  )}
                </Paper>
              )
            })
          }}
        />
      )}
    </>
  )
}

export const ReferencedComponent = forwardRef(Component)

export const ApplianceInterfaceSection = <T extends InterfaceSectionForm>({
  myRef,
  ...rest
}: FormikProps<T> & Props & { myRef: React.Ref<{ onAddInterfaceButtonClicked: Function }> }) => (
  <ReferencedComponent {...(rest as any)} ref={myRef} />
)
