import { BroadcastStandard, GlobalSettings, LogLevel } from 'common/api/v1/types'
import { ISettingsApi } from './api'
import { MOCK_DELAY_MS } from '../MockData'

export class MockSettingsApi implements ISettingsApi {
  getSettings(): Promise<GlobalSettings> {
    return new Promise(resolve =>
      setTimeout(() => {
        resolve({
          ristTunnelBasePort: 20000,
          ntpEnabled: true,
          ntpServer: 'pool.ntp.org',
          vaUpdateUrl: '',
          logLevel: LogLevel.info,
          defaultDelay: 1000,
          zixiFeederKey: '',
          zixiReceiverKey: '',
          defaultBroadcastStandard: BroadcastStandard.dvb,
          showAllNimbra400Alarms: false,
          expFeatures: {},
        })
      }, MOCK_DELAY_MS),
    )
  }
  saveSettings(): Promise<GlobalSettings> {
    return new Promise(resolve =>
      setTimeout(() => {
        resolve({
          ristTunnelBasePort: 20000,
          ntpEnabled: true,
          ntpServer: 'pool.ntp.org',
          vaUpdateUrl: '',
          logLevel: LogLevel.info,
          defaultDelay: 1000,
          zixiFeederKey: '',
          zixiReceiverKey: '',
          defaultBroadcastStandard: BroadcastStandard.dvb,
          showAllNimbra400Alarms: false,
          expFeatures: {},
        })
      }, MOCK_DELAY_MS),
    )
  }
}
