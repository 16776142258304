import { FormikProps } from 'formik'
import Button from '@material-ui/core/Button'
import {
  Address,
  Input,
  InputAdminStatus,
  FecLimits,
  LimitedPhysicalPort,
  Output,
  OutputAdminStatus,
  PortType,
  PortMode,
} from 'common/api/v1/types'
import { GridItem } from '../../../common/Form'
import IpPortForm from './IpPortForm'
import CoaxPortForm from './CoaxPortForm'
import { getPortsInUseBy } from '../../../../utils'

interface IPortFormProps {
  outputId?: string
  form: FormikProps<Output>
  index: number
  outputPort: LimitedPhysicalPort & { _inputs?: Array<Input>; _outputs?: Array<Output> }
  applianceFecLimits?: FecLimits
  allocatedPort?: { addresses: Address[]; portNumber: number }
  isModeDisabled: boolean
  onModeChanged?: () => void
  namePrefix: string
  onRemove?: (index: number) => void
  enforcedPortMode: PortMode | undefined
}

export const PortForm = ({
  outputId,
  outputPort,
  allocatedPort,
  isModeDisabled,
  onModeChanged,
  applianceFecLimits,
  namePrefix,
  enforcedPortMode,
  form,
  index,
  onRemove,
}: IPortFormProps) => {
  const portsInUseOnSelectedInterface = getPortsInUseBy({
    outputs:
      '_outputs' in outputPort && outputPort._outputs
        ? outputPort._outputs.filter(output => output.adminStatus == OutputAdminStatus.on && output.id != outputId)
        : [],
    inputs:
      '_inputs' in outputPort && outputPort._inputs
        ? outputPort._inputs.filter(input => input.adminStatus == InputAdminStatus.on)
        : [],
  })
  return (
    <>
      {outputPort.portType === PortType.ip && (
        <IpPortForm
          index={index}
          namePrefix={namePrefix}
          form={form}
          addresses={outputPort.addresses}
          applianceType={outputPort.appliance.type}
          applianceFecLimits={applianceFecLimits}
          occupiedPorts={portsInUseOnSelectedInterface}
          allocatedPort={allocatedPort}
          isModeDisabled={isModeDisabled}
          onModeChanged={onModeChanged}
          enforcedPortMode={enforcedPortMode}
        />
      )}

      {outputPort.portType === PortType.coax && <CoaxPortForm namePrefix={namePrefix} form={form} />}

      {onRemove && (
        <GridItem newLine>
          <Button style={{ marginTop: 12 }} variant="outlined" color="primary" onClick={() => onRemove(index)}>
            Remove interface
          </Button>
        </GridItem>
      )}
    </>
  )
}
