export function parseSearchParams<S extends Record<string, string>>(search: string) {
  const searchParamsMap = new URLSearchParams(search)
  return [...searchParamsMap.entries()].reduce((a, [key, value]) => {
    a[key] = value
    return a
  }, {} as Record<string, string>) as S
}

export const getLocationParams = () => {
  const search = document.location.href.split('?').pop()
  if (!search) return {}
  return parseSearchParams(search)
}
