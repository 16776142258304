import { useState, useRef } from 'react'
import { useDrag } from 'react-dnd'
import cn from 'classnames'

import { makeStyles, Theme, alpha } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import Thumbnail from '../../common/Thumbnail'
import SearchInput from '../../common/SearchInput'
import { InputHealthIndicator } from '../../common/Indicator'
import Pendable from '../../common/Pendable'
import { useDebouncedFilter, useInputsSelector } from '../../../utils'
import { inputOutputColor } from '../../../utils'
import { EnrichedInput } from '../../../api/nm-types'
import { getFormattedTransportStreamContent } from 'common/api/v1/helpers'
import { Pagination } from '../../common/Table/Pagination'
import useWindowDimensions from '../../../utils/hooks'

const LOCALE_STORAGE_PER_PAGE_KEY = 'edge:sm:inputsPerPage'

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    '.ddcontainer': {
      display: 'block !important',
    },
  },
  container: {
    padding: theme.spacing(0, 3),
  },
  toolbar: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      padding: theme.spacing(1, 3),
    },
  },
  item: {
    flexGrow: 1,
  },
  card: {
    background: theme.palette.common.white,
    cursor: 'grab',
    '&.dragging': {
      opacity: 0.7,
      cursor: 'grabbing',
    },
  },
  thumb: {
    position: 'relative',
    overflow: 'hidden',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
  },
  health: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 2, 0, 2),
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    paddingBottom: theme.spacing(2),
    color: theme.palette.common.white,
    background: alpha(theme.palette.common.black, 0.5),
  },
  subHeader: {
    display: 'flex',
    fontSize: theme.typography.subtitle2.fontSize,
    '&>*': {
      flexGrow: 1,
    },
  },
  sub: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.subtitle2.fontSize,
    padding: theme.spacing(0, 2, 2),
  },
  drag: {
    display: 'flex',
    alignItems: 'center',
    height: '50px',
    overflow: 'hidden',
    background: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
  },
  dragThumb: {
    height: '100%',
    width: '30%',
    background: theme.palette.common.black,
  },
}))

interface InputProps {
  input: EnrichedInput
}

const InputCard = ({ input }: InputProps) => {
  const classes = useStyles()
  const cardRef = useRef<HTMLElement>()

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'input',
    item: input,
    options: {
      dropEffect: 'copy',
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }))

  return (
    <Grid item className={classes.item} xs={12} sm={6} md={4} lg={3} xl={2}>
      <div ref={drag} style={{ position: 'relative' }}>
        <Card className={cn(classes.card, isDragging && 'dragging')} innerRef={cardRef}>
          <div className={classes.thumb}>
            <Thumbnail input={input} />
            <div className={classes.health}>
              <InputHealthIndicator inputId={input.id} inline />
              <div>{getFormattedTransportStreamContent((input?.tsInfo || [])[0])}</div>
            </div>
          </div>
          <CardHeader
            title={
              <Typography component="div" data-test-id="input-title" {...inputOutputColor(input)}>
                {input.name}
              </Typography>
            }
            titleTypographyProps={{ variant: 'subtitle1' }}
            subheader={
              <Typography color={input.adminStatus ? 'primary' : 'secondary'} variant="caption">
                {input.canSubscribe ? 'Full Access' : 'Preview'}
              </Typography>
            }
          />
          {!!input._owner && <div className={classes.sub}>Source: {input._owner.name}</div>}
        </Card>
      </div>
    </Grid>
  )
}

const Inputs = () => {
  const classes = useStyles()
  const [filter, setFilter] = useState('')
  const debouncedFilter = useDebouncedFilter(filter)
  const [pageNumber, setPageNumber] = useState('0')
  const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem(LOCALE_STORAGE_PER_PAGE_KEY) || '10')
  const { width } = useWindowDimensions()
  const hidePerPagePaginationOption = width < 600

  const { inputs, total, loading } = useInputsSelector({
    pageNumber,
    rowsPerPage,
    filter: debouncedFilter || undefined,
  })

  return (
    <div>
      <Toolbar className={classes.toolbar} id="inputs-toolbar">
        <Typography variant="h2">Inputs</Typography>
        <SearchInput
          stretchable
          onChange={input => {
            setPageNumber('0')
            setFilter(input)
          }}
        />
      </Toolbar>
      <Pendable pending={loading} cover id="inputs">
        <Pagination
          total={total}
          page={pageNumber}
          perPage={rowsPerPage}
          changePageCallback={setPageNumber}
          changeRowsPerPageCallback={rows => {
            localStorage.setItem(LOCALE_STORAGE_PER_PAGE_KEY, rows)
            setRowsPerPage(rows)
          }}
          useUrlSearchParams={false}
          id={'output-pagination'}
          labelRowsPerPage={`Items`}
          hidePerPageOption={hidePerPagePaginationOption}
        />
        <Grid container spacing={3} className={classes.container} id="inputs-list">
          {inputs.map(input => (
            <InputCard key={input.id} input={input} />
          ))}
        </Grid>
      </Pendable>
    </div>
  )
}

export default Inputs
