import { IOutputApi } from './api'
import { MOCK_APPLIANCES, MOCK_DELAY_MS, MOCK_GROUPS, MOCK_OUTPUT_LISTS, MOCK_OUTPUTS, MOCK_PORTS } from '../MockData'
import { listResult } from '../../utils'
import { EnrichedOutput, EnrichedOutputWithPorts } from '../nm-types'
import { outputStatus } from 'common/api/v1/helpers'
import {
  Entity,
  ListResult,
  Output,
  OutputFilter,
  OutputOperStatus,
  OutputRecipientList,
  OutputStatus,
} from 'common/api/v1/types'

export class MockOutputApi implements IOutputApi {
  createOutput(output: Output): Promise<Output> {
    return new Promise(resolve => setTimeout(() => resolve(Object.assign(output)), MOCK_DELAY_MS))
  }
  updateOutput(_id: string, output: Output): Promise<Output> {
    return new Promise(resolve => setTimeout(() => resolve(output), MOCK_DELAY_MS))
  }
  removeOutput(): Promise<Entity> {
    const entity = { id: MOCK_OUTPUTS[0].id }
    return new Promise(resolve => setTimeout(() => resolve(entity), MOCK_DELAY_MS))
  }
  removeOutputs(ids: Array<Output['id']>): Promise<{ deleted: Array<Output['id']>; notDeleted: Array<Output['id']> }> {
    return new Promise(resolve => setTimeout(() => resolve({ deleted: ids, notDeleted: [] }), MOCK_DELAY_MS))
  }

  getOutputHealth(): Promise<OutputStatus> {
    return new Promise(resolve =>
      setTimeout(() => resolve(outputStatus(OutputOperStatus.allOk, 'All is ok')), MOCK_DELAY_MS),
    )
  }

  getOutput(): Promise<EnrichedOutputWithPorts> {
    return new Promise(resolve =>
      setTimeout(
        () =>
          resolve({
            ...MOCK_OUTPUTS[0],
            ports: MOCK_OUTPUTS[0].ports.map(port => ({
              ...port,
              _port: { ...MOCK_PORTS[0], _appliance: MOCK_APPLIANCES[0] },
            })),
          }),
        MOCK_DELAY_MS,
      ),
    )
  }

  getBareOutputs(filter: OutputFilter): Promise<ListResult<Output>> {
    void filter
    return new Promise(resolve =>
      setTimeout(
        () =>
          resolve({
            items: MOCK_OUTPUTS,
            total: MOCK_OUTPUTS.length,
          }),
        MOCK_DELAY_MS,
      ),
    )
  }

  getOutputs(): Promise<ListResult<EnrichedOutput>> {
    return new Promise(resolve =>
      setTimeout(
        () =>
          resolve({
            items: MOCK_OUTPUTS.map(output => ({ ...output, _group: MOCK_GROUPS[0] })),
            total: MOCK_OUTPUTS.length,
          }),
        MOCK_DELAY_MS,
      ),
    )
  }
  getOutputsWithLists(): Promise<ListResult<EnrichedOutput | OutputRecipientList>> {
    const arr: Array<EnrichedOutput | OutputRecipientList> = [...MOCK_OUTPUTS, ...MOCK_OUTPUT_LISTS]
    return new Promise(resolve => setTimeout(() => resolve(listResult(arr)), MOCK_DELAY_MS))
  }
  getTotalOutputCount(): Promise<number> {
    return new Promise(resolve => setTimeout(() => resolve(2), MOCK_DELAY_MS))
  }
  getServiceOverviewOutputs(): Promise<ListResult<Output>> {
    return new Promise(resolve => setTimeout(() => resolve(listResult(MOCK_OUTPUTS)), MOCK_DELAY_MS))
  }
  enableOutputs(): Promise<EnrichedOutput[]> {
    return new Promise(resolve => setTimeout(() => resolve(MOCK_OUTPUTS), MOCK_DELAY_MS))
  }
  disableOutputs(): Promise<EnrichedOutput[]> {
    return new Promise(resolve => setTimeout(() => resolve(MOCK_OUTPUTS), MOCK_DELAY_MS))
  }
  setInputOfOutput(outputId: Output['id'], inputId: Output['input']): Promise<Output> {
    const output = MOCK_OUTPUTS.find(({ id }) => id === outputId)!
    return new Promise(resolve => setTimeout(() => resolve({ ...output, input: inputId }), MOCK_DELAY_MS))
  }
}
