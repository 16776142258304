import { createSlice } from '@reduxjs/toolkit'
import { EnhancedInputConfig } from 'common/api/v1/internal'
import { readGraph } from '../actions/graphActions'

interface State {
  graph?: EnhancedInputConfig
  loading: boolean
}

const initialState: State = {
  graph: undefined,
  loading: false,
}

const graphSlice = createSlice({
  name: 'graph',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(readGraph.pending, state => {
        state.loading = true
      })
      .addCase(readGraph.fulfilled, (state, { payload }) => {
        state.loading = false
        state.graph = payload
      })
      .addCase(readGraph.rejected, state => {
        state.loading = false
      })
  },
})

export default graphSlice.reducer
