import { useState } from 'react'
import cn from 'classnames'
import { FormikProps } from 'formik'
import { get } from 'lodash'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import { Output, OutputRedundancyMode } from 'common/api/v1/types'
import { Paper, Select, useStyles } from '../../common/Form'
import Thumbnail from '../../common/Thumbnail'
import DataSet from '../../common/DataSet'
import { Link } from '../../common/Link'
import { SelectInputDialog } from '../SelectInputDialog'
import routes from '../../../utils/routes'
import { isEditableGroup, useConfirmationDialog, useUser } from '../../../utils'
import { EnrichedOutputWithEnrichedPorts } from './index'
import { EnrichedInput } from '../../../api/nm-types'
import { getFormattedTransportStreamContent } from 'common/api/v1/helpers'
import { isCoreNode } from '../../common/Interface/Base'
import { inputRedundancy } from 'common/utils'

const useOwnStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
  },
  thumb: {
    width: theme.spacing(40),
    marginRight: theme.spacing(2),
  },
}))

interface InputPickerProps {
  /// Callback invoked when the user selects an input. Return true to proceed with the selection, or false to abort the selection.
  form: FormikProps<EnrichedOutputWithEnrichedPorts>
  selectedServiceIds?: number[]
}

const InputPicker = ({ form }: InputPickerProps) => {
  const classes = useStyles()
  const ownClasses = useOwnStyles()
  const [showSelectInputDialog, setShowSelectInputDialog] = useState(false)
  const user = useUser()
  const setConfirm = useConfirmationDialog()

  const onInputSelect = (output: Output, input: EnrichedInput) => {
    const didSelectDifferentInput = form.values.input !== input.id
    if (!didSelectDifferentInput) return
    const action = () => {
      form.setFieldValue('input', input.id)
      form.setFieldValue('_input', input)
    }
    const didSelectMultiApplianceInput = (input.appliances ?? []).length > 1
    const isRedundantOutput = output.redundancyMode != OutputRedundancyMode.none
    const isRedundantInput = inputRedundancy(input)
    if (didSelectMultiApplianceInput) {
      setConfirm(
        action,
        'You have selected a multi-appliance input - the form will be reset with the same appliances as the selected input. Proceed?',
      )
    } else if (isRedundantOutput && !isRedundantInput) {
      setConfirm(action, 'You have selected a non-redundant input - output redundancy will be disabled. Proceed?')
    } else {
      action()
    }
  }
  const onClearInput = () => {
    form.setFieldValue('input', undefined)
    form.setFieldValue('_input', undefined)
  }
  const input = get(form, 'values._input') as EnrichedInput | undefined
  const inputIsRedundant = input && input.ports && input.ports[0] && input.ports[0].copies && input.ports[0].copies > 1

  const format = getFormattedTransportStreamContent((input?.tsInfo || [])[0])
  return (
    <Paper
      classes={cn(classes.paper, 'outlined')}
      title="Input"
      collapsible
      actionsPane={[
        <Button
          key="select-input"
          id="select-input"
          variant="contained"
          color="secondary"
          onClick={() => setShowSelectInputDialog(true)}
        >
          Switch input
        </Button>,
      ]}
    >
      <Grid item xs={12}>
        {!!form.values._input && (
          <div className={ownClasses.container}>
            <div className={ownClasses.thumb}>
              <Thumbnail input={form.values._input} />
            </div>
            <DataSet
              values={{
                Name: (
                  <Link
                    to={routes.inputsUpdate({ id: form.values._input?.id })}
                    underline="hover"
                    available={!!form.values._input?.canSubscribe}
                  >
                    <span data-test-id={'selected-input-name'}>{form.values._input.name}</span>
                  </Link>
                ),
                Owner: (
                  <Link
                    to={routes.groupsUpdate({ id: form.values._input?._owner?.id })}
                    underline="hover"
                    available={
                      !!form.values._input?._owner?.id && isEditableGroup(form.values._input?._owner?.id, user)
                    }
                  >
                    {form.values._input?._owner?.name}
                  </Link>
                ),
                Format: format,
              }}
            />
          </div>
        )}

        {inputIsRedundant && !isCoreNode(form.values) && (
          <Select
            tooltip="Failover: a secondary redundant path will be activated if the primary one becomes unhealthy."
            name={'redundancyMode'}
            label="Redundancy mode"
            required
            options={Object.entries(OutputRedundancyMode)
              .filter(([, value]) => typeof value == 'number')
              .map(([name, value]) => ({ name, value }))}
            newLine
          />
        )}
      </Grid>
      <SelectInputDialog
        show={showSelectInputDialog}
        output={form.values}
        closeDialog={() => setShowSelectInputDialog(false)}
        onInputSelect={onInputSelect}
        onClearInput={onClearInput}
      />
    </Paper>
  )
}

export default InputPicker
