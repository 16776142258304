import { createSlice } from '@reduxjs/toolkit'

import { UsageReport } from 'common/api/v1/types'
import { readUsage } from '../actions/usageActions'

interface State {
  usage?: UsageReport
  loading: boolean
  error: boolean
}
export const initialStateUsage: State = {
  usage: undefined,
  loading: false,
  error: false,
}

export const usageSlice = createSlice({
  name: 'usage',
  initialState: initialStateUsage,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(readUsage.pending, (state): State => ({ ...state, loading: true, error: false, usage: undefined }))
      .addCase(
        readUsage.fulfilled,
        (state, { payload: usage }): State => ({ ...state, loading: false, error: false, usage }),
      )
      .addCase(readUsage.rejected, (state): State => ({ ...state, loading: false, error: true, usage: undefined }))
  },
})

export default usageSlice.reducer
