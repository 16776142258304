import { useEffect, useState } from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'

import { AlarmSeverityLevels } from 'common/api/v1/types'
import Wrapper from '../../common/Wrapper'
import Alarms from './Alarms'
import { usePageParams } from '../../../utils'

const PADDING = 2

export enum AlarmSeverityPicker {
  mostSevereWithAlarms = 'mostSevereWithAlarms',
}

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    justifyContent: 'flex-end',
    textTransform: 'uppercase',
  },
  paper: {
    padding: theme.spacing(PADDING),
  },
}))

const AlarmsTable = () => {
  const classes = useStyles()
  const [{ applianceName }] = usePageParams()
  const [{ alarmTab = AlarmSeverityPicker.mostSevereWithAlarms }] = usePageParams()
  const [tab, setTab] = useState<AlarmSeverityLevels | AlarmSeverityPicker>(
    alarmTab as AlarmSeverityLevels | AlarmSeverityPicker,
  )
  useEffect(() => {
    setTab(alarmTab as AlarmSeverityLevels | AlarmSeverityPicker)
  }, [alarmTab])

  return (
    <Wrapper name="Alarms" entityName={applianceName}>
      <Paper className={classes.paper}>
        <Alarms sideMargin={PADDING} tab={tab} showTexts />
      </Paper>
    </Wrapper>
  )
}

export default AlarmsTable
