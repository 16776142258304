import { Route } from 'react-router-dom'

import { Graph } from './Graph'
import routes from '../../utils/routes'

export const Graphs = () => (
  <>
    <Route exact path={routes.graph.route} component={Graph} />
  </>
)
