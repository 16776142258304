import { useDispatch } from 'react-redux'

import { makeStyles, Theme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import PersonIcon from '@material-ui/icons/Person'

import Logo from '../common/Logo'
import { logoutAndNavigateToMainPage, stopImpersonation } from '../../redux/actions/userActions'
import { useUser } from '../../utils'
import { AppDispatch } from 'src/store'

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    justifyContent: 'space-between',
  },
  logo: {
    maxWidth: '50%',
  },
  right: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    marginLeft: theme.spacing(3),
    fontSize: theme.typography.body2.fontSize,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  buttonText: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}))

const TopBar = () => {
  const classes = useStyles()
  const user = useUser()
  const dispatch = useDispatch<AppDispatch>()
  const logoutUserAction = () => void dispatch(logoutAndNavigateToMainPage())
  const stopImpersonationAction = () => void dispatch(stopImpersonation())

  return (
    <AppBar position="static">
      <Toolbar className={classes.toolbar} id="stream-manager-bar">
        <Logo className={classes.logo} />
        <div className={classes.right}>
          <Typography variant="body2" data-test-id="current-user">
            {user.username}
          </Typography>
          <Typography variant="body2">
            {user.impersonatedBy && (
              <span style={{ opacity: 0.5, marginLeft: 4 }}>
                (by <span data-test-id="current-impersonator">{user.impersonatedBy.username}</span>)
              </span>
            )}
          </Typography>
          {user.impersonatedBy ? (
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.button}
              onClick={stopImpersonationAction}
              id="stop-impersonation"
            >
              <span className={classes.buttonText}>Switch to admin</span>
              <PersonIcon />
            </Typography>
          ) : (
            <Typography variant="body2" color="textSecondary" className={classes.button} onClick={logoutUserAction}>
              <span className={classes.buttonText}>Log out</span>
              <PowerSettingsNewIcon />
            </Typography>
          )}
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
