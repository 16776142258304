import { toggleSidebarMenu, setSearchVisibility } from '../actions/uiActions'
import { logoutUser } from '../actions/userActions'
import { createSlice } from '@reduxjs/toolkit'

interface State {
  isSearchable: boolean
  open: boolean
}
export const initialStateUi: State = {
  isSearchable: false,
  open: true,
}

const uiSlice = createSlice({
  name: 'ui',
  initialState: initialStateUi,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(
        toggleSidebarMenu,
        (state): State => ({
          ...state,
          open: !state.open,
        }),
      )
      .addCase(setSearchVisibility, (state, { payload: isSearchable }): State => ({ ...state, isSearchable }))
      .addCase(logoutUser.fulfilled, (): State => initialStateUi)
  },
})

export default uiSlice.reducer
