import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { Form, Formik, FormikProps } from 'formik'
import routes from '../../utils/routes'
import Grid from '@material-ui/core/Grid'

import { IpMappingRead, IpMappingWrite } from 'common/api/v1/types'
import { Api, AppDispatch, GlobalState } from '../../store'
import { ButtonsPane, Paper, SafeRouting, useStyles, TextInput, Autocomplete } from '../common/Form'
import Pendable from '../common/Pendable'
import Wrapper from '../common/Wrapper'
import {
  clearEditMapping,
  createIpMapping,
  getIpMapping,
  updateIpMapping,
  removeIpMapping,
  writeModelFromReadModel,
} from '../../redux/actions/addressMappingActions'
import { useRegionsSelector } from '../../utils'

const { regionApi } = Api

const getInitialState = (ipMapping?: IpMappingRead, chosenRegion?: { id: string; name: string }): IpMappingRead => {
  const region = chosenRegion ? chosenRegion : { id: '', name: '' }
  return ipMapping || { privateIp: '', publicIp: '', internalIp: '', interRegionIp: '', region }
}

const MappingForm = (formik: FormikProps<IpMappingRead>, history: RouteComponentProps['history']) => {
  const classes = useStyles()
  const dispatch = useDispatch<AppDispatch>()
  const { ipMapping, loading } = useSelector(
    ({ addressMappingReducer }: GlobalState) => addressMappingReducer,
    shallowEqual,
  )

  const isEditing = !!ipMapping
  const identity = ipMapping?.nic ? `(${ipMapping?.nic.name} on ${ipMapping?.appliance?.name})` : ''

  useEffect(() => {
    formik.setSubmitting(loading)
  }, [loading])

  return (
    <Grid container>
      <Grid item xs={12}>
        <SafeRouting enabled={formik.dirty && !formik.isSubmitting} />
        <Form id="user-form" translate="no" noValidate>
          <Paper
            title={
              formik.values.privateIp
                ? `Address mappings for ${formik.values.privateIp} ${identity}`
                : 'Create address mapping'
            }
            classes={classes.paper}
          >
            <Autocomplete<{ id: string; name: string }>
              name="region"
              formik={formik}
              required={true}
              disabled={isEditing}
              api={regionApi.getRegions.bind(regionApi)}
              defaultOption={formik.values.region}
              getOptionLabel={option => option.name}
              getOptionValue={option => option}
              optionComparator={(o1, o2) => o1.id == o2.id}
            />
            <TextInput
              name="privateIp"
              required={true}
              validators={{
                ip: {},
              }}
              label="Private address"
              disabled={isEditing}
              tooltip="Private address of the interface (used if no other address is configured)."
            />
            <TextInput
              name="publicIp"
              label="Public address"
              required={true}
              validators={{ ip: {} }}
              tooltip="Used by appliances connecting from outside the cluster. Can be a NATed address or set to the private address."
            />
            <TextInput
              name="internalIp"
              label="Internal address"
              validators={{ ip: {} }}
              tooltip="Used by thumb nodes in the same region."
            />
            <TextInput
              name="interRegionIp"
              label="Inter region address"
              validators={{ ip: {} }}
              tooltip="Used by video nodes in other regions."
            />
          </Paper>

          <ButtonsPane
            main={{
              Cancel: {
                onClick: () => {
                  history.push(routes.addressMappings())
                },
              },
              Save: { savingState: false, primary: true, type: 'submit' },
            }}
            secondary={
              formik.values.privateIp && isEditing
                ? {
                    'Remove mapping': {
                      onClick: () => {
                        dispatch(removeIpMapping(writeModelFromReadModel(formik.values)))
                      },
                    },
                  }
                : undefined
            }
          />
        </Form>
      </Grid>
    </Grid>
  )
}

export const Edit = ({ history, match }: RouteComponentProps<{ privateIp?: string; region?: string }>) => {
  const dispatch = useDispatch<AppDispatch>()
  const { ipMapping } = useSelector(({ addressMappingReducer }: GlobalState) => addressMappingReducer, shallowEqual)
  const { regions, loading } = useRegionsSelector({ rowsPerPage: '100', pageNumber: '0' })
  const chosenRegion = ipMapping?.region || (regions.length == 1 ? regions[0] : undefined)

  const onSubmit = (editedIpMapping: IpMappingWrite) => {
    if (ipMapping) dispatch(updateIpMapping(editedIpMapping))
    else dispatch(createIpMapping(editedIpMapping))
  }

  useEffect(() => {
    const { privateIp, region } = match.params
    privateIp && region && dispatch(getIpMapping({ privateIp, region }))
    return () => {
      dispatch(clearEditMapping())
    }
  }, [])

  return (
    <Wrapper name="Address mappings" entityName={ipMapping ? ipMapping.privateIp : 'New'}>
      <Grid container spacing={0}>
        <Pendable pending={loading || (!!match.params.privateIp && !ipMapping)}>
          <Formik
            onSubmit={values => {
              onSubmit(writeModelFromReadModel(values))
            }}
            initialValues={getInitialState(ipMapping, chosenRegion)}
          >
            {formik => MappingForm(formik, history)}
          </Formik>
        </Pendable>
      </Grid>
    </Wrapper>
  )
}
