import Button from '@material-ui/core/Button'

import { Network, NetworkSortableField, Role } from 'common/api/v1/types'

import { usePageParamsFilteredSelector, useUser, useNetworksSelector } from '../../utils'
import Table, { MoveToEntity, TableConfig } from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import Wrapper from '../common/Wrapper'
import { Link } from '../common/Link'
import routes from '../../utils/routes'
import { NetworkIndicator } from '../common/Indicator'

export const NetworksList = () => {
  const { networks, total, loading } = usePageParamsFilteredSelector(useNetworksSelector)
  const user = useUser()

  const tableConfig: TableConfig<Network, NetworkSortableField> = [
    {
      title: 'name',
      getValue: ({ name }) => name,
      sorting: { byParameter: NetworkSortableField.name },
    },
    {
      title: 'enabled',
      getValue: ({ enabled }) => <NetworkIndicator enabled={enabled} />,
    },

    {
      getValue: ({ id }) => <MoveToEntity url={routes.networkUpdate({ id })} entityName="network" />,
      props: { align: 'right' },
    },
  ]
  if (!user || user.role !== Role.super) return null
  return (
    <Wrapper name="Networks">
      <Table<Network>
        emptyMessageComponent={
          <MissingContent message="No networks available" buttonText="Add network" url={routes.networkNew()} />
        }
        config={tableConfig}
        data={networks}
        id="networks-table"
        pending={loading}
        actions={
          user.role === Role.super
            ? [
                <Link key="addGroup" id="groups-table-create-button" to={routes.networkNew()}>
                  <Button variant="contained" color="primary">
                    Add network
                  </Button>
                </Link>,
              ]
            : []
        }
        pagination={{ total, useUrlSearchParams: true }}
      />
    </Wrapper>
  )
}
