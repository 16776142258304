import { Route } from 'react-router-dom'

import routes from '../../utils/routes'
import { List } from './List'
import { Edit } from './Edit'

export const Nodes = () => (
  <>
    <Route exact path={routes.kubernetesNodes.route} component={List} />
    <Route exact path={routes.kubernetesNodesUpdate.route} component={Edit} />
  </>
)
