import { createSlice } from '@reduxjs/toolkit'

import { ActiveService } from 'common/api/v1/types'
import { getActiveServices, setSelectedActiveServices, clearActiveServices } from '../actions/activeServicesActions'
import { logoutUser } from '../actions/userActions'

interface State {
  activeServices: Array<ActiveService>
  selected: Array<ActiveService>
  total: number
  loading: boolean
}
export const initialStateActiveServices: State = {
  activeServices: [],
  selected: [],
  total: 0,
  loading: false,
}

const activeServicesSlice = createSlice({
  name: 'activeServices',
  initialState: initialStateActiveServices,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getActiveServices.pending, (state): State => ({ ...state, loading: true }))
      .addCase(
        getActiveServices.fulfilled,
        (state, { payload: { items: activeServices, total } }): State => ({
          ...state,
          loading: false,
          activeServices,
          total,
        }),
      )
      .addCase(getActiveServices.rejected, (state): State => ({ ...state, loading: false }))
      .addCase(setSelectedActiveServices, (state, { payload: selected }): State => ({ ...state, selected }))
      .addCase(clearActiveServices, (state): State => ({ ...state, activeServices: [] }))
      .addCase(logoutUser.fulfilled, (): State => initialStateActiveServices)
  },
})

export default activeServicesSlice.reducer
