import { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import HlsPlayer from '../../hlsplayer'
import { getLogger } from '../../logger'
const log = getLogger('video')

const useStyles = makeStyles({
  video: {
    width: '100%',
  },
})

interface VideoProps {
  url: string
  poster?: string
  autoplay?: boolean
}

export const Video = (props: VideoProps) => {
  const classes = useStyles()
  const videoRef = useRef<HTMLVideoElement>(null)
  const [player, setPlayer] = useState<HlsPlayer>()

  const { url, poster, autoplay } = props

  useEffect(() => {
    const videoEl = videoRef.current
    if (!videoEl) return

    if (HlsPlayer.isSupported() && !player) {
      setPlayer(new HlsPlayer(videoEl))
    }

    player?.load(url).then(() => {
      log(`Video url ${url} loaded`)
      if (autoplay) {
        videoEl.play()
      }
    })

    return () => {
      player?.destroy()
    }
  }, [url, player, videoRef?.current])

  return (
    <video
      className={classes.video}
      ref={videoRef}
      poster={poster}
      controls
      autoPlay={autoplay}
      muted={autoplay}
    ></video>
  )
}
