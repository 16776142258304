import { get } from 'lodash/fp'

import {
  removeInput,
  clearInputs,
  removeInputs,
  draftInputs,
  updateInputRecipients,
  updateInputDistribution,
  getInputs,
  getInput,
  clearInput,
  createInput,
  updateInput,
  Draft,
  registerInputObserver,
  unregisterInputObserver,
  getBareInputs,
  enableInputs,
  disableInputs,
  rerouteInput,
} from '../actions/inputsActions'
import { logoutUser } from '../actions/userActions'
import { isOneOf } from '../actions'
import { EnrichedInput, EnrichedInputWithPorts } from '../../api/nm-types'
import { createSlice } from '@reduxjs/toolkit'
import { Input } from 'common/api/v1/types'

interface State {
  inputs: Array<EnrichedInput>
  total: number
  input?: EnrichedInputWithPorts
  loading: boolean
  saving?: boolean
  rerouting: boolean
  dialogSaving: boolean
  draft: Draft
  formErrors?: Array<{ name: string; reason: string }>
  inputsToObserve: { [inputId: string]: number }
}

const initialStateInputs: State = {
  inputs: [],
  total: 0,
  input: undefined,
  loading: false,
  rerouting: false,
  dialogSaving: false,
  draft: { inputs: [] },
  inputsToObserve: {},
}

const inputsSlice = createSlice({
  name: 'inputs',
  initialState: initialStateInputs,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(
        getInputs.fulfilled,
        (state, { payload: { items: inputs, total } }): State => ({
          ...state,
          loading: false,
          inputs,
          total,
        }),
      )
      .addCase(getInput.fulfilled, (state, { payload: input }): State => ({ ...state, input }))
      .addCase(clearInput, (state): State => ({ ...state, input: undefined, formErrors: undefined }))
      .addCase(
        draftInputs,
        (state, { payload: draft }): State => ({
          ...state,
          draft,
        }),
      )
      .addCase(clearInputs, (state): State => ({ ...state, inputs: [], draft: { inputs: [] } }))
      .addCase(logoutUser.fulfilled, (): State => initialStateInputs)
      .addCase(
        registerInputObserver.pending,
        (
          state,
          {
            meta: {
              arg: { inputId },
            },
          },
        ): State => ({
          ...state,
          inputsToObserve: {
            ...state.inputsToObserve,
            [inputId]: (state.inputsToObserve[inputId] || 0) + 1,
          },
        }),
      )
      .addCase(
        unregisterInputObserver,
        (state, { payload: { inputId } }): State => {
          const newObservers = { ...state.inputsToObserve }
          const numberOfObservers = state.inputsToObserve[inputId] - 1
          if (numberOfObservers < 1) {
            delete newObservers[inputId]
          } else {
            newObservers[inputId] = numberOfObservers
          }
          return { ...state, inputsToObserve: newObservers }
        },
      )
      .addCase(
        getBareInputs.fulfilled,
        (state, { payload: bareInputs }): State => {
          const updateInputMetricsAndTsInfo = <T extends Input>(enrichedInput: T): T => {
            const bareInput = bareInputs.items.find(i => i.id == enrichedInput.id)
            return bareInput
              ? { ...enrichedInput, metrics: bareInput.metrics, health: bareInput.health, tsInfo: bareInput.tsInfo }
              : enrichedInput
          }

          return {
            ...state,
            inputs: state.inputs.map(updateInputMetricsAndTsInfo),
            input: state.input ? updateInputMetricsAndTsInfo(state.input) : undefined,
          }
        },
      )
      .addCase(
        enableInputs.fulfilled,
        (state, { payload: enableInputs }): State => {
          return {
            ...state,
            inputs: state.inputs.map(input => {
              const enabledInput = enableInputs.find(i => i.id === input.id)
              if (enabledInput) {
                return { ...input, adminStatus: enabledInput.adminStatus }
              }
              return input
            }),
          }
        },
      )
      .addCase(
        disableInputs.fulfilled,
        (state, { payload: disableInputs }): State => {
          return {
            ...state,
            inputs: state.inputs.map(input => {
              const disabledInput = disableInputs.find(i => i.id === input.id)
              if (disabledInput) {
                return { ...input, adminStatus: disabledInput.adminStatus }
              }
              return input
            }),
          }
        },
      )
      .addMatcher(
        isOneOf([
          updateInputDistribution.fulfilled,
          updateInputDistribution.rejected,
          updateInputRecipients.fulfilled,
          updateInputRecipients.rejected,
        ]),
        (state): State => ({ ...state, dialogSaving: false }),
      )
      .addMatcher(
        isOneOf([getInputs.pending, removeInput.pending, removeInputs.pending]),
        (state): State => ({ ...state, loading: true, formErrors: undefined }),
      )
      .addMatcher(
        isOneOf([createInput.pending, updateInput.pending]),
        (state): State => ({ ...state, saving: true, formErrors: undefined }),
      )
      .addMatcher(
        isOneOf([
          getInputs.rejected,
          removeInput.rejected,
          removeInputs.rejected,
          removeInput.fulfilled,
          removeInputs.fulfilled,
        ]),
        (state): State => ({ ...state, loading: false }),
      )
      .addMatcher(
        isOneOf([updateInputDistribution.pending, updateInputRecipients.pending]),
        (state): State => ({ ...state, dialogSaving: true }),
      )
      .addMatcher(isOneOf([createInput.pending, updateInput.pending]), state => {
        state.saving = true
        state.formErrors = undefined
      })
      .addMatcher(isOneOf([rerouteInput.pending]), state => {
        state.rerouting = true
      })
      .addMatcher(isOneOf([rerouteInput.fulfilled, rerouteInput.rejected]), state => {
        state.rerouting = false
      })
      .addMatcher(isOneOf([createInput.fulfilled, updateInput.fulfilled]), state => {
        state.saving = undefined
      })
      .addMatcher(isOneOf([createInput.rejected, updateInput.rejected]), (state, payload) => {
        state.saving = false
        state.formErrors = get('errorInfo.origin.data.detail', payload)
      })
  },
})

export default inputsSlice.reducer
