import { parseISO } from 'date-fns'
import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { DatePicker, DatePickerProps } from '@material-ui/pickers/DatePicker'
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider'
import DateRange from '@material-ui/icons/DateRange'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import GetAppIcon from '@material-ui/icons/GetApp'
import { api } from '../../../utils/routes'
import { usePageParams } from '../../../utils'
import { endOfDayMaxNowUTC, startOfDayUTC, startOfMonthUTC } from 'common/api/v1/helpers'
import Select from '../../common/Filters/Select'
import { FilterType } from '../../common/Filters'

const useStyles = makeStyles((theme: Theme) => ({
  picker: {
    marginRight: theme.spacing(2),
  },
  input: {
    cursor: 'pointer',
  },
  buttonContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  buttonText: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  icon: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
}))

const Picker = (props: DatePickerProps) => {
  const classes = useStyles()
  return (
    <DatePicker
      autoOk
      disableFuture
      disableToolbar
      variant="inline"
      format="yyyy MMM dd"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <DateRange />
          </InputAdornment>
        ),
        classes: { root: classes.input, input: classes.input },
      }}
      {...props}
    />
  )
}

enum DateField {
  'startDate' = 'startDate',
  'endDate' = 'endDate',
}
const getRequestParams = (from: Date, to: Date) => ({
  [DateField.startDate]: from,
  [DateField.endDate]: to,
})

const UsageFilter = () => {
  const classes = useStyles()
  const [pageParams, setPageParams] = usePageParams()
  const { startDate, endDate, type } = pageParams
  const from = startDate ? parseISO(startDate) : startOfMonthUTC(new Date())
  const to = endDate ? parseISO(endDate) : new Date()

  const setDate = (dateField: DateField, value: Date | null) => {
    if (!value) return
    if (dateField === DateField.endDate) {
      value = endOfDayMaxNowUTC(value)
    } else {
      value = startOfDayUTC(value)
    }
    const params = {
      [DateField.startDate]: dateField === DateField.startDate ? value.toISOString() : startDate,
      [DateField.endDate]: dateField === DateField.endDate ? value.toISOString() : endDate,
      pageNumber: '0',
    }
    setPageParams(params)
  }

  return (
    <>
      <Grid item>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Picker
            label="From date:"
            value={startOfDayUTC(from)}
            onChange={value => setDate(DateField.startDate, value)}
            className={classes.picker}
            maxDate={to}
          />
          <Picker
            label="To date:"
            value={startOfDayUTC(to)}
            onChange={value => setDate(DateField.endDate, value)}
            className={classes.picker}
            minDate={from}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item>
        <Select
          type={FilterType.select}
          paramName={'type'}
          label={'Type'}
          options={[
            { name: 'Ingress', value: 'ingress' },
            { name: 'Egress', value: 'egress' },
          ]}
        />
      </Grid>
      <Grid item className={classes.buttonContainer}>
        <Link
          underline="none"
          href={api.usage({
            ...getRequestParams(from, to),
            format: 'csv',
            type: type as 'ingress' | 'egress' | undefined,
            rowsPerPage: '1', // ignored when using format csv
            pageNumber: '0', // ignored when using format csv
          })}
        >
          <Button variant="outlined">
            <span className={classes.buttonText}>Download</span>
            <GetAppIcon className={classes.icon} />
          </Button>
        </Link>
      </Grid>
    </>
  )
}

export default UsageFilter
