import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import SettingsIcon from '@material-ui/icons/Settings'

import { Link } from '../Link'

interface MoveToEntityProps {
  disabled?: boolean
  url: string
  entityName: string
  dataTestId?: string
}
/**
 * Common table link to entity
 * @param disabled - should it be disabled and lead anywhere
 * @param url - url to lead to
 * @param entityName - entity name ('input', 'output' etc.)
 */
export const MoveToEntity = ({ disabled, url, entityName, dataTestId }: MoveToEntityProps) =>
  disabled ? (
    <IconButton edge="end" aria-label="Edit" disabled data-test-id={dataTestId}>
      <SettingsIcon />
    </IconButton>
  ) : (
    <Tooltip title={`Edit ${entityName}`} placement="top">
      <span>
        <Link to={url}>
          <IconButton edge="end" aria-label="Edit" data-test-id={dataTestId}>
            <SettingsIcon />
          </IconButton>
        </Link>
      </span>
    </Tooltip>
  )
