import { useEffect } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Paper } from '../../common/Form'
import Wrapper from '../../common/Wrapper'
import Filter from './Filter'
import Total from './Total'
import Details from './Details'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { parseISO } from 'date-fns'
import { startOfMonthUTC } from 'common/api/v1/helpers'
import { readUsage } from '../../../redux/actions/usageActions'
import { usePageParams, withDefaultPagination } from '../../../utils'
import { AppDispatch, GlobalState } from '../../../store'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(1, 2, 3),
    marginBottom: theme.spacing(2),
    overflowX: 'auto',
  },
}))

const Report = () => {
  const classes = useStyles()
  const dispatch = useDispatch<AppDispatch>()
  const [pageParams] = usePageParams()
  const { startDate, endDate, rowsPerPage, pageNumber, type } = pageParams
  const { usage, loading, error } = useSelector(({ usageReducer }: GlobalState) => usageReducer, shallowEqual)

  useEffect(() => {
    const params = {
      ...withDefaultPagination(pageParams),
      startDate: startDate ? parseISO(startDate) : startOfMonthUTC(new Date()),
      endDate: endDate ? parseISO(endDate) : new Date(),
      type: type as 'ingress' | 'egress' | undefined,
    }
    dispatch(readUsage(params))
  }, [startDate, endDate, type, rowsPerPage, pageNumber])

  return (
    <Wrapper name="Usage">
      <Paper classes={classes.paper} title="Filter">
        <Filter />
      </Paper>
      {!error ? (
        <>
          <Paper classes={classes.paper} title="Total">
            <Total usage={usage} loading={loading} />
          </Paper>
          <Paper classes={classes.paper} title="Details">
            <Details usage={usage} loading={loading} />
          </Paper>
        </>
      ) : (
        'Failed to load usage data'
      )}
    </Wrapper>
  )
}

export default Report
