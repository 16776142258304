import React from 'react'

import { GridItem, Paper, useStyles } from '../../common/Form'
import DataSet from '../../common/DataSet'
import { ExternalLink } from '../../common/Link'
import { KubernetesNode } from 'common/api/v1/types'
export interface MetaProps {
  node: KubernetesNode
}

const Meta: React.FunctionComponent<MetaProps> = ({ node }) => {
  const classes = useStyles()
  const region = { external: false }

  const metrics = !region.external
    ? {
        Metrics: (
          <ExternalLink
            underline="always"
            href={`/grafana/d/000000127/system-resource-usage?orgId=1&refresh=1m&from=now-15m&to=now&var-datasource=InfluxDB&var-inter=10s&var-server=${node.name}&var-mountpoint=All&var-cpu=All&var-disk=All&var-netif=All`}
          >
            Resource Usage
          </ExternalLink>
        ),
      }
    : {}
  const meta = {
    Name: node.name,
    Region: node.region.name,
    Status: node.status,
    'Internal IP': node.internalIP,
    'External IP': node.externalIP,
    'Kubelet version': node.kubeletVersion,
    ...metrics,
  }

  return (
    <Paper title="Meta data" classes={classes.paper} collapsible actionsPane={[]}>
      <GridItem lg={12} xl={12}>
        <DataSet values={meta} />
      </GridItem>
      {/* {linkIsShown && <Divider style={{ width: '100%' }} />} */}
    </Paper>
  )
}

export default Meta
