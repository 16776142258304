import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3, 2),
    margin: theme.spacing(2, 0),
    width: '100%',
    '&.outlined': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.background.paper}`,
      '&.error': {
        borderColor: theme.palette.error.main,
      },
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'flex-end',
  },
  button: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    '.leftButtonContainer &': {
      marginLeft: 0,
      marginRight: theme.spacing(1),
    },
  },
}))
