import { createContext, useState, FC, ReactNode } from 'react'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    'padding-left': theme.spacing(2),
    'padding-right': theme.spacing(2),
    'padding-top': theme.spacing(0),
    'padding-bottom': theme.spacing(2),
  },
}))

interface ContentDialogStateData {
  isOpen: boolean
  comp?: ReactNode
  title?: string
}

export interface ContentDialogData {
  state: ContentDialogStateData
  actions: {
    handleOpen: (comp: ReactNode, title?: string) => void
    handleClose: () => void
  }
}

export const defaultContextValue: ContentDialogData = {
  state: {
    isOpen: false,
    comp: undefined,
    title: '',
  },
  actions: {
    handleOpen: () => undefined,
    handleClose: () => undefined,
  },
}

export const ContentDialogContext = createContext<ContentDialogData>(defaultContextValue)

export const ContentDialogProvider: FC = ({ children }) => {
  const classes = useStyles()
  const defaultState = defaultContextValue.state

  const [state, setState] = useState<ContentDialogStateData>(defaultState)

  const handleOpen = (comp: ReactNode, title?: string) => {
    setState(s => ({ ...s, isOpen: true, comp, title }))
  }

  const handleClose = () => {
    setState(s => ({ ...s, isOpen: false }))
  }

  return (
    <ContentDialogContext.Provider
      value={{
        state: state,
        actions: {
          handleOpen,
          handleClose,
        },
      }}
    >
      {children}
      {state.isOpen && (
        <Dialog
          open={state.isOpen}
          onClose={(_event, reason) => {
            if (reason === 'escapeKeyDown') {
              handleClose()
            }
          }}
        >
          <MuiDialogTitle disableTypography className={classes.title}>
            <Typography variant="h4">{state.title}</Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <div className={classes.content}>{state.comp}</div>
        </Dialog>
      )}
    </ContentDialogContext.Provider>
  )
}
