import { MOCK_NETWORKS, resolveMock } from '../MockData'
import { Network } from 'common/api/v1/types'
import * as uuid from 'uuid'
import { INetworksApi } from './api'

export class MockNetworksApi implements INetworksApi {
  createNetwork(network: Omit<Network, 'id'>) {
    return resolveMock({ id: uuid.v4(), ...network })
  }
  getNetwork(id: Network['id']) {
    return resolveMock(MOCK_NETWORKS.find(n => n.id === id) || MOCK_NETWORKS[0])
  }
  getNetworks() {
    return resolveMock({ total: MOCK_NETWORKS.length, items: MOCK_NETWORKS })
  }
  removeNetwork() {
    return resolveMock(MOCK_NETWORKS[0])
  }
  updateNetwork(id: Network['id'], network: Omit<Network, 'id'>) {
    return resolveMock({ ...network, id })
  }
}
