import { ListResult, Region, RegionFilter, ScaleOperation, ScaleStatus } from 'common/api/v1/types'
import { EdgeClient } from '../../../../common/src/generated/edgeClient'
import { PaginatedRequestParams, singleSortQueryFromPaginatedRequestParams } from '../nm-types'

export interface RegionDetails {
  region: Region
  videoScale: ScaleStatus
  thumbScale: ScaleStatus
}

export interface IRegionApi {
  getRegions(params: PaginatedRequestParams): Promise<ListResult<Region>>
  getRegionDetailsById(id: string): Promise<RegionDetails>
  setVideoScale(regionId: string, count: number): Promise<ScaleOperation>
  setThumbScale(regionId: string, count: number): Promise<ScaleOperation>
}

export class RegionApi implements IRegionApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  getRegions({ filter: searchName, ...params }: PaginatedRequestParams): Promise<ListResult<Region>> {
    const filter: RegionFilter = { searchName }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    return this.edgeClient.listRegions(query)
  }

  async getRegionDetailsById(id: string): Promise<RegionDetails> {
    const region = await this.edgeClient.getRegion(id)
    const videoScale = await this.edgeClient.getRegionVideoScale(id)
    const thumbScale = await this.edgeClient.getRegionThumbScale(id)

    return {
      region,
      videoScale,
      thumbScale,
    }
  }

  setVideoScale(regionId: string, count: number): Promise<ScaleOperation> {
    return this.edgeClient.setRegionVideoScale(regionId, { count, waitSeconds: 1 })
  }
  setThumbScale(regionId: string, count: number): Promise<ScaleOperation> {
    return this.edgeClient.setRegionThumbScale(regionId, { count, waitSeconds: 1 })
  }
}
