/// Tags populated and sent from edge-data
export interface RtmpStreamTags {
    channelId: number
    streamId: number
}

/// Stats populated and sent from edge-data
export interface RtmpInputStats {
    receiveBitrateKbps: number
}

export enum RtmpOutputState {
    ok = 'ok',
    incompatibleAudioCodec = 'incompatibleAudioCodec',
    incompatibleVideoCodec = 'incompatibleVideoCodec',
}

/// Stats populated and sent from edge-data
export interface RtmpOutputStats {
    state: RtmpOutputState
    restartCount: number
    sendBitrateKbps: number
}
