import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    width: '100%',
  },
  item: {
    padding: 0,
    alignItems: 'baseline',
  },
  title: {
    flexGrow: 1,
    fontSize: theme.typography.h3.fontSize,
  },
  value: {
    fontSize: theme.typography.h1.fontSize,
    fontWeight: 'bold',
    marginLeft: theme.spacing(4),
    whiteSpace: 'nowrap',
  },
}))

interface LineProps {
  title: React.ReactNode
  value: React.ReactNode
}
const Line = ({ title, value }: LineProps) => {
  const classes = useStyles()
  return (
    <ListItem className={classes.item}>
      <Typography component="div" className={classes.title}>
        {title}
      </Typography>
      <Typography component="div" className={classes.value}>
        {value}
      </Typography>
    </ListItem>
  )
}

interface DataSetProps {
  values: Array<{
    title: React.ReactNode
    value: React.ReactNode
  }>
}

/**
 * Simple dataset with short (mostly numbers) values
 * @param values - array of {title, value} objects
 */
const NumericalDataSet = ({ values }: DataSetProps) => {
  const classes = useStyles()
  return (
    <List className={classes.list}>
      {values.map(({ title, value }, ind) => (
        <Line key={ind} title={title} value={value} />
      ))}
    </List>
  )
}

export default NumericalDataSet
