import { useState } from 'react'
import { useFormikContext } from 'formik'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import { Group, GroupRecipientList, GroupRecipientListUpdate } from 'common/api/v1/types'
import { Api } from '../../../store'
import PaginatedList from '../../common/SelfStatePaginatedList'
import { Table } from '../../common/Table'
import { GroupsRequestParams } from '../../../api/nm-types'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minHeight: '48px',
    minWidth: '140px',
  },
}))

const groupListsApi = Api.groupListsApi

const Groups = () => {
  const classes = useStyles()
  const [hideFilter, setHideFilter] = useState(true)
  const { values, setFieldValue } = useFormikContext<
    GroupRecipientList & Pick<GroupRecipientListUpdate, 'removeGroups'>
  >()
  const onRemove = (id: Group['id']) => setFieldValue('removeGroups', values.removeGroups.concat(id))
  const onCancel = (id: Group['id']) =>
    setFieldValue(
      'removeGroups',
      values.removeGroups.filter(groupId => groupId !== id),
    )

  return (
    <Grid item xs={12}>
      <PaginatedList<GroupsRequestParams, Group>
        api={function(params) {
          return groupListsApi.getGroups(values.id, true, params)
        }}
        emptyMessage="no groups"
        hideSearch={hideFilter}
        notFoundMessage="no matching groups"
        onListChange={({ filter, total = 0 }) => {
          setHideFilter(!filter && total < 10)
        }}
        searchPlaceholder="Find specific group…"
        List={({ list }) => (
          <Table
            id="selectgroups-table"
            data={list}
            isSmall
            hasBorders
            noHeader
            config={[
              {
                getValue: ({ name }) => <Typography>{name}</Typography>,
              },
              {
                getValue: ({ id }) => (
                  <div className={classes.actions}>
                    {values.removeGroups.includes(id) ? (
                      <Button className={classes.button} onClick={() => onCancel(id)} variant="outlined" size="small">
                        Cancel remove
                      </Button>
                    ) : (
                      <Tooltip title="Remove from list" placement="top">
                        <IconButton edge="start" aria-label="remove" onClick={() => onRemove(id)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                ),
                props: {
                  align: 'right',
                },
              },
            ]}
          />
        )}
      />
    </Grid>
  )
}

export default Groups
