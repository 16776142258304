import { useState } from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import { Input, Output, OutputOperStatus } from 'common/api/v1/types'
import { Api } from '../../../../store'
import { Paper } from '../../../common/Form'
import PaginatedList from '../../../common/SelfStatePaginatedList'
import { Table } from '../../../common/Table'
import { Link } from '../../../common/Link'
import routes from '../../../../utils/routes'
import { OutputHealthIndicator as HealthIndicator } from '../../../common/Indicator'
import { inputOutputColor } from '../../../../utils'
import { EnrichedOutput, OutputsRequestParams } from '../../../../api/nm-types'
import { getOutputOperState } from 'common/api/v1/helpers'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minHeight: '48px',
    minWidth: '140px',
  },
  name: {
    fontWeight: 'bold',
  },
  paper: {
    padding: theme.spacing(1),
    margin: theme.spacing(0, 0, 2),
    boxShadow: 'none',
    border: `1px solid ${theme.palette.grey['800']}`,
  },
}))

const outputApi = Api.outputApi

interface RecipientsProps {
  input: Input
  onRemove: (output: Output) => void
  onCancel: (outputId: Output['id']) => void
  changes: Array<Output['id']>
}
const Recipients = ({ input, onCancel, onRemove, changes }: RecipientsProps) => {
  const classes = useStyles()
  const [hideFilter, setHideFilter] = useState(true)
  const [total, setTotal] = useState(0)

  return (
    <Paper
      collapsible
      title={`Current recipients${total ? ` (${total})` : ''}`}
      classes={classes.paper}
      collapsed
      titleTypography="h3"
    >
      <Grid item xs={12}>
        <PaginatedList<OutputsRequestParams, EnrichedOutput>
          api={outputApi.getOutputs.bind(outputApi)}
          emptyMessage="no recipients"
          hideSearch={hideFilter}
          notFoundMessage="no matching outputs"
          onListChange={({ filter, total = 0 }) => {
            setHideFilter(!filter && total < 10)
            setTotal(total)
          }}
          otherParams={{ input: input.id }}
          searchPlaceholder="Find specific output…"
          List={({ list }) => (
            <Table
              data={list}
              isSmall
              hasBorders
              noHeader
              config={[
                {
                  getValue: ({ name, adminStatus }) => (
                    <Typography
                      component="div"
                      className={classes.name}
                      data-test-id="output-name"
                      {...inputOutputColor({ adminStatus })}
                    >
                      {name}
                    </Typography>
                  ),
                },
                {
                  getValue: output =>
                    getOutputOperState(output).state === OutputOperStatus.outputError ? (
                      <Link type="button" to={routes.alarms()}>
                        <HealthIndicator outputId={output.id} />
                      </Link>
                    ) : (
                      <HealthIndicator outputId={output.id} />
                    ),
                },
                {
                  getValue: ({ _group }) =>
                    !!_group && (
                      <Typography color="textSecondary" variant="body2">
                        {_group.name}
                      </Typography>
                    ),
                },
                {
                  getValue: output => (
                    <div className={classes.actions}>
                      {changes.includes(output.id) ? (
                        <Button
                          className={classes.button}
                          onClick={() => onCancel(output.id)}
                          variant="outlined"
                          size="small"
                        >
                          Cancel remove
                        </Button>
                      ) : (
                        <Tooltip title="Remove from recipients" placement="top">
                          <IconButton edge="start" aria-label="remove" onClick={() => onRemove(output)}>
                            <HighlightOffIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  ),
                  props: {
                    align: 'right',
                  },
                },
              ]}
            />
          )}
        />
      </Grid>
    </Paper>
  )
}

export default Recipients
