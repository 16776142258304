import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import { RouteComponentProps } from 'react-router-dom'
import { get, isEqual, mergeWith, omit, omitBy, pick } from 'lodash'

import Grid from '@material-ui/core/Grid'

import {
  Appliance,
  CoaxOutputPort,
  CoaxPortMode,
  IpOutputPort,
  Output,
  OutputAdminStatus,
  OutputInit,
  OutputPort,
  OutputRedundancyMode,
  PhysicalPort,
  ZixiLink,
} from 'common/api/v1/types'
import { AppDispatch, GlobalState } from '../../../store'
import { formTransform, useConfirmationDialog } from '../../../utils'
import { clearOutput, createOutput, getOutput, updateOutput } from '../../../redux/actions/outputsActions'

import Pendable from '../../common/Pendable'
import Wrapper from '../../common/Wrapper'
import { getIpPortFormFields } from './PortForm/IpPortForm'
import OutputForm, { initialPort } from './OutputForm'
import { SrtFields } from './PortForm/IpPortForm/SrtForm'
import { getCoaxPortFormFields } from './PortForm/CoaxPortForm'
import { ZixiFields } from './PortForm/IpPortForm/ZixiForm'
import { LinkFields, linkSetDefault } from './PortForm/IpPortForm/ZixiForm/LinksArray'
import { getSettings } from '../../../redux/actions/settingsActions'
import { EnrichedOutputPort, EnrichedOutputWithPorts, EnrichedPhysicalPort } from '../../../api/nm-types'
import { groupPortsByApplianceOrRegion, collectPortsFromInterfaceSections } from '../../common/Interface/Base'

export type OutputPortWithEnrichedPhysicalPortAndAppliance = OutputPort & {
  _port: EnrichedPhysicalPort & { _appliance: Appliance }
}

export type EnrichedOutputWithEnrichedPorts = EnrichedOutputWithPorts & {
  ports: OutputPortWithEnrichedPhysicalPortAndAppliance[]
}

const getInitialState = (
  selectedOutput?: EnrichedOutputWithPorts,
  defaultDelay?: number,
): EnrichedOutputWithEnrichedPorts => {
  const receiver = {
    name: '',
    delay: defaultDelay || '',
    adminStatus: true,
    ports: [],
    upstreamAppliances: [],
    redundancyMode:
      selectedOutput && 'redundancyMode' in selectedOutput
        ? selectedOutput.redundancyMode
        : selectedOutput?.ports[0]?.copies == 2
        ? OutputRedundancyMode.active
        : OutputRedundancyMode.none,
  }
  mergeWith(receiver, omit(selectedOutput, ['metrics', 'alarms']), (_initial, existent, key) => {
    if (key === 'ports') {
      return existent.map((item: EnrichedOutputPort) =>
        mergeWith(
          initialPort({ physicalPortId: item.physicalPort, port: item._port }),
          item,
          (_initial, existent, key) => {
            if ((SrtFields.inputBw === key || SrtFields.maxBw === key) && existent !== undefined)
              return existent / 1000000
            if ((key === ZixiFields.linkSet1 || key === ZixiFields.linkSet2) && existent) {
              return existent.map((link: ZixiLink) => ({
                ...linkSetDefault,
                ...link,
                [LinkFields.rateLimit]: link.rateLimit ? link.rateLimit / 1000 : linkSetDefault.rateLimit,
              }))
            }
          },
        ),
      )
    }
    if (key === '_input') {
      return existent
    }
    if (key === 'adminStatus') {
      return existent === OutputAdminStatus.on
    }
  })

  const portsGroupedByApplianceOrRegion = groupPortsByApplianceOrRegion(receiver.ports)
  return ({ ...receiver, ...portsGroupedByApplianceOrRegion } as unknown) as EnrichedOutputWithEnrichedPorts
}

export const Edit = ({ match }: RouteComponentProps<{ id?: string }>) => {
  const dispatch = useDispatch<AppDispatch>()
  const outputId = match.params.id
  useEffect(() => {
    if (outputId) {
      dispatch(getOutput(outputId))
    } else {
      dispatch(getSettings())
    }

    return () => {
      dispatch(clearOutput())
    }
  }, [dispatch])
  const { selectedOutput, settings } = useSelector(
    ({ outputsReducer, settingsReducer }: GlobalState) => ({
      selectedOutput: outputsReducer.output,
      settings: settingsReducer,
    }),
    shallowEqual,
  )
  const initialState = getInitialState(selectedOutput, settings?.settings?.defaultDelay)
  const setConfirm = useConfirmationDialog()

  const onSubmit = (output: OutputInit | Output, changedPorts: boolean) => {
    if (selectedOutput) {
      const action = () => void dispatch(updateOutput({ output: output as Output, redirect: true }))
      if (selectedOutput && selectedOutput.input && changedPorts) {
        setConfirm(action, 'Current output might be in use. Are you sure you want to edit it?')
      } else {
        action()
      }
    } else {
      dispatch(createOutput({ output, redirect: true }))
    }
  }

  return (
    <Wrapper name="Outputs" entityName={outputId ? get(selectedOutput, 'name') : 'New'}>
      <Grid container spacing={0}>
        <Pendable pending={(!!outputId && !selectedOutput) || (!outputId && settings.loading)}>
          <Formik
            component={OutputForm}
            initialValues={initialState}
            onSubmit={(values, actions) => {
              values.ports = collectPortsFromInterfaceSections(values)
              if (!values.ports.length) {
                actions.setStatus({ port: "Interface can't be blank" })
                actions.setSubmitting(false)
                return
              }
              const changedPorts = !isEqual(initialState.ports, values.ports)
              const transformed = formTransform(values, {
                adminStatus: { _transform: (val: boolean) => (val ? OutputAdminStatus.on : OutputAdminStatus.off) },
                ports: {
                  [SrtFields.inputBw]: {
                    _transform: (bitrate: number | '') => (bitrate === '' ? undefined : bitrate * 1000000),
                  },
                  [SrtFields.maxBw]: {
                    _transform: (bitrate: number | '') => (bitrate === '' ? undefined : bitrate * 1000000),
                  },
                  [ZixiFields.linkSet1]: {
                    [LinkFields.rateLimit]: {
                      _transform: (limit: number | '') => (limit === '' ? undefined : limit * 1000),
                    },
                  },
                  [ZixiFields.linkSet2]: {
                    [LinkFields.rateLimit]: {
                      _transform: (limit: number | '') => (limit === '' ? undefined : limit * 1000),
                    },
                  },
                  _transform: (port: Partial<OutputPort> & { _port: PhysicalPort }) => {
                    const isCoaxPort = port.mode === CoaxPortMode.asi || port.mode === CoaxPortMode.sdi
                    const fields = pick(
                      port,
                      isCoaxPort
                        ? getCoaxPortFormFields(port as CoaxOutputPort)
                        : getIpPortFormFields(port as IpOutputPort),
                    )
                    return omit(fields, ['region.allocatedPort'])
                  },
                },
                _transform: (output: Partial<Output>) => {
                  const o = output as EnrichedOutputWithPorts
                  if (o.redundancyMode != OutputRedundancyMode.none) {
                    const input = o._input
                    if (input) {
                      const inputIsRedundant =
                        input && input.ports && input.ports[0] && input.ports[0].copies && input.ports[0].copies > 1
                      if (!inputIsRedundant) {
                        o.redundancyMode = OutputRedundancyMode.none
                      }
                    }
                  }
                  return omit(
                    omitBy(o, (_value: any, key: string) => key.startsWith('_')),
                    ['appliance', 'object', 'group', 'health'],
                  )
                },
              })

              onSubmit(transformed, changedPorts)
            }}
          />
        </Pendable>
      </Grid>
    </Wrapper>
  )
}
