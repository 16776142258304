import React from 'react'
import { FieldArray, FieldArrayRenderProps, FormikProps, useFormikContext } from 'formik'
import cn from 'classnames'
import { get } from 'lodash'

import Grid from '@material-ui/core/Grid'

import { AudioStream, EncoderFeatures, SupportedAudioCodec, VideoCodec } from 'common/api/v1/types'
import { Paper, Select, TextInput, Checkbox, useStyles } from '../../common/Form'
import AudioStreamForm from './AudioStream'
import { EnrichedInputWithEnrichedPorts } from '.'

const initialAudioStream = {
  codec: '',
  pair: '',
  bitrate: '',
  type: 'stereo',
}

const AudioStreamsPick: React.FunctionComponent<FieldArrayRenderProps & { supportedCodecs: SupportedAudioCodec[] }> = ({
  form,
  name,
  remove,
  supportedCodecs,
}) => {
  const streams = get(form, `values.${name}`)

  return (
    <Grid item xs={12}>
      {streams &&
        streams.length > 0 &&
        streams.map((_: AudioStream, ind: number) => (
          <AudioStreamForm
            supportedCodecs={supportedCodecs}
            key={ind}
            namePrefix={`${name}.${ind}`}
            index={ind}
            remove={remove}
            form={form}
          />
        ))}
    </Grid>
  )
}

interface EncoderSettingsProps {
  audioStreams: Array<AudioStream>
  namePrefix: string
  encoderFeatures?: EncoderFeatures
  setFieldValue: FormikProps<any>['setFieldValue']
}

const EncoderSettings = ({ audioStreams, namePrefix, encoderFeatures, setFieldValue }: EncoderSettingsProps) => {
  const classes = useStyles()
  const { values } = useFormikContext<EnrichedInputWithEnrichedPorts>()
  const chosenVideoCodec = encoderFeatures?.video.codecs.find(c => c.name == values.encoderSettings!.videoCodec)
  const videoFlags = encoderFeatures?.video.flags || []
  const enabledVideoFlags = videoFlags.filter(
    flag => chosenVideoCodec !== undefined && !flag.disabledForCodecs.includes(chosenVideoCodec?.name),
  )
  for (const flag of videoFlags) {
    if (!(flag.value in (values.encoderSettings?.videoFlags || {})) || !enabledVideoFlags.includes(flag)) {
      if (values.encoderSettings) {
        if (!values.encoderSettings.videoFlags) {
          values.encoderSettings.videoFlags = {}
        }
        values.encoderSettings.videoFlags[flag.value] = false
      }
    }
  }
  return (
    <>
      <Paper classes={cn(classes.paper, 'outlined')} title="Encoder Settings" collapsible>
        <Grid item xs={12}>
          <Paper classes={classes.paper}>
            <Select
              label="Video codec"
              name={`${namePrefix}.videoCodec`}
              required
              options={Object.values(VideoCodec)}
            />

            {chosenVideoCodec && chosenVideoCodec.profiles && chosenVideoCodec.profiles.length > 0 && (
              <Select label="Profile" name={`${namePrefix}.profile`} required options={chosenVideoCodec.profiles} />
            )}

            {encoderFeatures?.video.latencyModes && encoderFeatures?.video.latencyModes.length > 0 && (
              <Select
                label="Latency mode"
                name={`${namePrefix}.latencyMode`}
                required
                options={encoderFeatures?.video.latencyModes}
              />
            )}

            {encoderFeatures?.video.scalingModes && encoderFeatures?.video.scalingModes.length > 0 && (
              <Select
                label="Scaling mode"
                name={`${namePrefix}.scalingMode`}
                required
                options={encoderFeatures?.video.scalingModes}
              />
            )}

            <TextInput
              label="Total bitrate (Mbps)"
              name={`${namePrefix}.totalBitrate`}
              required
              type="number"
              noNegative
              validators={{
                number: {
                  greaterThanOrEqualTo: 1,
                  lessThanOrEqualTo: 60,
                  message: `Must be 1 - 60`,
                  noStrings: false,
                },
              }}
            />
            <TextInput
              name={`${namePrefix}.gopSizeFrames`}
              label="GOP Size Frames"
              required
              type="number"
              noNegative
              validators={{
                numericality: {
                  greaterThanOrEqualTo: 1,
                  lessThanOrEqualTo: 500,
                  message: `Must be 1 - 500`,
                  noStrings: false,
                },
              }}
            />

            {values.encoderSettings?.videoFlags &&
              enabledVideoFlags.map(flag => (
                <Checkbox
                  disabled={chosenVideoCodec !== undefined && flag.disabledForCodecs.includes(chosenVideoCodec.name)}
                  key={flag.value}
                  name={`${namePrefix}.videoFlags.${flag.value}`}
                  label={flag.name}
                />
              ))}
          </Paper>
        </Grid>
      </Paper>
      <Paper
        classes={cn(classes.paper, 'outlined')}
        title="Audio Streams"
        collapsible
        actionsPane={
          audioStreams.length < 8
            ? [
                {
                  title: 'Add Audio Stream',
                  onClick: () =>
                    setFieldValue(
                      'encoderSettings.audioStreams',
                      audioStreams.concat(({ ...initialAudioStream } as unknown) as AudioStream),
                    ),
                  id: 'add-audio-btn',
                },
              ]
            : []
        }
      >
        <FieldArray
          name="encoderSettings.audioStreams"
          render={(formikArrayHelpers: FieldArrayRenderProps) => {
            return <AudioStreamsPick {...formikArrayHelpers} supportedCodecs={encoderFeatures?.audio.codecs || []} />
          }}
        />
      </Paper>
    </>
  )
}

export default EncoderSettings
