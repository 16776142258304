import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './Form'
import Button from '@material-ui/core/Button'
import { SerializedError } from '@reduxjs/toolkit'

const ApplicationError = ({ error, onOKButtonClicked }: { error: SerializedError; onOKButtonClicked: () => void }) => {
  const classes = useStyles()
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h2" variant="h2">
          Something went wrong
        </Typography>
        <br />
        <Typography component="h2" variant="body2">
          {error?.message}
        </Typography>
        <br />
        <Button onClick={onOKButtonClicked} variant="contained" color="primary" className={classes.button}>
          Go back
        </Button>
      </div>
    </Container>
  )
}

export default ApplicationError
