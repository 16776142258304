import { debounce } from 'lodash'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { PaginatedRequestParams } from '../../api/nm-types'
import { ListResult } from 'common/api/v1/types'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import Autocomplete from '@material-ui/lab/Autocomplete'

type AutoCompleteProps<T> = {
  /// The label/placeholder text for the auto complete
  placeholder?: string
  api: (params: PaginatedRequestParams<any>) => Promise<ListResult<T>>
  /// Groups the results by the returned string
  groupBy?: (searchResults: T) => string
  /// Initially selected value of the autocomplete
  initialValue: T | null
  /// Callback invoked when user selects/clears a value
  onValueSelected: (value: T | null) => void
  // Used to fill the input field (i.e. the value in the text box) with a string value for the currently selected value
  formatSelectedValue: (value: T) => string
  // Used to render the available options
  renderOption?: (
    option: T,
    state: {
      selected: boolean
      inputValue: string
    },
  ) => ReactNode
  // Used to determine if a single option is disabled or selectable
  isOptionDisabled?: (option: T) => boolean

  /// true to allow the user to clear the selected value
  isClearable: boolean

  dataTestId: string
}

/// A standalone autocomplete component (free from Formik).
export const AutoComplete = <T extends { id: string; name: string }>(props: AutoCompleteProps<T>) => {
  // The value selected by the user, for instance when pressing Enter.
  const [selectedValue, setSelectedValue] = useState<T | null>(props.initialValue)

  // The value displayed in the textbox.
  const [enteredText, setEnteredText] = useState(props.initialValue?.name ?? '')

  // The list-result from the api call
  const [searchResults, setSearchResults] = useState<T[]>([])

  const [isLoading, setIsLoading] = useState(false)

  const debouncedFetch = useCallback(
    debounce(async (request: { input: string }, callback: (results?: T[]) => void) => {
      const { items } = await props.api({ pageNumber: '0', rowsPerPage: '500', filter: request.input })
      callback(items)
    }, 500),
    [],
  )

  useEffect(() => {
    let isMounted = true
    setIsLoading(true)
    const shouldShowAllOptions = enteredText === selectedValue?.name
    debouncedFetch({ input: shouldShowAllOptions ? '' : enteredText }, (results?: T[]) => {
      if (!isMounted) return
      setSearchResults(results ?? [])
      setIsLoading(false)
    })?.catch()
    return () => {
      isMounted = false
    }
  }, [selectedValue, enteredText, debouncedFetch])

  return (
    <Autocomplete
      value={selectedValue}
      getOptionLabel={props.formatSelectedValue}
      onChange={(_event, newValue) => {
        setEnteredText(newValue?.name ?? '')
        setSelectedValue(newValue)
        props.onValueSelected(newValue)
      }}
      inputValue={enteredText}
      onInputChange={(_event, newInputValue, reason) => {
        // Don't update `enteredText` when reason == 'reset' since it then contains
        // the **formatted** name of the selected value, not the actual selected value.
        // E.g. "xxx (appliance software is out of date)" instead of simply "xxx".
        if (['input', 'clear'].includes(reason)) {
          setEnteredText(newInputValue)
        }
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={props.placeholder}
          inputProps={{ ...params.inputProps, 'data-test-id': `${props.dataTestId}-autocomplete-input` }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      disableClearable={!props.isClearable}
      options={searchResults}
      loading={isLoading}
      groupBy={props.groupBy}
      renderOption={props.renderOption}
      getOptionDisabled={props.isOptionDisabled}
      filterOptions={x => x}
      autoHighlight
      fullWidth
      getOptionSelected={(option: T, value: T) => option.id === value.id}
      data-test-id={`${props.dataTestId}-autocomplete`}
      data-num-options={searchResults.length}
    />
  )
}
