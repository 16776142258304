import { Route } from 'react-router-dom'

import routes from '../../utils/routes'
import { List } from './List'
import { Edit } from './Edit'

export const Regions = () => (
  <>
    <Route exact path={routes.regions.route} component={List} />
    <Route exact path={routes.regionsUpdate.route} component={Edit} />
  </>
)
