import React from 'react'

import { Theme, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'

import { Table, TableProps } from './Table'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3, 2, 1),
  },
  right: {
    marginLeft: 'auto',
    '& > *': {
      marginLeft: theme.spacing(2),
    },
  },
}))

export * from './MoveToEntity'
export * from './Table'
export * from './Pagination'

export interface TableContainerProps<TEntity> extends TableProps<TEntity> {
  actions?: Array<React.ReactNode>
}
/**
 * Common container for List entities pages table
 * @param actions - buttons on top of the table (for example create entity)
 * @param rest - common table properties
 */
const TableContainer: <T>(props: TableContainerProps<T>) => React.ReactElement<TableContainerProps<T>> = ({
  actions,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Grid item xs={12}>
      {!!actions && (
        <Toolbar disableGutters>
          <div className={classes.right}>{actions.map(action => action)}</div>
        </Toolbar>
      )}
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Table {...rest} />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default TableContainer
