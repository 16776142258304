import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))

interface DialogTitleProps {
  title?: string
  onClose?: () => void
}
/**
 * Simple title for modal dialogs
 * @param title - title itself
 * @param onClose - callback to call if the 'close' button clicked (hidden if no callback provided)
 */
const DialogTitle = ({ title, onClose }: DialogTitleProps) => {
  const classes = useStyles()
  return (
    <MuiDialogTitle className={classes.root} disableTypography>
      <Typography variant="h2">{title || null}</Typography>
      {!!onClose && (
        <IconButton aria-label="close" onClick={onClose} edge="end">
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  )
}

export default DialogTitle
