import {
  ListOutputSortableField,
  ListResult,
  Output,
  OutputRecipientList,
  OutputRecipientListFilter,
  OutputRecipientListInit,
  OutputRecipientListUpdate,
} from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'
import { PaginatedRequestParams, singleSortQueryFromPaginatedRequestParams } from '../nm-types'

export interface IOutputListApi {
  createOutputList(outputList: OutputRecipientListInit): Promise<OutputRecipientList>
  getOutputs(
    listId: string,
    inList: boolean,
    params: PaginatedRequestParams & { group?: string },
  ): Promise<ListResult<Output>>
  getOutputList(id: OutputRecipientList['id']): Promise<OutputRecipientList>
  getOutputLists(params: PaginatedRequestParams): Promise<ListResult<OutputRecipientList>>
  removeOutputList(id: OutputRecipientList['id']): Promise<OutputRecipientList>
  updateOutputList(id: OutputRecipientList['id'], update: OutputRecipientListUpdate): Promise<OutputRecipientList>
}

export class OutputListApi implements IOutputListApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  createOutputList(outputList: OutputRecipientListInit): Promise<OutputRecipientList> {
    return this.edgeClient.createOutputRecipientList(outputList)
  }
  updateOutputList(id: OutputRecipientList['id'], update: OutputRecipientListUpdate): Promise<OutputRecipientList> {
    return this.edgeClient.updateOutputRecipientList(id, update)
  }
  removeOutputList(id: OutputRecipientList['id']): Promise<OutputRecipientList> {
    return this.edgeClient.deleteOutputRecipientList(id)
  }
  getOutputList(id: OutputRecipientList['id']): Promise<OutputRecipientList> {
    return this.edgeClient.getOutputRecipientList(id)
  }
  async getOutputLists(params: PaginatedRequestParams): Promise<ListResult<OutputRecipientList>> {
    const filter: OutputRecipientListFilter = { searchName: params.filter }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    return this.edgeClient.listOutputRecipientLists(query)
  }

  /**
   * Returns outputs for this outputList
   * @param listId - groupList id
   * @param inList - true to show those outputs that are already in list, false - for others
   * @param params - pagination parameters
   * @param group - we can only add own group's outputs to output lists
   * @param searchName - term to search for
   */
  getOutputs(
    listId: string,
    inList: boolean,
    { group, filter: searchName, ...params }: PaginatedRequestParams<ListOutputSortableField> & { group?: string },
  ): Promise<ListResult<Output>> {
    const filter: OutputRecipientListFilter = { group, searchName }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    return inList
      ? this.edgeClient.listOutputRecipientListOutputs(listId, query)
      : this.edgeClient.listOutputRecipientListOutputsNotInLists(listId, query)
  }
}
