import { AuditFilter, AuditLog, ListResult } from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'
import { AuditLogRequestParams, singleSortQueryFromPaginatedRequestParams } from '../nm-types'

export interface IAuditApi {
  getAudit(params: AuditLogRequestParams): Promise<ListResult<AuditLog>>
}

export class AuditApi implements IAuditApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  getAudit({
    entity,
    entityName,
    fromDate,
    operation,
    toDate,
    username,
    ...params
  }: AuditLogRequestParams): Promise<ListResult<AuditLog>> {
    const filter: AuditFilter = {
      error: false,
      entity,
      entityName,
      fromDate: (fromDate as unknown) as Date,
      operation,
      toDate: (toDate as unknown) as Date,
      username,
    }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    return this.edgeClient.listAuditLogs(query)
  }
}
