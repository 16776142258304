import { ExternalRegionMode, Region } from 'common/api/v1/types'
import { isEditableRegion, usePageParamsFilteredSelector, useRegionsSelector, useUser } from '../../utils'
import Table, { TableConfig, MoveToEntity } from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import Wrapper from '../common/Wrapper'
import routes from '../../utils/routes'

export const List = () => {
  const { regions, total, loading } = usePageParamsFilteredSelector(useRegionsSelector)
  const user = useUser()

  const tableConfig: TableConfig<Region> = [
    {
      title: 'name',
      getValue: ({ name }) => name,
    },
    {
      title: 'type',
      getValue: ({ default_region, external }) => {
        if (default_region) {
          return 'default'
        }
        switch (external) {
          case ExternalRegionMode.external:
            return 'external'
          default:
            return ''
        }
      },
    },
    {
      title: 'Actions',
      getValue: ({ id }) => (
        <MoveToEntity url={routes.regionsUpdate({ id })} disabled={!isEditableRegion(user)} entityName="region" />
      ),
    },
  ]

  return (
    <Wrapper name="Regions">
      <Table<Region>
        emptyMessageComponent={<MissingContent message="No regions available" />}
        config={tableConfig}
        data={regions}
        pending={loading}
        pagination={{ total, useUrlSearchParams: true }}
      />
    </Wrapper>
  )
}
