import { useEffect, useState } from 'react'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

const useStyles = makeStyles({
  form: {
    width: '100%',
    position: 'relative',
  },
  visibilityIcon: {
    position: 'absolute',
    top: '30px',
    right: '10px',
    cursor: 'pointer',
  },
})

/** <TextField> with a visibility toggle icon for type="password" */
export const PasswordField = (props: TextFieldProps & { showPassword?: { show: boolean; id: string } }) => {
  const [showPassword, setShowPassword] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    setShowPassword(Boolean(props.showPassword?.show))
  }, [props.showPassword?.id])

  return (
    <div className={cn(classes.form)}>
      <TextField {...props} type={showPassword ? 'text' : 'password'} />
      <span className={cn(classes.visibilityIcon)} onClick={() => setShowPassword(!showPassword)}>
        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </span>
    </div>
  )
}
