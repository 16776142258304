import { createSlice } from '@reduxjs/toolkit'
import { onThumbnailsFetched } from '../actions/thumbnailActions'
import { InputThumbnailMap, ThumbnailFetcher } from '../../thumbnail/ThumbnailFetcher'

interface State {
  thumbnails: InputThumbnailMap
}
const initialState: State = {
  thumbnails: {},
}

const thumbnailsSlice = createSlice({
  name: 'thumbnails',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      onThumbnailsFetched,
      (state, { payload: inputThumbnailMap }): State => {
        const allThumbs = { ...state.thumbnails, ...inputThumbnailMap }

        for (const inputId of Object.keys(allThumbs)) {
          const thumbnail = allThumbs[inputId]
          if (ThumbnailFetcher.isThumbnailStale(thumbnail)) {
            delete allThumbs[inputId]
          }
        }
        return {
          ...state,
          thumbnails: allThumbs,
        }
      },
    )
  },
})

export default thumbnailsSlice.reducer
