import { useEffect } from 'react'
import cn from 'classnames'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { Paper } from '../../common/Form'
import Wrapper from '../../common/Wrapper'
import Alarms from '../../alarms/Table/Alarms'
import Map, { MapDataType } from '../../geo/Map'

import Availability from './Availability'
import Graph from './Graph'
import { Link } from '../../common/Link'
import routes from '../../../utils/routes'
import { useDispatch } from 'react-redux'
import { setSearchVisibility } from '../../../redux/actions/uiActions'
import { AppDispatch } from 'src/store'

const PAPER_SIDE_SPACING = 2
const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(1, PAPER_SIDE_SPACING, 3, PAPER_SIDE_SPACING),
    overflow: 'hidden',
  },
  stretched: {
    height: '100%',
  },
  alarms: {
    margin: theme.spacing(0, -2),
  },
}))

export const Dashboard = () => {
  const classes = useStyles()
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    dispatch(setSearchVisibility(false))
  }, [dispatch])

  return (
    <Wrapper name="Overview">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} lg={6}>
          <Paper classes={classes.paper}>
            <Map sideMargin={PAPER_SIDE_SPACING} dataType={MapDataType.appliances} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper classes={cn(classes.paper, classes.stretched)} title="Availability">
            <Availability />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper classes={classes.paper}>
            <Graph sideMargin={PAPER_SIDE_SPACING} />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper
            classes={classes.paper}
            title={<Link to={routes.alarms()}>Alarms</Link>}
            containerProps={{ spacing: 0 }}
          >
            <Alarms sideMargin={PAPER_SIDE_SPACING} size={'small'} />
          </Paper>
        </Grid>
      </Grid>
    </Wrapper>
  )
}
