import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { AppDispatch, GlobalState } from '../../../store'
import { Grid } from '@material-ui/core'
import { ButtonsPane, Paper, SafeRouting, useStyles, TextInput } from '../../common/Form'
import { Form, Formik, FormikProps } from 'formik'
import Wrapper from '../../common/Wrapper'
import Pendable from '../../common/Pendable'
import { formTransform, useUser } from '../../../utils/index'
import { ExternalRegionMode, Role } from 'common/api/v1/types'
import Meta from './meta'
import { getRegionDetails, clearRegion, updateRegion } from '../../../redux/actions/regionsActions'
import { RegionDetails } from '../../../api/region/api'
import routes from '../../../utils/routes'

const getInitialState = (regionDetails?: RegionDetails): RegionDetails => ({
  region: {
    id: regionDetails?.region.id ?? '',
    name: regionDetails?.region.name ?? '',
    default_region: regionDetails?.region.default_region ?? true,
    external: ExternalRegionMode.external,
  },
  videoScale: {
    replicas: regionDetails?.videoScale.replicas ?? 0,
    currentReplicas: regionDetails?.videoScale.currentReplicas ?? 0,
  },
  thumbScale: {
    replicas: regionDetails?.thumbScale.replicas ?? 0,
    currentReplicas: regionDetails?.thumbScale.currentReplicas ?? 0,
  },
})

export const Edit = ({ history, match }: RouteComponentProps<{ id: string }>) => {
  const dispatch = useDispatch<AppDispatch>()
  const user = useUser()
  if (user.role !== Role.super) {
    history.goBack()
    return null
  }

  useEffect(() => {
    dispatch(getRegionDetails(match.params.id))
    return () => {
      dispatch(clearRegion())
    }
  }, [dispatch])

  const { regionDetails, loading } = useSelector(
    ({ regionsReducer }: GlobalState) => ({
      regionDetails: regionsReducer.regionDetails,
      loading: regionsReducer.loading,
    }),
    shallowEqual,
  )

  const onSubmit = (regionDetails: RegionDetails) => {
    dispatch(
      updateRegion({
        regionId: regionDetails.region.id,
        regionName: regionDetails.region.name,
        videoScaleCount: regionDetails.videoScale.replicas,
        thumbScaleCount: regionDetails.thumbScale.replicas,
      }),
    )
  }

  return (
    <Wrapper name="Region" entityName={regionDetails?.region.name}>
      <Grid container spacing={0}>
        <Pendable pending={loading}>
          <Formik
            onSubmit={values => {
              onSubmit(formTransform(values))
            }}
            initialValues={getInitialState(regionDetails)}
            component={RegionForm(history)}
          />
        </Pendable>
      </Grid>
    </Wrapper>
  )
}

const RegionForm = (history: RouteComponentProps['history']) => ({
  values,
  dirty,
  isSubmitting,
  setSubmitting,
}: FormikProps<RegionDetails>) => {
  const classes = useStyles()

  const { saving, regionDetails } = useSelector(
    ({ regionsReducer }: GlobalState) => ({
      saving: regionsReducer.saving,
      regionDetails: regionsReducer.regionDetails,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (saving === false) setSubmitting(saving)
  }, [saving])

  return (
    <Grid container>
      <Grid item xs={12}>
        <SafeRouting enabled={dirty && !isSubmitting && Boolean(regionDetails)} />
        <Form id="region-form" translate="no" noValidate>
          <Meta regionDetails={values} />
          <Paper title="Scaling" classes={classes.paper}>
            <TextInput
              label="Video Scale"
              name="videoScale.replicas"
              required
              type="number"
              noNegative
              validators={{
                number: {
                  greaterThanOrEqualTo: 0,
                  message: `Cannot be negative`,
                },
              }}
            />
            <TextInput
              label="Thumb Scale"
              name="thumbScale.replicas"
              required
              type="number"
              noNegative
              validators={{
                number: {
                  greaterThanOrEqualTo: 0,
                  message: `Cannot be negative`,
                },
              }}
            />
          </Paper>
          <ButtonsPane
            main={{
              Cancel: {
                onClick: () => {
                  history.push(routes.regions())
                },
              },
              Save: {
                id: 'button-save',
                savingState: !!saving,
                primary: true,
                type: 'submit',
              },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}
