import React, { Fragment } from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateRows: 'repeat(auto-fill, auto)',
    gridRowGap: theme.spacing(1),
    gridColumnGap: theme.spacing(3),
  },
  title: {
    fontWeight: 'bold',
  },
  value: {
    margin: 0,
  },
}))

interface DataSetProps {
  values: {
    [key: string]: string | number | undefined | React.ReactNode
  }
}

/**
 * Common data set display
 * @param values - map of key=title, value=value to show
 */
const DataSet = ({ values }: DataSetProps) => {
  const classes = useStyles()
  return (
    <dl className={classes.container}>
      {Object.entries(values).map(([key, value], index) => (
        <Fragment key={`${key}-${index}`}>
          <dt>
            <Typography className={classes.title}>{key}</Typography>
          </dt>
          <dd className={classes.value} data-value-for={key}>
            <Typography component="span">{value}</Typography>
          </dd>
        </Fragment>
      ))}
    </dl>
  )
}

export default DataSet
