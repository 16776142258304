import { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import { useDebouncedFilter, usePageParams } from '../../../utils'
import { FilterProps } from './utils'

/**
 * Simple text filter setting page parameters with debounce, and reseting page number
 * @param paramName - parameter to change
 * @param label - input field label to show
 */
const TextInput = ({ paramName, label }: FilterProps) => {
  const [params, setPageParams] = usePageParams()
  const paramValue = params[paramName] || ''
  const [value, setValue] = useState('')
  useEffect(() => {
    setValue(paramValue)
  }, [paramValue])
  useDebouncedFilter(value, debouncedValue =>
    setPageParams({ [paramName]: debouncedValue || undefined, pageNumber: '0' }),
  )

  return (
    <FormControl variant="outlined">
      <TextField variant="outlined" value={value} onChange={({ target }) => setValue(target.value)} label={label} />
    </FormControl>
  )
}

export default TextInput
