import { AllocatedPhysicalPort, AllocatePortRequest, ListResult, PortType, SharedPort } from 'common/api/v1/types'
import { IPortsApi } from './api'
import { MOCK_DELAY_MS, MOCK_PORTS, MOCK_APPLIANCES, MOCK_GROUPS, MOCK_REGIONS } from '../MockData'
import { listResult } from '../../utils'
import { EnrichedPhysicalPort } from '../nm-types'
import * as uuid from 'uuid'

export class MockPortsApi implements IPortsApi {
  updatePort(): Promise<Array<SharedPort>> {
    return new Promise(resolve =>
      setTimeout(() => {
        return resolve(MOCK_APPLIANCES[0].physicalPorts.map(({ id: port, owner }) => ({ port, owner, networks: [] })))
      }, MOCK_DELAY_MS),
    )
  }
  getPort(): Promise<EnrichedPhysicalPort> {
    return new Promise(resolve =>
      setTimeout(() => resolve({ ...MOCK_PORTS[0], _owner: MOCK_GROUPS[0] }), MOCK_DELAY_MS),
    )
  }
  getPorts(): Promise<ListResult<EnrichedPhysicalPort>> {
    return new Promise(resolve =>
      setTimeout(
        () =>
          resolve(
            listResult(
              MOCK_PORTS.map(port => ({
                ...port,
                _owner: MOCK_GROUPS[0],
                _appliance: {
                  ...MOCK_APPLIANCES[0],
                  _owner: MOCK_GROUPS[0],
                },
              })),
            ),
          ),
        MOCK_DELAY_MS,
      ),
    )
  }

  requestRegionalPort(_params: AllocatePortRequest): Promise<AllocatedPhysicalPort> {
    return new Promise(resolve =>
      setTimeout(
        () =>
          resolve({
            id: uuid.v4(),
            physicalPort: {
              id: MOCK_PORTS[0].id,
              name: MOCK_PORTS[0].name,
              addresses: MOCK_PORTS[0].addresses,
              appliance: { ...MOCK_PORTS[0].appliance, region: MOCK_REGIONS[0].id },
              portType: PortType.ip,
            },
            portNumber: 7778,
            createdAt: new Date(),
            expiresAt: new Date(),
          }),
        MOCK_DELAY_MS,
      ),
    )
  }
}
