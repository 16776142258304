import { ListResult } from 'common/api/v1/types'
import { PaginatedRequestParams } from '../../../api/nm-types'

export enum FilterType {
  date = 'date',
  text = 'text',
  select = 'select',
  autocomplete = 'autocomplete',
}
export interface AutocompleteProps<TEntity, TParams extends PaginatedRequestParams> {
  api: (params: TParams) => Promise<ListResult<TEntity>>
  getOptionValue: (item: TEntity) => string
  getOptionLabel: (item: TEntity) => string
}
export interface FilterProps {
  label: string
  paramName: string
  type: FilterType
  options?: Array<{ name: string; value: string }>
  autocomplete?: AutocompleteProps<any, any> // I just gave up, can't type it :(
  minDate?: Date
  maxDate?: Date
  minDateMessage?: string
  maxDateMessage?: string
}
