import Grid from '@material-ui/core/Grid'
import Button, { ButtonProps } from '@material-ui/core/Button'

import { useStyles } from './styles'
import Pendable from '../../common/Pendable'

const getStyle = (primary?: boolean): { color: ButtonProps['color']; variant: ButtonProps['variant'] } =>
  primary ? { color: 'primary', variant: 'contained' } : { color: 'secondary', variant: 'outlined' }

interface ButtonCompProps {
  text: string
  savingState?: boolean
  onClick?: () => void
  primary?: boolean
  id?: string
  type?: 'submit'
}
interface ButtonsPaneProps {
  main: { [key: string]: Omit<ButtonCompProps, 'text'> }
  secondary?: { [key: string]: Omit<ButtonCompProps, 'text'> }
}
/**
 * Button for common paper's button pane
 * @param text - button text
 * @param onClick - function to call
 * @param savingState - if true shows spinner instead
 * @param primary - true for main button (mostly 'save')
 * @param id
 */
const ButtonComp = ({ text, onClick, savingState, primary, id, type }: ButtonCompProps) => {
  const classes = useStyles()
  return (
    <div className={classes.button}>
      <Pendable pending={!!savingState}>
        <Button
          {...getStyle(primary)}
          onClick={onClick}
          id={
            id ||
            `button-${text
              .split(' ')
              .join('-')
              .toLowerCase()}`
          }
          type={type}
        >
          {text}
        </Button>
      </Pendable>
    </div>
  )
}

/**
 * Common Buttons pane on the bottom of the form
 * @param main - set of main buttons on the right side
 * @param secondary - set of optional buttons on the left side
 */
const ButtonsPane = ({ main, secondary }: ButtonsPaneProps) => {
  const classes = useStyles()
  return (
    <Grid container>
      {!!secondary && (
        <Grid item xs={12} sm={6} container className="leftButtonContainer">
          {Object.entries(secondary).map(([text, button], ind) => (
            <ButtonComp key={ind} {...button} text={text} />
          ))}
        </Grid>
      )}
      <Grid item xs={12} sm={secondary ? 6 : 12} container className={classes.buttonContainer}>
        {Object.entries(main).map(([text, button], ind) => (
          <ButtonComp key={ind} {...button} text={text} />
        ))}
      </Grid>
    </Grid>
  )
}

export default ButtonsPane
