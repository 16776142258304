import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Form, FormikProps } from 'formik'
import cn from 'classnames'
import { format } from 'date-fns'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { Appliance, IpPortMode, PhysicalPort, Region, RegionalPort } from 'common/api/v1/types'
import { AppDispatch, GlobalState } from '../../../store'
import { isEditableGroup, pluralizeWord, useConfirmationDialog, useUser } from '../../../utils'

import {
  ButtonsPane,
  Checkbox,
  FormikErrorFocus,
  GridItem,
  Paper,
  SafeRouting,
  TextInput,
  useStyles,
} from '../../common/Form'
import InputPicker from './InputPicker'
import {
  CommonFields,
  ristDefaults,
  rtmpDefaults,
  rtpDefaults,
  srtDefaults,
  udpDefaults,
  zixiDefaults,
} from './PortForm/IpPortForm'
import { coaxDefaults } from './PortForm/CoaxPortForm'
import DataSet from '../../common/DataSet'
import { OutputHealthIndicator } from '../../common/Indicator'
import routes from '../../../utils/routes'
import { Link } from '../../common/Link'
import { EnrichedOutputWithEnrichedPorts } from './index'
import {
  collectInterfaceSectionEntries,
  INTERFACE_SECTION_FORM_PREFIX,
  InterfaceSection,
  InterfaceSectionSelectionData,
  isAppliance,
  isApplianceOrRegionSelectable,
  isCoreNode,
  isRegionalPort,
  makeInterfaceSection,
} from '../../common/Interface/Base'
import { coreNodesList, getCoreNodesOutput } from '../../common/Metadata'
import { removeOutput } from '../../../redux/actions/outputsActions'
import { DEFAULT_BUFFER_DURATION } from 'common/constants'
import { DATE_FORMAT_LONG } from 'common/api/v1/helpers'
import { get, pick } from 'lodash'
import { useHistory } from 'react-router-dom'

export const initialPort = ({
  physicalPortId,
  port,
  enforcedMode,
  allocatedPortId,
}: {
  physicalPortId: string
  port?: PhysicalPort
  enforcedMode?: IpPortMode
  allocatedPortId?: string
}) => ({
  _port: port,
  [CommonFields.mode]: enforcedMode ?? '',
  [CommonFields.physicalPort]: physicalPortId,
  [CommonFields.copies]: 1,
  [CommonFields.allocatedPortId]: allocatedPortId,
  ...srtDefaults,
  ...udpDefaults,
  ...zixiDefaults,
  ...coaxDefaults,
  ...rtpDefaults,
  ...ristDefaults,
  ...rtmpDefaults,
})

const makeMissingInterfaceSections = (
  values: EnrichedOutputWithEnrichedPorts,
  requiredEntities: (
    | Pick<Appliance, 'id' | 'name' | 'type'>
    | (Pick<Region, 'id' | 'name'> & { appliance: Pick<Appliance, 'id' | 'name' | 'type'> })
  )[],
) => {
  const currentEntries = collectInterfaceSectionEntries(values)
  const missingInterfaceSection = requiredEntities
    .map(requiredApplianceOrRegion => {
      const isRegion = 'appliance' in requiredApplianceOrRegion
      const requiredAppliance = isRegion ? requiredApplianceOrRegion.appliance : requiredApplianceOrRegion
      const alreadyExists = !!currentEntries.find(([_, existingEntry]) => {
        if (isRegion) {
          return (
            existingEntry.region?.id === requiredApplianceOrRegion.id &&
            existingEntry.appliance?.id === requiredAppliance.id
          )
        }
        return existingEntry.appliance?.id === requiredAppliance.id
      })
      if (!alreadyExists) {
        const interfaceSection = makeInterfaceSection({
          region: isRegion ? requiredApplianceOrRegion : undefined,
          appliance: requiredAppliance, // Provide appliance AND region to enforce the appliance.
        })
        currentEntries.push(interfaceSection)
        return interfaceSection
      }
    })
    .filter(Boolean) as Array<[string, InterfaceSectionSelectionData<any>]>

  return missingInterfaceSection
}

const OutputForm = (propsForOutputsForm: FormikProps<EnrichedOutputWithEnrichedPorts>) => {
  const {
    values,
    initialValues,
    setStatus,
    setValues,
    setFieldValue,
    dirty,
    isSubmitting,
    setSubmitting,
  } = propsForOutputsForm
  const classes = useStyles()
  const { formErrors } = useSelector(({ outputsReducer }: GlobalState) => outputsReducer, shallowEqual)
  const isSaving = useSelector(({ outputsReducer }: GlobalState) => outputsReducer.saving, shallowEqual)
  const user = useUser()
  const history = useHistory()
  const dispatch = useDispatch<AppDispatch>()
  const setConfirm = useConfirmationDialog()

  useEffect(() => {
    setStatus(
      Array.isArray(formErrors)
        ? formErrors.reduce(
            (acc, item) => ({
              ...acc,
              [item.name]: item.reason,
            }),
            {},
          )
        : {},
    )
  }, [formErrors])

  useEffect(() => {
    if (isSaving === false) setSubmitting(false)
  }, [isSaving])

  const deleteOutput = () => void dispatch(removeOutput({ output: values, redirect: true }))
  const secondaryButton = values.id
    ? {
        'Delete output': {
          onClick: () => {
            setConfirm(deleteOutput, `Deleting output "${values.name}". Are you sure?`)
          },
        },
      }
    : undefined

  const coreNodes = getCoreNodesOutput(values)
  const input = get(values, ['_input']) as EnrichedOutputWithEnrichedPorts['_input']
  const hasMultiApplianceInput = (input?.appliances?.length ?? 0) > 1
  const inputApplianceAndRegions: Array<
    | Pick<Appliance, 'id' | 'name' | 'type'>
    | (Pick<Region, 'id' | 'name'> & { appliance: Pick<Appliance, 'id' | 'name' | 'type'> })
  > = (() => {
    if (isRegionalPort(input?.ports?.[0])) {
      return (input?.ports ?? []).map((p: RegionalPort) => ({
        id: p.region!.id,
        name: p.region!.name,
        appliance: p.region!.allocatedPort!.appliance,
      }))
    }
    return (input?.appliances ?? []).map(a => pick(a, ['id', 'name', 'type']))
  })()

  useEffect(() => {
    const didSelectDifferentInput = initialValues.input !== values.input
    if (didSelectDifferentInput && hasMultiApplianceInput) {
      // Temporary constraint: An output with a multi-appliance input must have the same appliances/regions as the input.
      // 1. Go through all current entries
      const newValues = Object.entries(values)
        .filter(([key, value]) => {
          const isInterfaceSection = key.startsWith(INTERFACE_SECTION_FORM_PREFIX)
          if (!isInterfaceSection) {
            // 2. Keep all non-interface section values
            return true
          }
          const existingInterfaceSection = value as InterfaceSectionSelectionData<any>
          const interfaceIsLocatedOnInputAppliance = inputApplianceAndRegions.find(requiredApplianceOrRegion => {
            const isRegion = 'appliance' in requiredApplianceOrRegion
            const requiredAppliance = isRegion ? requiredApplianceOrRegion.appliance : requiredApplianceOrRegion
            if (isRegion) {
              return (
                existingInterfaceSection.region?.id === requiredApplianceOrRegion.id &&
                existingInterfaceSection.appliance?.id === requiredAppliance.id
              )
            }
            return existingInterfaceSection.appliance?.id === requiredAppliance.id
          })
          // 3. Keep all current interface sections that are already on the input appliance,
          // i.e., remove all interface sections on other appliances.
          return interfaceIsLocatedOnInputAppliance
        })
        .reduce((newValues, [key, value]) => {
          newValues[key] = value
          return newValues
        }, {} as any)

      // 4. Add all missing interface sections
      for (const [key, value] of makeMissingInterfaceSections(newValues, inputApplianceAndRegions)) {
        newValues[key] = value
      }
      setValues(newValues)
    }
  }, [values.input, hasMultiApplianceInput])

  const onRemoveInputAppliance = (key: string) => setFieldValue(key, undefined)
  const initialInterfaceSections = collectInterfaceSectionEntries(initialValues).map(([_k, value]) => value)
  const interfaceSectionEntries = collectInterfaceSectionEntries(values)
  const enforcedPortMode =
    interfaceSectionEntries.length > 1 // Don't allow mixing of different port modes
      ? interfaceSectionEntries.flatMap(([_, data]) => data.ports.map(p => p.mode)).find(mode => !!mode)
      : undefined
  const interfaceSections = interfaceSectionEntries.map(([key, value], index) => {
    // Lock mode-select if 2 interfaces have been added or if this is the second appliance of a multi-appliance output
    const isModeSelectionDisabled = value.ports.length > 1 || index > 0
    return (
      <InterfaceSection<EnrichedOutputWithEnrichedPorts>
        key={key}
        namePrefix={key}
        isModeSelectionDisabled={isModeSelectionDisabled}
        enforcedPortMode={enforcedPortMode}
        index={index}
        initialApplianceOrRegionId={
          initialInterfaceSections[index]?.region?.id ?? initialInterfaceSections[index]?.appliance?.id
        }
        title={`Output appliance #${index + 1}`}
        onRemove={interfaceSectionEntries.length > 1 ? onRemoveInputAppliance : undefined}
        outputId={values.id}
        inputId={values.input}
        isInputForm={false}
        isEditingExistingEntity={!!values.id}
        isCopyingExistingEntity={false}
        isApplianceOrRegionSelectable={option => {
          if (hasMultiApplianceInput) {
            const alreadySelectedApplianceIds = interfaceSectionEntries
              .map(([_, value]) => value.appliance?.id)
              .filter(Boolean) as string[]

            // Temporary constraint: Only allow the same appliances and regions as the input if the user has selected a multi-appliance-input
            const isInputApplianceOrRegion = !!inputApplianceAndRegions.find(a => a.id === option.id)
            if (isAppliance(option)) {
              return isInputApplianceOrRegion && !alreadySelectedApplianceIds.includes(option.id)
            } else {
              const inputRegions = inputApplianceAndRegions.filter(aor => 'appliance' in aor)
              const inputRegionIds = Array.from(new Set(inputApplianceAndRegions.map(r => r.id)))
              if (inputRegionIds.length === 1) {
                // Input has 1 region (but may have several different appliances in that region, e.g. R1A1 + R1A2) - only allow selection of that region.
                return option.id === inputRegionIds[0]
              } else {
                // Input has 2 regions, e.g. R1A1 + R2A2. Don't allow selection of the same region again (since that will result in allocation of an interface on a different appliance in that region).
                for (const inputRegion of inputRegions) {
                  const hasAlreadySelectedApplianceInSelectedRegion =
                    inputRegion.id === option.id &&
                    alreadySelectedApplianceIds.includes((inputRegion as any).appliance.id)
                  if (hasAlreadySelectedApplianceInSelectedRegion) {
                    return false
                  }
                }
                return isInputApplianceOrRegion
              }
            }
          }
          return isApplianceOrRegionSelectable(option, values)
        }}
        onApplianceOrRegionSelected={selected => {
          setStatus({})
          if (!selected) {
            const [_, emptySection] = makeInterfaceSection({ region: undefined, appliance: undefined })
            setFieldValue(key, emptySection)
          } else if (isAppliance(selected)) {
            const [_, applianceSection] = makeInterfaceSection({ region: undefined, appliance: selected })
            setFieldValue(key, applianceSection)
          } else {
            if (hasMultiApplianceInput) {
              // Temporary constraint: Only allow the same appliances and regions as the input if the user has selected a multi-appliance-input
              const selectedInputRegion = inputApplianceAndRegions.find(
                aor => 'appliance' in aor && aor.id === selected.id,
              ) as (Pick<Region, 'id' | 'name'> & { appliance: Pick<Appliance, 'id' | 'name' | 'type'> }) | undefined
              if (!selectedInputRegion) throw 'Selected a disallowed region'
              const [_, regionWithEnforcedAppliance] = makeInterfaceSection({
                region: selectedInputRegion,
                appliance: selectedInputRegion.appliance,
              })
              setFieldValue(key, regionWithEnforcedAppliance)
            } else {
              const [_, regionalSection] = makeInterfaceSection({
                region: selected,
                appliance: undefined,
              })
              setFieldValue(key, regionalSection)
            }
          }
        }}
        {...propsForOutputsForm}
      />
    )
  })
  return (
    <Grid container data-test-output-id={`${values.id || ''}`}>
      <Grid item xs={12}>
        <SafeRouting enabled={dirty && !isSubmitting} />
        <Form id="outputs-form" translate="no" noValidate>
          <Paper classes={cn(classes.paper, 'outlined')} title="Meta data" collapsible>
            <Grid item xs={12}>
              <Paper classes={classes.paper}>
                <TextInput name="name" label="Output name" required autoFocus />
                <TextInput
                  name="delay"
                  label="Delay (ms)"
                  type="number"
                  tooltip={`Total end-to-end delay from the time the stream is ingested in the Edge input until it is delivered to the Edge output.`}
                  noNegative
                  required
                  validators={{
                    number: {
                      greaterThanOrEqualTo: 0,
                      lessThanOrEqualTo: DEFAULT_BUFFER_DURATION,
                      message: `Must be 0 - ${DEFAULT_BUFFER_DURATION}`,
                    },
                  }}
                />
                <Checkbox name="adminStatus" label="Enabled" />
              </Paper>

              {!!values.id && (
                <Paper classes={classes.paper}>
                  <GridItem lg={12} xl={12}>
                    <DataSet
                      values={{
                        Id: values.id,
                        Created: format(new Date(values.createdAt), DATE_FORMAT_LONG),
                        Updated: format(new Date(values.updatedAt), DATE_FORMAT_LONG),
                        Status: <OutputHealthIndicator outputId={values.id} inline />,
                        Owner: !!values._group?.id && (
                          <Link
                            to={routes.groupsUpdate({ id: values._group.id })}
                            underline="hover"
                            available={isEditableGroup(values._group.id, user)}
                          >
                            {values._group?.name}
                          </Link>
                        ),
                        [`Core ${pluralizeWord(coreNodes.length, 'node')}`]: coreNodesList(coreNodes, user),
                      }}
                    />
                  </GridItem>
                </Paper>
              )}
            </Grid>
          </Paper>

          {interfaceSections}

          {/* Only allow adding a second output appliance if the first one is a core node */}
          {interfaceSections.length < 2 && isCoreNode(values) && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                if (hasMultiApplianceInput) {
                  // Temporary constraint: An output with a multi-appliance input must have the same appliances/regions as the input.
                  const missingSections = makeMissingInterfaceSections(values, inputApplianceAndRegions)
                  if (missingSections.length > 0) {
                    const [key, value] = missingSections[0]
                    setFieldValue(key, value)
                  }
                } else {
                  const [key, value] = makeInterfaceSection({
                    region: undefined,
                    appliance: undefined,
                  })
                  setFieldValue(key, value)
                }
              }}
            >
              Add output appliance
            </Button>
          )}

          <div id="LinksArrayContainer" />

          <InputPicker form={propsForOutputsForm} />

          <ButtonsPane
            main={{
              Cancel: {
                onClick: () => {
                  history.push(routes.outputs())
                },
              },
              Save: { primary: true, savingState: isSaving, type: 'submit' },
            }}
            secondary={secondaryButton}
          />
          <FormikErrorFocus />
        </Form>
      </Grid>
    </Grid>
  )
}

export default OutputForm
